import { UseFormRegister } from 'react-hook-form';
import { FormDataMyPriceType } from '../../types/formDataMyPriceType';
import { formInputConfigType } from '../../types/formInputConfigType';

interface InputMyPriceProps {
  content: formInputConfigType;
  register: UseFormRegister<FormDataMyPriceType>;
}

export const InputMyPrice = ({ content, register }: InputMyPriceProps) => {
  return (
    <div className="form-my-price__item">
      <input
        required
        className="form-my-price__input"
        type={content.type}
        placeholder={content.placeholder}
        {...register(content.type as keyof FormDataMyPriceType)}
      />
    </div>
  );
};
