import React from 'react';
import { Link } from 'react-router-dom';
import { SvgIconStar } from '../../../../../ui/SvgIcon';
import { ListTextType } from '../types/ListTextType';

interface InSixtySecondsItemProps {
  item: ListTextType;
}

export const InSixtySecondsItem: React.FC<InSixtySecondsItemProps> = ({
  item,
}) => {
  const { text, link } = item;

  return (
    <li className="list__item">
      {link ? (
        <Link className="list__link" to={link}>
          <div className="list__svg">
            <SvgIconStar />
          </div>
          <span className="list__text">{text}</span>
        </Link>
      ) : (
        <>
          <div className="list__svg">
            <SvgIconStar />
          </div>
          <span className="list__text">{text}</span>
        </>
      )}
    </li>
  );
};
