/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { BookingField } from '../../../types/bookingFieldset';
import { getChoosedCleaningService } from '../helpers/getChoosedValue';
import {
  CleaningInformationEntity,
  CleaningService,
} from '../../../../../types';
import { useBookingFormData } from '../../../../../hooks/useBookingFormData';
import { BookingSelectInput } from '../../../ui/BookingSelectInput';

interface BookingCleaningServiceProperties {
  register: UseFormRegister<BookingField>;
  errors: FieldErrors<BookingField>;
  cleaningServices: CleaningService[];
}

export const BookingCleaningService: React.FC<
  BookingCleaningServiceProperties
> = ({ register, cleaningServices, errors }) => {
  const { setCleaningInformation } = useBookingFormData();
  const [isPlaceholderDisabled, setIsPalceholderDisabled] =
    useState<boolean>(false);

  const handleCleaningChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedCleaning = getChoosedCleaningService(
      event,
      cleaningServices
    ) as CleaningService;
    setIsPalceholderDisabled(true);
    const newCleaningInformation: CleaningInformationEntity = {
      id: selectedCleaning.id,
      name: selectedCleaning.name,
      price: selectedCleaning.price,
      discountByCode: selectedCleaning.discountByCode,
      discountByFrequency: selectedCleaning.discountByFrequency,
      cleaningExtras: [],
      cleaningParameters: [],
    };
    setCleaningInformation(newCleaningInformation);
  };

  return (
    <label
      className="fieldset__label fieldset__label_centerd"
      htmlFor="cleaningType"
    >
      <BookingSelectInput
        name="cleaningType"
        errors={errors}
        register={register}
        isPlaceholderDisabled={isPlaceholderDisabled}
        handleOptionChange={handleCleaningChange}
        options={cleaningServices}
      />
      <span className="fieldset__error-message">
        {errors.cleaningType ? errors.cleaningType?.message : ' '}
      </span>
    </label>
  );
};
