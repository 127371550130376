import book from '../../../assets/three-easy-steps/couple-on-this-computer-booking-a-cleaning-service.jpg';
import cleaner from '../../../assets/three-easy-steps/house-cleaners-by-crystal-clear.jpg';
import relax from '../../../assets/three-easy-steps/happy-family-enjoying-a-clean-home.jpg';
import { ThreeEasyStepsType } from './ThreeEasyStepsType';

export const ThreeEasyStepsDataBook: ThreeEasyStepsType = {
  img: book,
  title: 'Book online',
  desc: 'Customize and book your cleaning online. 48hr cancellation policy',
};

export const ThreeEasyStepsDataCleaner: ThreeEasyStepsType = {
  img: cleaner,
  title: 'Leave the stress to us',
  desc: 'Your home cleaned by a professional and background checked cleaner',
};

export const ThreeEasyStepsDataRelax: ThreeEasyStepsType = {
  img: relax,
  title: 'Enjoy what matters',
  desc: 'Rate your cleaning, relax, and prioritize what truly matters in Life',
};
