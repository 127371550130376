import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { BookingText } from '../../ui/BookingText';
import { BookingField } from '../../types/bookingFieldset';
import { BookingCleaningService } from './ui/BookingCleaningService';
import { BookingCleaningParameters } from './ui/BookingCleaningParameters';
import { CleaningService } from '../../../../types';

interface BookingCleaningTypesProperties {
  register: UseFormRegister<BookingField>;
  errors: FieldErrors<BookingField>;
  setValue: UseFormSetValue<BookingField>;
  cleaningServices: CleaningService[];
}

export const BookingCleaningTypes: React.FC<BookingCleaningTypesProperties> = ({
  register,
  setValue,
  errors,
  cleaningServices,
}) => {
  return (
    <fieldset className="fieldset">
      <div className="fieldset__text-wrapper">
        <BookingText
          text="Step 3 of 5: What type of cleaning do you need?"
          option="title"
        />
        <BookingText
          text="House cleaning package: a top-to-bottom cleaning for your home following our checklist shown here. Enter total number of bedrooms, bathrooms, and the total square footage of the home."
          option="description"
        />
        <BookingText
          text="House cleaning by the hour: a time-limited cleaning best for cleaning specific areas of the home. (Note: the hourly clean will not guarantee full coverage of your home)."
          option="description"
        />
        <BookingCleaningService
          register={register}
          errors={errors}
          cleaningServices={cleaningServices}
        />
      </div>
      <BookingCleaningParameters
        cleaningServices={cleaningServices}
        setValue={setValue}
        register={register}
      />
    </fieldset>
  );
};
