export const ServicesNotOffered = [
  'Cleaning of animal feces',
  'Mold',
  'Exterior of the home (garage, patio, balcony)',
  'Moving heavy furniture or fragile items',
  'Crawl spaces',
  'Bodily fluids',
  'Pest removal/infestation',
  'Dishes',
  'Full wash of ceilings and walls',
];
