import { Link } from 'react-router-dom';
import { companyInfo, paths } from '../../../../constants';
import { getFormattedPhoneNumber } from '../../../../helpers';

import './styles/HeroSectionActionsBtn.scss';

export const HeroSectionActionsBtn = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);

  return (
    <div className="actions-btn">
      <Link className="actions-btn__book" to={paths.book}>
        Book online
      </Link>
      <a className="actions-btn__call" href={`tel:${formattedPhone}`}>
        {companyInfo.phoneNumber}
      </a>
    </div>
  );
};
