import { ContainerComponent } from '../../ui/ContainerComponent';
import { FormMyPrice } from '../../components/FormMyPrice';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { breakpoints } from '../../constants';
import { getAltTextForImage } from '../../helpers';
import { Title } from '../../ui/MainTitle';

import happyCustomer from '../../assets/reviews-page-happy-customer/a happy woman clean an oven on her knees smiling1024x1024.jpg';
import cleaners from '../../assets/reviews-page-happy-customer/home-cleaners-services-by-crystalclear-canada.jpg';
import './styles/ReviewsPageHappyCustomer.scss';

export const ReviewsPageHappyCustomer = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <section className="happy-customer">
      <ContainerComponent>
        <div className="happy-customer__wrapper">
          <div className="happy-customer__content">
            <Title
              text="We want YOU to be our next Happy Customer! 💛"
              type="h2"
              color="black"
              isCentered
            />
            <p className="happy-customer__desc">
              24 hr Cancellation Policy, Quality Cleaning, 100% Satisfaction
              Guaranteed
            </p>
            <div className="happy-customer__form">
              <FormMyPrice />
            </div>
          </div>
          {isMobile ? (
            <div className="happy-customer__img-mobile">
              <img alt={getAltTextForImage(cleaners)} src={cleaners} />
            </div>
          ) : (
            <div className="happy-customer__img">
              <img
                alt={getAltTextForImage(happyCustomer)}
                src={happyCustomer}
              />
            </div>
          )}
        </div>
      </ContainerComponent>
    </section>
  );
};
