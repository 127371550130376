import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import CompareImage from 'react-compare-image';
import { BeforeAfterImg } from '../constants/BeforeAfterImg';
import { breakpoints, colorPalette } from '../../../constants';
import { useWidthWindow } from '../../../hooks/useWidthWindows';

export const BeforeAfterSwiper = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  const imageStyles = {
    width: '100%',
    height: '100%',
    objectFit: 'cover' as const,
  };
  return (
    <Swiper
      effect="coverflow"
      allowTouchMove={false}
      grabCursor
      centeredSlides
      slidesPerView={isMobile ? 1 : 3}
      coverflowEffect={
        isMobile
          ? undefined
          : {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }
      }
      loop
      navigation
      modules={[...(isMobile ? [Navigation] : [EffectCoverflow, Navigation])]}
    >
      {BeforeAfterImg.map((img) => {
        return (
          <SwiperSlide key={img.before} className="swiper__slide">
            <CompareImage
              leftImage={img.before}
              rightImage={img.after}
              sliderLineWidth={2}
              sliderLineColor={colorPalette.accentColor}
              leftImageCss={imageStyles}
              rightImageCss={imageStyles}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
