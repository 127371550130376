import React from 'react';
import { CleaningInstructionsEntity } from '../../../../../types';

interface CleaningInstructionsProps {
  cleaningIntstructions: CleaningInstructionsEntity | undefined;
}

export const CleaningInstructions: React.FC<CleaningInstructionsProps> = ({
  cleaningIntstructions,
}) => {
  if (!cleaningIntstructions) {
    return null;
  }

  return (
    <div className="dashboard-section-item__block cleaning-instructions">
      <h3 className="dashboard-section-item__title">Special Instructions</h3>
      <div className="dashboard-section-item__instructions dashboard-section-item__element__wrapper_grey">
        <p className="dashboard-section-item__instructions__title">
          Cleaning Instructions:
        </p>
        <p className="dashboard-section-item__instructions__desc">
          {cleaningIntstructions?.instructions}
        </p>
      </div>
      <div className="dashboard-section-item__instructions">
        <p className="dashboard-section-item__instructions__title">
          Home details:
        </p>
        <p className="dashboard-section-item__instructions__desc">
          {cleaningIntstructions?.home}
        </p>
      </div>
      <div className="dashboard-section-item__instructions dashboard-section-item__element__wrapper_grey">
        <p className="dashboard-section-item__instructions__title">Parking:</p>
        <p className="dashboard-section-item__instructions__desc">
          {cleaningIntstructions?.parking}
        </p>
      </div>
      <div className="dashboard-section-item__instructions">
        <p className="dashboard-section-item__instructions__title">Garage:</p>
        <p className="dashboard-section-item__instructions__desc">
          {cleaningIntstructions?.garbage}
        </p>
      </div>
      <div className="dashboard-section-item__instructions dashboard-section-item__element__wrapper_grey">
        <p className="dashboard-section-item__instructions__title">
          Restriction:
        </p>
        <p className="dashboard-section-item__instructions__desc">
          {cleaningIntstructions?.restriction}
        </p>
      </div>
      <div className="dashboard-section-item__instructions">
        <p className="dashboard-section-item__instructions__title">
          Flexibility details:
        </p>
        <p className="dashboard-section-item__instructions__desc">
          {cleaningIntstructions?.flexibility}
        </p>
      </div>
      <div className="dashboard-section-item__instructions dashboard-section-item__element__wrapper_grey">
        <p className="dashboard-section-item__instructions__title">Occasion:</p>
        <p className="dashboard-section-item__instructions__desc">
          {cleaningIntstructions?.occasion}
        </p>
      </div>
      <div className="dashboard-section-item__instructions">
        <p className="dashboard-section-item__instructions__title">About us:</p>
        <p className="dashboard-section-item__instructions__desc">
          {cleaningIntstructions?.aboutUs}
        </p>
      </div>
    </div>
  );
};
