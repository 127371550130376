import { Link } from 'react-router-dom';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';

export const ScarboroughPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Your Premier Cleaning Service in Scarborough"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            At Crystal Clear, we know that a clean space is essential for a
            happy and productive life, and we’re excited to bring our expert
            cleaning services to Scarborough. Whether you’re in{' '}
            <span className="page-description__text_bold">Guildwood</span>,{' '}
            <span className="page-description__text_bold">Agincourt</span>, or{' '}
            <span className="page-description__text_bold">Cliffcrest</span>, our
            dedicated team is ready to make your space shine. We proudly serve{' '}
            <span className="page-description__text_bold">Birch Cliff</span>,{' '}
            <span className="page-description__text_bold">
              Scarborough Village
            </span>
            ,{' '}
            <span className="page-description__text_bold">Highland Creek</span>,
            and <span className="page-description__text_bold">Malvern</span>{' '}
            because everyone in Scarborough deserves a spotless home or office.
          </p>
          <p className="page-description__text">
            From <span className="page-description__text_bold">West Hill</span>{' '}
            to <span className="page-description__text_bold">Rouge</span>{' '}
            <span className="page-description__text_bold">Woburn</span> to{' '}
            <span className="page-description__text_bold">Morningside</span>,
            and <span className="page-description__text_bold">Dorset Park</span>{' '}
            to <span className="page-description__text_bold">L’Amoreaux</span>,
            we’ve got all of Scarborough covered. We make it easy to customize
            your cleaning plan, whether you need a deep clean or regular upkeep.
            Booking? It’s a breeze with our seamless online system. And if your
            plans change, our{' '}
            <span className="page-description__text_bold">
              24-hour cancellation
            </span>{' '}
            policy has you covered.
          </p>
          <p className="page-description__text">
            Our fully vetted, insured, and expertly trained team will treat your
            space with respect. If you’re not completely satisfied, we’ll make
            it right.
          </p>
          <p className="page-description__text">
            Ready to elevate the cleanliness of your Vaughan home or business?
            Let’s get started!{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning
            </Link>{' '}
            today—visit our website or give us a call{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>
            . We can’t wait to bring a new level of cleanliness to your home or
            office!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
