import { listItems } from '../constants/listItems';
import { DescriptionItem } from './DescriptionItem';

export const DescriptionList = () => {
  return (
    <ol className="description__list">
      {listItems.map((text) => {
        return <DescriptionItem key={text} text={text} />;
      })}
    </ol>
  );
};
