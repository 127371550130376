export const formatDateTime = (date: Date): string => {
  const formattedDate = date.toLocaleDateString('en-US');

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };

  const startTime = date.toLocaleTimeString('en-US', options);

  const endDate = new Date(date);
  endDate.setHours(date.getHours() + 1);

  const endTime = endDate.toLocaleTimeString('en-US', options);

  return `${formattedDate} @ ${startTime} - ${endTime}`;
};
