import React from 'react';
import { RatingStars } from '../../helpers/RatingStars';
import { ReviewsPageDataType } from '../../types/ReviewsPageDataType';
import { getAltTextForImage } from '../../../../helpers';

import './styles/ReviewsPageItem.scss';

interface ReviewsPageItemProps {
  content: ReviewsPageDataType;
}

export const ReviewsPageItem: React.FC<ReviewsPageItemProps> = ({
  content,
}) => {
  const { img, name, rating, desc, icon } = content;
  const altText = getAltTextForImage(img);
  return (
    <div className="reviews-page-item">
      <div className="reviews-page-item__title">
        <div className="reviews-page-item__img">
          <img alt={altText} src={img} />
        </div>
        <p className="reviews-page-item__title__text">{name}</p>
      </div>
      <div className="reviews-page-item__rating">
        <RatingStars rating={rating} />
      </div>
      <p className="reviews-page-item__desc">{desc}</p>
      <div className="reviews-page-item__icon">{icon}</div>
    </div>
  );
};
