import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const UxbridgePageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Welcome to Crystal Clear - Your Go-To House Cleaning Service in Uxbridge"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Introducing Crystal Clear – your premier cleaning service for
            Uxbridge, tailored to meet the diverse needs of homeowners,
            landlords, and business owners across the city. With Crystal Clear,
            you can effortlessly customize your cleaning plans to fit your
            specific requirements. Our seamless online booking process makes
            scheduling a breeze, and with our flexible{' '}
            <span className="page-description__text_bold">
              24-hour cancellation policy
            </span>{' '}
            , you can adjust your plans without any stress or penalties. Our
            team of professional cleaners is fully insured, vetted, and
            committed to delivering excellence. We back our work with a{' '}
            <span className="page-description__text_bold">
              satisfaction guarantee
            </span>
            —if you’re not completely satisfied, we’ll re-clean until everything
            meets your expectations. Elevate your living or working environment
            with Crystal Clear’s exceptional cleaning services for{' '}
            <span className="page-description__text_bold">Uxbridge.</span>{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning online
            </Link>{' '}
            or call us today at{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>
            to experience the Crystal Clear difference!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
