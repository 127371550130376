import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import { PageTitleBackground } from '../modules/PageTitleBackground';
import { PrivacyPolicySection } from '../modules/PrivacyPolicySection';
import { MainWrapper as Main } from '../ui/MainWrapper';

export const PrivacyPolicyPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="privacyPolicy" />
      <PageTitleBackground title="Privacy Policy" />
      <PrivacyPolicySection />
    </Main>
  );
};
