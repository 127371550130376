import { paths } from '../../../constants/paths';
import { HelpfulLinks } from '../types/helpfulLinksType';

export const helpfulLinks: HelpfulLinks[] = [
  {
    name: 'Areas',
    path: paths.areas.path,
  },
  {
    name: 'Reviews',
    path: paths.reviews,
  },
  {
    name: 'Contact Us',
    path: paths.contactUs,
  },
  {
    name: 'Customer Login',
    path: paths.login,
  },
  {
    name: 'Book Now',
    path: paths.book,
  },
  {
    name: 'Gift Cards',
    path: paths.giftCards,
  },
];
