import { StepCardsType } from '../types/stepCardsType';
import booking from '../../../assets/how-we-work/couple-booking-house-cleaning-services.jpg';
import cleaning from '../../../assets/how-we-work/house-cleaning-ladies.jpg';
import happy from '../../../assets/how-we-work/family-enjoying-their-clean-home-after-booking-with-crystalclear.jpg';

export const stepsCardData: StepCardsType[] = [
  {
    title: '1. Book Online',
    description:
      'Customize and book your cleaning service online. 24hr cancellation policy',
    pathToImage: booking,
    alt: 'couple booking house cleaning services',
  },
  {
    title: '2. We Clean',
    description:
      'Your home cleaned by a professional and background checked cleaner',
    pathToImage: cleaning,
    alt: 'house cleaning ladies',
  },
  {
    title: '3. You Relax',
    description:
      'Rate your cleaning, relax, and enjoy what matters most in Life',
    pathToImage: happy,
    alt: 'family enjoying their clean home after booking with crystalclear',
  },
];
