/* eslint-disable @typescript-eslint/no-empty-function */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItemType } from '../../types/MenuItemType';
import { getNameFromRoute } from '../../../../helpers';
import { paths } from '../../../../constants';

import './styles/MenuItem.styles.scss';

interface MenuItemProps {
  route: string;
  type: MenuItemType;
  isCleaningService?: boolean;
  setIsSideMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  route,
  type,
  isCleaningService = false,
  setIsSideMenuOpen = () => {},
}) => {
  const {
    services: { path: servicesPath },
  } = paths;
  const formattedRoute = isCleaningService ? `${servicesPath}/${route}` : route;
  const title = getNameFromRoute(route);

  return (
    <li className={`${type}__item`}>
      <NavLink
        className={`${type}__link`}
        to={formattedRoute}
        onClick={() => setIsSideMenuOpen(false)}
      >
        {title}
      </NavLink>
    </li>
  );
};
