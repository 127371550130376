import * as Yup from 'yup';
import { checkPhoneDigits } from './helpers/checkPhoneDigits';

export const schemaLoginForm = Yup.object().shape({
  email: Yup.string()
    .email('Please write your email')
    .required('Please write your email'),
  password: Yup.string().required('Please enter your password'),
});

export const schemaRegisterForm = Yup.object().shape({
  firstName: Yup.string()
    .required('Please write your first name')
    .min(2, 'This field must contain at least 2 characters.')
    .max(25, 'This field must be no more than 15 characters long.'),
  lastName: Yup.string()
    .required('Please write your last name')
    .min(2, 'This field must contain at least 2 characters.')
    .max(25, 'This field must be no more than 15 characters long.'),
  email: Yup.string()
    .email('Please write your email')
    .required('Please write your email'),
  phone: Yup.string()
    .required('Please write your phone number')
    .test('Phone number must contain exactly 10 digits', checkPhoneDigits),
  password: Yup.string().required('Please enter your password'),
  passwordConfirmation: Yup.string().required(
    'Please enter your password for confirmation'
  ),
});
