import React from 'react';

import './styles/CleaningServicesDesc.scss';

interface CleaningServicesDescProps {
  text: string;
}

export const CleaningServicesDesc: React.FC<CleaningServicesDescProps> = ({
  text,
}) => {
  return <p className="cleaning-services-desc">{text}</p>;
};
