/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { CommercialSelectType } from '../type/CommercialSelectType';
import { CommercialFormValues } from '../type/CommercialFormValues';
import { discounts } from '../../../constants';

interface CommercialFormSelectProps {
  content: CommercialSelectType[];
  register: UseFormRegister<CommercialFormValues>;
  setValue: UseFormSetValue<CommercialFormValues>;
}

export const CommercialFormSelect: React.FC<CommercialFormSelectProps> = ({
  content,
  register,
  setValue,
}) => {
  const handleSubjectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedFrequency = Number(event.currentTarget.value);
    setValue('frequencyInformation.frequencyId', selectedFrequency);
  };

  return (
    <div className="commercial-form__select__content">
      <div className="commercial-form__select__wrapper">
        <label className="commercial-form__label" htmlFor="frequency">
          Frequency of Service
        </label>
        <select
          id="frequency"
          {...register('frequencyInformation.frequencyId', {
            onChange: (event) => {
              handleSubjectChange(event);
            },
          })}
          className="commercial-form__select "
        >
          {discounts.map((discount) => {
            return (
              <option
                className="commercial-form__option"
                value={discount.id}
                key={discount.name}
              >
                {discount.name}
              </option>
            );
          })}
        </select>
      </div>

      {content.map((select) => {
        return (
          <div className="commercial-form__select__wrapper" key={select.type}>
            <label className="commercial-form__label" htmlFor={select.type}>
              {select.label}
            </label>
            <select
              {...register(select.type as keyof CommercialFormValues)}
              id={select.type}
              className="commercial-form__select "
            >
              {select.options.map((option) => {
                return (
                  <option
                    className="commercial-form__option"
                    value={option}
                    key={option}
                  >
                    {option}
                  </option>
                );
              })}
            </select>
          </div>
        );
      })}
    </div>
  );
};
