/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lonely-if */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SvgIconArrowDown } from '../../../ui/SvgIcon';
import { MinMaxValues } from '../types/minMaxValues';
import { useBookingFormData } from '../../../hooks/useBookingFormData';

interface BookingExtraCardInputProperties {
  id: number;
  minMaxValues: MinMaxValues;
  cardRef: React.RefObject<HTMLDivElement>;
  setCurrentQuantity: React.Dispatch<React.SetStateAction<number>>;
  setIsQuantityInputShown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BookingExtraCardInput: React.FC<
  BookingExtraCardInputProperties
> = ({
  id,
  setIsQuantityInputShown,
  setCurrentQuantity,
  cardRef,
  minMaxValues,
}) => {
  const cardWrapperRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const {
    formData: { cleaningInformation },
  } = useBookingFormData();
  const choosedExtra = useMemo(() => {
    if (cleaningInformation && cleaningInformation.cleaningExtras) {
      return (
        cleaningInformation.cleaningExtras.find((extra) => id === extra.id) ||
        null
      );
    }

    return null;
  }, [id, cleaningInformation?.cleaningExtras]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
      event.stopPropagation();
      setIsQuantityInputShown((prev) => !prev);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberInput = Number(event.target.value);
    if (numberInput > minMaxValues.max) {
      setInputValue(String(minMaxValues.max));
    } else if (
      numberInput < minMaxValues.min &&
      event.target.value.length >= String(minMaxValues.min).length
    ) {
      setInputValue(String(minMaxValues.min));
    } else if (event.target.value.at(0) === '0') {
      const formattedInputValue = event.target.value.slice(1);
      setInputValue(formattedInputValue);
    } else {
      setInputValue(event.target.value);
    }
  };

  useEffect(() => {
    if (!choosedExtra) {
      setInputValue(String(minMaxValues.min));
    } else {
      setInputValue(String(choosedExtra.quantity));
    }
  }, []);

  useEffect(() => {
    setCurrentQuantity(Number(inputValue));
  }, [inputValue]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div ref={cardWrapperRef} className="extra-card__input-wrapper">
      <label htmlFor="quantity-input" className="extra-card__label">
        <input
          id="quantity-input"
          className="extra-card__input"
          type="number"
          minLength={1}
          maxLength={4}
          value={inputValue}
          onChange={handleInputChange}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      </label>
      <div className="extra-card__buttons-wrapper">
        <button
          className="extra-card__button extra-card__button-increment"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setInputValue((prev) => {
              if (Number(inputValue) >= minMaxValues.max) {
                return inputValue;
              }

              return String(Number(prev) + 1);
            });
          }}
        >
          <SvgIconArrowDown />
        </button>
        <button
          className="extra-card__button extra-card__button-decrement"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setInputValue((prev) => {
              if (Number(inputValue) === minMaxValues.min) {
                return inputValue;
              }

              return String(Number(prev) - 1);
            });
          }}
        >
          <SvgIconArrowDown />
        </button>
      </div>
    </div>
  );
};
