import { MainWrapper as Main } from '../../ui/MainWrapper';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { AreasTitle } from '../../modules/AreasTitle';
import { ActionButtons } from '../../modules/ActionButtons';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { OakvillePageDescription } from '../../modules/PageDescription';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsVaughanBook,
  ThreeEasyStepsVaughanCleaner,
  ThreeEasyStepsVaughanRelax,
} from '../constants/VaughanPageData/ThreeEasyStepsVaughanData';
import { TrustIndicatorsSection } from '../../modules/TrustIndicatorsSection';
import { TrustIndicatorsSectionData } from '../../modules/TrustIndicatorsSection/constants/TrustIndicatorsSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { breakpoints, companyInfo } from '../../constants';
import { OurStuff } from '../../modules/OurStuff';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { AboutSection } from '../../modules/AboutSection';
import { GetQuote } from '../../modules/GetQuote';
import { Reviews } from '../../modules/Reviews';
import { GiftCards } from '../../modules/GiftCards';
import { ContactSection } from '../../modules/ContactSection';
import { MapWrapper } from '../../ui/MapWrapper';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { AccordionDataOakville } from '../../modules/FrequentlyQuestions/constants/AccordionDataOakville';
import { AreasTestimonialOakvilleData } from '../../modules/AreasTitle/constants/AreasTestimonialData';

import imageOakville from '../../assets/oakville-page/oakville-background-image.jpg';

export const OakvillePage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="oakvilleArea" />
      <AreasTitle
        city="Oakville"
        title="Cleaning Services Oakville"
        imageSource={imageOakville}
        areasTestimonial={AreasTestimonialOakvilleData}
        subtitle="Discover Oakville's trusted house cleaners since 2017. Hire our professional cleaning services near you and enjoy a spotless home today!"
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <OakvillePageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsVaughanBook}
        cleaner={ThreeEasyStepsVaughanCleaner}
        relax={ThreeEasyStepsVaughanRelax}
      />
      {!isMobile && (
        <TrustIndicatorsSection content={TrustIndicatorsSectionData} />
      )}
      <OurStuff hasOnlyOneSection componentName="oakville" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <CleaningServicesText />
      <OurCleaningServices title="Our Cleaning Services in Oakville Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Oakville" componentName="oakville" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Oakville"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <AcrossCanada />
      <AboutSection />
      <ActionButtons hasBackground data={twoButtonsData} />
      <FrequentlyQuestions questions={AccordionDataOakville} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Oakville"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="oakvile" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews
        title="Reviews of our Cleaning Services in Oakville"
        maxItems={2}
        swiper
      />
      <GetQuote
        isTwoButtons
        title="Try our Cleaning Services in Oakville Today!"
      />
    </Main>
  );
};
