import React from 'react';
import { CleaningCardDataChildrenEntity } from '../../types/CleainigToolsCardDataEntity';
import { getAltTextForImage } from '../../../../helpers';

interface CleaningToolsCardProperties {
  cardData: CleaningCardDataChildrenEntity;
}

export const CleaningToolsCard: React.FC<CleaningToolsCardProperties> = ({
  cardData,
}) => {
  const { description, title, imageSource } = cardData;
  const altText = getAltTextForImage(imageSource);

  return (
    <div className="cleaning-tools__card">
      <h3 className="cleaning-tools__card-title">{title}</h3>
      <img
        className="cleaning-tools__card-image"
        src={imageSource}
        alt={altText}
      />
      {description && (
        <p className="cleaning-tools__card-description">{description}</p>
      )}
    </div>
  );
};
