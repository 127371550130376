import { Link } from 'react-router-dom';
import { paths } from '../../../constants';

export const ReasonsTrustListBrampton = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Premier Housekeeping in Brampton:
        </span>{' '}
        Dependable, top-tier home cleaning.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Eco-Friendly Cleaning Options:
        </span>{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
        >
          Green cleaning
        </Link>{' '}
        for a safer home.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Satisfaction Guarantee:
        </span>{' '}
        We ensure your complete happiness.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Competitive Pricing in Brampton:
        </span>{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.cleaningServices}`}
        >
          High-quality services{' '}
        </Link>
        at affordable rates.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Screened and Verified Cleaners:
        </span>{' '}
        Trustworthy, thoroughly vetted maids.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Simple Online Booking:{' '}
        </span>{' '}
        <Link className="reasons-trust-list__link" to={paths.book}>
          Easy scheduling
        </Link>{' '}
        in just a few clicks.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Customer-Centric Support: 24/7
        </span>{' '}
        communication and support.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Renowned Reputation:</span>{' '}
        Highly praised by our{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          satisfied clients.
        </Link>
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Local Expertise in Brampton:
        </span>{' '}
        Trusted, community-rooted service.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Transparent and Honest Pricing:
        </span>{' '}
        No hidden costs, just clear pricing.
      </li>
    </ul>
  );
};
