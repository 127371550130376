import React from 'react';
import { Link } from 'react-router-dom';
import { companyInfo, paths } from '../../../../constants';
import {
  getAltTextForImage,
  getFormattedPhoneNumber,
} from '../../../../helpers';
import { LocationSectionType } from '../../type/LocationSectionType';
import img from '../../../../assets/open-location/home-cleaners-services-by-crystalclear-canada.jpg';
import {
  SvgIconClockSmall,
  SvgIconClose,
  SvgIconEmail,
  SvgIconMarker,
  SvgIconPhone,
  SvgIconWorld,
} from '../../../../ui/SvgIcon';
import './styles/OpenLocation.scss';

interface OpenLocationProps {
  content: LocationSectionType;
  close: () => void;
}

export const OpenLocation: React.FC<OpenLocationProps> = ({
  content,
  close,
}) => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  const altText = getAltTextForImage(img);
  const { name, address, officesMap, link, hours } = content;

  return (
    <div className="open-location">
      <div className="open-location__header">
        <div className="open-location__images">
          <img alt={altText} src={img} />
        </div>
        <button onClick={() => close()} className="open-location__close">
          <SvgIconClose />
        </button>
      </div>
      <div className="open-location__container">
        <h2 className="open-location__title">Crystal Clear {name}</h2>
        <div className="open-location__information">
          <div className="open-location__phone">
            <span className="open-location__icon">
              <SvgIconPhone />
            </span>
            <a href={`tel:${formattedPhone}`}>{companyInfo.phoneNumber}</a>
          </div>
          <div className="open-location__address">
            <span className="open-location__icon">
              <SvgIconMarker />
            </span>
            <a href={officesMap}>
              <p>{address}</p>
            </a>
          </div>
          <div className="open-location__email">
            <span className="open-location__icon">
              <SvgIconEmail />
            </span>
            <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
          </div>
          <div className="open-location__link">
            <span className="open-location__icon">
              <SvgIconWorld />
            </span>
            <Link to={link.paths}>{link.url}</Link>
          </div>
          <div className="open-location__hours">
            <span className="open-location__icon">
              <SvgIconClockSmall />
            </span>
            <p>{hours}</p>
          </div>
          <Link to={paths.book} className="open-location__button">
            Get a quote
          </Link>
        </div>
      </div>
    </div>
  );
};
