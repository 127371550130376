import './styles/IFrame.scss';

export const IFrameNorthYork = () => {
  return (
    <div className="frame">
      <iframe
        title="North York"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92220.67808329346!2d-79.52439882746305!3d43.754252449362376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b322ec4918c75%3A0xfb330d877abf7e3d!2z0J3QvtGA0YIt0JnQvtGA0LosINCe0L3RgtCw0YDQuNC-LCDQmtCw0L3QsNC00LA!5e0!3m2!1sen!2sca!4v1729582273686!5m2!1sen!2sca"
        loading="lazy"
      />
    </div>
  );
};

export const IFrameToronto = () => {
  return (
    <div className="frame">
      <iframe
        title="Toronto"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d184484.65264096358!2d-79.53531129522848!3d43.74020285966994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb90d7c63ba5%3A0x323555502ab4c477!2z0KLQvtGA0L7QvdGC0L4sINCe0L3RgtCw0YDQuNC-LCDQmtCw0L3QsNC00LA!5e0!3m2!1sen!2sca!4v1729585712176!5m2!1sen!2sca"
        loading="lazy"
      />
    </div>
  );
};

export const IFrameEtobicoke = () => {
  return (
    <div className="frame">
      <iframe
        title="Etobicoke"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d109791.828696514!2d-79.61695888885619!3d43.68745363450756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3760d44c33c5%3A0x59e9c8bf2149d577!2z0K3RgtC-0LHQuNC60L4sINCe0L3RgtCw0YDQuNC-LCDQmtCw0L3QsNC00LA!5e0!3m2!1sen!2sca!4v1729585842717!5m2!1sen!2sca"
        loading="lazy"
      />
    </div>
  );
};
