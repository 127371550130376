import { Link } from 'react-router-dom';
import '../styles/TextDescription.scss';
import { paths } from '../../../../../constants';

export const TextDescription = () => {
  return (
    <div className="text__description description">
      <p className="description__top">
        Looking for the perfect gift? Consider the gift of a pristine home with{' '}
        <Link to={paths.giftCards} className="description_link">
          Crystal Clear&apos;s cleaning gift cards!
        </Link>{' '}
        Whether it&apos;s a birthday, a token of appreciation, or a thoughtful
        gesture for your loved ones or hardworking employees, our cleaning gift
        cards are a unique and practical choice.
      </p>
      <p className="description__bottom">
        Show your care by giving the gift of a clean and organized living space.
        With our professional cleaning services, you&apos;ll be providing not
        just a one-time delight but an ongoing sense of comfort and relaxation.
        Surprise your friends and family,{' '}
        <span className="description_bold">
          purchase your cleaning gift card
        </span>{' '}
        today!
      </p>
    </div>
  );
};
