/* eslint-disable no-param-reassign */
import { useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { CommercialFormValues } from './type/CommercialFormValues';
import { CommercialSelectData } from './constants/CommercialSelectData';
import { CommercialFormSelect } from './components/CommercialFormSelect';
import { handleVerifyCaptcha } from '../../components/Captcha/helpers/captchaValidation';
import { GoogleCaptcha } from '../../components/Captcha/GoogleCaptcha';
import { CommercialTextareaData } from './constants/CommercialTextareaData';
import { schemaCommercialForm } from '../../validators/ValidateCommercialForm';
import { CommercialFormInput } from './components/CommercialFormInput';
import { getCommercialFormData } from './helpers/getCommercialFormData';
import Api from '../../service/Api';

import './styles/CommercialForm.scss';
import { Title } from '../../ui/MainTitle';

interface CommercialFormProps {
  formRef: React.RefObject<HTMLElement>;
}

export const CommercialForm: React.FC<CommercialFormProps> = ({ formRef }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CommercialFormValues>({
    resolver: yupResolver(schemaCommercialForm),
  });
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const isMounted = useRef<boolean>(false);
  const [isSubmited, setIsSubmitted] = useState<boolean>(false);

  const onSubmit = async (data: CommercialFormValues) => {
    const CommercialFormData = getCommercialFormData(data);
    try {
      const recaptchaValue = captchaRef.current?.getValue();
      if (recaptchaValue) {
        handleVerifyCaptcha(recaptchaValue);
        await Api.post('/booking/new', CommercialFormData);
        toast.success(
          'Your submission was successful. Our team will contact you as soon as possible.'
        );
      } else {
        toast.error('Please complete the captcha.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('Something went wrong, please try again later.');
    }
  };

  useEffect(() => {
    if (isSubmited && isMounted.current) {
      if (Object.keys(errors).length > 0) {
        toast.error(
          'Please check the highlighted fields for any invalid data.'
        );
      }
    } else {
      isMounted.current = true;
    }
  }, [errors, isSubmited]);

  return (
    <section className="commercial-form" ref={formRef}>
      <ContainerComponent>
        <div className="commercial-form__container">
          <Title
            text="Fill in the form below for a quick quote"
            type="h2"
            color="black"
          />
          <form
            className="commercial-form__form"
            onSubmit={(event) => {
              event.preventDefault();
              setIsSubmitted(true);
              handleSubmit(onSubmit)();
            }}
          >
            <CommercialFormInput
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <CommercialFormSelect
              content={CommercialSelectData}
              register={register}
              setValue={setValue}
            />
            <textarea
              className="commercial-form__textarea"
              placeholder={CommercialTextareaData.placeholder}
              {...register(
                CommercialTextareaData.name as keyof CommercialFormValues
              )}
            />
            <div className="commercial-form__captcha">
              <GoogleCaptcha captchaRef={captchaRef} />
            </div>
            <button className="commercial-form__button">get a quote</button>
          </form>
        </div>
      </ContainerComponent>
    </section>
  );
};
