import { FormMyPrice } from '../../components/FormMyPrice';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { HeroSection } from '../../modules/HeroSection';
import { ProfessionalCleaning } from '../../modules/ProfessionalCleaning';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { ActionButtons } from '../../modules/ActionButtons';
import { CleaningZones } from '../../modules/CleaningZones';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { OurStuff } from '../../modules/OurStuff';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { ValuesCleaning } from '../../modules/ValuesCleaning';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { RecurringCleaning } from '../../modules/RecurringCleaning';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { Reviews } from '../../modules/Reviews';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { SvgIconSmile } from '../../ui/SvgIcon';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import homeCleaningPageImage from '../../assets/heroSection/home-cleaning.jpg';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { AccordionData } from '../../modules/FrequentlyQuestions/constants/AccordionData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

export const CondoCleaningPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="condoCleaningService" />
      <HeroSection
        title="Apartment & Condo Cleaning Services"
        subtitle="Canada’s #1 Apartment & Condo Cleaning Services Life’s too short for cleaning, leave the mess to us!"
        imageSource={homeCleaningPageImage}
      />
      <ProfessionalCleaning
        title="Apartment & Condo Cleaning Services"
        desc="condo-cleaning"
      />
      <ThreeEasySteps
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <CleaningZones
        content={CleaningZonesData}
        title="Our Apartment & Condo Cleaning Services Checklist"
      />
      <AcrossCanada />
      <OurStuff hasOnlyOneSection />
      <ActionButtons hasBackground data={twoButtonsData} />
      <OurCleaningServices />
      <ServiceExclusions />
      <ValuesCleaning />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <RecurringCleaning />
      <FrequentlyQuestions questions={AccordionData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <WrapperMyPrice
        title="Try Our Cleaning Services Today!"
        desc="If you're not happy, we'll work to make it right!"
        form={<FormMyPrice isMinimized />}
        icon={<SvgIconSmile />}
      />
    </Main>
  );
};
