import { Link } from 'react-router-dom';
import { paths } from '../../../constants';

export const ReasonsTrustListEastGwillimbury = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Premier Housekeeping in East Gwillimbury:
        </span>{' '}
        Reliable cleaners delivering exceptional service.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Eco-Friendly Cleaning:</span>{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
        >
          Green options
        </Link>{' '}
        available for a healthier home.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Satisfaction Guarantee:
        </span>{' '}
        We ensure you’re completely happy with our work.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Affordable Pricing in East Gwillimbury:
        </span>{' '}
        Quality cleaning at prices that fit your budget.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Vetted Cleaners:</span>{' '}
        Trustworthy maids carefully selected for their reliability.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Easy Online Booking: </span>{' '}
        <Link className="reasons-trust-list__link" to={paths.book}>
          Schedule your cleaning
        </Link>{' '}
        with just a few clicks.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">24/7 Support:</span> We’re
        here for you anytime you need us.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Top-Rated Service:</span> Our
        clients love us, and our{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          reviews
        </Link>{' '}
        show it.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Local East Gwillimbury Experts:
        </span>{' '}
        A cleaning service deeply rooted in the community.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Clear Pricing:</span> Honest
        rates with no surprises.
      </li>
    </ul>
  );
};
