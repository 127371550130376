import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { FormMyPrice } from '../../components/FormMyPrice';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { breakpoints } from '../../constants';
import { HeroSectionActionsBtn } from './components/HeroSectionActionsBtn/HeroSectionActionsBtn';
import { SvgIconCheck } from '../../ui/SvgIcon';
import { serviceGuarantees } from './constants/serviceGuarantees';

import './styles/HeroSection.scss';
import { Title } from '../../ui/MainTitle';

interface HeroSectionProperties {
  title: string;
  subtitle?: string;
  desc?: string;
  imageSource: string;
}

export const HeroSection: React.FC<HeroSectionProperties> = ({
  title,
  subtitle,
  desc,
  imageSource,
}) => {
  const { windowWidth } = useWidthWindow();

  return (
    <section className="hero-section">
      <div
        style={{
          backgroundImage: `url(${imageSource})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        className="hero-section__background"
      />
      <ContainerComponent>
        <div className="hero-section__wrapper">
          <Title text={title} type="h1" />
          <p className="hero-section__subtitle">{subtitle}</p>
          <FormMyPrice />
          {desc && <p className="hero-section__description">{desc}</p>}
          {windowWidth >= breakpoints.desktop ? (
            <HeroSectionActionsBtn />
          ) : (
            <div className="service-guarantees">
              {serviceGuarantees.map((guarantee) => (
                <p key={guarantee} className="service-guarantees__item">
                  <SvgIconCheck />
                  {guarantee}
                </p>
              ))}
            </div>
          )}
        </div>
      </ContainerComponent>
    </section>
  );
};
