import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { ReviewsPageSwiper } from './components/ReviewsPageSwiper';
import { ReviewsPageDataType } from './types/ReviewsPageDataType';
import { getAltTextForImage } from '../../helpers';

import './styles/ReviewsPageClientsSay.scss';

interface ReviewsPageClientsSayProps {
  content: ReviewsPageDataType[];
  imgReviews: string;
}

export const ReviewsPageClientsSay: React.FC<ReviewsPageClientsSayProps> = ({
  content,
  imgReviews,
}) => {
  const altText = getAltTextForImage(imgReviews);
  return (
    <section className="reviews-page-clients-say">
      <ContainerComponent>
        <div className="reviews-page-clients-say__wrapper">
          <div className="reviews-page-clients-say__img">
            <img alt={altText} src={imgReviews} />
          </div>
          <div className="reviews-page-clients-say__container">
            <h3 className="reviews-page-clients-say__title">
              What our clients say about Crystal Clear
            </h3>
            <p className="reviews-page-clients-say__desc">
              Your Happiness is our Priority!
            </p>
            <ReviewsPageSwiper content={content} />
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
