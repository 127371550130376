import { FieldErrors } from 'react-hook-form';
import { submitContactInformation } from '../../../service/submitContactInformation';
import { ContactInformationEntity } from '../../../types';
import { BookingField } from '../types/bookingFieldset';
import { countryCode } from '../constants/countryCode';

export const handleContactInformationOnBlur = async (
  name: keyof BookingField,
  formFields: BookingField,
  errors: FieldErrors<BookingField>,
  setContactInformation: (contactInformation: ContactInformationEntity) => void
) => {
  const { phone, email, firstName, lastName, useSms } = formFields;
  let formattedPhone = '';
  let formattedEmail = '';

  if (name === 'email') {
    formattedPhone =
      phone && !errors.phone ? countryCode + phone.replaceAll(' ', '') : '';
    formattedEmail = email;
  }

  if (name === 'phone') {
    formattedPhone = phone ? countryCode + phone.replaceAll(' ', '') : '';
    formattedEmail = !errors.email ? email : '';
  }

  const contactInformation: ContactInformationEntity = {
    phone: formattedPhone,
    email: formattedEmail,
    firstName: !errors.firstName ? firstName : '',
    lastName: !errors.lastName ? lastName : '',
    useSms: phone ? useSms : false,
  };

  setContactInformation(contactInformation);
  await submitContactInformation(contactInformation);
};
