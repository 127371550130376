import { paths } from '../../../constants';
import { getNumberSequence } from '../helpers/getNumberSequence';
import { getServiceType } from '../helpers/getServiceType';
import { formSelectConfigType } from '../types/formSelectConfigType';

const bedroomsEnv = 7;
const bathroomsEnv = 4;

const bedRoomsStep = 1;
const bathRoomsStep = 0.5;

const amountBedrooms: (string | number)[] = [
  'No. of Bedrooms',
  ...getNumberSequence(bedroomsEnv, bedRoomsStep),
];
const amountBathrooms: (string | number)[] = [
  'No. of Washrooms',
  ...getNumberSequence(bathroomsEnv, bathRoomsStep),
];

const {
  services: { children },
} = paths;
const servicesArray = getServiceType(Object.values(children));

servicesArray.unshift('Type of Cleaning');

export const formSelectConfig: formSelectConfigType[] = [
  {
    value: 'serviceType',
    option: servicesArray.filter((service) => service !== 'checklist'),
  },
  {
    value: 'bedrooms',
    option: amountBedrooms,
  },
  {
    value: 'washrooms',
    option: amountBathrooms,
  },
];
