import { Link } from 'react-router-dom';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';
import { TitleItem } from '../ui/TitleItem';

import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';

export const ExpertHouseWhitby = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="House Cleaning Professionals of Whitby" />
          <p className="text__desc">
            At Crystal Clear, we believe that happiness is at the heart of
            everything we do—both for our cleaners and our clients. Our team is
            not only skilled but genuinely enjoys bringing a touch of joy to
            every home they service. Whether you need a{' '}
            <Link
              className="three-sections__link text__desc_bold"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep cleaning
            </Link>{' '}
            to refresh your space or recurring services to maintain that perfect
            shine, we&apos;re here to deliver quality results that make a
            difference. Living in{' '}
            <span className="text__desc_bold">Whitby,</span> you deserve the
            best, and with Crystal Clear&apos;s cleaning services Whitby, we
            ensure your home is more than just clean—it&apos;s a place where
            happiness thrives. Trust us to create a welcoming environment that
            reflects our commitment to excellence and your satisfaction.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
