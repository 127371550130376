/* eslint-disable react/no-array-index-key */
import React from 'react';

interface RatingStarsProps {
  rating: number;
}

export const RatingStars: React.FC<RatingStarsProps> = ({ rating }) => {
  const stars = Array.from({ length: 5 }, (_, i) => {
    if (i + 1 <= Math.floor(rating)) return 'full';
    if (i < rating && rating < i + 1) return 'gray';
    return 'empty';
  });

  return (
    <div className="rating-stars">
      {stars.map((type, index) => (
        <svg
          key={index}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          className={`star star--${type}`}
        >
          <polygon
            points="12 2 15 10 23 10 17 15 19 23 12 18 5 23 7 15 1 10 9 10"
            fill={type === 'full' ? '#FFD700' : 'lightgray'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ))}
    </div>
  );
};
