import React, { useEffect, useState } from 'react';
import {
  CleaningInformationAdditionsEntity,
  CleaningInformationEntity,
} from '../../../../../types';

interface CleaningInformationProps {
  cleaningInformation: CleaningInformationEntity | undefined;
  totalPrice: number;
  recurringPrice: number | null | undefined;
}

export const CleaningInformation: React.FC<CleaningInformationProps> = ({
  cleaningInformation,
  totalPrice,
  recurringPrice,
}) => {
  const [parameters, setParameters] = useState<
    CleaningInformationAdditionsEntity[]
  >([]);

  useEffect(() => {
    if (
      cleaningInformation &&
      cleaningInformation.cleaningExtras &&
      cleaningInformation.cleaningParameters
    ) {
      setParameters([
        ...cleaningInformation.cleaningParameters,
        ...cleaningInformation.cleaningExtras,
      ]);
    }
  }, []);
  return (
    <div className="dashboard-section-item__block cleaning-information">
      <h3 className="dashboard-section-item__title">Type of Cleaning</h3>
      {cleaningInformation?.id !== 0 ? (
        <div className="dashboard-section-item__block__wrapper">
          <div className="dashboard-section-item__element__wrapper">
            <p className="dashboard-section-item__element__sub-title booking-summary__type-cleaning">
              {cleaningInformation?.name}
            </p>
            <p className="dashboard-section-item__element_price">
              {cleaningInformation?.price}$
            </p>
          </div>
          {parameters ? (
            parameters?.map((parameter, index) => {
              return (
                <div
                  className={`dashboard-section-item__element__wrapper
                    ${
                      index === 0 || index % 2 === 0
                        ? 'dashboard-section-item__element__wrapper_grey'
                        : ''
                    }`}
                  key={parameter.id}
                >
                  <div className="booking-summary__parameters__marker" />
                  <p className="dashboard-section-item__element">
                    {parameter.name}
                  </p>
                  <p className="dashboard-section-item__element_price">
                    {parameter.totalPrice}$
                  </p>
                </div>
              );
            })
          ) : (
            <div className="dashboard-section-item__element__wrapper">
              <p className="dashboard-section-item__element">
                The user has not selected anything
              </p>
            </div>
          )}
          <div className="dashboard-section-item__price">
            <div className="dashboard-section-item__price__wrapper dashboard-section-item__element__wrapper_grey">
              <p className="dashboard-section-item__element__sub-title">
                {recurringPrice !== 0 ? 'Initial Clean Fee' : "Today's fee"}
              </p>
              <p className="dashboard-section-item__element_price price__accent">
                {totalPrice}$
              </p>
            </div>
            {recurringPrice !== 0 && (
              <div className="dashboard-section-item__price__wrapper">
                <p className="dashboard-section-item__element__sub-title">
                  Recurring Clean Fee
                </p>
                <p className="dashboard-section-item__element_price price__accent">
                  {recurringPrice}$
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="dashboard-section-item__element__wrapper">
          <p className="dashboard-section-item__element">
            The user has not selected anything
          </p>
        </div>
      )}
    </div>
  );
};
