import React, { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { SvgIconArrowDown } from '../../../ui/SvgIcon';
import { InputMinMaxType } from '../type/InputMinMaxType';
import { CommercialFormValues } from '../type/CommercialFormValues';

interface CommercialFormSquareProps {
  setValue: UseFormSetValue<CommercialFormValues>;
}

export const CommercialFormSquare: React.FC<CommercialFormSquareProps> = ({
  setValue,
}) => {
  const minMaxValues: InputMinMaxType = {
    min: 0,
    max: 10000,
  };
  const [inputValue, setInputValue] = useState<string>('');
  const [isShowArrow, setIsShowArrow] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberInput = Number(event.target.value);
    if (numberInput > minMaxValues.max) {
      setInputValue(String(minMaxValues.max));
    } else if (
      numberInput < minMaxValues.min &&
      event.target.value.length >= String(minMaxValues.min).length
    ) {
      setInputValue(String(minMaxValues.min));
    } else if (event.target.value.at(0) === '0') {
      const formattedInputValue = event.target.value.slice(1);
      setInputValue(formattedInputValue);
    } else {
      setInputValue(event.target.value);
    }
  };

  useEffect(() => {
    setValue('squareFootage', Number(inputValue));
  }, [inputValue]);

  return (
    <div
      onMouseEnter={() => setIsShowArrow(true)}
      onMouseLeave={() => setIsShowArrow(false)}
      className="commercial-form__square"
    >
      <input
        className="fieldset__input commercial-form__input"
        type="number"
        placeholder="square footage"
        minLength={1}
        maxLength={10}
        min={0}
        value={inputValue}
        onChange={handleInputChange}
        onClick={(event) => {
          event.stopPropagation();
        }}
        max={10000}
      />
      {isShowArrow && (
        <div className="commercial-form-square__button__wrapper">
          <button
            className="commercial-form-square__button commercial-form-square__button_increment"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setInputValue((prev) => {
                if (Number(inputValue) >= minMaxValues.max) {
                  return inputValue;
                }

                return String(Number(prev) + 1);
              });
            }}
          >
            <SvgIconArrowDown />
          </button>
          <button
            className="commercial-form-square__button commercial-form-square__button_decrement"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setInputValue((prev) => {
                if (Number(inputValue) === minMaxValues.min) {
                  return inputValue;
                }

                return String(Number(prev) - 1);
              });
            }}
          >
            <SvgIconArrowDown />
          </button>
        </div>
      )}
    </div>
  );
};
