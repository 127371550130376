import { ServicesNotOffered } from '../constants/ServicesNotOffered';

export const SpecialInstructionsText = () => {
  return (
    <div className="special-instructions-text">
      <h3 className="special-instructions-text__title">Services Not Offered</h3>
      <p className="special-instructions-text__desc">
        To ensure our cleaning associates are able to work safely and
        effectively, we are unable to provide the following:
      </p>
      <ul className="special-instructions-text__list">
        {ServicesNotOffered.map((service) => {
          return (
            <li key={service}>
              <em>{service}</em>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
