import { Link } from 'react-router-dom';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';
import { TitleItem } from '../ui/TitleItem';

import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';

export const ExperienceHappiness = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Experience Happiness and Quality with Crystal Clear’s Premier Cleaning Services in Brampton" />
          <p className="text__desc">
            At Crystal Clear, we believe happiness is key to success, both for
            our cleaners and clients. Our skilled team members genuinely enjoy
            what they do, ensuring exceptional service with our cleaning
            services Brampton residents trust. Whether you need a{' '}
            <Link
              className="three-sections__link text__desc_bold"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep clean
            </Link>{' '}
            or regular maintenance, our dedicated team delivers top-quality
            results every time. Living in Brampton, you deserve the best, and
            that’s what we provide. It’s more than just cleaning—it’s about
            creating a home filled with happiness and comfort, knowing it’s
            cared for by a team that values your satisfaction.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
