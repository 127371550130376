import { Link } from 'react-router-dom';
import { TitleItem } from '../ui/TitleItem';
import { getAltTextForImage } from '../../../../helpers';
import { titleTeam } from '../../constants/title';

import '../../styles/Text.scss';
import peopleTeam from '../../../../assets/three-sections-of-stuff/crystal-clear-service-team.jpg';

export const TeamSection = () => {
  const altImg = getAltTextForImage(peopleTeam);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title={titleTeam} />
          <p className="text__desc">
            Behind our exceptional cleaning services stands a dedicated
            15-member office team, committed to supporting both our cleaners and
            clients every day. Their passion ensures that our cleaners have the
            tools and support they need, while our clients receive the top-notch
            service they deserve.
          </p>
          <p className="text__desc">
            Want to know more about our story?{' '}
            <Link className="three-sections__link text__desc_bold" to="/">
              Visit our About Us page
            </Link>{' '}
            to learn more and meet the incredible people who make it all happen!
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altImg} src={peopleTeam} />
        </div>
      </div>
    </div>
  );
};
