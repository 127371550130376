import React, { useEffect, useState } from 'react';
import { useBookingFormData } from '../../../../hooks/useBookingFormData';
import { BookingText } from '../../ui/BookingText';
import { BookingExtraCard } from '../../ui/BookingExtraCard';
import { CleaningService, ExtrasEntity } from '../../../../types';

interface BookingExtrasProperties {
  cleaningServices: CleaningService[];
}

export const BookingExtras: React.FC<BookingExtrasProperties> = ({
  cleaningServices,
}) => {
  const { formData } = useBookingFormData();
  const [extras, setExtras] = useState<ExtrasEntity[]>([]);

  useEffect(() => {
    const selectedService =
      cleaningServices.find(
        (service) => service.id === formData.cleaningInformation?.id
      ) || null;

    if (selectedService && selectedService.extras) {
      setExtras(selectedService.extras);
    }
  }, [formData.cleaningInformation, cleaningServices]);

  return (
    <fieldset className="fieldset">
      <div className="fieldset__text-wrapper">
        <BookingText
          text="Step 4 of 5: Customize your Cleaning"
          option="title"
        />
        <BookingText
          text="If you selected House Cleaning package in Step 2, we highly recommend first-time customers to book a Deep Clean to ensure their property is adequately cleaned."
          option="description"
        />
        <BookingText
          text="Choosing the right package is important to guarantee assigning the right amount of hours and cleaners. If a job requires deep cleaning, and the package selected is a standard cleaning, the job may need additional hours. Additional hours on the same day cannot be guaranteed. Visit our checklist to see what our cleaning packages include."
          option="description"
        />
      </div>
      <div className="fieldset__extra-card-wrapper">
        {extras?.map((extraService) => {
          return (
            <BookingExtraCard key={extraService.id} extra={extraService} />
          );
        })}
      </div>
    </fieldset>
  );
};
