import React from 'react';
import { ReviewsVideoWrapper } from '../../../Reviews/components/ReviewsVideoWrapper/ReviewsVideoWrapper';
import { ReviewsImagesType } from '../../../Reviews/components/ReviewsVideoWrapper/types/ReviewsImagesType';
import { Title } from '../../../../ui/MainTitle';

interface ReviewsPageVideoContentProps {
  title: string;
  content: ReviewsImagesType[];
}

export const ReviewsPageVideoContent: React.FC<
  ReviewsPageVideoContentProps
> = ({ title, content }) => {
  return (
    <div className="reviews-page-video__content">
      <Title text={title} type="h2" color="black" />
      <div className="reviews-page-video__video">
        {content.map((review) => (
          <ReviewsVideoWrapper review={review} key={review.alt} />
        ))}
      </div>
    </div>
  );
};
