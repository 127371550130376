import {
  SvgIconBiohazards,
  SvgIconChandeliers,
  SvgIconExterior,
  SvgIconFeces,
  SvgIconHardReach,
  SvgIconHeavyFurniture,
  SvgIconPaintDrippings,
  SvgIconPest,
  SvgIconWashWalls,
} from '../../../ui/SvgIcon';
import { ServiceExclusionsType } from '../type/ServiceExclusionsType';

export const ServiceExclusionsData: ServiceExclusionsType[] = [
  {
    icon: <SvgIconChandeliers />,
    text: 'Chandeliers',
  },
  {
    icon: <SvgIconBiohazards />,
    text: 'Biohazards',
    desc: '(mold, bodily fluids)',
  },
  {
    icon: <SvgIconFeces />,
    text: 'Feces',
  },
  {
    icon: <SvgIconExterior />,
    text: 'Exterior areas',
    desc: '(outside windows, patios)',
  },
  {
    icon: <SvgIconPest />,
    text: 'Pest removal',
  },
  {
    icon: <SvgIconHardReach />,
    text: 'Hard to reach areas',
  },
  {
    icon: <SvgIconHeavyFurniture />,
    text: 'Under heavy furniture',
  },
  {
    icon: <SvgIconWashWalls />,
    text: 'Full wash of walls',
  },
  {
    icon: <SvgIconPaintDrippings />,
    text: 'Removal of paint drippings',
  },
];
