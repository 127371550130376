import { Link } from 'react-router-dom';
import { paths } from '../../../constants';

export const ReasonsTrustListScarborough = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Premier Housekeeping:</span>{' '}
        Top-tier service from trusted Scarborough cleaners.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Eco-Friendly Cleaning:</span>{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
        >
          Green options
        </Link>{' '}
        to match your values.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Satisfaction Guarantee:
        </span>{' '}
        Your happiness is our priority.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Competitive Pricing:</span>{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.cleaningServices}`}
        >
          Quality cleaning{' '}
        </Link>
        at affordable Scarborough rates.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Screened Cleaners:</span>{' '}
        Reliable, vetted maids you can trust.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Simple Booking: </span> Easy{' '}
        <Link className="reasons-trust-list__link" to={paths.book}>
          online scheduling
        </Link>{' '}
        at your convenience.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">24/7 Support:</span> We’re
        always here to help, day or night.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Renowned Reputation:</span>{' '}
        Highly praised by{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          satisfied clients
        </Link>
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Local Expertise:</span>{' '}
        Trusted cleaning rooted in Scarborough.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Transparent Pricing:</span>{' '}
        Honest rates with no hidden fees.
      </li>
    </ul>
  );
};
