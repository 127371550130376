import livingWage from '../../../../../assets/differences-between-us/living-wage-canada-crystalclear-cleaning.png';
import cancerSociety from '../../../../../assets/differences-between-us/canadian-cancer-society-logo-crystalclear.png';
import googleReviews from '../../../../../assets/differences-between-us/crystalclear-google-reviews.png';
import threeBest from '../../../../../assets/differences-between-us/threebestrated-crystalclear.png';
import indeed from '../../../../../assets/differences-between-us/crystalclear-indeed-top-employer-cleaning-job.webp';

export const imagesSrc = [
  livingWage,
  cancerSociety,
  googleReviews,
  threeBest,
  indeed,
];
