import { breakpoints, companyInfo } from '../../constants';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { AreasTitle } from '../../modules/AreasTitle';
import { MainWrapper } from '../../ui/MainWrapper';
import { AreasTestimonialScarboroughData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { ActionButtons } from '../../modules/ActionButtons';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsVaughanBook,
  ThreeEasyStepsVaughanCleaner,
  ThreeEasyStepsVaughanRelax,
} from '../constants/VaughanPageData/ThreeEasyStepsVaughanData';
import { OurStuff } from '../../modules/OurStuff';
import { ScarboroughPageDescription } from '../../modules/PageDescription';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { CleaningZones } from '../../modules/CleaningZones';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { AboutSection } from '../../modules/AboutSection';
import { Reviews } from '../../modules/Reviews';
import { GetQuote } from '../../modules/GetQuote';
import { AreaCleaning } from '../../modules/AreaCleaning';
import { MapWrapper } from '../../ui/MapWrapper';
import { SpecificArea } from '../../types';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { GiftCards } from '../../modules/GiftCards';
import { ContactSection } from '../../modules/ContactSection';
import { IFrameToronto } from '../../modules/ContactSection/components/IFrame';
import { AccordionDataScarborough } from '../../modules/FrequentlyQuestions/constants/AccordionDataScarborough';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

import imgVaughan from '../../assets/vaughan-page/vaughan-page.jpg';

export const ScarboroughPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;
  return (
    <MainWrapper>
      <DynamicBrowserHeaderTitle page="scarboroughArea" />
      <AreasTitle
        title="Cleaning Services Scarborough"
        city="Scarborough"
        subtitle="Premier Home Cleaning Services – Hire House Cleaners Near You in Scarborough Today!"
        imageSource={imgVaughan}
        areasTestimonial={AreasTestimonialScarboroughData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ScarboroughPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsVaughanBook}
        cleaner={ThreeEasyStepsVaughanCleaner}
        relax={ThreeEasyStepsVaughanRelax}
      />
      <OurStuff hasOnlyOneSection componentName="scarborough" />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Scarborough Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Scarborough" componentName="scarborough" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Scarborough"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AreaCleaning
        title="Cleaning Services for Toronto's Six Districts"
        map={
          <MapWrapper
            area="scarborough"
            specificArea={SpecificArea.Scarborough}
          />
        }
      />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataScarborough} />
      <ContactSection
        city="Scarborough"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<IFrameToronto />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <GetQuote title="Try our Cleaning Services in Scarborough Today!" />
    </MainWrapper>
  );
};
