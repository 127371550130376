import React from 'react';
import { getAltTextForImage } from '../../../../helpers';
import { AreasTestimonialType } from '../../type/AreasTestimonialType';

import './styles/AreasTestimonial.scss';

interface AreasTestimonialProps {
  content: AreasTestimonialType;
}

export const AreasTestimonial: React.FC<AreasTestimonialProps> = ({
  content,
}) => {
  const { text, img, name, city } = content;
  const altText = getAltTextForImage(img);

  return (
    <div className="areas-testimonial">
      <div className="areas-testimonial__wrapper">
        <div className="areas-testimonial__image-wrapper">
          <img className="areas-testimonial__image" alt={altText} src={img} />
        </div>
        <p className="areas-testimonial__name">{name}</p>
        <p className="areas-testimonial__city">{city}</p>
      </div>
      <div className="areas-testimonial__text">
        <div className="areas-testimonial__text__container">{text}</div>
      </div>
    </div>
  );
};
