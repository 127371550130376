import { Title } from '../../../../ui/MainTitle';
import { BookingText } from '../../ui/BookingText';

export const BookingFormHeader = () => {
  return (
    <div className="booking__header">
      <Title text="5 Simple Steps" type="h2" color="black" />
      <BookingText
        option="description"
        text="Customize your cleaning, view your quote, and book when ready."
      />
      <BookingText
        option="description"
        text="No payment until day of service. 24hr cancellation policy."
      />
    </div>
  );
};
