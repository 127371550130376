import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';

import './styles/ProfessionalCleaning.scss';
import { Title } from '../../ui/MainTitle';

interface ProfessionalCleaningProps {
  title: string;
  desc: string;
}

export const ProfessionalCleaning: React.FC<ProfessionalCleaningProps> = ({
  title,
  desc,
}) => {
  return (
    <section className="professional-cleaning">
      <ContainerComponent>
        <div className="professional-cleaning-text">
          <Title
            text={`Professional ${title} of Canada`}
            color="black"
            type="h2"
            isCentered
          />
          <p className="professional-cleaning-text__desc">
            Introducing Crystal Clear – your Canadian {desc} partner designed
            for homeowners, landlords, and business owners seeking spotless
            spaces. Tailor your cleaning with ease through customizable plans
            and seamless online booking. Our seasoned, insured, and vetted
            cleaning pros provide confidence, backed by a flexible 24-hour
            cancellation policy. What sets us apart? If you’re not ecstatic with
            our service, we’ll re-clean until it’s perfect. Elevate the quality
            of your life with our award-winning cleaning service today!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
