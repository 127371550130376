import { FormMyPrice } from '../../components/FormMyPrice';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { ActionButtons } from '../../modules/ActionButtons';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { HeroSection } from '../../modules/HeroSection';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { RecurringCleaning } from '../../modules/RecurringCleaning';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { OurStuff } from '../../modules/OurStuff';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { SvgIconSmileBeam } from '../../ui/SvgIcon';
import homeCleaningPageImage from '../../assets/heroSection/home-cleaning.jpg';
import { ProfessionalCleaning } from '../../modules/ProfessionalCleaning';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { ValuesCleaning } from '../../modules/ValuesCleaning';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { Reviews } from '../../modules/Reviews';
import { ServiceInclusions } from '../../modules/ServiceInclusions';
import { TrustedChoice } from '../../modules/TrustedChoice';
import { CleaningServicesDesc } from '../../modules/BookingForm/components/CleaningServicesDesc';
import { airbnbInclusionsBData } from '../../modules/ServiceInclusions/constants/airbnbInclusionsData';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { AccordionData } from '../../modules/FrequentlyQuestions/constants/AccordionData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

export const AirBnBCleaningPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="airBnBService" />
      <HeroSection
        title="AirBnB Cleaning Service"
        subtitle="Canada’s #1 AirBnB Cleaning Service. Guest-Ready Cleanliness, Ensuring 5-Star Stays, Every time!"
        imageSource={homeCleaningPageImage}
      />
      <ServiceInclusions inclusionsData={airbnbInclusionsBData} />
      <ProfessionalCleaning
        title="AirBnB and Short-Term Rental Cleaning Services"
        desc="AirBnB cleaning"
      />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <CleaningServicesDesc
        text="Do note, you can provide your own custom AirBnB Cleaning Checklist
        involving restocking, linen folding, dishwashing and more!"
      />
      <CleaningZones
        content={CleaningZonesData}
        title="Our AirBnB Cleaning Services Checklist"
      />
      <TrustedChoice />
      <OurStuff hasOnlyOneSection />
      <CleaningServicesText />
      <OurCleaningServices />
      <ServiceExclusions />
      <ValuesCleaning />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <RecurringCleaning />
      <FrequentlyQuestions questions={AccordionData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <WrapperMyPrice
        title="Empowering Cleaners, Elevating Homes"
        desc="If you're not happy, we'll work to make it right!"
        form={<FormMyPrice isMinimized />}
        icon={<SvgIconSmileBeam />}
      />
    </Main>
  );
};
