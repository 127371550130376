import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { Text } from './components/Text/Text';
import { getAltTextForImage } from '../../helpers';

import frame from '../../assets/gift-cards/brushstroke-frame.png';
import image from '../../assets/gift-cards/senior-couple-enjoying-their-kitchen-after-gifted-cleaning-service.jpg';

import './styles/GiftCards.scss';

export const GiftCards = () => {
  const altText = getAltTextForImage(image);

  return (
    <section className="gift-cards">
      <Container>
        <div className="gift-cards__wrapper">
          <Text />
          <a
            style={{
              background: `url(${image})
              center center / cover no-repeat`,
            }}
            href="/"
            className="gift-cards__image-wrapper"
          >
            <img className="gift-cards__image" src={frame} alt={altText} />
          </a>
        </div>
      </Container>
    </section>
  );
};
