import {
  CleaningInformationAdditionsEntity,
  RangesEntity,
} from '../../../../../types';

export const getDefaultValues = (id: number, firstRange: RangesEntity) => {
  const { label: name, price: totalPrice, quantity } = firstRange;
  const defaultValue: CleaningInformationAdditionsEntity = {
    id,
    name,
    quantity,
    totalPrice,
  };

  return defaultValue;
};
