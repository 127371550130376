import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonDataType } from '../../../types/buttonDataType';

interface ButtonProps {
  data: ButtonDataType;
}

export const Button: React.FC<ButtonProps> = ({ data }) => {
  const { link, color, text } = data;

  return (
    <Link to={link} className={`action-buttons_${color}`}>
      {text}
    </Link>
  );
};
