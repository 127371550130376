import { getAltTextForImage } from '../../helpers';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { images } from './constants/images';

import './styles/WeFeaturedOn.scss';

export const WeFeaturedOn = () => {
  return (
    <section className="we-featured">
      <Container>
        <div className="we-featured__wrapper">
          <h2 className="we-featured__title">We&apos;ve been featured on</h2>
          <div className="we-featured__images">
            {images.map((image) => {
              const altText = getAltTextForImage(image.imageSource);

              return (
                <a
                  key={altText}
                  className="we-featured__link"
                  href={image.link}
                >
                  <img
                    className="we-featured__image"
                    src={image.imageSource}
                    alt={altText}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};
