import React from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { CommercialFormValues } from '../type/CommercialFormValues';
import { CommercialInputData } from '../constants/CommercialInputData';
import { CommercialFormSquare } from './CommercialFormSquare';
import { SvgIconCanada } from '../../../ui/SvgIcon';
import { countryCode } from '../../BookingForm/constants/countryCode';

interface CommercialFormInputProps {
  register: UseFormRegister<CommercialFormValues>;
  errors: FieldErrors<CommercialFormValues>;
  setValue: UseFormSetValue<CommercialFormValues>;
}

export const CommercialFormInput: React.FC<CommercialFormInputProps> = ({
  register,
  errors,
  setValue,
}) => {
  const handlePhoneChange = (event: React.FormEvent<HTMLInputElement>) => {
    let input = event.currentTarget.value.replace(/\D/g, '');
    if (input.length > 3) {
      input = `${input.slice(0, 3)} ${input.slice(3)}`;
    }
    if (input.length > 7) {
      input = `${input.slice(0, 7)} ${input.slice(7, 11)}`;
    }
    setValue('phone', input);
  };
  return (
    <div className="commercial-form__input__wrapper">
      {CommercialInputData.map((input) => {
        return (
          <div key={input.name}>
            <input
              className={
                errors[input.name as keyof CommercialFormValues]
                  ? `commercial-form__input fieldset__input error`
                  : `commercial-form__input fieldset__input`
              }
              type={input.type}
              placeholder={input.placeholder}
              {...register(input.name as keyof CommercialFormValues)}
            />
            <span className="commercial-form__error-message">
              {errors[input.name as keyof CommercialFormValues]
                ? errors[input.name as keyof CommercialFormValues]?.message
                : ''}
            </span>
          </div>
        );
      })}
      <fieldset className="fieldset">
        <label className="fieldset__label" htmlFor="phone">
          <div className="phone-input__wrapper">
            <div
              className={
                errors.phone
                  ? 'phone-input__country error'
                  : 'phone-input__country'
              }
            >
              <SvgIconCanada />
              <span className="phone-input__country-code">{`+${countryCode}`}</span>
            </div>
            <input
              {...register('phone', {
                onChange: handlePhoneChange,
              })}
              id="phone"
              className={
                errors.phone
                  ? 'fieldset__input phone-input__input error'
                  : 'phone-input__input fieldset__input'
              }
              placeholder="123 456 7890"
              type="tel"
            />
          </div>
          <span className="commercial-form__error-message">
            {errors.phone ? errors.phone.message : ' '}
          </span>
        </label>
      </fieldset>
      <CommercialFormSquare setValue={setValue} />
    </div>
  );
};
