import React from 'react';
import { Link } from 'react-router-dom';
import { SlideDataType } from '../types/SlideDataType';

interface SwiperSlideContentProperties {
  slideData: SlideDataType;
}

export const SwiperSlideContent: React.FC<SwiperSlideContentProperties> = ({
  slideData,
}) => {
  const { title, description, buttonData, imageSource } = slideData;

  return (
    <>
      <div
        style={{
          background: `url(${imageSource})
          center center / cover no-repeat`,
        }}
        className="main-swiper__slide-image"
      />
      <div className="main-swiper__slide-card slide-card">
        <div className="slide-card__wrapper">
          <h3 className="slide-card__title">{title}</h3>
          <p className="slide-card__description">{description}</p>
          <div className="slide-card__divider" />
          {buttonData && (
            <Link className="slide-card__button" to={buttonData.link}>
              {buttonData.text}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
