import {
  SvgIconFlexibleScheduling,
  SvgIconInsuredVetted,
  SvgIconPeopleCentric,
  SvgIconQualityCleaning,
  SvgIconRate,
  SvgIconTechnology,
} from '../../../ui/SvgIcon';
import { featuresType } from '../types/featuresType';

export const features: featuresType[] = [
  {
    img: <SvgIconQualityCleaning />,
    title: 'Quality Cleaning',
    desc: 'Detailed cleaning guided by a comprehensive checklist',
  },
  {
    img: <SvgIconInsuredVetted />,
    title: 'Insured & Vetted',
    desc: 'Background checked cleaners hired for good character',
  },
  {
    img: <SvgIconFlexibleScheduling />,
    title: 'Flexible Scheduling',
    desc: 'Tailored cleaning plans that work around your schedule',
  },
  {
    img: <SvgIconTechnology />,
    title: 'Technology Backed',
    desc: 'Customize or reschedule your clean easily on our platform',
  },
  {
    img: <SvgIconRate />,
    title: 'Rate your Cleaning',
    desc: 'Continuous improvement for satisfaction at every visit',
  },
  {
    img: <SvgIconPeopleCentric />,
    title: 'People-Centric',
    desc: 'Cleaner empowerment, charity-driven, client focused',
  },
];
