import { v4 as uuid } from 'uuid';
import { ProfessionalCleaningServicesListItemEntity } from '../types/ProfessionalCleaningServicesDataEntity';
import {
  SvgIconCheckedCalendar,
  SvgIconClock,
  SvgIconUserShield,
} from '../../../ui/SvgIcon';

export const ProfessionalCleaningServicesData: ProfessionalCleaningServicesListItemEntity[] =
  [
    {
      id: uuid(),
      title: 'Trusted Cleaners',
      description:
        'Our background-checked, professional, and friendly cleaners.',
      icon: <SvgIconUserShield />,
    },
    {
      id: uuid(),
      title: 'Flexible Scheduling',
      description:
        'One-time deep cleanings, biweekly cleans, and more options!',
      icon: <SvgIconCheckedCalendar />,
    },
    {
      id: uuid(),
      title: '24hr Cancellation',
      description: 'Change of plans? No problem. We work around your schedule!',
      icon: <SvgIconClock />,
    },
  ];
