/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import {
  CleaningInformationAdditionsEntity,
  CleaningInformationEntity,
  ExtrasEntity,
} from '../../../types';
import { useBookingFormData } from '../../../hooks/useBookingFormData';
import { BookingExtraCardInput } from './BookingExtraCardInput';
import { MinMaxValues } from '../types/minMaxValues';

interface BookingExtraCardProperties {
  extra: ExtrasEntity;
}

export const BookingExtraCard: React.FC<BookingExtraCardProperties> = ({
  extra,
}) => {
  const [currentQuantity, setCurrentQuantity] = useState<number>(0);
  const [isQuantityInputShown, setIsQuantityInputShown] =
    useState<boolean>(false);
  const [minMaxValues, setMinMaxValues] = useState<MinMaxValues>({
    min: 0,
    max: 0,
  });
  const cardRef = useRef<HTMLDivElement>(null);
  const {
    removeChoosedAddition,
    formData: { cleaningInformation },
    setCleaningInformation,
  } = useBookingFormData();
  const { image, name, id, quantityBased } = extra;

  const handleCardClick = () => {
    if (!quantityBased) {
      setCurrentQuantity((prevQuantity) => (prevQuantity === 0 ? 1 : 0));
    }

    if (quantityBased) {
      setIsQuantityInputShown((prev) => !prev);
    }
  };

  useEffect(() => {
    if (currentQuantity > 0) {
      const { cleaningExtras } =
        cleaningInformation as CleaningInformationEntity;
      const isInFormData =
        cleaningExtras &&
        cleaningExtras.find((currentParameter) => id === currentParameter.id);
      let newExtras = [
        ...(cleaningExtras as CleaningInformationAdditionsEntity[]),
      ];
      const formattedExtra: CleaningInformationAdditionsEntity = {
        id: extra.id,
        name: extra.name,
        quantity: currentQuantity,
        totalPrice: 0,
      };

      if (!quantityBased) {
        formattedExtra.totalPrice = extra.price;
      }

      if (quantityBased) {
        formattedExtra.totalPrice = extra.price * currentQuantity;
      }

      if (!isInFormData) {
        newExtras.push(formattedExtra);
      } else {
        newExtras = cleaningExtras.filter(
          (currentExtra) => currentExtra.id !== id
        );
        newExtras.push(formattedExtra);
      }

      const newCleaningInformation: CleaningInformationEntity = {
        ...cleaningInformation,
        cleaningExtras: newExtras,
      };
      setCleaningInformation(newCleaningInformation);
    } else {
      removeChoosedAddition(id, 'cleaningExtras');
    }
  }, [currentQuantity]);

  useEffect(() => {
    const isSqft = name.includes('sqft');
    if (isSqft) {
      const minMaxNumbers = name.split(' ').reduce(
        (accum, currentWord) => {
          const normalizedNumber = currentWord
            .replace('.', '')
            .replace(',', '');
          const numbers = normalizedNumber.match(/\d+/g)?.map(Number);

          if (numbers) {
            if (name.includes('+')) {
              accum.min = Math.min(...numbers, accum.min);
              accum.max = 100000;
            } else if (name.includes('<')) {
              accum.max = Math.max(...numbers, accum.max);
              accum.min = 0;
            } else {
              accum.min = Math.min(...numbers, accum.min);
              accum.max = Math.max(...numbers, accum.max);
            }
          }

          return accum;
        },
        { min: Infinity, max: -Infinity }
      );
      setMinMaxValues(minMaxNumbers);
    } else {
      const minMaxNumbers: MinMaxValues = {
        min: 0,
        max: 5,
      };

      setMinMaxValues(minMaxNumbers);
    }

    setCurrentQuantity(minMaxValues.min);
  }, [name]);

  return (
    <div
      ref={cardRef}
      className={
        currentQuantity > 0 && !isQuantityInputShown
          ? 'fieldset__extra-card extra-card extra-card-active'
          : 'fieldset__extra-card extra-card'
      }
      onClick={handleCardClick}
    >
      {isQuantityInputShown ? (
        <BookingExtraCardInput
          id={id}
          setCurrentQuantity={setCurrentQuantity}
          setIsQuantityInputShown={setIsQuantityInputShown}
          cardRef={cardRef}
          minMaxValues={minMaxValues}
        />
      ) : (
        <img className="extra-card__image" src={image as string} alt={name} />
      )}
      <p className="extra-card__title">{name}</p>
    </div>
  );
};
