import React from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { fields } from '../../constants/fields';
import { BookingFieldsetInput } from '../../ui/BookingFieldsetInput';
import { BookingText } from '../../ui/BookingText';
import { BookingField } from '../../types/bookingFieldset';

interface BookingContactInfoProperties {
  register: UseFormRegister<BookingField>;
  setValue: UseFormSetValue<BookingField>;
  errors: FieldErrors<BookingField>;
  trigger: UseFormTrigger<BookingField>;
  formFields: BookingField;
}

export const BookingContactInfo: React.FC<BookingContactInfoProperties> = ({
  register,
  setValue,
  errors,
  formFields,
  trigger,
}) => {
  return (
    <fieldset className="booking__fieldset fieldset">
      <div className="fieldset__text-wrapper">
        <BookingText
          text="Step 1 of 5: Your Contact Information"
          option="title"
        />
        <BookingText
          text="This information will be used to contact you about your service."
          option="description"
        />
      </div>

      <div className="fieldset__input-wrapper">
        {Object.values(fields.contactInformation).map((field) => {
          const { name } = field;

          return (
            <BookingFieldsetInput
              key={name}
              fieldData={field}
              register={register}
              setValue={setValue}
              formFields={formFields}
              trigger={trigger}
              errors={errors}
            />
          );
        })}
      </div>
    </fieldset>
  );
};
