import React from 'react';
import { Title } from '../../../../ui/MainTitle';

interface TitleItemProps {
  title: string;
}

export const TitleItem: React.FC<TitleItemProps> = ({ title }) => {
  return <Title text={title} color="black" type="h2" />;
};
