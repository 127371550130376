import React, { useEffect, useState } from 'react';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { OurCleaningServicesItem } from './components/OurCleaningServicesItem/OurCleaningServicesItem';
import { OurCleaningServicesData } from './constants/OurCleaningServicesData';
import { breakpoints } from '../../constants';
import { OurCleaningServicesDataType } from './types/OurCleaningServicesDataType';
import { itemMobilNoDisplay } from './constants/itemMobilNoDisplay';
import { Title } from '../../ui/MainTitle';

import './styles/OurCleaningServices.scss';

interface OurCleaningServicesProperties {
  title?: string;
}

export const OurCleaningServices: React.FC<OurCleaningServicesProperties> = ({
  title = 'Our Cleaning Services',
}) => {
  const { windowWidth } = useWidthWindow();
  const [itemsCleaningServices, setItemsCleaningServices] = useState<
    OurCleaningServicesDataType[]
  >([]);

  useEffect(() => {
    if (windowWidth <= breakpoints.tablet) {
      const mobileData = OurCleaningServicesData.filter(
        (item) => item.title !== itemMobilNoDisplay
      );
      setItemsCleaningServices(mobileData);
    } else {
      setItemsCleaningServices(OurCleaningServicesData);
    }
  }, [windowWidth]);

  return (
    <section className="our-cleaning-services">
      <ContainerComponent>
        <div className="our-cleaning-services__wrapper">
          <Title text={title} type="h2" color="black" isCentered />
          <div className="our-cleaning-services__container">
            {itemsCleaningServices.map((item) => (
              <OurCleaningServicesItem key={item.title} item={item} />
            ))}
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
