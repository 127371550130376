import { Link } from 'react-router-dom';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { paths } from '../../constants';

import './styles/NotFoundModule.scss';
import { Title } from '../../ui/MainTitle';

export const NotFoundModule = () => {
  return (
    <section className="not-found">
      <Container>
        <div className="not-found__wrapper">
          <div className="not-found__image-wrapper">
            <div className="not-found__image" />
          </div>
          <div className="not-found__text-wrapper">
            <Title text="This place is forbidden" type="h1" color="black" />
            <p className="not-found__description">
              The page you were looking for doesn&apos;t exist, isn&apos;t
              avaliable or was loading incorrectly.
            </p>
            <Link className="not-found__button" to={paths.root}>
              Go to the main page
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};
