import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  HomePage,
  NorthYorkPage,
  NotFoundPage,
  TorontoPage,
  EtobicokePage,
  BookingPage,
  ThankYouPage,
  ContactUsPage,
  ReviewsPage,
  GiftCardsPage,
  AreasPage,
  ChecklistPage,
  CleaningServicePage,
  CondoCleaningPage,
  EcoCleaningPage,
  CleaningSeniorsPage,
  SpringCleaningPage,
  PostRenovationCleaningPage,
  MoveInCleaningPage,
  DeepCleaningPage,
  AirBnBCleaningPage,
  CleaningToolsPage,
  CommercialCleaningPage,
  MississaugaPage,
  VaughanPage,
  ScarboroughPage,
  MarkhamPage,
  RichmondHillPage,
  PickeringPage,
  AjaxPage,
  BramptonPage,
  MiltonPage,
  OshawaPage,
  UxbridgePage,
  WhitbyPage,
  CaledonPage,
  AuroraPage,
  NewmarketPage,
  KingPage,
  EastGwillimburyPage,
  BurlingtonPage,
  GeorginaPage,
  HaltonHillsPage,
  StouffvillePage,
  OakvillePage,
  LoginPage,
  RegisterPage,
  PrivacyPolicyPage,
  TermsConditionsPage,
  DashboardPage,
} from '../pages';
import { paths } from '../constants';

interface RoutesAppProperties {
  setUserName: React.Dispatch<React.SetStateAction<string>>;
}

export const RoutesApp: React.FC<RoutesAppProperties> = ({ setUserName }) => {
  const { services, areas } = paths;

  return (
    <Routes>
      <Route path={paths.root} element={<HomePage />} />
      <Route path={paths.book} element={<BookingPage />} />
      <Route path={paths.thankYou} element={<ThankYouPage />} />
      <Route path={paths.reviews} element={<ReviewsPage />} />
      <Route path={paths.giftCards} element={<GiftCardsPage />} />
      <Route path={paths.contactUs} element={<ContactUsPage />} />
      <Route path={paths.tools} element={<CleaningToolsPage />} />
      <Route
        path={paths.dashboard}
        element={<DashboardPage setUserName={setUserName} />}
      />
      <Route path={paths.login} element={<LoginPage />} />
      <Route path={paths.register} element={<RegisterPage />} />
      <Route path={paths.privacyPolicy} element={<PrivacyPolicyPage />} />
      <Route path={paths.termsConditions} element={<TermsConditionsPage />} />

      <Route path={areas.path}>
        <Route path="" element={<AreasPage />} />
        <Route path={areas.children.northYork} element={<NorthYorkPage />} />
        <Route path={areas.children.etobicoke} element={<EtobicokePage />} />
        <Route path={areas.children.toronto} element={<TorontoPage />} />
        <Route
          path={areas.children.mississauga}
          element={<MississaugaPage />}
        />
        <Route path={areas.children.vaughan} element={<VaughanPage />} />
        <Route
          path={areas.children.scarborough}
          element={<ScarboroughPage />}
        />
        <Route path={areas.children.markham} element={<MarkhamPage />} />
        <Route
          path={areas.children.richmondHill}
          element={<RichmondHillPage />}
        />
        <Route path={areas.children.pickering} element={<PickeringPage />} />
        <Route path={areas.children.ajax} element={<AjaxPage />} />
        <Route path={areas.children.brampton} element={<BramptonPage />} />
        <Route path={areas.children.milton} element={<MiltonPage />} />
        <Route path={areas.children.oshawa} element={<OshawaPage />} />
        <Route path={areas.children.caledon} element={<CaledonPage />} />
        <Route path={areas.children.aurora} element={<AuroraPage />} />
        <Route path={areas.children.newmarket} element={<NewmarketPage />} />
        <Route path={areas.children.whitby} element={<WhitbyPage />} />
        <Route path={areas.children.uxbridge} element={<UxbridgePage />} />
        <Route
          path={areas.children.haltonHills}
          element={<HaltonHillsPage />}
        />
        <Route path={areas.children.burlington} element={<BurlingtonPage />} />
        <Route path={areas.children.georgina} element={<GeorginaPage />} />
        <Route
          path={areas.children.stouffville}
          element={<StouffvillePage />}
        />
        <Route path={areas.children.oakville} element={<OakvillePage />} />
        <Route path={areas.children.king} element={<KingPage />} />
        <Route
          path={areas.children.eastGwillimbury}
          element={<EastGwillimburyPage />}
        />
      </Route>

      <Route path={services.path}>
        <Route path={services.children.checklist} element={<ChecklistPage />} />
        <Route
          path={services.children.cleaningServices}
          element={<CleaningServicePage />}
        />
        <Route
          path={services.children.ecoCleaning}
          element={<EcoCleaningPage />}
        />
        <Route
          path={services.children.deepCleaning}
          element={<DeepCleaningPage />}
        />
        <Route
          path={services.children.seniorsCleaning}
          element={<CleaningSeniorsPage />}
        />
        <Route
          path={services.children.springCleaning}
          element={<SpringCleaningPage />}
        />
        <Route
          path={services.children.moveInCleaning}
          element={<MoveInCleaningPage />}
        />
        <Route
          path={services.children.postRenovationCleaning}
          element={<PostRenovationCleaningPage />}
        />
        <Route
          path={services.children.airbnbCleaning}
          element={<AirBnBCleaningPage />}
        />
        <Route
          path={services.children.apartmentCleaning}
          element={<CondoCleaningPage />}
        />
        <Route
          path={services.children.commercialCleaning}
          element={<CommercialCleaningPage />}
        />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
