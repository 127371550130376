import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BookingField } from './types/bookingFieldset';
import { BookingContactInfo } from './components/BookingContactInfo/BookingContactInfo';
import { BookingFormHeader } from './components/BookingFormHeader/BookingFormHeader';
import { BookingCleaningTypes } from './components/BookingCleaningTypes/BookingCleaningTypes';
import { WhereWhenModule } from './components/WhereWhenModule';
import { BookingExtras } from './components/BookingExtras/BookingExtras';
import { useBookingFormData } from '../../hooks/useBookingFormData';
import { CleaningService } from '../../types';
import { SpecialInstructions } from './components/SpecialInstructions';
import { schemaBookingForm } from '../../validators';
import { BookingSummary } from './components/BookingSummary';
import { breakpoints, paths } from '../../constants';
import { BookingContentPanel } from './components/BookingContentPanel';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { BookingSubmitWrapper } from './components/BookingSubmitWrapper/BookingSubmitWrapper';
import { BookingFinalStep } from './components/BookingFinalStep/BookingFinalStep';
import { handleVerifyCaptcha } from '../../components/Captcha/helpers/captchaValidation';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import Api from '../../service/Api';

import './styles/BookingForm.scss';

export const BookingForm = () => {
  const isMounted = useRef<boolean>(false);
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const [cleaningServices, setCleaningServices] = useState<CleaningService[]>(
    []
  );
  const [isSubmited, setIsSubmitted] = useState<boolean>(false);
  const { formData } = useBookingFormData();
  const { windowWidth } = useWidthWindow();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm<BookingField>({
    resolver: yupResolver(schemaBookingForm),
    mode: 'onBlur',
    defaultValues: {
      frequencyId: 6,
      restriction: '',
      province: 'ON',
    },
  });
  const navigate = useNavigate();
  const formFields = watch();
  const isMobile = windowWidth <= breakpoints.desktop;
  const Summary = (
    <BookingSummary
      formFields={formFields}
      cleaningServices={cleaningServices}
    />
  );

  const onSubmit = async () => {
    try {
      const recaptchaValue = captchaRef.current?.getValue();
      if (recaptchaValue) {
        handleVerifyCaptcha(recaptchaValue);
        await Api.put('booking/new', formData);
        toast.success(
          'Your submission was successful. Our team will contact you as soon as possible.'
        );
        navigate(paths.thankYou);
      } else {
        toast.error('Please complete the captcha.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('Something went wrong, please try again later.');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // const result = await Api.get('booking/services');
        const result = await axios.get('./getService.json');
        setCleaningServices(result.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (isSubmited && isMounted.current) {
      if (Object.keys(errors).length > 0) {
        toast.error(
          'Please check the highlighted fields for any invalid data.'
        );
      }
    } else {
      isMounted.current = true;
    }
  }, [errors, isSubmited]);

  return (
    <Container>
      <div className="booking__container">
        <form
          className="booking"
          onSubmit={(event) => {
            event.preventDefault();
            setIsSubmitted(true);
            handleSubmit(onSubmit)();
          }}
        >
          <div className="booking__wrapper">
            <BookingFormHeader />
            <BookingContactInfo
              register={register}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
              formFields={formFields}
            />
            <WhereWhenModule
              register={register}
              trigger={trigger}
              errors={errors}
              setValue={setValue}
              formFields={formFields}
            />
            <BookingCleaningTypes
              setValue={setValue}
              register={register}
              errors={errors}
              cleaningServices={cleaningServices}
            />
            <BookingExtras cleaningServices={cleaningServices} />
            <SpecialInstructions register={register} formFields={formFields} />
            <BookingFinalStep captchaRef={captchaRef} />
            {isMobile && Summary}
            <BookingSubmitWrapper />
          </div>
        </form>
        <div className="booking__container__panel">
          <BookingContentPanel />
          {Summary}
        </div>
      </div>
    </Container>
  );
};
