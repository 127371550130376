import React from 'react';
import { featuresType } from '../../types/featuresType';

import './styles/SetsApartItem.scss';

interface SetsApartItemProps {
  item: featuresType;
}

export const SetsApartItem: React.FC<SetsApartItemProps> = ({ item }) => {
  return (
    <div className="item">
      <div className="item__img">{item.img}</div>
      <h3 className="item__title">{item.title}</h3>
      <p className="item__desc">{item.desc}</p>
    </div>
  );
};
