/* eslint-disable */
import ReCAPTCHA from 'react-google-recaptcha';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaFormContacUs } from '../../validators';
import Api from '../../service/Api';
import { handleVerifyCaptcha } from '../Captcha/helpers/captchaValidation';
import { ContactUsFormValues } from './types/ContactUsFormValues';
import { countryCode } from '../../modules/BookingForm/constants/countryCode';
import { SvgIconArrowDown, SvgIconCanada } from '../../ui/SvgIcon';
import { GoogleCaptcha } from '../Captcha/GoogleCaptcha';
import { subjectsFormData } from './constants/subjectsFormData';
import { Title } from '../../ui/MainTitle';

import './styles/FormContactUs.scss';

export const FormContactUs = () => {
  const isMounted = useRef<boolean>(false);
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ContactUsFormValues>({
    resolver: yupResolver(schemaFormContacUs),
    defaultValues: {
      subject: subjectsFormData[0],
    },
  });

  const [isSubmited, setIsSubmitted] = useState(false);
  const [isPlaceholderDisabled, setIsPlaceholderDisabled] = useState(false);

  const subjectField = watch('subject');

  const handlePhoneChange = (event: React.FormEvent<HTMLInputElement>) => {
    let input = event.currentTarget.value.replace(/\D/g, '');
    if (input.length > 3) {
      input = `${input.slice(0, 3)} ${input.slice(3)}`;
    }
    if (input.length > 7) {
      input = `${input.slice(0, 7)} ${input.slice(7, 11)}`;
    }
    setValue('phone', input);
  };

  const onSubmit = async (data: any) => {
    try {
      const recaptchaValue = captchaRef.current?.getValue();
      if (recaptchaValue) {
        handleVerifyCaptcha(recaptchaValue);
        await Api.post('email/new', data);
        toast.success(
          'Your submission was successful. Our team will contact you as soon as possible.'
        );
      } else {
        toast.error('Please complete the captcha.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('Something went wrong, please try again later.');
    }
  };

  const handleSubjectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setIsPlaceholderDisabled(true);
    setValue('subject', event.target.value);
  };

  useEffect(() => {
    if (isSubmited && isMounted.current) {
      if (Object.keys(errors).length > 0) {
        toast.error(
          'Please check the highlighted fields for any invalid data.'
        );
      }
    } else {
      isMounted.current = true;
    }
  }, [errors, isSubmited]);

  return (
    <form
      className="contact-us-form"
      onSubmit={(event) => {
        event.preventDefault();
        setIsSubmitted(true);
        handleSubmit(onSubmit)();
      }}
    >
      <Title text="Send us a Message!" type="h2" isCentered />
      <span className="contact-us-form__description">
        We&apos;ll get back to you ASAP
      </span>
      <fieldset className="fieldset fieldset_10-gap fieldset_two-in-row">
        <label className="fieldset__label" htmlFor="name">
          <input
            id="name"
            type="text"
            placeholder="Name"
            className={
              errors.name
                ? `fieldset__input name error`
                : `fieldset__input name`
            }
            {...register('name')}
          />
          <span className="fieldset__error-message">
            {errors.name ? errors.name.message : ' '}
          </span>
        </label>
        <label className="fieldset__label" htmlFor="email">
          <input
            id="email"
            type="email"
            placeholder="Email"
            className={
              errors.email
                ? `fieldset__input email error`
                : `fieldset__input email`
            }
            {...register('email')}
          />
          <span className="fieldset__error-message">
            {errors.email ? errors.email.message : ' '}
          </span>
        </label>
      </fieldset>
      <fieldset className="fieldset">
        <label className="fieldset__label" htmlFor="phone">
          <div className="phone-input__wrapper">
            <div
              className={
                errors.phone
                  ? 'phone-input__country error'
                  : 'phone-input__country'
              }
            >
              <SvgIconCanada />
              <span className="phone-input__country-code">{`+${countryCode}`}</span>
            </div>
            <input
              {...register('phone', {
                onChange: handlePhoneChange,
              })}
              id="phone"
              className={
                errors.phone
                  ? 'fieldset__input phone-input__input error'
                  : 'phone-input__input fieldset__input'
              }
              placeholder="123 456 7890"
              type="tel"
            />
          </div>
          <span className="fieldset__error-message">
            {errors.phone ? errors.phone.message : ' '}
          </span>
        </label>
      </fieldset>
      <fieldset className="fieldset">
        <label className="fieldset__label" htmlFor="subject">
          <div
            className={
              !errors.subject
                ? 'fieldset__select-wrapper'
                : 'fieldset__select-wrapper error'
            }
          >
            <select
              className="fieldset__select"
              id="subject"
              value={subjectField}
              {...register('subject', {
                onChange: (event) => {
                  handleSubjectChange(event);
                },
              })}
            >
              {subjectsFormData.map((subjectName) => {
                const isPlaceholder = subjectName === subjectsFormData[0];

                return (
                  <option
                    disabled={isPlaceholder && isPlaceholderDisabled}
                    key={subjectName}
                    value={subjectName}
                    className="fieldset__option"
                  >
                    {subjectName}
                  </option>
                );
              })}
            </select>
            <SvgIconArrowDown />
          </div>
          <span className="fieldset__error-message">
            {errors.subject ? errors.subject.message : ' '}
          </span>
        </label>
      </fieldset>
      <fieldset className="fieldset">
        <label className="fieldset__label" htmlFor="additionalMessage">
          <textarea
            className="contact-us-form__textarea"
            id="additionalMessage"
            placeholder="What can we help you?"
            {...register('additionalMessage')}
          />
          <span className="fieldset__error-message">
            {errors.additionalMessage ? errors.additionalMessage.message : ' '}
          </span>
        </label>
      </fieldset>
      <GoogleCaptcha captchaRef={captchaRef} />
      <button className="contact-us-form__button" type="submit">
        Send Message
      </button>
    </form>
  );
};
