import { LocationSectionType } from '../type/LocationSectionType';
import { companyInfo, paths } from '../../../constants';

export const LocationSectionData: LocationSectionType[] = [
  {
    province: 'ON',
    name: 'Toronto',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.toronto}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.toronto}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Etobicoke',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.etobicoke}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.etobicoke}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'North York',
    address: companyInfo.offices['north york'],
    officesMap: companyInfo.officesMap['north york'],
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.northYork}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.northYork}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Mississauga',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.milton}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.mississauga}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Vaughan',
    address: companyInfo.offices['north york'],
    officesMap: companyInfo.officesMap['north york'],
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.vaughan}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.vaughan}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Scarborough',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.scarborough}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.scarborough}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Brampton',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.brampton}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.brampton}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Richmond Hill',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.richmondHill}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.richmondHill}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Caledon',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.caledon}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.caledon}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Markham',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.markham}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.markham}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Aurora',
    address: companyInfo.offices['north york'],
    officesMap: companyInfo.officesMap['north york'],
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.aurora}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.aurora}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Newmarket',
    address: companyInfo.offices['north york'],
    officesMap: companyInfo.officesMap['north york'],
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.newmarket}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.newmarket}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'King',
    address: companyInfo.offices['north york'],
    officesMap: companyInfo.officesMap['north york'],
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.king}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.king}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'East Gwillimbury',
    address: companyInfo.offices['north york'],
    officesMap: companyInfo.officesMap['north york'],
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.eastGwillimbury}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.eastGwillimbury}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Stouffville',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.stouffville}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.stouffville}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Georgina',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.georgina}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.georgina}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Pickering',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.pickering}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.pickering}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Ajax',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.ajax}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.ajax}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Whitby',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.whitby}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.whitby}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Oshawa',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.oshawa}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.oshawa}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Uxbridge',
    address: companyInfo.offices.toronto,
    officesMap: companyInfo.officesMap.toronto,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.uxbridge}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.uxbridge}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Oakville',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.oakville}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.oakville}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Burlington',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.burlington}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.burlington}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Milton',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.milton}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.milton}`,
    },
    hours: companyInfo.hours,
  },
  {
    province: 'ON',
    name: 'Halton Hills',
    address: companyInfo.offices.etobicoke,
    officesMap: companyInfo.officesMap.etobicoke,
    link: {
      paths: `${paths.areas.path}/${paths.areas.children.haltonHills}`,
      url: `crystalclear.art${paths.areas.path}/${paths.areas.children.haltonHills}`,
    },
    hours: companyInfo.hours,
  },
];
