import { listText } from './constants/listText';
import { InSixtySecondsItem } from './ui/InSixtySecondsItem';

import './styles/InSixtySecondsList.scss';

export const InSixtySecondsList = () => {
  return (
    <ul className="in-sixty-seconds__list list">
      {listText.map((item) => {
        return <InSixtySecondsItem key={item.text} item={item} />;
      })}
    </ul>
  );
};
