import { breakpoints, companyInfo } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { ContactSection } from '../../modules/ContactSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { GiftCards } from '../../modules/GiftCards';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { MainWrapper } from '../../ui/MainWrapper';
import { RichmondHillPageDescription } from '../../modules/PageDescription';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { AboutSection } from '../../modules/AboutSection';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { GetQuote } from '../../modules/GetQuote';
import { MapWrapper } from '../../ui/MapWrapper';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { AreasTestimonialRichmondHillData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { AccordionDataRichmondHill } from '../../modules/FrequentlyQuestions/constants/AccordionDataRichmondHill';
import { AreaCleaning } from '../../modules/AreaCleaning';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

import imgRichmondHill from '../../assets/richmond-hill/richmond-hill.jpg';

export const RichmondHillPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;
  return (
    <MainWrapper>
      <DynamicBrowserHeaderTitle page="richmondHillArea" />
      <AreasTitle
        title="Cleaning Services Richmond Hill"
        city="Richmond Hill"
        subtitle="Expert Maid and House Cleaning Services – Hire Reliable Cleaners Near You in Richmond Hill!"
        imageSource={imgRichmondHill}
        areasTestimonial={AreasTestimonialRichmondHillData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <RichmondHillPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="richmondHill" />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Richmond Hill Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Richmond Hill" componentName="richmondHill" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Richmond Hill"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <AreaCleaning
        title="Cleaning Services across the GTA"
        map={<MapWrapper area="richmond-hill" />}
        description="Crystal Clear's cleaning services are tailored to sparkle your property in , Richmond Hill, York Region and all across the GTA. Whether you’re maintaining your condo, house, or office space, we’ve got you covered with our customizable cleaning plans!"
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataRichmondHill} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Richmond Hill"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="richmond-hill" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <GetQuote title="Try our Cleaning Services in Richmond Hill Today!" />
    </MainWrapper>
  );
};
