import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import { MainWrapper as Main } from '../ui/MainWrapper';
import { AuthSection } from '../modules/AuthSection';

export const RegisterPage = () => {
  return (
    <Main hasBackground>
      <DynamicBrowserHeaderTitle page="register" />
      <AuthSection type="register" />
    </Main>
  );
};
