import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const MississaugaPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Top-Rated House Cleaning Service in Mississauga"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Welcome to Crystal Clear Mississauga, where we understand the
            balance between a busy lifestyle and the desire for a clean,
            inviting home. From the picturesque waterfronts of{' '}
            <span className="page-description__text_bold">Port Credit</span> to
            the bustling{' '}
            <span className="page-description__text_bold">Square One</span>{' '}
            shopping centre,{' '}
            <Link
              className="page-description__link"
              to={companyInfo.officesMap.toronto}
            >
              Mississauga
            </Link>{' '}
            is a city of landmarks and living spaces as diverse as its people.
            Our mission? To deliver cleaning services that match the city’s
            dynamic spirit, ensuring every space, whether home or office,
            mirrors the cleanliness and energy Mississauga is known for.
          </p>
          <p className="page-description__text">
            With a professional team that’s knowledgeable about Mississauga’s
            specific needs—from high-rise condos near{' '}
            <span className="page-description__text_bold">
              Celebration Square
            </span>{' '}
            to family homes along the{' '}
            <span className="page-description__text_bold">Credit River</span>{' '}
            —we’re equipped with the latest in cleaning technology to provide a
            comprehensive service tailored just for you. Whether it’s
            maintaining the pristine condition of your living space with regular
            cleaning, undertaking a thorough{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep clean
            </Link>{' '}
            , or devising a{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.cleaningServices}`}
            >
              customized plan
            </Link>{' '}
            that fits your busy lifestyle, Crystal Clear is here to elevate your
            environment.
          </p>
          <p className="page-description__text">
            Make your Mississauga home or office shine,{' '}
            <Link className="page-description__link" to={paths.book}>
              book your cleaning online
            </Link>{' '}
            or call us at
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>{' '}
            today!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
