import { breakpoints, companyInfo } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { AreasTestimonialGeorginaData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { GeorginaPageDescription } from '../../modules/PageDescription';
import { OurStuff } from '../../modules/OurStuff';
import { MainSwiper } from '../../components/MainSwiper/MainSwiper';
import { TrustIndicatorsSection } from '../../modules/TrustIndicatorsSection';
import { TrustIndicatorsSectionData } from '../../modules/TrustIndicatorsSection/constants/TrustIndicatorsSectionData';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { CleaningZones } from '../../modules/CleaningZones';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { AboutSection } from '../../modules/AboutSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { AccordionDataGeorgina } from '../../modules/FrequentlyQuestions/constants/AccordionDataGeorgina';
import { ContactSection } from '../../modules/ContactSection';
import { MapWrapper } from '../../ui/MapWrapper';
import { GetQuote } from '../../modules/GetQuote';

import imgGeorgina from '../../assets/georgina-page/georgina.jpg';

export const GeorginaPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="georginaArea" />
      <AreasTitle
        title="Cleaning Services Georgina"
        city="Georgina"
        subtitle="Trusted Cleaning Services of Georgina – Professional and Local House Cleaners"
        imageSource={imgGeorgina}
        areasTestimonial={AreasTestimonialGeorginaData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <GeorginaPageDescription />
      <ThreeEasySteps
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="georgina" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <MainSwiper />
      {!isMobile && (
        <TrustIndicatorsSection content={TrustIndicatorsSectionData} />
      )}
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Georgina Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Georgina" componentName="georgina" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Georgina"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <AcrossCanada />
      <AboutSection />
      <ActionButtons hasBackground data={twoButtonsData} />
      <FrequentlyQuestions questions={AccordionDataGeorgina} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Georgina"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="georgina" />}
      />
      <GetQuote title="Try our Cleaning Services in Georgina Today!" />
    </Main>
  );
};
