import React from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormDataMyPriceType } from '../FormMyPrice/types/formDataMyPriceType';

interface InputPhoneProps {
  register: UseFormRegister<FormDataMyPriceType>;
  setValue: UseFormSetValue<FormDataMyPriceType>;
}

export const InputPhone: React.FC<InputPhoneProps> = ({
  register,
  setValue,
}) => {
  const handlePhoneChange = (e: React.FormEvent<HTMLInputElement>) => {
    let input = e.currentTarget.value.replace(/\D/g, '');
    if (input.length > 3) {
      input = `${input.slice(0, 3)} ${input.slice(3)}`;
    }
    if (input.length > 7) {
      input = `${input.slice(0, 7)} ${input.slice(7, 11)}`;
    }
    setValue('phone', input);
  };

  return (
    <input
      type="tel"
      placeholder="123 456 7890"
      {...register('phone')}
      onChange={handlePhoneChange}
    />
  );
};
