import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { SpecialInstructionsInput } from '../SpecialInstructions/components/SpecialInstructionsInput';
import { BookingField } from '../../types/bookingFieldset';

import './styles/DiscountCode.scss';

interface DiscountCodeProps {
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
}

export const DiscountCode: React.FC<DiscountCodeProps> = ({
  register,
  formFields,
}) => {
  return (
    <div className="discount-code">
      <div className="discount-code__text">
        <h3 className="discount-code__title">Discount Code?</h3>
        <p className="discount-code__desc">(optional)</p>
      </div>
      <div className="discount-code__actions">
        <SpecialInstructionsInput
          placeholder="Enter Code"
          name="discountCode"
          register={register}
          formFields={formFields}
        />
        <button className="discount-code__button" type="button">
          apply
        </button>
      </div>
    </div>
  );
};
