/* eslint-disable no-param-reassign */
import { CleaningInformationAdditionsEntity } from '../../../../../types';

export const getSubTotal = (
  parameters: CleaningInformationAdditionsEntity[],
  mainInformation: number
) => {
  const totalSum = parameters.reduce((acc, item) => {
    if (item.totalPrice) {
      acc += item.totalPrice;
    }

    return acc;
  }, 0);
  return totalSum + mainInformation;
};
