import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const OakvillePageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Welcome to Crystal Clear - Your Trusted Cleaning Services in Oakville"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Introducing Crystal Clear - your trusted cleaning service for
            Oakville, catering to homeowners, landlords, and business owners
            across this vibrant community. Crystal Clear is here to ensure your
            spaces are impeccably clean.
          </p>
          <p className="page-description__text">
            Life in Oakville can be busy, so we make it easy to customize your
            cleaning plan. Whether you need a one-time{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep clean
            </Link>{' '}
            or regular upkeep, we’ve got you covered. Booking is a breeze with
            our simple online system. Plus, our{' '}
            <span className="page-description__text_bold">
              flexible 24-hour cancellation policy
            </span>{' '}
            gives you peace of mind.
          </p>
          <p className="page-description__text">
            Our fully vetted, insured, and highly trained professionals treat
            your space with respect and care. We stand by our work with a
            satisfaction guarantee—if you&apos;re not happy, we&apos;ll make it
            right.
          </p>
          <p className="page-description__text">
            Ready to experience the Crystal Clear difference?{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning service today online
            </Link>{' '}
            or call{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>
            .
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
