import sinkAfter from '../../../assets/before-after-section/sink-after.jpg';
import sinkBefore from '../../../assets/before-after-section/sink-before.jpg';
import tapAfter from '../../../assets/before-after-section/tap-after.jpg';
import tapBefore from '../../../assets/before-after-section/tap-before.jpg';
import ovenAfter from '../../../assets/before-after-section/oven-after.png';
import ovenBefore from '../../../assets/before-after-section/oven-before.png';
import kitchenAfter from '../../../assets/before-after-section/kitchen-after.png';
import kitchenBefore from '../../../assets/before-after-section/kitchen-before.png';

export const BeforeAfterImg = [
  {
    before: sinkBefore,
    after: sinkAfter,
  },
  {
    before: tapBefore,
    after: tapAfter,
  },
  {
    before: ovenBefore,
    after: ovenAfter,
  },
  {
    before: kitchenBefore,
    after: kitchenAfter,
  },
];
