import { breakpoints } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { FormMyPrice } from '../../components/FormMyPrice';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { PageTitleBackground } from '../../modules/PageTitleBackground';
import { RecurringCleaning } from '../../modules/RecurringCleaning';
import { Reviews } from '../../modules/Reviews';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { SvgIconSmileBeam } from '../../ui/SvgIcon';
import { MainSwiper } from '../../components/MainSwiper/MainSwiper';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

export const ChecklistPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="checklist" />
      <PageTitleBackground title="Our Cleaning Checklist" />
      <CleaningZones
        content={CleaningZonesData}
        title="Our  Cleaning Services Checklist"
      />
      <ServiceExclusions />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <MainSwiper />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AcrossCanada />
      <RecurringCleaning />
      <InSixtySeconds />
      <Reviews title="Customer Reviews of Our Cleaning Services" />
      <WrapperMyPrice
        title="Try Our Cleaning Services Today!"
        desc="If you're not happy, we'll work to make it right!"
        form={<FormMyPrice isMinimized />}
        icon={<SvgIconSmileBeam />}
      />
    </Main>
  );
};
