import * as Yup from 'yup';
import { phoneRegExp } from './constants/phoneRegExp';
import { checkPhoneDigits } from './helpers/checkPhoneDigits';

export const schemaCommercialForm = Yup.object().shape({
  name: Yup.string().optional(),
  businessName: Yup.string().optional(),
  officeAddress: Yup.string().optional(),
  email: Yup.string()
    .email('Please write your email')
    .required('Please write your email'),
  phone: Yup.string()
    .matches(phoneRegExp, 'The phone must match the type: 123 456 7890')
    .test('Phone number must contain exactly 10 digits', checkPhoneDigits)
    .required('Please write your phone number'),
  squareFootage: Yup.number().optional(),
  frequency: Yup.string().optional(),
  washrooms: Yup.string().optional(),
  kitchennetes: Yup.string().optional(),
  additionalMessage: Yup.string().optional(),
});
