import React from 'react';
import { Link } from 'react-router-dom';
import { getAltTextForImage } from '../../../../helpers';
import { ItemsDataType } from '../../types/ItemsDataType';

import './styles/RecurringCleaningItem.scss';

interface RecurringCleaningItemProps {
  data: ItemsDataType;
}

export const RecurringCleaningItem: React.FC<RecurringCleaningItemProps> = ({
  data,
}) => {
  const altImg = getAltTextForImage(data.img);
  return (
    <Link className="recurring-cleaning-item" to="/">
      <div className="recurring-cleaning-item__img">
        <img alt={altImg} src={data.img} />
      </div>
      <p className="recurring-cleaning-item__text">{data.text}</p>
    </Link>
  );
};
