import { Link } from 'react-router-dom';

import logo from '../../../../assets/header/logoTransparent86x86.png';
import './styles/HeaderLogo.styles.scss';

export const HeaderLogo = () => {
  return (
    <Link className="logo" to="/">
      <img className="logo__image" src={logo} alt="Crystal Clear logo" />
    </Link>
  );
};
