import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import { PageTitleBackground } from '../modules/PageTitleBackground';
import { TermsConditionsSection } from '../modules/TermsConditionsSection';
import { MainWrapper as Main } from '../ui/MainWrapper';

export const TermsConditionsPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="termsConditions" />
      <PageTitleBackground title="Terms and Conditions" />
      <TermsConditionsSection />
    </Main>
  );
};
