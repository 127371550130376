import { twoButtonsData } from '../../constants/buttonSectionData';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { AreasTestimonialEtobicokeData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { EtobicokePageDescription } from '../../modules/PageDescription';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { OurStuff } from '../../modules/OurStuff';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { AreaCleaning } from '../../modules/AreaCleaning';
import { MapWrapper } from '../../ui/MapWrapper';
import { AboutSection } from '../../modules/AboutSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { ContactSection } from '../../modules/ContactSection';
import { GiftCards } from '../../modules/GiftCards';
import { Reviews } from '../../modules/Reviews';
import { GetQuote } from '../../modules/GetQuote';
import { AccordionDataEtobicoke } from '../../modules/FrequentlyQuestions/constants/AcordionDataEtobicoke';
import { companyInfo } from '../../constants';
import { IFrameEtobicoke } from '../../modules/ContactSection/components/IFrame';

import etobicokeImage from '../../assets/etobicoke-page/etobicoke-background-image.jpg';

export const EtobicokePage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="etobicokeArea" />
      <AreasTitle
        areasTestimonial={AreasTestimonialEtobicokeData}
        city="Etobicoke"
        title="Cleaning Services Etobicoke"
        imageSource={etobicokeImage}
        subtitle="Premier Maid and House Cleaning Services - Hire Reliable Cleaners Near You in Etobicoke Today!"
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <EtobicokePageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="etobicoke" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <CleaningServicesText />
      <OurCleaningServices title="Our Cleaning Services in Etobicoke Include" />
      <ReasonsTrust city="Etobicoke" componentName="etobicoke" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Etobicoke"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AreaCleaning
        title="Cleaning Services for Toronto's Six Districts"
        map={<MapWrapper area="etobicoke" />}
        description="Our cleaning services are tailored to meet the distinctive requirements of Etobicoke and its neighboring GTA areas. Whether you’re maintaining a high rise condo or ensuring your family house stays immaculate, our committed cleaning services are ready to tackle every aspect. With our professional and reliable approach, your Etobicoke space will retain its flawless condition, ensuring comfort and cleanliness wherever you reside!"
      />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataEtobicoke} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        address={companyInfo.offices.etobicoke}
        city="Etobicoke"
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.etobicoke}
        frame={<IFrameEtobicoke />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Reviews of our Cleaning Services in Etobicoke" />
      <GetQuote title="Try our Cleaning Services in Etobicoke Today!" />
    </Main>
  );
};
