import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BookingField } from '../types/bookingFieldset';
import { CleaningInstructionsEntity } from '../../../types';
import { useBookingFormData } from '../../../hooks/useBookingFormData';

interface BookingFieldsetTextareaProps {
  placeholder: string;
  register: UseFormRegister<BookingField>;
  name: keyof BookingField;
  formFields: BookingField;
}

export const BookingFieldsetTextarea: React.FC<
  BookingFieldsetTextareaProps
> = ({ placeholder, register, name, formFields }) => {
  const {
    setInstructionsInformation,
    formData: { cleaningIntstructions },
  } = useBookingFormData();

  const handleDataAddOnBlur = () => {
    const cleaningInstructions: CleaningInstructionsEntity = {
      ...cleaningIntstructions,
      [name]: formFields[name],
    };
    setInstructionsInformation(cleaningInstructions);
  };
  return (
    <textarea
      placeholder={placeholder}
      cols={200}
      rows={2}
      {...register(name, {
        onBlur: handleDataAddOnBlur,
      })}
    />
  );
};
