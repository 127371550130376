export const getServingAreas = (areas: string[], quantity: number) => {
  const servingAreas: string[] = [];
  const iterations = Math.min(quantity, areas.length);

  for (let i = 0; i < iterations; i++) {
    servingAreas.push(areas[i]);
  }

  return servingAreas;
};
