import { GuaranteesItem } from '../../components/GuaranteesItem';
import { servicesGuarantees } from '../../constants';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';

import './styles/CommercialCleaningTitle.scss';

export const CommercialCleaningTitle = () => {
  return (
    <section className="commercial-cleaning-title">
      <div className="commercial-cleaning-title__background" />
      <ContainerComponent>
        <div className="commercial-cleaning-title__container">
          <div className="commercial-cleaning-title__text">
            <Title text="Commercial & Office Cleaning Services" type="h1" />
            <p className="commercial-cleaning-title__desc">
              Canada’s Leading Commercial and Office Cleaning Services — Keeping
              Workspaces Pristine Across Ontario, Alberta, and BC
            </p>
          </div>
          <div className="commercial-cleaning-title__guarantees">
            {servicesGuarantees.map((text) => {
              return <GuaranteesItem key={text} text={text} color="white" />;
            })}
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
