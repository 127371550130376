import { provincesType } from '../types/provinceType';

export const provinces: provincesType[] = [
  {
    name: 'AB',
    tax: 13,
  },
  {
    name: 'BC',
    tax: 13,
  },
  {
    name: 'MB',
    tax: 13,
  },
  {
    name: 'NB',
    tax: 13,
  },
  {
    name: 'NL',
    tax: 13,
  },
  {
    name: 'NS',
    tax: 13,
  },
  {
    name: 'NT',
    tax: 13,
  },
  {
    name: 'NU',
    tax: 13,
  },
  {
    name: 'ON',
    tax: 13,
  },
  {
    name: 'PE',
    tax: 13,
  },
  {
    name: 'QC',
    tax: 13,
  },
  {
    name: 'SK',
    tax: 13,
  },
  {
    name: 'YT',
    tax: 13,
  },
];
