import {
  CleaningService,
  PricingParametersEntity,
  RangesEntity,
} from '../../../../../types';
import {
  CreatePricingParametersDataEntity,
  PricingParametersNewNameEntity,
} from '../types/CreatePricingParametersDataEntity';

const getLabelByQuantity = (count: number, priceParameterName: string) => {
  if (count === 1) {
    return `${count} ${priceParameterName}`;
  }

  if (count === 0) {
    return `No ${priceParameterName}s`;
  }

  return `${count} ${priceParameterName}s`;
};

const getPriceByName = (
  pricingParameterPrice: number,
  count: number,
  name: PricingParametersNewNameEntity
) => {
  if (name === 'Hour') {
    return count;
  }

  return pricingParameterPrice * count;
};

const createPricingParametersRanges = (
  pricingParameter: PricingParametersEntity
) => {
  const {
    quantityMinimum,
    quantityMaximum,
    name,
    price: pricingParameterPrice,
  } = pricingParameter;
  const pricingParameterRanges: RangesEntity[] = [];

  for (let i = quantityMinimum; i <= quantityMaximum; i++) {
    const label = getLabelByQuantity(i, name);
    const price = pricingParameterPrice
      ? getPriceByName(
          pricingParameterPrice,
          i,
          name as PricingParametersNewNameEntity
        )
      : 0;

    const pricingParameterValue = {
      label,
      price,
      quantity: i,
      duration: null,
    };

    pricingParameterRanges.push(pricingParameterValue);
  }

  return pricingParameterRanges;
};

export const createPricingParameters = (
  data: CreatePricingParametersDataEntity[],
  activeService: CleaningService
) => {
  const formattedPricingParameters: PricingParametersEntity[] = [];

  if (activeService.pricingParameters) {
    data.forEach((pricingParameter) => {
      const parameterInJSON = activeService.pricingParameters?.find(
        (parameter) => parameter.name === pricingParameter.oldName
      );
      if (parameterInJSON) {
        const { id, quantityMaximum, quantityMinimum, price } = parameterInJSON;
        const { newName: name } = pricingParameter;
        const newPricingParameter: PricingParametersEntity = {
          id,
          name,
          quantityMaximum,
          quantityMinimum,
          price,
        };
        newPricingParameter.ranges =
          createPricingParametersRanges(newPricingParameter);
        formattedPricingParameters.push(newPricingParameter);
      }
    });
  }

  if (activeService.hourly) {
    const {
      price,
      hourly: {
        quantityMinimum,
        quantityMaximum,
        minutesMaximum,
        minutesMinimum,
      },
    } = activeService;

    data.forEach((pricingParameter) => {
      const { id, newName: name } = pricingParameter;
      const newPricingParameter: PricingParametersEntity = {
        id: id || 0,
        name,
        quantityMaximum,
        quantityMinimum,
        price,
      };
      if (id && name === 'Hour') {
        newPricingParameter.quantityMaximum = minutesMaximum / 60;
        newPricingParameter.quantityMinimum = minutesMinimum / 60;
      }
      newPricingParameter.ranges =
        createPricingParametersRanges(newPricingParameter);
      formattedPricingParameters.push(newPricingParameter);
    });
  }

  return formattedPricingParameters;
};
