import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { paths } from '../../constants';

import './styles/PageDescription.scss';

export const BurlingtonPageDescription = () => {
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Reliable Cleaning Services of Burlington"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Welcome to our cleaning services, tailored specifically for the
            beautiful and bustling life in the{' '}
            <span className="page-description__text_bold">Burlington</span>{' '}
            community. We know that keeping your spaces clean and cozy is
            essential, whether you’re enjoying the stunning lakeside views or
            hosting friends and family. That’s why Crystal Clear is here to
            help, offering{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.cleaningServices}`}
            >
              personalized cleaning plans
            </Link>{' '}
            to fit your unique needs.
          </p>
          <p className="page-description__text">
            Our friendly, professional cleaning team comes ready with everything
            needed to make your home or office sparkle. Whether you’re looking
            for a regular touch-up, a thorough deep clean, or something in
            between, we’ve got you covered. Let’s work together to create a plan
            that suits your schedule and puts more time back into your life!{' '}
            <Link className="page-description__link" to={paths.book}>
              Customize your cleaning online
            </Link>{' '}
            today!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
