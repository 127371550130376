import { MainWrapper as Main } from '../../ui/MainWrapper';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { AreasTitle } from '../../modules/AreasTitle';
import { ActionButtons } from '../../modules/ActionButtons';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { NorthYorkPageDescription } from '../../modules/PageDescription';
import { AreasTestimonialNorthYorkData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { OurStuff } from '../../modules/OurStuff';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { AreaCleaning } from '../../modules/AreaCleaning';
import { MapWrapper } from '../../ui/MapWrapper';
import { AboutSection } from '../../modules/AboutSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { ContactSection } from '../../modules/ContactSection';
import { GiftCards } from '../../modules/GiftCards';
import { companyInfo } from '../../constants';
import { IFrameNorthYork } from '../../modules/ContactSection/components/IFrame';
import { AccordionDataNorthYork } from '../../modules/FrequentlyQuestions/constants/AcordionDataNorthYork';
import { Reviews } from '../../modules/Reviews';
import { GetQuote } from '../../modules/GetQuote';

import northYorkImage from '../../assets/north-york-page/north-york-background-image.jpg';

export const NorthYorkPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="northYorkArea" />
      <AreasTitle
        areasTestimonial={AreasTestimonialNorthYorkData}
        title="Cleaning Services North York"
        city="North York"
        imageSource={northYorkImage}
        subtitle="Professional Maid and House Cleaning Services - Hire Expert Cleaners Near You in North York!"
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <NorthYorkPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="northYork" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <CleaningServicesText />
      <OurCleaningServices title="Our Cleaning Services in North York Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="North York" componentName="northYork" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in North York"
        content={CleaningZonesData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <InSixtySeconds />
      <AreaCleaning
        title="Cleaning Services for Toronto's Six Districts"
        map={<MapWrapper area="north-york" />}
        description="Our cleaning services are specially designed to cater to the unique needs of North York and the surrounding GTA areas. Whether you're keeping a modern condo spotless or making sure your family home in the suburbs is impeccably clean, our dedicated cleaning services are equipped to handle every detail. With our professional and dependable approach, your space in North York will maintain its pristine condition, ensuring comfort and cleanliness wherever you are!"
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataNorthYork} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        address={companyInfo.offices['north york']}
        city="North York"
        hours={companyInfo.hours}
        officesMap={companyInfo.offices['north york']}
        frame={<IFrameNorthYork />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews
        title="Reviews of our Cleaning Services in North York"
        maxItems={2}
        swiper
      />
      <GetQuote title="Try our Cleaning Services in North York Today!" />
    </Main>
  );
};
