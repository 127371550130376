/* eslint-disable no-param-reassign */
export const scrollToElement = (
  container: HTMLElement,
  element: HTMLElement,
  duration: number
) => {
  const containerTop = container.getBoundingClientRect().top;
  const elementTop = element.getBoundingClientRect().top;
  const targetScroll =
    container.scrollTop +
    (elementTop - containerTop) -
    container.clientHeight / 2 +
    element.clientHeight / 2;
  const startScroll = container.scrollTop;
  const distance = targetScroll - startScroll;
  let startTime: number | null = null;

  const animation = (currentTime: number) => {
    if (startTime === null) {
      startTime = currentTime;
    }
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);

    container.scrollTop = startScroll + distance * progress;

    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  };

  requestAnimationFrame(animation);
};
