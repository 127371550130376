import React, { ReactElement } from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { AreaCleaningItem } from './components/AreaCleaningItem/AreaCleaningItem';
import { Title } from '../../ui/MainTitle';
import { SpecificArea } from '../../types';

import './styles/AreaCleaning.scss';

interface AreaCleaningProps {
  title: string;
  description?: string;
  map: ReactElement;
}

export const AreaCleaning: React.FC<AreaCleaningProps> = ({
  title,
  description,
  map,
}) => {
  const areasToronto = Object.values(SpecificArea);
  return (
    <div className="area-cleaning">
      <ContainerComponent>
        <div className="area-cleaning__container">
          <div className="area-cleaning__text">
            <Title text={title} type="h2" color="black" />
            <p className="area-cleaning__description">{description}</p>
            <ul className="area-cleaning__list">
              {areasToronto.map((area) => (
                <AreaCleaningItem key={area} area={area} />
              ))}
            </ul>
          </div>
          <div className="area-cleaning__map">{map}</div>
        </div>
      </ContainerComponent>
    </div>
  );
};
