import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import './styles/PageDescription.scss';

export const NewmarketPageDescription = () => {
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Reliable Cleaning Services of Newmarket"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Introducing Crystal Clear – your Newmarket cleaning partner designed
            for homeowners, landlords, and business owners seeking spotless
            spaces. Tailor your cleaning with ease through customizable plans
            and seamless online booking. Our seasoned, insured, and vetted
            cleaning pros provide confidence, backed by a flexible 24-hour
            cancellation policy. What sets us apart? If you’re not ecstatic with
            our service, we’ll re-clean until it’s perfect. Elevate the quality
            of your life with our award-winning cleaning service today!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
