import book from '../../../assets/three-easy-steps/couple-on-this-computer-booking-a-cleaning-service.jpg';
import cleaner from '../../../assets/three-easy-steps/house-cleaners-by-crystal-clear.jpg';
import relax from '../../../assets/three-easy-steps/happy-family-enjoying-a-clean-home.jpg';
import { ThreeEasyStepsVaughanType } from './ThreeEasyStepsVaughanType';

export const ThreeEasyStepsVaughanBook: ThreeEasyStepsVaughanType = {
  img: book,
  title: 'Book online',
  desc: 'Customize and book your cleaning online. 24 hr cancellation policy',
};

export const ThreeEasyStepsVaughanCleaner: ThreeEasyStepsVaughanType = {
  img: cleaner,
  title: 'Leave the stress to us',
  desc: 'Your home cleaned by a professional Vaughan house cleaner',
};

export const ThreeEasyStepsVaughanRelax: ThreeEasyStepsVaughanType = {
  img: relax,
  title: 'Enjoy what matters',
  desc: 'Rate your cleaning, relax, and prioritize what truly matters in Life',
};
