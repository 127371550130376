import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';

import './styles/PageTitleBackground.scss';

interface PageTitleBackgroundProps {
  title: string;
  hasTriangleShape?: boolean;
}

export const PageTitleBackground: React.FC<PageTitleBackgroundProps> = ({
  title,
  hasTriangleShape = false,
}) => {
  return (
    <section className="page-title-background">
      <ContainerComponent>
        <div
          className={
            hasTriangleShape
              ? 'page-title-background__wrapper page-title-background_triangle-shape'
              : 'page-title-background__wrapper'
          }
        >
          <Title text={title} type="h1" isCentered />
        </div>
      </ContainerComponent>
    </section>
  );
};
