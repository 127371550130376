import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BookingField } from '../../../types/bookingFieldset';
import { discountsType } from '../../../../../types/discountsType';

interface ButtonOftenProps {
  discounts: discountsType[];
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
  handleDataAddOnChange: () => void;
}

export const ButtonOften: React.FC<ButtonOftenProps> = ({
  discounts,
  register,
  formFields,
  handleDataAddOnChange,
}) => {
  const { frequencyId } = formFields;

  return (
    <div className="frequency__values-wrapper">
      {discounts.map((item) => {
        return (
          <label
            className={
              Number(frequencyId) === item.id
                ? 'frequency__label frequency__label-active'
                : 'frequency__label'
            }
            htmlFor={String(item.id)}
            key={item.id}
          >
            <input
              type="radio"
              value={item.id}
              {...register('frequencyId', {
                onChange: handleDataAddOnChange,
              })}
              id={String(item.id)}
              className={
                frequencyId === item.id
                  ? 'frequency__input frequency__input-active'
                  : 'frequency__input'
              }
            />
            <span className="frequency__text">{item.name}</span>
          </label>
        );
      })}
    </div>
  );
};
