import {
  SvgIconCheckBlack,
  SvgIconDeep,
  SvgIconMoveIn,
  SvgIconPlus,
} from '../../../ui/SvgIcon';
import { CleaningZonesType, TasksType } from '../type/CleaningZonesType';

import livingAreas from '../../../assets/checklist-page/living-area.jpg';
import kitchenArea from '../../../assets/checklist-page/kitchen-area.jpg';
import bathroomArea from '../../../assets/checklist-page/bathroom-area.jpg';
import additionalAreas from '../../../assets/checklist-page/additional-areas.jpg';

export const BedroomsTasksData: TasksType[] = [
  {
    icon: <SvgIconCheckBlack />,
    name: 'Dust and wipe table-top surfaces',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Sweep and mop floors',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Vacuum carpets',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean mirrors',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Dust furniture',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean hallways and staircases',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean glass on patio door & front door',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Empty bins',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Clean baseboards',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Clean under furniture',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Wipe window sills',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Clean window frames',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Wipe door frames',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Spot clean walls',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Clean and wipe vents',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Dusting ceiling',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Wiping fans',
  },
];

export const KitchenTasksData: TasksType[] = [
  {
    icon: <SvgIconCheckBlack />,
    name: 'Dust and wipe table-top surfaces',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean and shine sink',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Sweep and mop floors',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean and wipe appliances (exterior)',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean stovetop',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Empty bins',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Clean microwave interior',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Clean kitchen baseboards',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Wipe and clean backsplash',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Spot clean walls',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Clean behind appliances',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Clean range hood',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Clean and wipe vents',
  },
  {
    icon: <SvgIconMoveIn />,
    name: 'Dusting ceiling',
  },
];

export const BathroomTasksData: TasksType[] = [
  {
    icon: <SvgIconCheckBlack />,
    name: 'Wipe and sanitize all surfaces',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean and shine sink',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean mirrors',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean and sanitize toilets',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Sweep and mop floors',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean shower faucets',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Clean and scrub tub',
  },
  {
    icon: <SvgIconCheckBlack />,
    name: 'Empty bins',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Clean bathroom baseboards',
  },
  {
    icon: <SvgIconDeep />,
    name: 'Clean doors and cabinet exteriors',
  },
];

export const AdditionalTasksData: TasksType[] = [
  {
    icon: <SvgIconPlus />,
    name: 'Basement Area',
  },
  {
    icon: <SvgIconPlus />,
    name: 'Extra Kitchen',
  },
  {
    icon: <SvgIconPlus />,
    name: 'Inside cabinets',
  },
  {
    icon: <SvgIconPlus />,
    name: 'Inside fridge',
  },
  {
    icon: <SvgIconPlus />,
    name: 'Inside oven',
  },
  {
    icon: <SvgIconPlus />,
    name: 'Windows and tracks',
  },
  {
    icon: <SvgIconPlus />,
    name: 'Load Dishwasher',
  },
  {
    icon: <SvgIconPlus />,
    name: 'Change Bed Sheets',
  },
  {
    icon: <SvgIconPlus />,
    name: 'Load Laundry',
  },
];

export const CleaningZonesData: CleaningZonesType[] = [
  {
    img: livingAreas,
    title: 'Bedrooms & Living Areas',
    tasks: BedroomsTasksData,
  },
  {
    img: kitchenArea,
    title: 'Kitchen Area',
    tasks: KitchenTasksData,
  },
  {
    img: bathroomArea,
    title: 'Washroom Area',
    tasks: BathroomTasksData,
  },
  {
    img: additionalAreas,
    title: 'Additional Areas & Extras',
    tasks: AdditionalTasksData,
  },
];
