import { breakpoints, companyInfo } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { ContactSection } from '../../modules/ContactSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { GiftCards } from '../../modules/GiftCards';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { MississaugaPageDescription } from '../../modules/PageDescription';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { AboutSection } from '../../modules/AboutSection';
import { AccordionDataMississauga } from '../../modules/FrequentlyQuestions/constants/AccordionDataMississauga';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { GetQuote } from '../../modules/GetQuote';
import { MapWrapper } from '../../ui/MapWrapper';
import { AreasTestimonialMississaugaData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

import imgMississauga from '../../assets/mississauga-page/mississauga.png';

export const MississaugaPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="mississaugaArea" />
      <AreasTitle
        title="Cleaning Services Mississauga"
        city="Mississauga"
        subtitle="Professional Housekeeping | Trusted House Cleaning Service of Mississauga"
        imageSource={imgMississauga}
        areasTestimonial={AreasTestimonialMississaugaData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <MississaugaPageDescription />
      <ThreeEasySteps
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="mississauga" />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Mississauga Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Mississauga" componentName="mississauga" />
      <CleaningZones
        content={CleaningZonesData}
        title="Our Cleaning Services in Mississauga Include"
      />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataMississauga} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Mississauga"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="mississauga" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <GetQuote title="Try our Cleaning Services in Mississauga Today!" />
    </Main>
  );
};
