import React, { useEffect, useState } from 'react';
import { MapContainer, GeoJSON, TileLayer, Popup } from 'react-leaflet';
import axios from 'axios';
import { GeoJsonObject } from 'geojson';
import { MapBoundsSetter } from '../../../AreasMap/components/MapBounderSetter';
import { onEachLocations } from '../../helpers/onEachLocations';
import { colorPalette } from '../../../../constants';
import { calculateCentroid } from '../../helpers/calculateCentroid';

interface MapLocationsProps {
  ClickToArea: (name: string) => void;
  onCityHover: (
    cityName: string | null,
    coordinates: [number, number] | null
  ) => void;
  highlightedCity: { name: string; coordinates: [number, number] } | null;
  hoveredCity: string | null;
}

export const MapLocations: React.FC<MapLocationsProps> = ({
  ClickToArea,
  onCityHover,
  highlightedCity,
  hoveredCity,
}) => {
  const [geoData, setGeoData] = useState<GeoJsonObject>();

  useEffect(() => {
    (async () => {
      const result = await axios.get(`../locations/allLocations.geojson`);
      setGeoData(result.data);
    })();
  }, []);

  useEffect(() => {
    if (hoveredCity && geoData) {
      const feature = (geoData as any).features.find(
        (f: any) => f.properties.name === hoveredCity
      );

      if (feature) {
        const centroid = calculateCentroid(feature.geometry.coordinates[0]);
        onCityHover(hoveredCity, centroid);
      }
    } else {
      onCityHover(null, null);
    }
  }, [hoveredCity, geoData]);

  return (
    <>
      {geoData && (
        <MapContainer
          className="map__container"
          zoomControl={false}
          dragging={false}
          scrollWheelZoom={false}
          doubleClickZoom={false}
          attributionControl={false}
          touchZoom={false}
          boxZoom={false}
          keyboard={false}
        >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          />
          <MapBoundsSetter data={geoData} />
          <GeoJSON
            data={geoData}
            style={{
              fillColor: colorPalette.accentColor,
              color: colorPalette.errorColor,
              fill: true,
              weight: 3,
              fillOpacity: 0.2,
            }}
            onEachFeature={(feature, layer) =>
              onEachLocations(feature, layer, ClickToArea, onCityHover)
            }
          />
          {highlightedCity && (
            <Popup position={highlightedCity.coordinates}>
              {highlightedCity.name}
            </Popup>
          )}
        </MapContainer>
      )}
    </>
  );
};
