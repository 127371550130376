import React, { useState } from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { AccordionItem } from './components/AccordionItem';
import { AccordionDataType } from './type/AccordionDataType';
import { Title } from '../../ui/MainTitle';

import './styles/FrequentlyQuestions.scss';

interface FrequentlyQuestionsProps {
  questions: AccordionDataType[];
}

export const FrequentlyQuestions: React.FC<FrequentlyQuestionsProps> = ({
  questions,
}) => {
  const [openQuestion, setOpenQuestion] = useState<string>('');

  const onClick = (idQuestion: string) => {
    if (openQuestion === idQuestion) {
      setOpenQuestion('');
    } else {
      setOpenQuestion(idQuestion);
    }
  };

  return (
    <section className="frequently-questions">
      <ContainerComponent>
        <div className="frequently-questions__wrapper">
          <Title
            text="Frequently Asked Questions"
            type="h2"
            color="black"
            isCentered
          />
          <div className="frequently-questions__accordion">
            {questions.map((item) => (
              <AccordionItem
                key={item.id}
                item={item}
                clickQuestions={onClick}
                selected={openQuestion === item.id}
              />
            ))}
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
