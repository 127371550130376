import React from 'react';
import { getAltTextForImage } from '../../../../helpers';
import { imagesSrc } from './constants/images';

import './styles/TrustIndicators.scss';

interface TrustIndicatorsProperties {
  isMinimized?: boolean;
}

export const TrustIndicators: React.FC<TrustIndicatorsProperties> = ({
  isMinimized = false,
}) => {
  const formattedImagesData = imagesSrc;
  if (isMinimized) {
    formattedImagesData.length = 2;
  }

  return (
    <div className="differences-between-us__trust-indicators trust-indicators">
      {formattedImagesData.map((imageSource) => {
        const altText = getAltTextForImage(imageSource);

        return (
          <img
            key={altText}
            className="trust-indicators__image"
            src={imageSource}
            alt={altText}
          />
        );
      })}
    </div>
  );
};
