import { useRef, useState } from 'react';
import { LocationSectionArea } from './components/LocationSectionArea';
import { MapLocations } from './components/MapLocations';
import { LocationSectionData } from './constants/LocationSectionData';
import { LocationSectionType } from './type/LocationSectionType';
import { scrollToElement } from './helpers/scrollToElement';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { SvgIconList, SvgIconMarker } from '../../ui/SvgIcon';

import './styles/LocationSection.scss';

export const LocationSection = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= 700;

  const cardContainer = useRef<HTMLDivElement | null>(null);
  const openLocationRef = useRef<HTMLDivElement | null>(null);
  const [selectedLocation, setSelectedLocation] =
    useState<LocationSectionType>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedCity, setHighlightedCity] = useState<{
    name: string;
    coordinates: [number, number];
  } | null>(null);
  const [hoveredCity, setHoveredCity] = useState<string | null>(null);
  const [viewMap, setViewMap] = useState<boolean>(true);

  const quantityLocations = LocationSectionData.length;

  const ClickToArea = (name: string) => {
    const location = LocationSectionData.find((item) => item.name === name);
    setSelectedLocation(location);
    setIsOpen(true);
    setViewMap(false);

    setTimeout(() => {
      if (openLocationRef.current && cardContainer.current) {
        const container = cardContainer.current;
        const element = openLocationRef.current;
        scrollToElement(element, container);
      }
    }, 0);
  };

  const handleCityHover = (
    cityName: string | null,
    coordinates: [number, number] | null
  ) => {
    if (cityName && coordinates) {
      setHighlightedCity({ name: cityName, coordinates });
    } else {
      setHighlightedCity(null);
    }
  };

  const getViewMap = () => {
    if (viewMap) {
      setViewMap(false);
    } else {
      setViewMap(true);
    }
  };

  const Map = (
    <div className="location-section__map">
      <MapLocations
        ClickToArea={ClickToArea}
        onCityHover={handleCityHover}
        highlightedCity={highlightedCity}
        hoveredCity={hoveredCity}
      />
    </div>
  );

  const LocationList = (
    <div className="location-section__area-wrapper">
      {LocationSectionData.map((content) => {
        return (
          <div key={content.name}>
            <LocationSectionArea
              ClickToArea={ClickToArea}
              content={content}
              highlightedCity={highlightedCity}
              cardContainer={cardContainer}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              selectedLocation={selectedLocation}
              openLocationRef={openLocationRef}
              onCityHover={setHoveredCity}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="location-section">
      <div className="location-section__container" ref={cardContainer}>
        {!isOpen && (
          <div className="location-section__header">
            <h2 className="location-section__title">
              Proudly Serving these Locations & Surrounding in ON
            </h2>
          </div>
        )}
        <div className="location-section__content">
          {!viewMap && (
            <div className="location-section__length">
              {quantityLocations} locations
            </div>
          )}
          {viewMap && isMobile ? Map : LocationList}
        </div>
      </div>
      {!isMobile && Map}
      {isMobile && !isOpen && (
        <div className="location-section__action">
          <button className="location-section__button" onClick={getViewMap}>
            {viewMap ? (
              <>
                <SvgIconList />
                View List
              </>
            ) : (
              <>
                <SvgIconMarker /> View on Map
              </>
            )}
          </button>
        </div>
      )}
    </div>
  );
};
