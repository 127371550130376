import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import { ThankYou } from '../modules/ThankYou';
import { MainWrapper as Main } from '../ui/MainWrapper';

export const ThankYouPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="thankYou" />
      <ThankYou />
    </Main>
  );
};
