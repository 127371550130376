import { AreasTitle } from '../../modules/AreasTitle';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { AreasTestimonialAuroraData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { ActionButtons } from '../../modules/ActionButtons';
import { AuroraPageDescription } from '../../modules/PageDescription';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { breakpoints, companyInfo } from '../../constants';
import { OurStuff } from '../../modules/OurStuff';
import { TrustIndicatorsSection } from '../../modules/TrustIndicatorsSection';
import { TrustIndicatorsSectionData } from '../../modules/TrustIndicatorsSection/constants/TrustIndicatorsSectionData';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { AboutSection } from '../../modules/AboutSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { AccordionDataAurora } from '../../modules/FrequentlyQuestions/constants/AccordionDataAurora';
import { ContactSection } from '../../modules/ContactSection';
import { MapWrapper } from '../../ui/MapWrapper';
import { GiftCards } from '../../modules/GiftCards';
import { Reviews } from '../../modules/Reviews';
import { GetQuote } from '../../modules/GetQuote';

import imgAurora from '../../assets/aurora-page/aurora.png';

export const AuroraPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="auroraArea" />
      <AreasTitle
        title="Cleaning Services Aurora"
        city="Aurora"
        subtitle="Professional Cleaning Services of Aurora- Hire Expert House Cleaners Near You Today!"
        imageSource={imgAurora}
        areasTestimonial={AreasTestimonialAuroraData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AuroraPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="aurora" />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && (
        <TrustIndicatorsSection content={TrustIndicatorsSectionData} />
      )}
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Aurora Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Aurora" componentName="aurora" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Aurora"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataAurora} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Caledon"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="aurora" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews
        title="Reviews of our Cleaning Services in Aurora"
        maxItems={2}
        swiper
      />
      <GetQuote title="Try our Cleaning Services in Aurora Today!" />
    </Main>
  );
};
