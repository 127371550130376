export const scrollToElement = (
  element: HTMLDivElement,
  container: HTMLDivElement
) => {
  if (container && element) {
    const offsetTop = element.offsetTop - container.offsetTop;
    container.scrollTo({
      top: offsetTop,
      behavior: 'smooth',
    });
  }
};
