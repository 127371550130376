import React, { useEffect, useMemo, useState } from 'react';
import { useBookingFormData } from '../../../../hooks/useBookingFormData';
import { discounts } from '../../../../constants';
import { discountsType } from '../../../../types/discountsType';
import { formatDateTime } from './helpers/formatDateTime';
import {
  CleaningInformationAdditionsEntity,
  CleaningService,
} from '../../../../types';
import { getSubTotal } from './helpers/getSubTotal';
import { BookingSummaryPrice } from './components/BookingSummaryPrice';
import { provinces } from '../../../../constants/provinces';
import { BookingField } from '../../types/bookingFieldset';
import { BookingSummaryPriceParameter } from './components/BookingSummaryPriceParameter';

import './styles/BookingSummary.scss';
import { handleParameterName } from './helpers/handleParameterName';
import { formatString } from './helpers/formatString';

interface BookingSummaryProps {
  formFields: BookingField;
  cleaningServices: CleaningService[];
}

interface finalPriceType {
  subTotal: number;
  total: number;
  salesTax: number;
  recurring: number;
}

export const BookingSummary: React.FC<BookingSummaryProps> = ({
  formFields,
  cleaningServices,
}) => {
  const {
    formData: { cleaningInformation, dateInformation },
    setTotalPrice,
    setRecurringPrice,
  } = useBookingFormData();
  const [frequency, setFrequency] = useState<discountsType>();
  const [dateTime, setDateTime] = useState<string>();
  const [parameters, setParameters] =
    useState<CleaningInformationAdditionsEntity[]>();
  const { province: currentProvince, frequencyId } = formFields;
  const taxPercent = provinces.find(
    (province) => province.name === currentProvince
  )?.tax;
  const [finalPrice, setFinalPrice] = useState<finalPriceType>({
    subTotal: 0,
    total: 0,
    salesTax: 0,
    recurring: 0,
  });
  const choosedCleaningType = useMemo(() => {
    return cleaningServices.find(
      (currentService) => currentService.id === cleaningInformation?.id
    );
  }, [cleaningInformation]);
  const isCleaningByHour = choosedCleaningType?.id === 144;
  const parameterNameForHourlyCleaning = handleParameterName(
    choosedCleaningType,
    null,
    parameters
  );

  useEffect(() => {
    if (dateInformation?.date) {
      setDateTime(formatDateTime(dateInformation.date));
    }
  }, [dateInformation?.date]);

  useEffect(() => {
    if (frequencyId) {
      setFrequency(discounts.find((item) => item.id === Number(frequencyId)));
    }
  }, [frequencyId]);

  useEffect(() => {
    if (
      cleaningInformation &&
      cleaningInformation.cleaningExtras &&
      cleaningInformation.cleaningParameters
    ) {
      setParameters([
        ...cleaningInformation.cleaningParameters,
        ...cleaningInformation.cleaningExtras,
      ]);
    }
  }, [cleaningInformation]);

  useEffect(() => {
    let subTotal = 0;
    let discount = 0;
    let total = 0;

    if (parameters) {
      if (isCleaningByHour) {
        const cleanerParameter = parameters.find(
          (parameter) => parameter.id === 10001
        );
        const hourParameter = parameters.find(
          (parameter) => parameter.id === 10002
        );

        if (
          cleanerParameter &&
          hourParameter &&
          cleanerParameter.totalPrice &&
          hourParameter.totalPrice
        ) {
          subTotal = cleanerParameter.totalPrice * hourParameter.totalPrice;
        }
      } else {
        subTotal = getSubTotal(
          parameters,
          cleaningInformation?.price as number
        );
      }
    } else {
      subTotal = cleaningInformation?.price as number;
    }
    const taxAmount = (Number(taxPercent) / 100) * subTotal;
    total = taxAmount + subTotal;
    if (frequency) {
      discount = total * (frequency.value / 100);
    }
    const recurring = total - discount;

    setFinalPrice((prevPrice) => ({
      ...prevPrice,
      subTotal,
      salesTax: taxAmount,
      total,
      recurring,
    }));
    setTotalPrice(total);
    if (frequency?.value !== 0) {
      setRecurringPrice(recurring);
    }
  }, [parameters, frequency]);

  return (
    <section className="booking-summary">
      <div className="booking-summary__title">
        <h2>Booking Summary</h2>
      </div>
      <div className="booking-summary__main-information">
        <div className="booking-summary__type-cleaning">
          {cleaningInformation?.id !== 0 ? (
            <div className="booking-summary__type-cleaning__item booking-summary-text">
              <p>{cleaningInformation?.name}</p>
              <p>
                $
                {cleaningInformation?.price &&
                  cleaningInformation.price.toFixed(2)}
              </p>
            </div>
          ) : (
            <p>Choose type of cleaning...</p>
          )}
        </div>
        <div className="booking-summary__parameters">
          <ul className="booking-summary__parameters__wrapper">
            {!isCleaningByHour &&
              parameters?.map((parameter) => {
                const parameterName = handleParameterName(
                  choosedCleaningType,
                  parameter
                );

                return (
                  <BookingSummaryPriceParameter
                    key={parameter.id}
                    parameter={parameter}
                    parameterName={formatString(parameterName)}
                  />
                );
              })}
            {isCleaningByHour && (
              <BookingSummaryPriceParameter
                parameterName={parameterNameForHourlyCleaning}
                subTotal={finalPrice.subTotal}
              />
            )}
          </ul>
        </div>
        <div className="booking-summary__date booking-summary-text">
          {dateTime ? <p>{dateTime}</p> : <p>Choose service date...</p>}
        </div>
        <div className="booking-summary__frequency">
          {frequency ? <p>{frequency.name}</p> : <p>One time</p>}
        </div>
      </div>
      {cleaningInformation?.id !== 0 && (
        <BookingSummaryPrice
          subTotal={finalPrice.subTotal}
          salesTax={finalPrice.salesTax}
          frequency={frequency}
          total={Number(finalPrice.total)}
          recurring={finalPrice.recurring}
        />
      )}
    </section>
  );
};
