import reviewByCustomerAl from '../../../../../assets/reviews/cleaner-review-by-customer-al.png';
import reviewByCustomerRai from '../../../../../assets/reviews/cleaner-review-by-customer-rai.png';
import reviewByCustomerSara from '../../../../../assets/reviews/cleaner-review-by-customer-sara.png';
import reviewByCustomerOlivia from '../../../../../assets/reviews/cleaner-review-by-customer-olivia.png';
import { getAltTextForImage } from '../../../../../helpers';
import { ReviewsImagesType } from '../types/ReviewsImagesType';

export const ReviewsImages: ReviewsImagesType[] = [
  {
    alt: getAltTextForImage(reviewByCustomerAl),
    img: reviewByCustomerAl,
  },
  {
    alt: getAltTextForImage(reviewByCustomerRai),
    img: reviewByCustomerRai,
  },
  {
    alt: getAltTextForImage(reviewByCustomerSara),
    img: reviewByCustomerSara,
  },
  {
    alt: getAltTextForImage(reviewByCustomerOlivia),
    img: reviewByCustomerOlivia,
  },
];
