import React from 'react';
import {
  ContactInformationEntity,
  LocationInformationEntity,
} from '../../../../../types';

interface ContactInformationProps {
  contactInformation: ContactInformationEntity;
  locationInformation: LocationInformationEntity;
}

export const ContactInformation: React.FC<ContactInformationProps> = ({
  contactInformation,
  locationInformation,
}) => {
  const { address, apt, city, postal, province } = locationInformation;
  return (
    <div className="dashboard-section-item__block contact-information">
      <h3 className="dashboard-section-item__title">Contact Information</h3>
      <div className="dashboard-section-item__block__wrapper">
        <div className="dashboard-section-item__element dashboard-section-item__element__wrapper_grey">
          <p className="dashboard-section-item__element__title">Address:</p>
          <p className="dashboard-section-item__element__desc">{`${address} ${apt}, ${city}, ${province}, ${postal}`}</p>
        </div>
        <div className="dashboard-section-item__element">
          <p className="dashboard-section-item__element__title">Name:</p>
          <p className="dashboard-section-item__element__desc">{`${contactInformation.firstName} ${contactInformation.lastName}`}</p>
        </div>
        <div className="dashboard-section-item__element dashboard-section-item__element__wrapper_grey">
          <p className="dashboard-section-item__element__title">Email:</p>
          <p className="dashboard-section-item__element__desc">
            {contactInformation.email}
          </p>
        </div>
        <div className="dashboard-section-item__element">
          <p className="dashboard-section-item__element__title">Phone:</p>
          <p className="dashboard-section-item__element__desc">
            {contactInformation.phone}
          </p>
        </div>
      </div>
    </div>
  );
};
