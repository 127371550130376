import { BookingText } from '../../ui/BookingText';
import { BookingSubmitButton } from '../../ui/BookingSubmitButton';

export const BookingSubmitWrapper = () => {
  return (
    <div className="booking__submit-wrapper">
      <BookingText
        option="description"
        text="We will not charge your card until AFTER your service is complete and you will receive an email receipt instantly."
      />
      <BookingText
        option="description"
        text="If you selected a recurring cleaning service, your ongoing discount will
        begin to apply after the first cleaning."
      />
      <BookingText
        option="subtitle"
        text="By booking our services, you are agreeing to the terms and conditions."
      />
      <BookingSubmitButton />
    </div>
  );
};
