import { ChangeEvent } from 'react';
import { RangesEntity, CleaningService } from '../../../../../types';

export const getChoosedParameter = (
  event: ChangeEvent<HTMLSelectElement>,
  parameters: RangesEntity[]
) => {
  return (
    parameters.find((parameter) => parameter.label === event.target.value) ||
    null
  );
};

export const getChoosedCleaningService = (
  event: ChangeEvent<HTMLSelectElement>,
  cleaningServices: CleaningService[]
) => {
  return (
    cleaningServices.find((service) => service.name === event.target.value) ||
    null
  );
};
