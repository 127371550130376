import { companyInfo } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { AboutSection } from '../../modules/AboutSection';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { ContactSection } from '../../modules/ContactSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { AccordionDataMarkham } from '../../modules/FrequentlyQuestions/constants/AccordionDataMarkham';
import { GetQuote } from '../../modules/GetQuote';
import { GiftCards } from '../../modules/GiftCards';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { MapWrapper } from '../../ui/MapWrapper';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { AreasTestimonialMarkhamData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { MarkhamPageDescription } from '../../modules/PageDescription';
import { AreaCleaning } from '../../modules/AreaCleaning';

import imgMarkham from '../../assets/markham-page/markham-background-image.jpg';

export const MarkhamPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="markhamArea" />
      <AreasTitle
        title="Cleaning Services Markham"
        city="Markham"
        subtitle="Professional Housekeeping | Trusted House Cleaning Service of Markham"
        imageSource={imgMarkham}
        areasTestimonial={AreasTestimonialMarkhamData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <MarkhamPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="markham" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <CleaningServicesText />
      <OurCleaningServices title="Our Cleaning Services in Markham Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Markham" componentName="markham" />
      <CleaningZones
        title="Our Checklist of Cleaning Services in Markham"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <AreaCleaning
        title="Cleaning Services across the GTA"
        map={<MapWrapper area="markham" />}
        description="Crystal Clear's cleaning services are tailored to sparkle your property in , Richmond Hill, York Region and all across the GTA. Whether you’re maintaining your condo, house, or office space, we’ve got you covered with our customizable cleaning plans!"
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AcrossCanada />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataMarkham} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Markham"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="markham" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <GetQuote title="Try our Cleaning Services in Markham Today!" />
    </Main>
  );
};
