/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef } from 'react';
import { HeaderButton } from '../HeaderButton/HeaderButton';
import { HeaderMenu } from '../HeaderMenu/HeaderMenu';
import { breakpoints } from '../../../../constants';
import { useWidthWindow } from '../../../../hooks/useWidthWindows';
import { HeaderAuthButtonTypeEntity } from '../../types/HeaderButtonDataEntity';

import './styles/HeaderSideMenu.styles.scss';

interface HeaderSideMenuProperties {
  setIsSideMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  burgerButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  authButtonType: HeaderAuthButtonTypeEntity;
}

export const HeaderSideMenu: React.FC<HeaderSideMenuProperties> = ({
  setIsSideMenuOpen,
  burgerButtonRef,
  authButtonType,
}) => {
  const asideMenuRef = useRef<HTMLElement | null>(null);
  const { windowWidth } = useWidthWindow();

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      asideMenuRef.current &&
      burgerButtonRef.current &&
      !asideMenuRef.current.contains(event.target as Node) &&
      !burgerButtonRef.current.contains(event.target as Node)
    ) {
      event.stopPropagation();
      setIsSideMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <aside ref={asideMenuRef} className="aside">
      <div className="aside__wrapper">
        <HeaderMenu setIsSideMenuOpen={setIsSideMenuOpen} />
        {windowWidth < breakpoints.tablet && (
          <HeaderButton
            type={authButtonType}
            setIsSideMenuOpen={setIsSideMenuOpen}
          />
        )}
      </div>
    </aside>
  );
};
