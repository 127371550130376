import { getFormattedPhoneNumber } from '../helpers';
import { ButtonDataType } from '../types/buttonDataType';
import { companyInfo } from './companyInfo';
import { paths } from './paths';

export const threeButtonsData: ButtonDataType[] = [
  {
    text: 'About Us',
    link: '/',
    color: 'white',
  },
  {
    text: 'Book online',
    link: paths.book,
    color: 'yellow',
  },
  {
    text: `${companyInfo.phoneNumber}`,
    link: `tel:${getFormattedPhoneNumber(companyInfo.phoneNumber)}`,
    color: 'white',
  },
];

export const twoButtonsData: ButtonDataType[] = [
  {
    text: 'Book',
    link: paths.book,
    color: 'yellow',
  },
  {
    text: `${companyInfo.phoneNumber}`,
    link: `tel:${getFormattedPhoneNumber(companyInfo.phoneNumber)}`,
    color: 'white',
  },
];
