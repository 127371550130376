import { breakpoints, companyInfo } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { ContactSection } from '../../modules/ContactSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { GiftCards } from '../../modules/GiftCards';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { MainWrapper } from '../../ui/MainWrapper';
import { AjaxPageDescription } from '../../modules/PageDescription';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { AboutSection } from '../../modules/AboutSection';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { GetQuote } from '../../modules/GetQuote';
import { MapWrapper } from '../../ui/MapWrapper';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { AreasTestimonialAjaxData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { AccordionDataAjax } from '../../modules/FrequentlyQuestions/constants/AccordionDataAjax';

import imgPickering from '../../assets/pickering-page/pickering.jpg';

export const AjaxPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;
  return (
    <MainWrapper>
      <DynamicBrowserHeaderTitle page="ajaxArea" />
      <AreasTitle
        title="Cleaning Services Ajax"
        city="Ajax"
        subtitle="Reliable House Cleaning Tailored to Your Ajax Home – Satisfaction Guaranteed!"
        imageSource={imgPickering}
        areasTestimonial={AreasTestimonialAjaxData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AjaxPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="ajax" />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Ajax" componentName="ajax" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Ajax"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AcrossCanada />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataAjax} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Ajax"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="ajax" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <GetQuote title="Try our Cleaning Services in Ajax Today!" />
    </MainWrapper>
  );
};
