import { FormContactUs } from '../../components/FormContactUs';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { companyInfo } from '../../constants';
import { PhoneIcon } from '../Header/components/PhoneButton/ui/PhoneIcon';
import { getFormattedPhoneNumber } from '../../helpers';
import { SvgIconClock, SvgIconMail } from '../../ui/SvgIcon';
import { Title } from '../../ui/MainTitle';

import './styles/ContactUsSection.scss';

export const ContactUsSection = () => {
  const formattedPhoneNumber = getFormattedPhoneNumber(companyInfo.phoneNumber);

  return (
    <section className="contact-us">
      <Container>
        <Title isCentered text="contact us" type="h1" />
        <div className="contact-us__wrapper">
          <div className="contact-us__contacts contacts">
            <ul className="contacts__list">
              <li className="contacts__item">
                <PhoneIcon />
                <span className="contacts__text">Call Us</span>
                <a
                  className="contacts__link contacts__bottom"
                  href={`tel:${formattedPhoneNumber}`}
                >
                  {companyInfo.phoneNumber}
                </a>
              </li>
              <li className="contacts__item">
                <SvgIconMail />
                <span className="contacts__text">Email us</span>
                <p className="contacts__text-wrapper contacts__bottom">
                  <span className="contacts__text-wrapper__text">
                    Email for contacts
                  </span>
                  <a
                    className="contacts__link"
                    href={`mailto:${companyInfo.email}`}
                  >
                    {companyInfo.email}
                  </a>
                </p>
              </li>
              <li className="contacts__item">
                <SvgIconClock />
                <span className="contacts__text">Service Hours</span>
                <p className="contacts__hours contacts__bottom">
                  {companyInfo.hours}
                </p>
              </li>
            </ul>
          </div>
          <FormContactUs />
        </div>
      </Container>
    </section>
  );
};
