export const formatString = (input: string): string => {
  const parts = input.split(' ');
  const firstPart = parts[0];

  if (!isNaN(Number(firstPart))) {
    return `${firstPart} х ${parts.slice(1).join(' ')}`;
  }

  return input;
};
