import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from '../../../../constants';
import { getNameFromRoute } from '../../../../helpers';

import './styles/AreaItem.styles.scss';
import { additionalText } from '../../constants/addtionalText';

interface AreaItemProps {
  route?: string;
  isAreasLink?: boolean;
}

export const AreaItem: React.FC<AreaItemProps> = ({
  route = '',
  isAreasLink = false,
}) => {
  const {
    areas: { path: areasPath },
  } = paths;
  const formattedRoute = isAreasLink ? areasPath : `${areasPath}/${route}`;
  const name = isAreasLink
    ? additionalText.allLocations
    : getNameFromRoute(route);

  return (
    <li className="where-we-work__item">
      <Link to={formattedRoute} className="where-we-work__link">
        {name}
      </Link>
    </li>
  );
};
