import React from 'react';
import { useLocation } from 'react-router-dom';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { CustomBreadcrumbsItem } from './components/CustomBreadcrumbsItem';

import './styles/CustomBreadcrumbs.scss';

interface CustomBreadcrumbsProps {
  userName?: string;
}

export const CustomBreadcrumbs: React.FC<CustomBreadcrumbsProps> = ({
  userName,
}) => {
  const { pathname } = useLocation();

  return (
    <div className="custom-breadcrumbs">
      <Container>
        <CustomBreadcrumbsItem path={pathname} userName={userName} />
      </Container>
    </div>
  );
};
