import { twoButtonsData } from '../../constants/buttonSectionData';
import { AboutSection } from '../../modules/AboutSection';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { AreasTestimonialHaltonHillssData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { ContactSection } from '../../modules/ContactSection';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { GetQuote } from '../../modules/GetQuote';
import { GiftCards } from '../../modules/GiftCards';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { MapWrapper } from '../../ui/MapWrapper';
import { companyInfo } from '../../constants';
import { HaltonHillsPageDescription } from '../../modules/PageDescription';
import { AccordionDataHaltonHills } from '../../modules/FrequentlyQuestions/constants/AccordionDataHaltonHills';

import imageHaltonHills from '../../assets/halton-hills-page/halton-hill-background-image.png';

export const HaltonHillsPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="haltonHillArea" />
      <AreasTitle
        areasTestimonial={AreasTestimonialHaltonHillssData}
        city="Halton Hills"
        imageSource={imageHaltonHills}
        title="Cleaning Services Halton Hills"
        subtitle="Professional Cleaning Services of Halton Hills - Hire Expert House Cleaners Near You Today!"
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <HaltonHillsPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="haltonHills" />
      <CleaningServicesText />
      <OurCleaningServices title="Our Cleaning Services in Halton Hills Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Halton Hills" componentName="haltonHills" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Halton Hills"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataHaltonHills} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        address={companyInfo.offices.etobicoke}
        city="Halton Hills"
        frame={<MapWrapper area="halton-hills" />}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.etobicoke}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews
        title="Reviews of our Cleaning Services in Halton Hills"
        maxItems={2}
        swiper
      />
      <GetQuote
        title="Try our Cleaning Services in Halton Hills Today!"
        isTwoButtons
      />
    </Main>
  );
};
