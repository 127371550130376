import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { getAltTextForImage } from '../../helpers';

import team from '../../assets/about-section/cleaning-service-team-at-crystal-clear.png';
import office from '../../assets/about-section/crystal-clear-cleaning-company-office.png';
import './styles/AboutSection.scss';

export const AboutSection = () => {
  return (
    <section className="about-section">
      <ContainerComponent>
        <div className="about-section__wrapper">
          <div className="about-section__text">
            <div className="about-section__line" />
            <Title text="About Crystal Clear" type="h2" color="black" />
            <p className="about-section__desc">
              <span className="about-section__desc_bold">Since 2017</span>,
              Crystal Clear has been committed to transforming the cleaning
              experience, ensuring a hassle-free and sparkling clean home or
              office. With a focus on cleaner excellence and customer support,
              we’ve achieved thousands of 5 star reviews across multiple
              locations in Canada, while championing initiatives like{' '}
              <Link
                className="about-section__desc_link"
                to="https://www.ontariolivingwage.ca/"
              >
                Living Wage Canada
              </Link>{' '}
              and the{' '}
              <Link
                className="about-section__desc_link"
                to="https://cancer.ca/en"
              >
                Canadian Cancer Society
              </Link>
              .
            </p>
          </div>
          <div className="about-section__img">
            <div className="about-section__img__wrapper">
              <img alt={getAltTextForImage(team)} src={team} />
            </div>
            <div className="about-section__img__wrapper">
              <img alt={getAltTextForImage(office)} src={office} />
            </div>
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
