import React from 'react';
import { ThreeEasyStepsType } from '../../../../pages/constants/CondoCleaningPageData/ThreeEasyStepsType';
import { getAltTextForImage } from '../../../../helpers';

import './styles/ThreeEasyStepsItem.scss';

interface ThreeEasyStepsItemProps {
  item: ThreeEasyStepsType;
}

export const ThreeEasyStepsItem: React.FC<ThreeEasyStepsItemProps> = ({
  item,
}) => {
  const { img, title, desc } = item;
  const AltText = getAltTextForImage(img);
  return (
    <div className="three-easy-steps-item">
      <div className="three-easy-steps-item__img">
        <img alt={AltText} src={img} />
      </div>
      <h3 className="three-easy-steps-item__title">{title}</h3>
      <p className="three-easy-steps-item__desc">{desc}</p>
    </div>
  );
};
