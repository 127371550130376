/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { paths } from '../../../../constants';
import { MenuItem } from '../MenuItem/MenuItem';
import { ServicesLinkWithDropdown } from '../ServicesLinkWithDropDown/ServicesLinkWithDropdown';

import './styles/HeaderMenu.styles.scss';

interface HeaderMenuProperties {
  setIsSideMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderMenu: React.FC<HeaderMenuProperties> = ({
  setIsSideMenuOpen = () => {},
}) => {
  const {
    services: { path: servicesPath },
    areas: { path: areasPath },
    contactUs,
    giftCards,
  } = paths;
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(false);
  const navigationRoutes = [servicesPath, areasPath, giftCards, contactUs];

  return (
    <nav className="menu">
      <ul className="menu__list">
        {navigationRoutes.map((route) => {
          const isServicesLink = servicesPath === route;

          return (
            <Fragment key={route}>
              {isServicesLink ? (
                <ServicesLinkWithDropdown
                  route={route}
                  isDropdownShown={isDropdownShown}
                  setIsDropdownShown={setIsDropdownShown}
                  setIsSideMenuOpen={setIsSideMenuOpen}
                />
              ) : (
                <MenuItem
                  route={route}
                  type="menu"
                  setIsSideMenuOpen={setIsSideMenuOpen}
                />
              )}
            </Fragment>
          );
        })}
      </ul>
    </nav>
  );
};
