import { ContainerComponent } from '../../ui/ContainerComponent';
import { ProfessionalCommercialCleaningText } from './components/ProfessionalCommercialCleaningText';
import imgOffice from '../../assets/commercial-cleaning-page/commercial-cleaning-office.jpg';
import { getAltTextForImage } from '../../helpers';
import { CommercialGuaranteesData } from './constants/CommercialGuaranteesData';
import { GuaranteesItem } from '../../components/GuaranteesItem/GuaranteesItem';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { breakpoints } from '../../constants';

import './styles/ProfessionalCommercialCleaning.scss';

export const ProfessionalCommercialCleaning = () => {
  const altText = getAltTextForImage(imgOffice);
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <section className="professional-commercial-cleaning">
      <ContainerComponent>
        <div className="professional-commercial-cleaning__wrapper">
          {!isMobile && <ProfessionalCommercialCleaningText />}
          <div className="professional-commercial-cleaning__img">
            <img alt={altText} src={imgOffice} />
          </div>
          <p className="professional-commercial-cleaning__subtitle">
            We provide commercial cleaning to all office sizes and premises
          </p>
          {!isMobile && (
            <div className="professional-commercial-cleaning__guarantees">
              {CommercialGuaranteesData.map((text) => (
                <GuaranteesItem key={text} text={text} />
              ))}
            </div>
          )}
        </div>
      </ContainerComponent>
    </section>
  );
};
