export const combineDateTime = (date: Date, timeStr: string) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const [time, period] = timeStr.split(' ')[0].split(':');
  let hours = parseInt(time, 10);
  const minutes = parseInt(timeStr.match(/:(\d+)/)?.[1] || '0', 10);

  if (period.includes('PM') && hours < 12) hours += 12;
  if (period.includes('AM') && hours === 12) hours = 0;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${year}-${month}-${day}T${formattedHours}:${formattedMinutes}:00`;
};
