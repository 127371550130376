export const WhereWhenTimeDesc = () => {
  return (
    <p className="where-when__desc">
      <strong>Important:</strong> this is the time for{' '}
      <strong>
        <i>arrival</i>
      </strong>
      , not the cleaning duration. Cleaning appointments can vary from 2-6 hours
      depending on property size and cleaning requirements. If you can&apos;t
      find a suitable day or time, please give us a call.
    </p>
  );
};
