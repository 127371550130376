import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { GiftCardFormInputData } from '../constants/GiftCardFormInputData';
import { FormDataGiftCardType } from '../type/FormDataGiftCardType';

interface GiftCardFormInputProps {
  errors: FieldErrors<FormDataGiftCardType>;
  register: UseFormRegister<FormDataGiftCardType>;
}

export const GiftCardFormInput: React.FC<GiftCardFormInputProps> = ({
  errors,
  register,
}) => {
  return (
    <>
      {GiftCardFormInputData.map((item) => {
        return (
          <label
            htmlFor={item.name}
            key={item.name}
            className="gift-card-form__label"
          >
            <span className="gift-card-form__label__text">{item.name} *</span>
            <div className="gift-card-form__label__container">
              <input
                id={item.name}
                className={
                  errors[item.name as keyof FormDataGiftCardType]
                    ? 'gift-card-form__label__input error'
                    : 'gift-card-form__label__input'
                }
                type={item.type}
                {...register(item.name as keyof FormDataGiftCardType)}
              />
              <span className="gift-card-form__error-message">
                {errors[item.name as keyof FormDataGiftCardType]
                  ? errors[item.name as keyof FormDataGiftCardType]?.message
                  : ''}
              </span>
            </div>
          </label>
        );
      })}
    </>
  );
};
