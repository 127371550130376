import { Link } from 'react-router-dom';
import { paths } from '../../../constants';

export const ReasonsTrustListGeorgina = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Skilled Professionals:</span>{' '}
        Exceptional cleaning with a personal touch in Georgina.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Eco-friendly Solutions:
        </span>
        Tailored{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
        >
          green cleaning
        </Link>{' '}
        for your needs (upon request)
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          100% Satisfaction Guaranteed:
        </span>{' '}
        Complete happiness with every clean.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Competitive Pricing:</span>{' '}
        Top-quality service at great rates.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Vetted Cleaners:</span>{' '}
        Professional, background-checked cleaners.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Easy Booking:</span>{' '}
        Schedule, quote, and{' '}
        <Link className="reasons-trust-list__link" to={paths.book}>
          customize effortlessly online.
        </Link>
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">24/7 Support:</span> Seamless
        communication from start to finish.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Proven Quality:</span>{' '}
        Celebrated by Georgina residents in{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          reviews
        </Link>
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Top Local Choice:</span>{' '}
        Georgina’s preferred home cleaning company.
      </li>
    </ul>
  );
};
