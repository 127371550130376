import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import { MainWrapper as Main } from '../ui/MainWrapper';
import { AuthSection } from '../modules/AuthSection';

export const LoginPage = () => {
  return (
    <Main hasBackground>
      <DynamicBrowserHeaderTitle page="login" />
      <AuthSection type="login" />
    </Main>
  );
};
