/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { BookingField, FieldDefinition } from '../types/bookingFieldset';
import { BookingCheckboxInput } from './BookingCheckboxInput';
import { handleContactInformationOnBlur } from '../helpers/handleContactInformationOnBlur';
import { BookingPhoneInput } from './BookingPhoneInput';
import {
  ContactInformationEntity,
  LocationInformationEntity,
} from '../../../types';
import { useBookingFormData } from '../../../hooks/useBookingFormData';

interface BookingFieldsetInputProperties {
  fieldData: FieldDefinition;
  register: UseFormRegister<BookingField>;
  setValue?: UseFormSetValue<BookingField>;
  trigger: UseFormTrigger<BookingField>;
  errors: FieldErrors<BookingField>;
  formFields: BookingField;
}

export const BookingFieldsetInput: React.FC<BookingFieldsetInputProperties> = ({
  fieldData,
  register,
  setValue,
  errors,
  trigger,
  formFields,
}) => {
  const {
    setContactInformation,
    setLocationInformation,
    formData: { contactInformation, locationInformation },
  } = useBookingFormData();
  const { name, type, placeholder } = fieldData;
  const isPhoneInput = name === 'phone' && setValue;
  const isEmailInput = name === 'email';
  const isSmsInput = name === 'useSms' && setValue;

  const handleDataAddOnBlur = () => {
    if (fieldData.category === 'contactInformation') {
      const newContactInformation: ContactInformationEntity = {
        ...contactInformation,
        [name]: formFields[name],
      };
      setContactInformation(newContactInformation);
    }

    if (fieldData.category === 'locationInformation') {
      const newLocationInformation: LocationInformationEntity = {
        ...locationInformation,
        [name]: formFields[name],
      };
      setLocationInformation(newLocationInformation);
    }
  };

  return (
    <>
      {!isSmsInput ? (
        <label
          htmlFor={name}
          className={
            isPhoneInput ? `fieldset__label` : `fieldset__label ${name}`
          }
        >
          {isPhoneInput ? (
            <BookingPhoneInput
              formFields={formFields}
              fieldData={fieldData}
              register={register}
              setValue={setValue}
              trigger={trigger}
              errors={errors}
            />
          ) : (
            <input
              {...register(name, {
                onBlur: isEmailInput
                  ? async () => {
                      const isValid = await trigger(name);

                      if (isValid) {
                        await handleContactInformationOnBlur(
                          name,
                          formFields,
                          errors,
                          setContactInformation
                        );
                      }
                    }
                  : handleDataAddOnBlur,
              })}
              id={name}
              className={
                errors[name]
                  ? `fieldset__input ${name} error`
                  : `fieldset__input ${name}`
              }
              placeholder={placeholder}
              type={type}
            />
          )}
          <span className="fieldset__error-message">
            {errors[name] ? errors[name]?.message : ' '}
          </span>
        </label>
      ) : (
        <BookingCheckboxInput
          register={register}
          fieldData={fieldData}
          setValue={setValue}
        />
      )}
    </>
  );
};
