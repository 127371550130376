import { ContainerComponent } from '../../ui/ContainerComponent';
import './styles/CleaningServicesText.scss';

export const CleaningServicesText = () => {
  return (
    <ContainerComponent>
      <p className="cleaning-services-text">Comprehensive Cleaning Services</p>
    </ContainerComponent>
  );
};
