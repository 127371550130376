/* eslint-disable no-continue */
export const getTitleFromString = (value: string) => {
  let title = '';

  for (let i = 0; i < value.length; i++) {
    if (i === 0) {
      title += value[i].toUpperCase();
      continue;
    }

    if (value[i] === value[i].toUpperCase()) {
      title += ` ${value[i]}`;
      continue;
    }

    title += value[i];
  }

  return title;
};
