import { Link } from 'react-router-dom';
import { companyInfo, paths } from '../../../../constants';
import { getFormattedPhoneNumber } from '../../../../helpers';
import './styles/NavTouch.scss';

export const NavTouch = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);

  return (
    <div className="nav-touch">
      <p className="nav-touch__title">Get in Touch</p>
      <button className="nav-touch_call">
        <a href={`tel:${formattedPhone}`}>{companyInfo.phoneNumber}</a>
      </button>
      <button className="nav-touch_email">
        <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
      </button>
      <Link className="nav-touch_book" to={paths.book}>
        Book Online
      </Link>
      <Link className="nav-touch_cards" to={paths.giftCards}>
        Gift Cards 🎁
      </Link>
      <Link className="nav-touch_review" to={paths.reviews}>
        Our Reviews
      </Link>
    </div>
  );
};
