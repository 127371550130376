import { SvgIconCheck, SvgIconLeaf, SvgIconShield } from '../../../ui/SvgIcon';

export const AreasGuaranteesData = [
  {
    text: 'Quality Maid Service',
    svg: <SvgIconCheck />,
  },
  {
    text: 'Verified House Cleaners',
    svg: <SvgIconShield />,
  },
  {
    text: 'Leading Cleaning Service in ',
    svg: <SvgIconLeaf />,
  },
];
