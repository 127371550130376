import forbes from '../../../assets/we-featured-on/forbes-logo.png';
import newYorkPost from '../../../assets/we-featured-on/new-york-post-logo.png';
import marthaStewart from '../../../assets/we-featured-on/martha-stewart-logo.png';
import redfin from '../../../assets/we-featured-on/redfin-logo.png';
import apartmentTherapy from '../../../assets/we-featured-on/apartment-therapy-logo.png';
import realHomes from '../../../assets/we-featured-on/real-homes-logo.png';
import pureWow from '../../../assets/we-featured-on/pure-wow-logo.png';
import southernLiving from '../../../assets/we-featured-on/southern-living-logo.png';
import readersDigest from '../../../assets/we-featured-on/readers-digest-logo.png';
import yahoo from '../../../assets/we-featured-on/yahoo-logo.png';
import womansWorld from '../../../assets/we-featured-on/womans-world-logo.png';
import homesAndGardens from '../../../assets/we-featured-on/homes-and-gardens-logo.png';
import wikiHow from '../../../assets/we-featured-on/wiki-how-logo.png';

export const images = [
  { imageSource: forbes, link: '/' },
  { imageSource: newYorkPost, link: '/' },
  { imageSource: marthaStewart, link: '/' },
  { imageSource: redfin, link: '/' },
  {
    imageSource: apartmentTherapy,
    link: '/',
  },
  { imageSource: realHomes, link: '/' },
  { imageSource: pureWow, link: '/' },
  { imageSource: southernLiving, link: '/' },
  { imageSource: readersDigest, link: '/' },
  { imageSource: yahoo, link: '/' },
  { imageSource: womansWorld, link: '/' },
  {
    imageSource: homesAndGardens,
    link: '/',
  },
  { imageSource: wikiHow, link: '/' },
];
