import React from 'react';
import { AuthFormTypeEntity } from './types/AuthFormDataEntity';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { AuthForm } from './components/AuthForm';

import './styles/AuthSection.scss';

interface AuthSectionProperties {
  type: AuthFormTypeEntity;
}

export const AuthSection: React.FC<AuthSectionProperties> = ({ type }) => {
  return (
    <section className="auth">
      <Container>
        <div className="auth__wrapper">
          <AuthForm type={type} />
          <div className="auth__image-wrapper">
            <div className="auth__image" />
          </div>
        </div>
      </Container>
    </section>
  );
};
