import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageNameEntity } from './types/BrowserHeaderTitleDataEntity';
import { BrowserHeaderTitleData } from './constants/BrowserHeaderTitleData';

interface DynamicBrowserHeaderTitleProperties {
  page: PageNameEntity;
}

export const DynamicBrowserHeaderTitle: React.FC<
  DynamicBrowserHeaderTitleProperties
> = ({ page }) => {
  return (
    <Helmet>
      <title>{BrowserHeaderTitleData[page]}</title>
    </Helmet>
  );
};
