import { AreasTestimonialType } from '../type/AreasTestimonialType';

import imgMississauga from '../../../assets/mississauga-page/areas-testimonial/review-for-crystal-clear-cleaning-services-mississauga.png';
import imgVaughan from '../../../assets/vaughan-page/areas-testimonial/vaughan-testimonial-by-Deanna-for-house-cleaning-services.png';
import imgScarborough from '../../../assets/scarboroug-page/client-testimonial-for-crystal-clear-cleaning-services-of-scarborough.png';
import imgMarkham from '../../../assets/markham-page/client-testimonial-for-crystal-clear-cleaning-services-of-markham.jpg';
import imgToronto from '../../../assets/toronto-page/client-testimonial-for-crystal-clear-cleaning-services-of-toronto.jpg';
import imgEtobicoke from '../../../assets/etobicoke-page/client-testimonial-for-crystal-clear-cleaning-services-of-etobicoke.jpg';
import imgNorthYork from '../../../assets/north-york-page/client-testimonial-for-crystal-clear-cleaning-services-of-north-york.jpg';
import imgAjax from '../../../assets/ajax-page/ajax-smiling-with-satisfaction-after-crystal-clear-cleaned-her-home.png';
import imgRichmondHill from '../../../assets/richmond-hill/testimonial-for-crystal-clear-cleaning-services-in-richmond-hill.png';
import imgPickering from '../../../assets/pickering-page/provided-a-testimonial-for-crystal-clear-cleaning-services.png';
import imgBrampton from '../../../assets/brampton-page/smiling-middle-aged-woman-with-short-blonde-hair-representing-a-satisfied-customer-from-brampton-on.png';
import imgMilton from '../../../assets/milton-page/kala-testimonial-for-crystal-clear-cleaning-services-milton.png';
import imgOshawa from '../../../assets/oshawa-page/karen-t-from-oshawa-on-after-crystal-clear-cleaning.png';
import imgUxbridge from '../../../assets/uxbridge-page/uxbridge-testimonial.jpg';
import imgWhitby from '../../../assets/whitby-page/client-testimonial-for-crystal-clear-cleaning-services-of-whitby.jpg';
import imgCaledon from '../../../assets/caledon-page/caledon-testimonial.jpg';
import imgAurora from '../../../assets/aurora-page/aurora-testimonial.jpg';
import imgNewmarket from '../../../assets/newmarket-page/newmarket-testimonial.jpg';
import imgKing from '../../../assets/king-page/king-testimonial.jpg';
import imgEastGwillimbury from '../../../assets/east-gwillimbury/east-gwillimbury-testimonial.jpg';
import imgHaltonHills from '../../../assets/halton-hills-page/client-testimonial-for-crystal-clear-cleaning-services-of-halton-hill.jpg';
import imgBurlington from '../../../assets/burlington-page/burlington-testimonial.jpg';
import imgGeorgina from '../../../assets/georgina-page/georgina-testimonial.jpg';
import imgStouffville from '../../../assets/stouffville-page/stouffville-testimonial.jpg';
import imgOakville from '../../../assets/oakville-page/client-testimonial-for-crystal-clear-cleaning-services-of-oakville.jpg';

export const AreasTestimonialMississaugaData: AreasTestimonialType = {
  text: 'Lara and Claudia did a fantastic job cleaning our house. The cleaning ladies were on time, efficient and very friendly. Highly recommend their cleaning services in Sauga, will definitely have them back!',
  img: imgMississauga,
  name: 'Kimberly W.',
  city: 'Mississauga',
};

export const AreasTestimonialVaughanData: AreasTestimonialType = {
  text: "Greeted by Jordana with a huge smile and very professional. She was on time, tidy, and enthusiastic to take on the job! I was very comfortable and confident in our house cleaner to do a fabulous job. I could tell she's happy working for Crystal Clear cleaning services. I would certainly recommend this cleaning company in Vaughan to anyone",
  img: imgVaughan,
  name: 'Deanna D.',
  city: 'Vaughan',
};

export const AreasTestimonialScarboroughData: AreasTestimonialType = {
  text: "Really enjoyed the cleaning service we booked with Crystal Clear Fast communication, wonderful maids, and my house is ready for my wife's return after a month being in the hospital. Was urgently needing to find a trustworthy house cleaning service in Scarborough, and I found them! Thank you again Crystal Clear team",
  img: imgScarborough,
  name: 'John S.',
  city: 'Scarborough',
};

export const AreasTestimonialMarkhamData: AreasTestimonialType = {
  text: 'Very happy with this cleaning service. Fast communication, lovely maids and my house is perfectly ready for my wife&apos;s arrival after a month long stay in the hospital. Really needed to find reliable house cleaning services near me in Markham, and I found them. Thank you Crystal Clear!',
  img: imgMarkham,
  name: 'Joon-ho L.',
  city: 'Markham',
};

export const AreasTestimonialTorontoData: AreasTestimonialType = {
  text: 'SO incredibly happy! This was my first time using a house cleaning service and I was blown away by the attention to detail. What a fun surprise to see the toilet paper turned into a flower! I`m just so happy with how clean my house is and I can`t wait for them to come back next month!',
  img: imgToronto,
  name: 'Marci G.',
  city: 'Toronto',
};

export const AreasTestimonialNorthYorkData: AreasTestimonialType = {
  text: 'I get my house cleaned once a month and love coming home to a clean home. The quality of work remains high. The cleaning ladies are friendly and efficient. The communication and reminders from the office are clear and their web site makes it easy to make changes to your schedule. Best cleaning company in Toronto hands down!',
  img: imgNorthYork,
  name: 'Wendy H.',
  city: 'North York',
};

export const AreasTestimonialEtobicokeData: AreasTestimonialType = {
  text: 'For a first time cleaning service with Crystal Clear, I am very happy with the results! Katherine was very thorough, hit all the dusty spots and was very comfortable with the dog at the front door to greet her. I am very happy with this company!',
  img: imgEtobicoke,
  name: 'Ashley K.',
  city: 'Etobicoke',
};

export const AreasTestimonialAjaxData: AreasTestimonialType = {
  text: 'Crystal Clear exceeded my expectations when they came to clean our family home in Pickering Village, Ajax. We had just finished a messy renovation, and I was worried the dust and debris would never come out. But Mary and the team were thorough and left our place spotless, even getting into those tricky corners! I’ll definitely be calling them again. Thanks, Crystal Clear!',
  img: imgAjax,
  name: 'Jane D.',
  city: 'Ajax',
};

export const AreasTestimonialRichmondHillData: AreasTestimonialType = {
  text: 'I had family stay for 3 months with 2 dogs. I needed a deep clean when they left. Hannah and Kerry-Ann were fantastic. Dog hair gone from carpets, floors and furniture. Fridge and stove sparkle. Kitchen and bathrooms fresh. I feel so clean! Best house cleaning service in Richmond Hill, will recommend.',
  img: imgRichmondHill,
  name: 'Lori T.',
  city: 'Richmond Hill',
};

export const AreasTestimonialPickeringData: AreasTestimonialType = {
  text: "After a hectic week, the last thing I wanted to deal with was a messy home. I booked Crystal Clear for a deep clean of my living room and kitchen. The team arrived right on time and left the place spotless. The floors were gleaming, and even those hard-to-reach corners were dust-free. I couldn't believe how thorough they were! I'll definitely be calling them again for regular cleanings.",
  img: imgPickering,
  name: 'James M.',
  city: 'Pickering',
};

export const AreasTestimonialBramptonData: AreasTestimonialType = {
  text: "Absolutely delighted with Crystal Clear's service. After a big family reunion, our home needed a thorough clean, especially the living room where we had most of the gatherings. The team arrived on time and left everything sparkling clean. It felt like a brand new space when they were done. Highly recommend their services!",
  img: imgBrampton,
  name: 'Linda M.',
  city: 'Brampton',
};

export const AreasTestimonialMiltonData: AreasTestimonialType = {
  text: "Just WOW!! I've used many home cleaning services before, and this was hands down the BEST cleaning service I have ever had! Olivia on time, she was very friendly, professional and incredibly thorough! She even cleaned my doggies eating area for her! ♥️ If you need a professional house cleaning service in Milton, look no further!",
  img: imgMilton,
  name: 'Kala G.',
  city: 'Milton',
};

export const AreasTestimonialOshawaData: AreasTestimonialType = {
  text: 'I’m extremely pleased with the service I received from Crystal Clear. The team was professional, friendly, and did a fantastic job cleaning my home. Every corner was spotless, and they paid attention to the little details that really make a difference. I will definitely be using their services again and would recommend them to anyone looking for a reliable cleaning company',
  img: imgOshawa,
  name: 'Karen T.',
  city: 'Oshawa',
};

export const AreasTestimonialUxbridgeData = {
  text: "I've had CrystalClear clean my home twice now and I'm very happy with the service. I appreciate that the house cleaners arrive on time, bring their gear and supplies, work thoroughly and effectively. I also love the easy online booking!",
  img: imgUxbridge,
  name: 'Ayesha M.',
  city: 'Uxbridge',
};

export const AreasTestimonialCaledonData: AreasTestimonialType = {
  text: 'Ladies were on time, brought their own supplies, were very pleasant to interact with, and did a great job. They cleaned everything they were supposed to, and it did well. I couldn’t ask for anything more. Also, I LOVE how easy it is to book online, and you find out the price right there. I live such a busy life, so the fact that I could just do it all online, without having to talk to anyone on the phone to set it up, was super efficient and I love it. Such an easy, amazing, reliable cleaning service!',
  img: imgCaledon,
  name: 'Caitlin C.',
  city: 'Caledon',
};

export const AreasTestimonialAuroraData: AreasTestimonialType = {
  text: 'Crystal Clear did a fantastic job! Linda and her team made my home super clean. The kitchen and bathrooms look amazing. I highly recommend them!',
  img: imgAurora,
  name: 'Mary L.',
  city: 'Aurora',
};

export const AreasTestimonialNewmarketData: AreasTestimonialType = {
  text: 'Extremely pleased with the cleaning service. As a busy professional, I needed someone to clean my apartment before hosting a dinner party. They did a phenomenal job, leaving the place spotless and ready for my guests. Grateful to have found such a dependable and thorough service!',
  img: imgNewmarket,
  name: 'John T.',
  city: 'Newmarket',
};

export const AreasTestimonialWhitbyData: AreasTestimonialType = {
  text: "I needed a thorough clean before hosting my parents' anniversary dinner, and Crystal Clear delivered beyond expectations. They focused on the kitchen and living room, and I was amazed at how spotless everything was—especially the tile grout that I hadn't seen so clean in years. I highly recommend Crystal Clear to anyone in Whitby looking for a reliable and meticulous cleaning service.",
  img: imgWhitby,
  name: 'Mike S.',
  city: 'Whitby',
};

export const AreasTestimonialOakvilleData: AreasTestimonialType = {
  text: 'Company is great to work with. The cleaners are on time, bring their own cleaning supplies - are careful and thoughtful with my home. Alexandra really takes her time and does a thorough job - and also super sweet to talk to and is happy to do added tasks.',
  img: imgOakville,
  name: 'Allia Akkad',
  city: 'Oakville',
};

export const AreasTestimonialBurlingtonData: AreasTestimonialType = {
  text: "Hosting a family reunion left our home in a mess. The cleaning team arrived on time and transformed our kitchen and living room. Their attention to detail and efficiency were outstanding. We're now considering home cleaning services with Crystal Clear!",
  img: imgBurlington,
  name: 'Theresa R.',
  city: 'Burlington',
};

export const AreasTestimonialGeorginaData: AreasTestimonialType = {
  text: 'Crystal Clear cleaning services did a fantastic job of doing a deep cleaning of my home. The house cleaners were thorough with their work and friendly, always had a smile while they were working. Some of the stains were hard to remove but they persisted. End result was amazing. Highly recommend them to anyone.',
  img: imgGeorgina,
  name: 'Becca C.',
  city: 'Georgina',
};

export const AreasTestimonialStouffvilleData: AreasTestimonialType = {
  text: "I recently had Crystal Clear clean my home, and I couldn't be happier. The team tackled the kitchen and bathrooms with incredible detail, leaving everything spotless and fresh. I needed a thorough cleaning before my family visited, and Crystal Clear delivered beyond my expectations. Their efficiency and professionalism have convinced me to become a regular customer. Highly recommend!",
  img: imgStouffville,
  name: 'Michael T.',
  city: 'Stouffville',
};

export const AreasTestimonialHaltonHillssData: AreasTestimonialType = {
  text: 'I was thrilled with the service from Crystal Clear. After an extensive renovation in our home, I needed a thorough cleaning service to get rid of all the dust and debris. Stephanie and her team arrived promptly and did an exceptional job. The kitchen and living room were spotless, and they even managed to make our hardwood floors shine like new. I will definitely be using Crystal Clear again for all our cleaning needs!',
  img: imgHaltonHills,
  name: 'Stephanie M.',
  city: 'Hallton Hill',
};

export const AreasTestimonialKingData: AreasTestimonialType = {
  text: 'With a busy schedule and two kids at home, I was struggling to keep up with the housework. I hired Crystal Clear to clean our house in Kingston, and I’m beyond impressed! The kitchen, especially the greasy stovetop and oven, looks spotless now—something I couldn’t achieve on my own. The team was friendly, efficient, and left my home looking pristine. I’m definitely booking them again!',
  img: imgKing,
  name: 'Robert T.',
  city: 'King',
};

export const AreasTestimonialEastGwillimburyData: AreasTestimonialType = {
  text: 'After finishing up a bathroom renovation, our Guelph condo was left with a thick layer of dust. We had Crystal Clear come by to clean our unit and it was a positive experience!  Everything looked great when I got home. Things were beautifully organized and tidy.  Their staff is polite, professional, and gave great advice for maintaining clean floors.  I’d definitely recommend their cleaning services in East Gwillimbury!',
  img: imgEastGwillimbury,
  name: 'Tracey S.',
  city: 'East Gwillimbury',
};
