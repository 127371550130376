import { MainWrapper as Main } from '../../ui/MainWrapper';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { AreasTitle } from '../../modules/AreasTitle';
import { ProfessionalCleaningServices } from '../../modules/ProfessionalCleaningServices';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { ActionButtons } from '../../modules/ActionButtons';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { MainSwiper } from '../../components/MainSwiper/MainSwiper';
import { CleaningZones } from '../../modules/CleaningZones';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { AreasTestimonialTorontoData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { AboutSection } from '../../modules/AboutSection';
import { AreaCleaning } from '../../modules/AreaCleaning';
import { MapWrapper } from '../../ui/MapWrapper';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { ContactSection } from '../../modules/ContactSection';
import { Reviews } from '../../modules/Reviews';
import { companyInfo } from '../../constants';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { FormMyPrice } from '../../components/FormMyPrice';
import { SvgIconSmile } from '../../ui/SvgIcon';
import { AccordionDataToronto } from '../../modules/FrequentlyQuestions/constants/AcordionDataToronto';
import { IFrameToronto } from '../../modules/ContactSection/components/IFrame';

import torontoImage from '../../assets/toronto-page/toronot-background-image.jpg';

export const TorontoPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="torontoArea" />
      <AreasTitle
        title="Cleaning Services Toronto"
        subtitle="Expert Maid Services - Trusted Cleaning Services Near Me in Toronto"
        city="Toronto"
        areasTestimonial={AreasTestimonialTorontoData}
        imageSource={torontoImage}
      />
      <ProfessionalCleaningServices />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <OurCleaningServices title="Our Cleaning Services in Toronto Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <MainSwiper />
      <CleaningZones
        title="Our Home Cleaning Services Checklist"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <ReasonsTrust city="Toronto" componentName="toronto" />
      <AboutSection />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AreaCleaning
        title="Cleaning Services across the GTA"
        map={<MapWrapper area="toronto" />}
        description="Toronto's #1 House Cleaning Company"
      />
      <ContactSection
        city="Toronto"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<IFrameToronto />}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Videos of our Happy Clients in Toronto" />
      <FrequentlyQuestions questions={AccordionDataToronto} />
      <WrapperMyPrice
        title="Try our Cleaning Services in Toronto Today!"
        desc="If you`re not happy, we`ll work to make it right!"
        form={<FormMyPrice />}
        icon={<SvgIconSmile />}
      />
    </Main>
  );
};
