import React from 'react';
import { discountsType } from '../../../../../types/discountsType';
import { PriceRecurring } from './PriceRecurring';
import { TodayPrice } from './TodayPrice';

interface BookingSummaryPriceProps {
  subTotal: number;
  salesTax: number;
  frequency: discountsType | undefined;
  total: number;
  recurring: number;
}

export const BookingSummaryPrice: React.FC<BookingSummaryPriceProps> = ({
  subTotal,
  salesTax,
  frequency,
  total,
  recurring,
}) => {
  return (
    <div className="booking-summary-price">
      <div className="booking-summary-price__sub-total booking-summary-text">
        <p>SUB-TOTAL</p>
        <p>${subTotal.toFixed(2)}</p>
      </div>
      <div className="booking-summary-price__sales booking-summary-text">
        <p>SALES TAX</p>
        <p>${salesTax.toFixed(2)}</p>
      </div>
      <div>
        {frequency && frequency.value !== 0 ? (
          <PriceRecurring total={total} recurring={recurring} />
        ) : (
          <TodayPrice total={total} />
        )}
      </div>
    </div>
  );
};
