import { ReviewsPageClientsSay } from '../modules/ReviewsPageClientsSay';
import { ReviewsPageHappyCustomer } from '../modules/ReviewsPageHappyCustomer';
import { PageTitleBackground } from '../modules/PageTitleBackground';
import { ReviewsPageVideo } from '../modules/ReviewsPageVideo/ReviewsPageVideo';
import { MainWrapper as Main } from '../ui/MainWrapper';
import { ReviewsPageData } from '../modules/ReviewsPageClientsSay/constants/ReviewsPageData';
import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';

import ratedImg from '../assets/reviews-page-clients-say/rated-across-Canada.png';

export const ReviewsPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="reviews" />
      <PageTitleBackground title="Crystal clear reviews" />
      <ReviewsPageVideo />
      <ReviewsPageClientsSay content={ReviewsPageData} imgReviews={ratedImg} />
      <ReviewsPageHappyCustomer />
    </Main>
  );
};
