import React from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { WhereWhenAddress } from './components/WhereWhenAddress/WhereWhenAddress';
import { WhereWhenOften } from './components/WhereWhenOften/WhereWhenOften';
import { WhereWhenTime } from './components/WhereWhenTime/WhereWhenTime';
import { BookingField } from '../../types/bookingFieldset';

import './styles/WhereWhenModule.scss';

interface WhereWhenProps {
  register: UseFormRegister<BookingField>;
  setValue: UseFormSetValue<BookingField>;
  errors: FieldErrors<BookingField>;
  trigger: UseFormTrigger<BookingField>;
  formFields: BookingField;
}
export const WhereWhenModule: React.FC<WhereWhenProps> = ({
  register,
  setValue,
  formFields,
  errors,
  trigger,
}) => {
  return (
    <fieldset className="fieldset">
      <WhereWhenAddress
        register={register}
        trigger={trigger}
        errors={errors}
        formFields={formFields}
      />
      <WhereWhenTime setValue={setValue} />
      <WhereWhenOften register={register} formFields={formFields} />
    </fieldset>
  );
};
