export const calculateCentroid = (
  polygon: number[][]
): [number, number] | null => {
  if (!Array.isArray(polygon)) {
    console.error('Invalid polygon data:', polygon);
    return null;
  }

  let xSum = 0;
  let ySum = 0;
  const totalPoints = polygon.length;

  polygon.forEach(([longitude, latitude]) => {
    xSum += longitude;
    ySum += latitude;
  });

  return [ySum / totalPoints, xSum / totalPoints];
};
