import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const HaltonHillsPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Professional House Cleaning Services of Halton Hills"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Introducing Crystal Clear - the premier cleaning service for Halton
            Hills, dedicated to providing top-quality cleaning solutions for
            homeowners, landlords, and businesses across the region. Whether
            you&apos;re Crystal Clear is here to ensure your spaces are
            impeccably clean.
          </p>
          <p className="page-description__text">
            With Crystal Clear, you can easily customize your cleaning plans to
            perfectly match your unique needs. Our{' '}
            <span className="page-description__text_bold">
              seamless online booking
            </span>{' '}
            system lets you schedule a cleaning service at your convenience, and
            with our{' '}
            <span className="page-description__text_bold">
              flexible 24-hour cancellation policy
            </span>
            , you can adjust your plans without any hassle.
          </p>
          <p className="page-description__text">
            Our team of professional cleaners is fully insured, thoroughly
            vetted, and committed to delivering outstanding results every time.
            We stand by our work with a satisfaction guarantee - if you&apos;re
            not completely happy, we&apos;ll re-clean until everything meets
            your expectations.
          </p>
          <p className="page-description__text">
            Experience the difference of Crystal Clear&apos;s exceptional
            cleaning services in Halton Hills.{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning service today online
            </Link>{' '}
            or call us today at{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>{' '}
            to transform your home or office into a spotless sanctuary!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
