import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { SvgIconSmile } from '../../ui/SvgIcon';

import './styles/ThankYou.scss';

export const ThankYou = () => {
  return (
    <section className="thank-you">
      <Container>
        <div className="thank-you__wrapper">
          <div className="thank-you__header">
            <h1 className="thank-you__title">
              Thank you for booking a cleaning with Crystal Clear!
            </h1>
            <SvgIconSmile />
          </div>
          <div className="thank-you__footer">
            <p className="thank-you__description thank-you__description_underline">
              Please check your email for a confirmation of your cleaning
              details.
            </p>
            <p className="thank-you__description">
              If there are any errors in your booking request, or if anything
              comes up between now and the day of your cleaning appointment,
              please don&apos;t hesitate to{' '}
              <a href="/" className="thank-you__link">
                contact us
              </a>{' '}
              directly.
            </p>
            <p className="thank-you__description">
              We look forward to cleaning your property!
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};
