import { ContainerComponent } from '../../ui/ContainerComponent';
import valuesImg from '../../assets/values-cleaning/values-at-crystal-clear-include-integrity-professionalism-and-quality-cleaning.png';
import { getAltTextForImage } from '../../helpers';
import { ValuesCleaningText } from './components/ValuesCleaningText';
import { ValuesCleaningData } from './constants/ValuesCleaningData';

import './styles/ValuesCleaning.scss';

export const ValuesCleaning = () => {
  const altText = getAltTextForImage(valuesImg);
  return (
    <section className="values-cleaning">
      <ContainerComponent>
        <div className="values-cleaning__wrapper">
          <div className="values-cleaning__text">
            {ValuesCleaningData.map((text) => (
              <ValuesCleaningText key={text.title} text={text} />
            ))}
          </div>
          <div className="values-cleaning__img">
            <img alt={altText} src={valuesImg} />
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
