import quickTurnover from '../../../assets/airbnb-cleaning-page/quick-turnover-between-guests-for-airbnb-cleaning-services.png';
import restocking from '../../../assets/airbnb-cleaning-page/restocking-of-soap-and-towels-and-linens-supplies-airbnb-cleaning-services.png';
import bedSheets from '../../../assets/airbnb-cleaning-page/changing-bedsheets-airbnb-cleaning-services.png';
import dishwashing from '../../../assets/airbnb-cleaning-page/washing-dishes-airbnb-cleaning-services.png';
import laundry from '../../../assets/airbnb-cleaning-page/laundry-loading-and-folding-airbnb-cleaning-services.png';
import customChecklist from '../../../assets/airbnb-cleaning-page/your-own-custom-cleaning-checklist.png';
import { InclusionDataEntity } from '../types/InclusionDataEntity';

export const airbnbInclusionsBData: InclusionDataEntity[] = [
  {
    imageSource: quickTurnover,
    text: 'Quick Turnover between Guests',
  },
  {
    imageSource: restocking,
    text: 'Restocking of supplies (soap and towels)',
  },
  {
    imageSource: bedSheets,
    text: 'Changing and Prepping Bed Sheets',
  },
  {
    imageSource: dishwashing,
    text: 'Dishwashing and Organizing Utensils',
  },
  {
    imageSource: laundry,
    text: 'Laundry and Dryer Loading',
  },
  {
    imageSource: customChecklist,
    text: 'Your own Custom Cleaning Checklist',
  },
];
