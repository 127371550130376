import React from 'react';
import { FieldsetTextOption } from '../types/fieldsetTextOption';

interface BookingTextProperties {
  text: string;
  option: FieldsetTextOption;
}

export const BookingText: React.FC<BookingTextProperties> = ({
  text,
  option,
}) => {
  return (
    <>
      {option === 'title' && <h3 className="booking__title">{text}</h3>}
      {option === 'description' && (
        <p className="booking__description">{text}</p>
      )}
      {option === 'subtitle' && (
        <span className="booking__subtitle">{text}</span>
      )}
    </>
  );
};
