import React from 'react';
import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import logo from '../../assets/logo120х120.png';
import { NavAreas } from './components/NavAreas';
import { NavServices } from './components/NavServices';
import { NavHelpfulLinks } from './components/NavHelpfulLinks';
import { NavTouch } from './components/NavTouch';
import { SocialLinks } from './components/SocialLinks/SocialLinks';
import { PartnersLinks } from './components/PartnersLinks/PartnersLinks';
import { FooterSignature } from './components/FooterSignature';
import { MissionStatements } from './components/MissionStatements/MissionStatements';

import './styles/Footer.scss';

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <ContainerComponent>
        <div className="footer__nav">
          <NavAreas />
          <NavServices />
          <NavHelpfulLinks />
          <NavTouch />
        </div>
        <div className="footer__container">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <MissionStatements />
          <SocialLinks />
          <PartnersLinks />
          <FooterSignature />
        </div>
      </ContainerComponent>
    </footer>
  );
};
