import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Header } from './modules/Header';
import { Footer } from './modules/Footer';
import { CustomBreadcrumbs } from './modules/Breadcrumbs';
import { RoutesApp } from './router';
import { ScrollToTop } from './helpers';
import { paths } from './constants';

import 'swiper/scss';
import 'swiper/scss/scrollbar';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const { pathname } = useLocation();
  const isHomePage = pathname === paths.root;
  const [userName, setUserName] = useState<string>('');

  return (
    <>
      <ScrollToTop />
      <Header />
      {!isHomePage && <CustomBreadcrumbs userName={userName} />}
      <RoutesApp setUserName={setUserName} />
      <Footer />
      <ToastContainer
        position="top-left"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
      />
    </>
  );
};
export default App;
