import * as Yup from 'yup';
import { nameRegExp } from './constants/nameRegExp';
import { phoneRegExp } from './constants/phoneRegExp';
import { checkPhoneDigits } from './helpers/checkPhoneDigits';

export const schemaFormMyPrice = (isMinimized = false) => {
  return Yup.object().shape({
    name: !isMinimized
      ? Yup.string()
          .required('Please write your name')
          .matches(nameRegExp, 'To indicate only Latin letters')
          .min(2, 'This field must contain at least 2 characters.')
          .max(25, 'This field must be no more than 15 characters long.')
      : Yup.string(),
    email: Yup.string()
      .email('Please write your email')
      .required('Please write your email'),
    phone: Yup.string()
      .matches(phoneRegExp, 'The phone must match the type: 333 333 4444')
      .test('Phone number must contain exactly 10 digits', checkPhoneDigits)
      .required('Please write your phone number'),
  });
};
