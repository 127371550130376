import { BrowserHeaderTitleDataEntity } from '../types/BrowserHeaderTitleDataEntity';

export const BrowserHeaderTitleData: BrowserHeaderTitleDataEntity = {
  home: 'Canada Cleaning Service | Crystal Clear',
  areas: 'Areas We Served | Crystal Clear',
  booking: 'Book Your Cleaning Services Online | Crystal Clear',
  contactUs: 'Contact Us | Crystal Clear',
  giftCards: 'Cleaning Service Gift Card 🎁 | Crystal Clear',
  reviews: 'Honest Feedback | Crystal Clear',
  thankYou: 'Thank You for Booking With Us! | Crystal Clear',
  notFound: 'This Place is Forbidden 😭 | Crystal Clear',
  login: 'Login to Your Account | Crystal Clear',
  register: 'Create Your Account | Crystal Clear',
  privacyPolicy: 'Privacy Policy | Crystal Clear',
  termsConditions: 'Terms and Conditions | Crystal Clear',
  dashboard: 'Dashboard | Crystal Clear',
  checklist: 'Cleaning Services Checklist | Crystal Clear',
  airBnBService: 'AirBnB Cleaning Services | Crystal Clear',
  cleaningService: 'House Cleaning Services | Crystal Clear',
  commercialCleaningService:
    'Commercial and Office Cleaning Services | Crystal Clear',
  condoCleaningService: 'Condo and Apartment Cleaning Services | Crystal Clear',
  deepCleaningService: 'Deep Cleaning Services | Crystal Clear',
  ecoCleaningService:
    'Green and Eco-Frieandly Cleaning Services | Crystal Clear',
  moveInCleaningService:
    'Move In and Move Out Cleaning Services | Crystal Clear',
  postRenovationCleaningService:
    'Post-Renovation and Construction Cleaning Services | Crystal Clear',
  seniorsCleaningService: 'Cleaning Services for Seniors | Crystal Clear',
  springCleaningService: 'Spring Cleaning Services | Crystal Clear',
  etobicokeArea: 'Cleaning Services Etobicoke | Book Online | Crystal Clear',
  torontoArea: 'Cleaning Services Toronto | Book Online | Crystal Clear',
  northYorkArea: 'Cleaning Services North York | Book Online | Crystal Clear',
  bramptonArea: 'Cleaning Services Brampton | Book Online | Crystal Clear',
  mississaugaArea:
    'Cleaning Services Mississauga | Book Online | Crystal Clear',
  ajaxArea: 'Cleaning Services Ajax | Book Online | Crystal Clear',
  miltonArea: 'Cleaning Services Milton | Book Online | Crystal Clear',

  vaughanArea: 'Cleaning Services Vaughan | Book Online | Crystal Clear',
  oakvilleArea: 'Cleaning Services Oakville | Book Online | Crystal Clear',
  scarboroughArea:
    'Cleaning Services Scarborough | Book Online | Crystal Clear',
  oshawaArea: 'Cleaning Services Oshawa | Book Online | Crystal Clear',
  richmondHillArea:
    'Cleaning Services Richmond Hill | Book Online | Crystal Clear',
  markhamArea: 'Cleaning Services Markham | Book Online | Crystal Clear',
  thornhillArea: 'Cleaning Services Thornhill | Book Online | Crystal Clear',
  woodbridgeArea: 'Cleaning Services Woodbridge | Book Online | Crystal Clear',
  pickeringArea: 'Cleaning Services Pickering | Book Online | Crystal Clear',
  whitbyArea: 'Cleaning Services Whitby | Book Online | Crystal Clear',
  uxbridgeArea: 'Cleaning Services Uxbridge | Book Online | Crystal Clear',
  caledonArea: 'Cleaning Services Caledon | Book Online | Crystal Clear',
  auroraArea: 'Cleaning Services Aurora | Book Online | Crystal Clear',
  newmarketArea: 'Cleaning Services Newmarket | Book Online | Crystal Clear',
  kingArea: 'Cleaning Services King | Book Online | Crystal Clear',
  eastGwillimburyArea:
    'Cleaning Services East Gwillimbury | Book Online | Crystal Clear',
  haltonHillArea:
    'Cleaning Services Halton Hills | Book Online | Crystal Clear',
  burlingtonArea: 'Cleaning Services Burlington | Book Online | Crystal Clear',
  georginaArea: 'Cleaning Services Georgina | Book Online | Crystal Clear',
  stouffvilleArea:
    'Cleaning Services Stouffville | Book Online | Crystal Clear',
};
