import { paths } from '../constants';

export const getAreaURL = (area: string) => {
  const {
    areas: { path: areasPath },
  } = paths;
  const wordsArea = area.split(' ');
  const normalizedArea = wordsArea.join('-').toLowerCase();

  return `${areasPath}/cleaning-services-${normalizedArea}`;
};
