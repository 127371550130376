import { companyInfo } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import { ContainerComponent } from '../../ui/ContainerComponent';

import './styles/PrivacyPolicySection.scss';

export const PrivacyPolicySection = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <ContainerComponent>
      <div className="privacy-policy">
        <div className="privacy-policy__desc">
          <p>
            Your privacy is very important to us. Accordingly, we have developed
            this Policy in order for you to understand how we collect, use,
            communicate and disclose and make use of personal information.{' '}
          </p>
          <p>The following outlines our privacy policy.</p>
          <p>
            Before or at the time of collecting personal information, we will
            identify the purposes for which information is being collected. We
            will collect and use of personal information solely with the
            objective of fulfilling those purposes specified by us and for other
            compatible purposes, unless we obtain the consent of the individual
            concerned or as required by law. We will only retain personal
            information as long as necessary for the fulfillment of those
            purposes. We will collect personal information by lawful and fair
            means and, where appropriate, with the knowledge or consent of the
            individual concerned.Personal data should be relevant to the
            purposes for which it is to be used, and, to the extent necessary
            for those purposes, should be accurate, complete, and up-to-date. We
            will protect personal information by reasonable security safeguards
            against loss or theft, as well as unauthorized access, disclosure,
            copying, use or modification. We will make readily available to
            customers information about our policies and practices relating to
            the management of personal information. We are committed to
            conducting our business in accordance with these principles in order
            to ensure that the confidentiality of personal information is
            protected and maintained.
          </p>
        </div>
        <div className="privacy-policy__container">
          <p>Effective Date: October 15, 2024</p>
          <p>Last Updated: October 15, 2024</p>
          <p>
            CrystalClear (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) is
            committed to safeguarding your privacy and ensuring the
            confidentiality of your personal information. This Privacy Policy
            outlines how we collect, use, disclose, and protect your data when
            you interact with our services, including our website, booking
            forms, and marketing activities. By using our services, you agree to
            the terms of this Privacy Policy.
          </p>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">1. Information We Collect</h2>
            <p>
              We collect various types of personal information when you engage
              with CrystalClear, as detailed below:
            </p>
            <p>a. Personal Identification Information</p>
            <ul className="privacy-policy__list">
              <li>Full Name: To identify you.</li>
              <li>
                Email Address: For confirmations, service updates, and
                promotional communications.
              </li>
              <li>
                Phone Number: For scheduling, service updates, and SMS
                promotions.
              </li>
            </ul>
            <p>b. Service-Related Information</p>
            <ul className="privacy-policy__list">
              <li>
                Cleaning Preferences: Type of service (e.g., residential or
                commercial).
              </li>
              <li>Property Details: Size and scope of cleaning needs.</li>
              <li>
                Additional Instructions: Information for personalized service.
              </li>
            </ul>
            <p>c. Payment Information</p>
            <ul className="privacy-policy__list">
              <li>
                Payment details are processed by third-party providers adhering
                to industry-standard security protocols. CrystalClear does not
                store credit card or sensitive financial information directly.
              </li>
            </ul>
            <p>d. Automatically Collected Information</p>
            <ul className="privacy-policy__list">
              <li>
                Technical Data: IP address, browser type, operating system,
                device details.
              </li>
              <li>
                Usage Data: Pages viewed, date/time of access, and other
                analytics collected via cookies or similar technologies.
              </li>
            </ul>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">
              2. How We Use Your Information
            </h2>
            <p>a. Service Provision</p>
            <ul className="privacy-policy__list">
              <li>
                To manage bookings, deliver services, and respond to inquiries.
              </li>
              <li>To send confirmations, reminders, and status updates.</li>
            </ul>
            <p>b. Marketing and Promotions</p>
            <ul className="privacy-policy__list">
              <li>With consent, to send promotional emails or SMS.</li>
              <li>To analyze user preferences for personalized content.</li>
            </ul>
            <p>c. Business Operations</p>
            <ul className="privacy-policy__list">
              <li>
                To enhance service delivery through feedback and data analysis.
              </li>
            </ul>
            <p>d. Legal Compliance</p>
            <ul className="privacy-policy__list">
              <li>
                To comply with legal obligations or protect our legitimate
                interests.
              </li>
            </ul>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">
              3. Sharing Your Information
            </h2>
            <p>
              We do not sell or rent your personal data. Information may be
              shared as follows:
            </p>
            <p>a. Service Providers:</p>
            <ul className="privacy-policy__list">
              <li>
                Payment processors, communication platforms, and customer
                service tools.
              </li>
            </ul>
            <p>b. Legal Requirements:</p>
            <ul className="privacy-policy__list">
              <li>
                Disclosures mandated by law or necessary to protect our rights.
              </li>
            </ul>
            <p>c. Business Transfers:</p>
            <ul className="privacy-policy__list">
              <li>
                In the event of a merger, acquisition, or asset sale, personal
                data may be transferred.
              </li>
            </ul>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">4. Data Retention</h2>
            <p>
              Personal information is retained only for as long as necessary to
              fulfill the purposes outlined or as required by law. When no
              longer needed, data is securely deleted or anonymized.
            </p>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">5. Your Privacy Rights</h2>
            <p>You have the right to:</p>
            <p>Access and Correct:</p>
            <ul className="privacy-policy__list">
              <li>Review and update your information.</li>
            </ul>
            <p>Data Portability:</p>
            <ul className="privacy-policy__list">
              <li>Request a copy of your data in a usable format.</li>
            </ul>
            <p>Erasure:</p>
            <ul className="privacy-policy__list">
              <li>
                Request deletion of personal data, subject to legal constraints.
              </li>
            </ul>
            <p>Withdraw Consent:</p>
            <ul className="privacy-policy__list">
              <li>Opt out of marketing communications.</li>
            </ul>
            <p>Restrict Processing</p>
            <ul className="privacy-policy__list">
              <li>Limit the use of your data under certain conditions.</li>
            </ul>
            <p>To exercise these rights, contact us at:</p>
            <ul className="privacy-policy__list">
              <li>
                <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
              </li>
            </ul>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">6. Data Security</h2>
            <p>
              We implement reasonable security measures to protect personal
              information against unauthorized access, disclosure, alteration,
              or destruction. While we strive to ensure data security, no method
              of transmission or storage is entirely secure.
            </p>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">
              7. International Data Transfers
            </h2>
            <p>
              Your data may be transferred or processed in jurisdictions outside
              your country of residence, in compliance with applicable privacy
              laws.
            </p>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">8. Cookies</h2>
            <p>
              Our website uses cookies to enhance user experience and improve
              functionality. Cookie preferences can be managed through your
              browser settings.
            </p>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">
              9. Updates to This Privacy Policy
            </h2>
            <p>
              CrystalClear reserves the right to amend this Privacy Policy
              periodically. Changes will be communicated via updates on our
              website. We encourage you to review the policy regularly.
            </p>
          </div>
          <div className="privacy-policy__block">
            <h2 className="privacy-policy__title">10. Contact Information</h2>
            <p>
              For inquiries or concerns regarding this Privacy Policy, please
              contact:
            </p>
            <p>CrystalClear</p>
            <p>
              Email:{' '}
              <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
            </p>
            <p>
              Phone:{' '}
              <a href={`tel:${formattedPhone}`}>{companyInfo.phoneNumber}</a>
            </p>
            <p>Address: {companyInfo.offices.toronto}</p>
            <p>
              By continuing to use CrystalClear’s services, you acknowledge and
              agree to the terms of this Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </ContainerComponent>
  );
};
