import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderButtons } from './components/HeaderButtons/HeaderButtons';
import { HeaderMenu } from './components/HeaderMenu/HeaderMenu';
import { HeaderSideMenu } from './components/HeaderSideMenu/HeaderSideMenu';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { HeaderLogo } from './components/HeaderLogo/HeaderLogo';
import { breakpoints, paths } from '../../constants';
import { toggleScrollBar } from '../../helpers';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { HeaderAuthButtonTypeEntity } from './types/HeaderButtonDataEntity';

import './styles/Header.scss';

export const Header: React.FC = () => {
  const { pathname } = useLocation();
  const { windowWidth } = useWidthWindow();
  const [authButton, setAuthButton] =
    useState<HeaderAuthButtonTypeEntity>('login');
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false);
  const burgerButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (pathname === paths.login) {
      setAuthButton('register');
    } else if (pathname === paths.dashboard) {
      setAuthButton('logout');
    } else {
      setAuthButton('login');
    }
  }, [pathname]);

  useEffect(() => {
    if (!isFirstRender) {
      setIsFirstRender(true);
    } else {
      toggleScrollBar();
    }
  }, [isSideMenuOpen]);

  return (
    <header className="header">
      <Container>
        <div className="header__wrapper">
          <HeaderLogo />
          {windowWidth >= breakpoints.desktop && <HeaderMenu />}
          <HeaderButtons
            isSideMenuOpen={isSideMenuOpen}
            setIsSideMenuOpen={setIsSideMenuOpen}
            burgerButtonRef={burgerButtonRef}
            authButtonType={authButton}
          />
        </div>
      </Container>
      {isSideMenuOpen && (
        <HeaderSideMenu
          authButtonType={authButton}
          setIsSideMenuOpen={setIsSideMenuOpen}
          burgerButtonRef={burgerButtonRef}
        />
      )}
    </header>
  );
};
