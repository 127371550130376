import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getPageName } from '../../../helpers';
import { getPathnames } from '../helpers/getPathnames';
import { paths } from '../../../constants';

interface CustomBreadcrumbsItemProps {
  path: string;
  userName?: string;
}

export const CustomBreadcrumbsItem: React.FC<CustomBreadcrumbsItemProps> = ({
  path,
  userName,
}) => {
  const {
    services: { path: servicesRoute, children: servicesRoutes },
  } = paths;
  const [pathnames, setPathnames] = useState<string[]>([]);

  useEffect(() => {
    if (path === paths.dashboard) {
      setPathnames(getPathnames(`${path}/${userName}`));
    } else {
      setPathnames(getPathnames(path));
    }
  }, [userName, path]);

  return (
    <ol className="custom-breadcrumbs__wrapper">
      <li className="custom-breadcrumbs__item">
        <Link className="custom-breadcrumbs__link" to="/">
          Crystal Clear
        </Link>
      </li>
      {pathnames.map((pathname, index) => {
        const isServicesPath = pathname === servicesRoute.replace('/', '');
        const link = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast =
          index === pathnames.length - 1 || path === paths.dashboard;
        const namePage = getPageName(pathname);
        return (
          <li key={link} className="custom-breadcrumbs__item">
            <span className="custom-breadcrumbs__icon">&#10132;</span>
            {isLast ? (
              <span className="custom-breadcrumbs__link custom-breadcrumbs__current">
                {namePage}
              </span>
            ) : (
              <Link
                to={
                  isServicesPath
                    ? `/${pathname}/${servicesRoutes.cleaningServices}`
                    : link
                }
                className="custom-breadcrumbs__link"
              >
                {namePage}
              </Link>
            )}
          </li>
        );
      })}
    </ol>
  );
};
