import { DescriptionList } from './DescriptionList';

import '../styles/TextDescription.scss';

export const TextDescription = () => {
  return (
    <div className="text__description description">
      <p className="description__top">
        At Crystal Clear, we’re not just a cleaning company; we’re a movement
        reshaping the entire cleaning experience for both homeowners and
        cleaners. Proudly serving multiple provinces in Canada, we’ve forged a
        path to redefine the cleaning industry.
        <span className="description_bold">
          {' '}
          Our mission consists of 3 core elements:
        </span>
      </p>
      <DescriptionList />
      <p className="description__bottom">
        By utilizing latest booking technology, investing in our cleaners, and
        going above and beyond with our clients, we strive to continuously be
        the best cleaning service in Canada on all fronts. Support our journey
        and book your first cleaning today!
      </p>
    </div>
  );
};
