import React, { useEffect, useState } from 'react';
import { CleaningInformationAdditionsEntity } from '../../../../../types';
import { useBookingFormData } from '../../../../../hooks/useBookingFormData';

interface BookingSummaryPriceParameter {
  parameter?: CleaningInformationAdditionsEntity;
  parameterName?: string;
  subTotal?: number;
}

export const BookingSummaryPriceParameter: React.FC<
  BookingSummaryPriceParameter
> = ({ parameter, parameterName, subTotal }) => {
  const {
    formData: { cleaningInformation },
  } = useBookingFormData();
  const [parameterPrice, setParameterPrice] = useState<string>('0');

  useEffect(() => {
    if (subTotal) {
      setParameterPrice(String(subTotal.toFixed(2)));
    } else if (parameter && parameter.totalPrice !== undefined) {
      setParameterPrice(parameter.totalPrice.toFixed(2));
    } else if (cleaningInformation?.cleaningParameters) {
      const matchedParameter = cleaningInformation.cleaningParameters.find(
        (currentParameter) => currentParameter.id === parameter?.id
      );
      if (matchedParameter && matchedParameter.totalPrice !== undefined) {
        setParameterPrice(matchedParameter.totalPrice.toFixed(2));
      } else {
        setParameterPrice('0');
      }
    } else {
      setParameterPrice('0');
    }
  }, [subTotal, parameter, cleaningInformation?.cleaningParameters]);

  return (
    <li
      className="booking-summary__parameters__item booking-summary-text"
      key={parameter?.id}
    >
      <div className="booking-summary__parameters__marker" />
      <p className="booking-summary__parameters__item_name">{parameterName}</p>
      <p className="booking-summary__parameters__item_price">
        ${parameterPrice}
      </p>
    </li>
  );
};
