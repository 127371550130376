import { DifferencesBetweenUs } from '../modules/DifferencesBetweenUs';
import { HowWeWork } from '../modules/HowWeWork';
import { HeroSection } from '../modules/HeroSection';
import { SetsApart } from '../modules/SetsApart';
import { WhereWeWork } from '../modules/WhereWeWork';
import { MainWrapper as Main } from '../ui/MainWrapper';
import { OurCleaningServices } from '../modules/OurCleaningServices';
import { OurStuff } from '../modules/OurStuff';
import { RecurringCleaning } from '../modules/RecurringCleaning';
import { WeFeaturedOn } from '../modules/WeFeaturedOn';
import { ActionButtons } from '../modules/ActionButtons';
import {
  threeButtonsData,
  twoButtonsData,
} from '../constants/buttonSectionData';
import { GiftCards } from '../modules/GiftCards';
import { Reviews } from '../modules/Reviews';
import { InSixtySeconds } from '../modules/InSixtySeconds';
import { BeforeAfterSection } from '../modules/BeforeAfterSection';
import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import homePageImage from '../assets/heroSection/toronto-canada.png';

export const HomePage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="home" />
      <HeroSection
        title="Canada's Best Cleaning Service"
        imageSource={homePageImage}
      />
      <WhereWeWork />
      <HowWeWork />
      <ActionButtons hasBackground data={twoButtonsData} />
      <DifferencesBetweenUs />
      <SetsApart />
      <OurCleaningServices />
      <ActionButtons hasBackground data={twoButtonsData} />
      <BeforeAfterSection />
      <OurStuff />
      <ActionButtons data={threeButtonsData} />
      <WeFeaturedOn />
      <InSixtySeconds />
      <RecurringCleaning />
      <GiftCards />
      <Reviews title="Customer Reviews of Our Cleaning Services" />
    </Main>
  );
};
