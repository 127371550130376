import { Link } from 'react-router-dom';
import { paths } from '../../../constants';
import { Title } from '../../../ui/MainTitle';

export const ProfessionalCommercialCleaningText = () => {
  return (
    <div className="professional-commercial-cleaning__text">
      <Title
        text="Professional Commercial and Office Cleaning Services"
        type="h2"
        color="black"
        isCentered
      />
      <p className="professional-commercial-cleaning__desc">
        Introducing Crystal Clear Commercial and Office Cleaning Services – your
        trusted partner for maintaining immaculate workspaces in Canada’s
        bustling cities, including{' '}
        <Link to={`${paths.areas.path}/${paths.areas.children.toronto}`}>
          Toronto
        </Link>
        ,{' '}
        <Link to={`${paths.areas.path}/${paths.areas.children.northYork}`}>
          North York
        </Link>
        ,{' '}
        <Link to={`${paths.areas.path}/${paths.areas.children.etobicoke}`}>
          Etobicoke
        </Link>
        ,{' '}
        <Link to={`${paths.areas.path}/${paths.areas.children.brampton}`}>
          Brampton
        </Link>
        , and more (
        <Link to={paths.areas.path}>explore all our service areas</Link>
        ). Streamline your cleaning needs effortlessly with our customizable
        service plans and user-friendly online booking system. Our experienced,
        insured, and carefully screened cleaning professionals offer peace of
        mind, supported by our accommodating 24-hour cancellation policy.
      </p>
      <p className="professional-commercial-cleaning__desc">
        What distinguishes us from the rest? Your satisfaction is our top
        priority. If you’re not completely satisfied with our service, we will
        revisit your premises and re-clean until it meets your expectations.
        Elevate the productivity and well-being of your team with our
        award-winning commercial and office cleaning services. Contact Crystal
        Clear today to experience the difference in cleanliness and
        professionalism.
      </p>
    </div>
  );
};
