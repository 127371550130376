import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const PickeringPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Discover Premier Cleaning Services in Pickering with Crystal Clear"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Introducing Crystal Clear – the premier cleaning service designed
            specifically for{' '}
            <span className="page-description__text_bold">Pickering</span>,
            catering to the unique needs of homeowners, renters, and business
            owners throughout the area. Whether you’re located in the bustling{' '}
            <span className="page-description__text_bold">
              Amberlea, Dunbarton, Liverpool, Rougemount,
            </span>{' '}
            or nearby suburbs like{' '}
            <span className="page-description__text_bold">Bay Ridges</span> and{' '}
            <span className="page-description__text_bold">Rosebank</span>,
            Crystal Clear is dedicated to keeping your home or office spotless
            and inviting.
          </p>
          <p className="page-description__text">
            With Crystal Clear, you can easily customize your cleaning plans to
            match your exact requirements, whether it’s a one-time{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep clean
            </Link>{' '}
            or a regular weekly service. Our seamless online booking process
            makes it simple to schedule your cleaning at a time that works best
            for you. Plus, our{' '}
            <span className="page-description__text_bold">
              flexible 24-hour cancellation policy
            </span>{' '}
            ensures that you can make changes to your plans without any hassle.
          </p>
          <p className="page-description__text">
            Our team of professional cleaners is fully vetted, insured, and
            committed to delivering the highest standard of service. We stand by
            our work with a satisfaction guarantee—if you’re not completely
            satisfied, we’ll re-clean your space until it meets your
            expectations.
          </p>
          <p className="page-description__text">
            Take the first step towards a cleaner, more organized space in
            Pickering with Crystal Clear’s exceptional cleaning services.{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning online
            </Link>{' '}
            or call us at{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>{' '}
            today!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
