import React from 'react';
import { TitleColorEntity, TitleTypeEntity } from './types/TitleTypeEntity';

import './styles/MainTitle.scss';

interface TitleProperties {
  text: string;
  type: TitleTypeEntity;
  color?: TitleColorEntity;
  isCentered?: boolean;
}

export const Title: React.FC<TitleProperties> = ({
  text,
  type,
  color = 'white',
  isCentered = false,
}) => {
  const textAlignStyle: React.CSSProperties = isCentered
    ? {
        textAlign: 'center',
      }
    : {
        textAlign: 'start',
      };

  return (
    <>
      {type === 'h1' ? (
        <h1
          style={textAlignStyle}
          className={
            color === 'white' ? 'main-title' : 'main-title main-title_black'
          }
        >
          {text}
        </h1>
      ) : (
        <h2
          style={textAlignStyle}
          className={
            color === 'white'
              ? 'section-title'
              : 'section-title section-title_black'
          }
        >
          {text}
        </h2>
      )}
    </>
  );
};
