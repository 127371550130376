import React from 'react';

interface TodayPriceProps {
  total: number;
}

export const TodayPrice: React.FC<TodayPriceProps> = ({ total }) => {
  return (
    <div className="booking-summary-price__today booking-summary-text">
      <p className="booking-summary-price__today__title">Today&apos;s fee</p>
      <p className="booking-summary-price__today_accent">${total.toFixed(2)}</p>
    </div>
  );
};
