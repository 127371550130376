import {
  CleaningInformationAdditionsEntity,
  CleaningService,
} from '../../../../../types';

export const handleParameterName = (
  choosedCleaningType: CleaningService | undefined,
  parameter?: CleaningInformationAdditionsEntity | null,
  parameters?: CleaningInformationAdditionsEntity[]
) => {
  if (parameter) {
    const choosedExtra = choosedCleaningType?.extras?.find(
      (currentExtra) => currentExtra.id === parameter.id
    );
    const isQuantityBased = choosedExtra && choosedExtra.quantityBased;

    if (isQuantityBased) {
      return `${parameter.quantity} ${parameter.name}`;
    }

    if (!isQuantityBased) {
      return `${parameter.name}`;
    }
  }

  if (parameters) {
    const cleanersParameter = parameters.find(
      (currentParameter) => currentParameter.id === 10001
    );
    const hoursParameter = parameters.find(
      (currentParameter) => currentParameter.id === 10002
    );

    return `${cleanersParameter?.name} x ${hoursParameter?.name}`;
  }

  return '';
};
