import React from 'react';
import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { breakpoints, paths } from '../../constants';
import { ReviewsImages } from './components/ReviewsVideoWrapper/constants/Images';
import { ReviewsVideoWrapper } from './components/ReviewsVideoWrapper/ReviewsVideoWrapper';
import { Title } from '../../ui/MainTitle';
import { ReviewsCleaningSwiper } from './components/ReviewsCleaningSwiper';
import { ReviewsPageData } from '../ReviewsPageClientsSay/constants/ReviewsPageData';
import { useWidthWindow } from '../../hooks/useWidthWindows';

import './styles/Reviews.scss';

interface ReviewsProps {
  title: string;
  swiper?: boolean;
  maxItems?: number;
}

export const Reviews: React.FC<ReviewsProps> = ({
  title,
  swiper,
  maxItems,
}) => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <div className="reviews">
      <ContainerComponent>
        <div className="reviews__container">
          <Title text={title} type="h2" color="black" />
          <div className="reviews__content">
            <div
              style={!swiper || isMobile ? { width: '100%' } : { width: '70%' }}
              className="reviews__video"
            >
              {ReviewsImages.slice(0, maxItems ?? ReviewsImages.length).map(
                (review) => (
                  <ReviewsVideoWrapper review={review} key={review.alt} />
                )
              )}
            </div>
            {swiper && <ReviewsCleaningSwiper content={ReviewsPageData} />}
          </div>
          <Link className="reviews__button" to={paths.reviews}>
            View more reviews!
          </Link>
        </div>
      </ContainerComponent>
    </div>
  );
};
