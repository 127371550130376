import { getAltTextForImage } from '../../../../helpers';
import bigFrame from '../../../../assets/differences-between-us/big-frame.png';
import image from '../../../../assets/differences-between-us/home-cleaners-services-by-crystalclear-canada.jpg';

export const PhotoWrapper = () => {
  const altText = getAltTextForImage(image);

  return (
    <div className="differences-between-us__image-wrapper">
      <img
        style={{
          background: `url(${image})
          center center / contain no-repeat`,
        }}
        className="differences-between-us__image"
        src={bigFrame}
        alt={altText}
      />
    </div>
  );
};
