import { UseFormRegister } from 'react-hook-form';
import { FormDataMyPriceType } from '../../types/formDataMyPriceType';
import { formSelectConfigType } from '../../types/formSelectConfigType';
import { OptionMyPrice } from '../OptionMyPrice/OptionMyPrice';

interface SelectMyPriceProps {
  content: formSelectConfigType;
  register: UseFormRegister<FormDataMyPriceType>;
}

export const SelectMyPrice = ({ content, register }: SelectMyPriceProps) => {
  return (
    <div className="form-my-price__item">
      <select {...register(content.value as keyof FormDataMyPriceType)}>
        <OptionMyPrice arrayOptions={content.option} />
      </select>
    </div>
  );
};
