import { ValuesCleaningType } from '../type/ValuesCleaningType';

export const ValuesCleaningData: ValuesCleaningType[] = [
  {
    title: 'Honesty and Integrity in our Cleaning Process',
    desc: 'ur team of cleaners are not only experienced and skilled, but also trustworthy and reliable. You can be rest assured that your home is in good hands when you choose our cleaning service. We carefully screen and background check all of our cleaners to ensure that they meet our high standards for quality and professionalism, and not to forget, friendliness!',
  },
  {
    title:
      'Rigorous Background Checks and Privacy Measures for a Safe and Easy Cleaning Experience',
    desc: 'Whether you’re looking for cleaning services for your home or office, Crystal Clear has got you covered. Our comprehensive cleaning services are designed to meet the needs of every space, from top to bottom. From dusting and vacuuming to deep cleaning and sanitizing, our professional cleaners are equipped to handle any cleaning challenge. With a commitment to quality and customer satisfaction, our cleaning services are the perfect solution for your home or office, no matter the size or style.',
  },
];
