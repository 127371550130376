import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { GiftCardFormTextareaData } from '../constants/GiftCardFormTextareaData';
import { FormDataGiftCardType } from '../type/FormDataGiftCardType';

interface GiftCardFormTextareaProps {
  errors: FieldErrors<FormDataGiftCardType>;
  register: UseFormRegister<FormDataGiftCardType>;
}

export const GiftCardFormTextarea: React.FC<GiftCardFormTextareaProps> = ({
  errors,
  register,
}) => {
  return (
    <label
      htmlFor={GiftCardFormTextareaData.name}
      className="gift-card-form__label"
    >
      <span className="gift-card-form__label__text">
        {GiftCardFormTextareaData.name}*
      </span>
      <div className="gift-card-form__label__container">
        <textarea
          className={
            errors.message
              ? 'gift-card-form__label__textarea error'
              : 'gift-card-form__label__textarea'
          }
          {...register(
            GiftCardFormTextareaData.name as keyof FormDataGiftCardType
          )}
          defaultValue={GiftCardFormTextareaData.defaultValue}
        />
        <span className="gift-card-form__error-message">
          {errors.message ? errors.message?.message : ''}
        </span>
      </div>
    </label>
  );
};
