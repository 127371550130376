import React from 'react';
import { ButtonDataType } from '../../types/buttonDataType';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { Button } from './ui/Button';

import './styles/ActionButtons.scss';
import { colorPalette } from '../../constants';

interface ActionButtonsProperties {
  data: ButtonDataType[];
  hasBackground?: boolean;
}

export const ActionButtons: React.FC<ActionButtonsProperties> = ({
  data,
  hasBackground = false,
}) => {
  return (
    <div
      style={{
        backgroundColor: hasBackground ? colorPalette.gray : 'transparent',
      }}
      className="action-buttons"
    >
      <Container>
        <div className="action-buttons__wrapper">
          {data.map((buttonData) => {
            return <Button key={buttonData.text} data={buttonData} />;
          })}
        </div>
      </Container>
    </div>
  );
};
