import { Link } from 'react-router-dom';
import { paths } from '../../../constants';

export const ReasonsTrustListMarkham = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Skilled Cleaning Professionals:
        </span>{' '}
        Expert cleaners delivering top-notch service.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Sustainable Cleaning Solutions:
        </span>{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
        >
          Eco-friendly Cleaning:
        </Link>{' '}
        products for a safer home.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Complete Satisfaction Guarantee:
        </span>{' '}
        Your happiness guaranteed, or we’ll make it right.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Affordable Rates:</span>{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.cleaningServices}`}
        >
          Superior cleaning{' '}
        </Link>
        at competitive prices in Markham.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Thoroughly Vetted Staff:
        </span>{' '}
        Trusted maids chosen for their integrity
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Easy Online Scheduling:
        </span>{' '}
        <Link className="reasons-trust-list__link" to={paths.book}>
          Book effortlessly{' '}
        </Link>
        with our simple online system
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Exceptional Customer Support:
        </span>{' '}
        24/7 communication from start to finish
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Proven Excellence:</span>{' '}
        Highly praised services, reflected in our{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          reviews
        </Link>
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Fully Insured Services:
        </span>{' '}
        Complete privacy and peace of mind assured.
      </li>
    </ul>
  );
};
