export const companyInfo = {
  phoneNumber: '+1 (647) 916-9276',
  email: 'info@crystalclear.art',
  hours: 'Mon-Sun 8:00AM - 8:00PM',
  socialLinks: {
    instagram: '/',
    linkedin: 'https://www.linkedin.com/company/crystal-clear-artistry',
    facebook: 'https://www.facebook.com/people/Crystal-Clear/61566571511324',
    youtube: 'https://www.youtube.com/channel/UCDiR0hiRE6hXQmqjeGKel0w',
    tiktok: '/',
  },
  offices: {
    toronto: '99 Davisville Ave, Toronto, ON M4S 0A8',
    etobicoke: '123 La Rose Ave, Toronto, ON M9P 1A6',
    'north york': '16 Dallimore Cir, North York, ON M3C 4C4',
  },
  officesMap: {
    toronto: 'https://maps.app.goo.gl/C8Ngi9QdBjs7aNCB8',
    etobicoke: 'https://maps.app.goo.gl/S96NTRXv5KHZPSMY9',
    'north york': 'https://maps.app.goo.gl/EWC2k9K7oaFE5Eys8',
  },
};
