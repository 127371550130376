import { PathsEntity } from '../types';

export const paths: PathsEntity = {
  root: '/',
  book: '/book-online',
  thankYou: '/thank-you',
  reviews: '/reviews',
  giftCards: '/gift-cards',
  contactUs: '/contact-us',
  tools: '/cleaning-tools',
  login: '/login',
  register: '/register',
  dashboard: '/dashboard',
  privacyPolicy: '/privacy-policy',
  termsConditions: 'terms-conditions',
  areas: {
    path: '/areas',
    children: {
      toronto: 'cleaning-services-toronto',
      etobicoke: 'cleaning-services-etobicoke',
      northYork: 'cleaning-services-north-york',
      brampton: 'cleaning-services-brampton',
      mississauga: 'cleaning-services-mississauga',
      vaughan: 'cleaning-services-vaughan',
      ajax: 'cleaning-services-ajax',
      milton: 'cleaning-services-milton',
      oakville: 'cleaning-services-oakville',
      scarborough: 'cleaning-services-scarborough',
      oshawa: 'cleaning-services-oshawa',
      richmondHill: 'cleaning-services-richmond-hill',
      markham: 'cleaning-services-markham',
      thornhill: 'cleaning-services-thornhill',
      woodbridge: 'cleaning-services-woodbridge',
      burlington: 'cleaning-services-burlington',
      whitby: 'cleaning-services-whitby',
      pickering: 'cleaning-services-pickering',
      uxbridge: 'cleaning-services-uxbridge',
      caledon: 'cleaning-services-caledon',
      aurora: 'cleaning-services-aurora',
      newmarket: 'cleaning-services-newmarket',
      king: 'cleaning-services-king',
      eastGwillimbury: 'cleaning-services-east-gwillimbury',
      haltonHills: 'cleaning-services-halton-hills',
      georgina: 'cleaning-services-georgina',
      stouffville: 'cleaning-services-stouffville',
    },
  },
  services: {
    path: '/services',
    children: {
      checklist: 'checklist',
      cleaningServices: 'cleaning-services',
      apartmentCleaning: 'condo-and-apartment-cleaning-services',
      commercialCleaning: 'commercial-office-cleaning-services',
      deepCleaning: 'deep-cleaning-services',
      moveInCleaning: 'move-in-move-out-cleaning-services',
      ecoCleaning: 'green-and-eco-friendly-cleaning-services',
      postRenovationCleaning: 'post-renovation-cleaning-services',
      airbnbCleaning: 'airbnb-cleaning-services',
      seniorsCleaning: 'cleaning-services-for-seniors',
      springCleaning: 'spring-cleaning-services',
    },
  },
};
