import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const KingPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Professional Cleaning Services in King"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Introducing Crystal Clear – your trusted provider of professional
            cleaning services in King, catering to the unique needs of
            homeowners and businesses alike.
          </p>
          <p className="page-description__text">
            With Crystal Clear, customizing your house cleaning services has
            never been easier. Our user-friendly online booking process allows
            you to schedule your cleaning at your convenience, and our flexible{' '}
            <span className="page-description__text_bold">
              24-hour cancellation policy
            </span>{' '}
            gives you peace of mind if plans change.
          </p>
          <p className="page-description__text">
            Our professional cleaning team is fully insured, highly trained, and
            dedicated to delivering the highest quality of service. Your
            satisfaction is our priority, and if you’re not completely happy
            with our work, we’ll return to make it right.
          </p>
          <p className="page-description__text">
            Experience the difference with Crystal Clear’s professional cleaning
            services in King.{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning online today
            </Link>{' '}
            or call us at{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>{' '}
            to get started. Let us take care of the cleaning, so you can enjoy a
            spotless home without the hassle.
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
