import React from 'react';
import { useLocation } from 'react-router-dom';
import { paths } from '../../constants';
import whiteBackground from '../../assets/background-white.png';

import './styles/MainWrapper.scss';

interface MainWrapperProps {
  children: React.ReactNode;
  hasBackground?: boolean;
}

export const MainWrapper: React.FC<MainWrapperProps> = ({
  children,
  hasBackground = false,
}) => {
  const { pathname } = useLocation();
  const isHomePage = pathname === paths.root;
  const backgroundStyle = hasBackground
    ? {
        background: `url(${whiteBackground})
    center center / auto repeat`,
      }
    : {};

  return (
    <main
      className={
        isHomePage ? 'page-wrapper page-wrapper_no-breadcrumbs' : 'page-wrapper'
      }
      style={backgroundStyle}
    >
      {children}
    </main>
  );
};
