import React, { useEffect } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { TimeRestrictionOptions } from '../constants/TimeRestrictionOptions';
import { BookingField } from '../../../types/bookingFieldset';
import { CleaningInstructionsEntity } from '../../../../../types';
import { useBookingFormData } from '../../../../../hooks/useBookingFormData';

interface TimeRestrictionProps {
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
}

export const TimeRestriction: React.FC<TimeRestrictionProps> = ({
  register,
  formFields,
}) => {
  const {
    setInstructionsInformation,
    formData: { cleaningIntstructions },
  } = useBookingFormData();

  const handleDataAddOnChange = () => {
    const cleaningInstructions: CleaningInstructionsEntity = {
      ...cleaningIntstructions,
      restriction: formFields.restriction,
    };
    setInstructionsInformation(cleaningInstructions);
  };

  useEffect(() => {
    handleDataAddOnChange();
  }, [formFields.restriction]);

  return (
    <div className="checklist">
      <p className="checklist__title">
        Do you have a service time restriction?
      </p>
      <div className="checklist__container">
        {TimeRestrictionOptions.map((item) => {
          return (
            <label className="checklist__label" htmlFor={item} key={item}>
              <input
                className={
                  formFields.restriction === item
                    ? 'checklist__input checklist__input__active'
                    : 'checklist__input'
                }
                type="radio"
                value={item}
                id={item}
                {...register('restriction', {
                  onChange: handleDataAddOnChange,
                })}
              />
              <span>{item}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};
