import { CommercialInputType } from '../type/CommercialInputType';

export const CommercialInputData: CommercialInputType[] = [
  {
    type: 'text',
    name: 'name',
    placeholder: 'name',
  },
  {
    type: 'text',
    name: 'businessName',
    placeholder: 'business name',
  },
  {
    type: 'text',
    name: 'officeAddress',
    placeholder: 'office address',
  },
  {
    type: 'email',
    name: 'email',
    placeholder: 'email',
  },
];
