import React from 'react';
import { Link } from 'react-router-dom';
import { OurCleaningServicesDataType } from '../../types/OurCleaningServicesDataType';
import { paths } from '../../../../constants';
import './styles/OurCleaningServicesItem.scss';

interface OurCleaningServicesItemProps {
  item: OurCleaningServicesDataType;
}

export const OurCleaningServicesItem: React.FC<
  OurCleaningServicesItemProps
> = ({ item }) => {
  const formattedURL = `${paths.services.path}/${item.path}`;

  return (
    <Link to={formattedURL} className="our-cleaning-services-item">
      <div className="our-cleaning-services-item__img">
        <img alt={item.title} src={item.img} />
      </div>
      <div className="our-cleaning-services-item__text">
        <h3 className="our-cleaning-services-item__title">{item.title}</h3>
        <p className="our-cleaning-services-item__desc">{item.desc}</p>
      </div>
    </Link>
  );
};
