import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';

export const FriendlyTrustedAurora = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Friendly and Trusted - Home Cleaning Services of Aurora" />
          <p className="text__desc">
            At Crystal Clear, happiness is at the heart of what we do, both for
            our cleaners and our clients in Aurora. When our team is happy and
            motivated, it shines through in the quality of our work, ensuring
            your space is always immaculate. We offer tailored cleaning
            services, whether you need a deep clean or recurring maintenance, to
            fit your lifestyle perfectly. In Aurora, a city rich in culture and
            community, we aim to elevate your living experience with our
            top-notch cleaning solutions. Let our happy and dedicated team bring
            joy to your life, one clean home at a time.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
