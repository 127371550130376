import { Layer } from 'leaflet';
import { colorPalette } from '../../../constants';
import { calculateCentroid } from './calculateCentroid';

export const onEachLocations = (
  feature: any,
  layer: Layer,
  ClickToArea: (name: string) => void,
  onCityHover: (cityName: string, coordinates: [number, number] | null) => void
) => {
  const cityName = feature.properties?.name;
  const { geometry } = feature;

  if (geometry) {
    const polygonCoordinates = geometry.coordinates[0];

    if (Array.isArray(polygonCoordinates)) {
      const centroid = calculateCentroid(polygonCoordinates);

      if (centroid) {
        layer.on('mouseover', (event) => {
          event.target.setStyle({
            fillColor: colorPalette.secondaryColor,
          });
          onCityHover(cityName, centroid);
        });

        layer.on('mouseout', (event) => {
          event.target.setStyle({
            fillColor: colorPalette.accentColor,
          });
          onCityHover('', null);
        });

        layer.on('click', () => {
          ClickToArea(cityName);
        });
      }
    }
  }
};
