import { PhoneIcon } from './ui/PhoneIcon';
import { companyInfo } from '../../../../constants';
import { getFormattedPhoneNumber } from '../../../../helpers';

import './styles/PhoneButton.styles.scss';

export const PhoneButton = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);

  return (
    <a href={`tel:${formattedPhone}`} className="phone">
      <PhoneIcon />
    </a>
  );
};
