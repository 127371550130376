import React from 'react';

import { BurgerIcon } from './ui/BurgerIcon';
import { BurgerIconType } from '../../types/BurgerIconType';

import './styles/BurgerButton.styles.scss';

interface BurgerButtonProps {
  isSideMenuOpen: boolean;
  setIsSideMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  burgerButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
}

export const BurgerButton: React.FC<BurgerButtonProps> = ({
  isSideMenuOpen,
  setIsSideMenuOpen,
  burgerButtonRef,
}) => {
  const iconType: BurgerIconType = isSideMenuOpen ? 'close' : 'burger';

  return (
    <button
      ref={burgerButtonRef}
      className="burger"
      onClick={() =>
        setIsSideMenuOpen((prevSideMenuStatus) => !prevSideMenuStatus)
      }
    >
      <BurgerIcon type={iconType} />
    </button>
  );
};
