import { paths } from '../../../../../constants';
import { ListTextType } from '../types/ListTextType';

export const listText: ListTextType[] = [
  {
    text: 'Customize your own cleaning service',
    link: paths.book,
  },
  {
    text: 'View upfront pricing (no in-house estimates!)',
    link: null,
  },
  {
    text: 'Secure payment (no charge until day of service)',
    link: null,
  },
  {
    text: 'Professional, background checked and insured cleaners',
    link: null,
  },
  {
    text: '24/7 customer support that is available via phone, website chat, email, and more',
    link: null,
  },
  {
    text: 'Convenient online booking makes it fast and easy to schedule your cleaning sessions',
    link: paths.book,
  },
  {
    text: '3-day and 1-day useSms/text reminders of your appointment to always keep you posted',
    link: null,
  },
  {
    text: 'Satisfaction guarantee service. Not happy with the cleaning? We`ll make it right!',
    link: null,
  },
];
