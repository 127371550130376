import { AreasTitle } from '../../modules/AreasTitle';
import { MainWrapper } from '../../ui/MainWrapper';
import { AreasTestimonialVaughanData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { ActionButtons } from '../../modules/ActionButtons';
import { VaughanPageDescription } from '../../modules/PageDescription';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { breakpoints, companyInfo } from '../../constants';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { AboutSection } from '../../modules/AboutSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { ContactSection } from '../../modules/ContactSection';
import { MapWrapper } from '../../ui/MapWrapper';
import { GiftCards } from '../../modules/GiftCards';
import { Reviews } from '../../modules/Reviews';
import { GetQuote } from '../../modules/GetQuote';
import { AccordionDataVaughan } from '../../modules/FrequentlyQuestions/constants/AccordionDataVaughan';
import {
  ThreeEasyStepsVaughanBook,
  ThreeEasyStepsVaughanCleaner,
  ThreeEasyStepsVaughanRelax,
} from '../constants/VaughanPageData/ThreeEasyStepsVaughanData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

import imgVaughan from '../../assets/vaughan-page/vaughan-page.jpg';

export const VaughanPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;
  return (
    <MainWrapper>
      <DynamicBrowserHeaderTitle page="vaughanArea" />
      <AreasTitle
        title="Cleaning Services Vaughan"
        city="Vaughan"
        subtitle="Professional House Cleaning Services | Hire Trusted Cleaners Near you in Vaughan Today!"
        imageSource={imgVaughan}
        areasTestimonial={AreasTestimonialVaughanData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <VaughanPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsVaughanBook}
        cleaner={ThreeEasyStepsVaughanCleaner}
        relax={ThreeEasyStepsVaughanRelax}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <OurStuff hasOnlyOneSection componentName="vaughan" />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Vaughan Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Vaughan" componentName="vaughan" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Vaughan"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataVaughan} />
      <ContactSection
        city="Vaughan"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="vaughan" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <GetQuote title="Try our Cleaning Services in Vaughan Today!" />
    </MainWrapper>
  );
};
