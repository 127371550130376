import React, { Dispatch, SetStateAction } from 'react';
import { DashboardSection } from '../modules/DashboardSection';
import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import { PageTitleBackground } from '../modules/PageTitleBackground';
import { MainWrapper as Main } from '../ui/MainWrapper';

interface DashboardPageProps {
  setUserName: Dispatch<SetStateAction<string>>;
}

export const DashboardPage: React.FC<DashboardPageProps> = ({
  setUserName,
}) => {
  return (
    <Main hasBackground>
      <DynamicBrowserHeaderTitle page="dashboard" />
      <PageTitleBackground title="Dashboard Page" />
      <DashboardSection setUserName={setUserName} />
    </Main>
  );
};
