import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GeoJSON, TileLayer } from 'react-leaflet';
import { GeoJsonObject } from 'geojson';
import { useNavigate } from 'react-router-dom';
import { getFilterdAreas } from './helpers/getFilteredAreas';
import { onEachArea } from './helpers/onEachArea';
import { MapBoundsSetter } from './components/MapBounderSetter';
import { SpecificArea, MainArea } from '../../types';
import { colorPalette } from '../../constants';

import './styles/MapAreas.scss';

interface ServiceAreaProperties {
  area: MainArea;
  specificArea?: SpecificArea;
}

export const MapAreas: React.FC<ServiceAreaProperties> = ({
  area,
  specificArea,
}) => {
  const navigate = useNavigate();
  const [geoData, setGeoData] = useState<GeoJsonObject>();

  useEffect(() => {
    (async () => {
      const result = await axios.get(`../locations/${area}.geojson`);
      setGeoData(result.data);
    })();
  }, [area]);

  return (
    <>
      {geoData && (
        <>
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          />
          <MapBoundsSetter data={geoData} />
          <GeoJSON
            data={geoData}
            style={{
              fillColor: colorPalette.accentColor,
              color: colorPalette.errorColor,
              fill: true,
              weight: 3,
              fillOpacity: 0.2,
            }}
            filter={(feature) => getFilterdAreas(feature, specificArea)}
            onEachFeature={(feature, layer) =>
              onEachArea(feature, layer, navigate)
            }
          />
        </>
      )}
    </>
  );
};
