import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { InSixtySecondsList } from './components/InSixtySecondsList/InSixtySecondsList';
import { getAltTextForImage } from '../../helpers';
import { Title } from '../../ui/MainTitle';
import image from '../../assets/how-we-work/house-cleaning-ladies.jpg';

import './styles/InSixtySeconds.scss';

export const InSixtySeconds = () => {
  const altText = getAltTextForImage(image);

  return (
    <section className="in-sixty-seconds">
      <Container>
        <div className="in-sixty-seconds__wrapper">
          <div className="in-sixty-seconds__text">
            <span className="in-sixty-seconds__subtitle">
              A clean home, just a few clicks away
            </span>
            <Title
              text="Book your Cleaning Service Online in 60 seconds!"
              type="h2"
              color="black"
            />
            <InSixtySecondsList />
          </div>
          <div className="in-sixty-seconds__image-wrapper">
            <img
              className="in-sixty-seconds__image"
              src={image}
              alt={altText}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};
