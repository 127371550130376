import React from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import { SvgIconCanada } from '../../../ui/SvgIcon';
import { BookingField, FieldDefinition } from '../types/bookingFieldset';
import { handleContactInformationOnBlur } from '../helpers/handleContactInformationOnBlur';
import { countryCode } from '../constants/countryCode';
import { useBookingFormData } from '../../../hooks/useBookingFormData';

interface BookingPhoneInputProperties {
  fieldData: FieldDefinition;
  register: UseFormRegister<BookingField>;
  setValue: UseFormSetValue<BookingField>;
  trigger: UseFormTrigger<BookingField>;
  errors: FieldErrors<BookingField>;
  formFields: BookingField;
}

export const BookingPhoneInput: React.FC<BookingPhoneInputProperties> = ({
  fieldData,
  errors,
  setValue,
  register,
  trigger,
  formFields,
}) => {
  const { setContactInformation } = useBookingFormData();
  const { name, placeholder, type } = fieldData;

  const handlePhoneChange = (event: React.FormEvent<HTMLInputElement>) => {
    let input = event.currentTarget.value.replace(/\D/g, '');
    if (input.length > 3) {
      input = `${input.slice(0, 3)} ${input.slice(3)}`;
    }
    if (input.length > 7) {
      input = `${input.slice(0, 7)} ${input.slice(7, 11)}`;
    }
    setValue(name, input);
  };

  return (
    <div className="phone-input__wrapper">
      <div
        className={
          errors[name] ? 'phone-input__country error' : 'phone-input__country'
        }
      >
        <SvgIconCanada />
        <span className="phone-input__country-code">{`+${countryCode}`}</span>
      </div>
      <input
        {...register(name, {
          onChange: handlePhoneChange,
          onBlur: async () => {
            const isValid = await trigger(name);

            if (isValid) {
              await handleContactInformationOnBlur(
                name,
                formFields,
                errors,
                setContactInformation
              );
            }
          },
        })}
        id={name}
        className={
          errors[name]
            ? 'fieldset__input phone-input__input error'
            : 'phone-input__input fieldset__input'
        }
        placeholder={placeholder}
        type={type}
      />
    </div>
  );
};
