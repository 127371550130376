import { Link } from 'react-router-dom';
import { TitleItem } from '../ui/TitleItem';
import { getAltTextForImage } from '../../../../helpers';
import { titleEmpowered } from '../../constants/title';

import '../../styles/Text.scss';
import teamOffice from '../../../../assets/three-sections-of-stuff/crystal-clear-cleaning-team.jpg';

export const EmpoweredCleanersSection = () => {
  const altImg = getAltTextForImage(teamOffice);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title={titleEmpowered} />
          <p className="text__desc">
            At our cleaning service, we prioritize the happiness of our cleaners
            above all else because we believe that their well-being directly
            translates into the satisfaction of our clients. When our cleaners
            are happy, they feel secure, appreciated, and respected – and that’s
            our number one goal. Their happiness creates a positive ripple
            effect, leading to delighted clients who continue to choose our
            services.
          </p>
          <p className="text__desc">
            By fostering an environment that values the happiness of both our
            cleaners and clients, we consistently deliver exceptional service
            that surpasses expectations every time.
          </p>
        </div>
        <div className="three-sections__img">
          <Link to="/">
            <img alt={altImg} src={teamOffice} />
          </Link>
        </div>
      </div>
    </div>
  );
};
