import { Link } from 'react-router-dom';
import { paths } from '../../../constants';

export const ReasonsTrustListPickering = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Premier Housekeeping in Pickering:
        </span>{' '}
        Dependable, top-tier home cleaning service.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Eco-Friendly Cleaning Options:
        </span>{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
        >
          Green cleaning
        </Link>{' '}
        solutions for a healthier home.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Satisfaction Guarantee:
        </span>{' '}
        We ensure your complete happiness or we’ll make it right.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Competitive Pricing in Pickering:
        </span>{' '}
        Affordable, high-quality cleaning services.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Screened and Verified Cleaners:
        </span>{' '}
        Trustworthy, professional maids you can rely on.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Simple Online Booking:{' '}
        </span>{' '}
        Easily{' '}
        <Link className="reasons-trust-list__link" to={paths.book}>
          schedule your cleaning
        </Link>{' '}
        session online.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Customer-Centric Support: 24/7 communication
        </span>{' '}
        and support available.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Renowned Reputation:</span>{' '}
        Highly acclaimed by clients with r{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          glowing reviews.
        </Link>
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Local Expertise in Pickering, ON:
        </span>{' '}
        Trusted, community-rooted cleaning service.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Transparent and Honest Pricing:
        </span>{' '}
        Clear, straightforward pricing with no hidden costs.
      </li>
    </ul>
  );
};
