import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { slidesData } from './constants/slidesData';
import { SwiperSlideContent } from './ui/SwiperSlideContent';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { breakpoints } from '../../constants';

import './styles/MainSwiper.scss';

export const MainSwiper = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <section className="main-swiper">
      <Container>
        <Swiper
          className="main-swiper__swiper"
          modules={[Pagination, Autoplay, Navigation]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={!isMobile}
          loop
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
        >
          {slidesData.map((slideData) => {
            const { id } = slideData;

            return (
              <SwiperSlide className="main-swiper__slide" key={id}>
                <SwiperSlideContent slideData={slideData} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </section>
  );
};
