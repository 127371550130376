import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { SvgIconChevronRight } from '../../ui/SvgIcon';
import { ThreeEasyStepsItem } from './components/ThreeEasyStepsItem';

import './styles/ThreeEasySteps.scss';
import { ThreeEasyStepsType } from '../../pages/constants/CondoCleaningPageData/ThreeEasyStepsType';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { breakpoints } from '../../constants';
import { Title } from '../../ui/MainTitle';

interface ThreeEasyStepsProps {
  isShowTitle?: boolean;
  book: ThreeEasyStepsType;
  cleaner: ThreeEasyStepsType;
  relax: ThreeEasyStepsType;
}

export const ThreeEasySteps: React.FC<ThreeEasyStepsProps> = ({
  isShowTitle = false,
  book,
  cleaner,
  relax,
}) => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <section className="three-easy-steps">
      <ContainerComponent>
        <div className="three-easy-steps__wrapper">
          {isShowTitle && (
            <Title
              text="3 Easy Steps to a Pristine Home"
              type="h2"
              color="black"
            />
          )}
          <div className="three-easy-steps__container">
            <ThreeEasyStepsItem item={book} />
            <div className="three-easy-steps__icon">
              {!isMobile && <SvgIconChevronRight />}
            </div>
            <ThreeEasyStepsItem item={cleaner} />
            <div className="three-easy-steps__icon">
              {!isMobile && <SvgIconChevronRight />}
            </div>
            <ThreeEasyStepsItem item={relax} />
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
