import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { ReasonsTrustItems } from './constants/ReasonsTrustItems';

import './styles/ReasonsTrust.scss';

interface ReasonsTrustProps {
  city: string;
  componentName: string;
}

export const ReasonsTrust: React.FC<ReasonsTrustProps> = ({
  city,
  componentName,
}) => {
  const Component = ReasonsTrustItems[componentName];
  if (!Component) {
    return null;
  }

  return (
    <section className="reasons-trust">
      <ContainerComponent>
        <div className="reasons-trust__wrapper">
          <div className="reasons-trust__title">
            <Title
              text={`Why Trust Crystal Clear for Your Cleaning Needs in ${city}?`}
              type="h2"
              color="black"
            />
            <div className="reasons-trust__icon" />
          </div>
          <div className="reasons-trust__widget">
            <div className="reasons-trust__widget__wrapper">
              <Component />
            </div>
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
