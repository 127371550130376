import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { BookingField } from '../../../types/bookingFieldset';
import { BookingCleaningServiceParameter } from './BokingCleaningServiceParameter';
import { useBookingFormData } from '../../../../../hooks/useBookingFormData';
import {
  CleaningInformationAdditionsEntity,
  CleaningService,
  PricingParametersEntity,
} from '../../../../../types';
import { createPricingParameters } from '../helpers/createFormattedPricingParameters';
import { CreatePricingParametersDataEntity } from '../types/CreatePricingParametersDataEntity';
import {
  pricingParametersNames,
  hourlyPricingParametersNames,
} from '../constants/pricingParametersData';
import { getDefaultValues } from '../helpers/getDefaultValues';
import { getFieldNameFromName } from '../helpers/getFieldNameFromName';

interface BookingCleaningServiceParametersProperties {
  cleaningServices: CleaningService[];
  register: UseFormRegister<BookingField>;
  setValue: UseFormSetValue<BookingField>;
}

export const BookingCleaningParameters: React.FC<
  BookingCleaningServiceParametersProperties
> = ({ register, setValue, cleaningServices }) => {
  const {
    formData: { cleaningInformation },
    setCleaningInformation,
  } = useBookingFormData();
  const [pricingParameters, setPricingParameters] = useState<
    PricingParametersEntity[]
  >([]);
  const activeService = useMemo(() => {
    return (
      cleaningServices.find(
        (currentService) => cleaningInformation?.id === currentService.id
      ) || null
    );
  }, [cleaningInformation?.id, cleaningServices]);

  useEffect(() => {
    if (activeService && activeService.pricingParameters) {
      const pricingParametersData: CreatePricingParametersDataEntity[] =
        pricingParametersNames;
      const formattedPricingParameters: PricingParametersEntity[] =
        createPricingParameters(pricingParametersData, activeService);

      setPricingParameters([
        ...activeService.pricingParameters,
        ...formattedPricingParameters,
      ]);
    }

    if (activeService && activeService.hourly) {
      const pricingParametersData: CreatePricingParametersDataEntity[] =
        hourlyPricingParametersNames;
      const formattedPricingParameters: PricingParametersEntity[] =
        createPricingParameters(pricingParametersData, activeService);

      setPricingParameters(formattedPricingParameters);
    }

    if (
      activeService &&
      !activeService.hourly &&
      !activeService.pricingParameters
    ) {
      setPricingParameters([]);
    }
  }, [activeService]);

  useEffect(() => {
    if (activeService) {
      const cleaningParameters: CleaningInformationAdditionsEntity[] = [];
      pricingParameters.forEach((parameter) => {
        const { ranges, id, name } = parameter;

        if (
          ranges &&
          (name === 'Hour' || name === 'Square Feet' || name === 'Cleaner')
        ) {
          const defaultValue = getDefaultValues(id, ranges[0]);
          const fieldName = getFieldNameFromName(name);
          setValue(fieldName as keyof BookingField, defaultValue.name);
          cleaningParameters.push(defaultValue);
        }
      });

      setCleaningInformation({
        ...cleaningInformation,
        cleaningParameters,
      });
    }
  }, [pricingParameters, activeService, setValue]);

  return (
    <div className="fieldset__input-wrapper fieldset__input-wrapper_centered">
      {Object.values(pricingParameters).map((parameter) => {
        const { name, ranges, id } = parameter;
        const nameNoSpaces = name.replaceAll(' ', '');
        const nameCamelCase =
          nameNoSpaces[0].toLowerCase() + nameNoSpaces.slice(1);

        return (
          <Fragment key={name}>
            {ranges && (
              <BookingCleaningServiceParameter
                parameterNameNormalized={nameCamelCase}
                parameterID={id}
                parameterRanges={ranges}
                register={register}
                setValue={setValue}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
