import { Link } from 'react-router-dom';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';
import { TitleItem } from '../ui/TitleItem';

import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';

export const HaltonHillsExpert = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Friendly and Trusted - Home Cleaning Services of Halton Hills" />
          <p className="text__desc">
            At Crystal Clear, we believe that happiness is at the core of
            everything we do—both for our cleaners and our clients. We take
            pride in ensuring our team is not only highly skilled but also
            genuinely happy in their work, because we know that a positive
            attitude leads to exceptional results. Whether you’re in need of a{' '}
            <Link
              className="three-sections__link text__desc_bold"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep clean
            </Link>{' '}
            to refresh your space or recurring services to maintain a spotless
            home, our commitment to quality shines through in every task we
            undertake. Living in Halton Hills, you deserve the best, and our{' '}
            <span className="text__desc_bold">
              cleaning services Halton Hills
            </span>{' '}
            are designed to bring a little more happiness and cleanliness into
            your life. With Crystal Clear, it’s about more than just cleaning;
            it’s about creating a welcoming environment that reflects our
            dedication to your satisfaction, one visit at a time.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
