/* eslint-disable no-param-reassign */
import React from 'react';
import { MapContainer } from 'react-leaflet';
import { MapAreas } from '../../modules/AreasMap';
import { MainArea, SpecificArea } from '../../types';

import './styles/MapWrapper.scss';

interface MapWrapperProps {
  area: MainArea;
  specificArea?: SpecificArea;
}

export const MapWrapper: React.FC<MapWrapperProps> = ({
  area,
  specificArea = null,
}) => {
  return (
    <div className="map__wrapper">
      <MapContainer
        className="map__container"
        zoomControl={false}
        dragging={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        attributionControl={false}
        touchZoom={false}
        boxZoom={false}
        keyboard={false}
      >
        {specificArea ? (
          <MapAreas area={area} specificArea={specificArea} />
        ) : (
          <MapAreas area={area} />
        )}
      </MapContainer>
    </div>
  );
};
