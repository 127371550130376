import { TitleItem } from '../ui/TitleItem';
import { getAltTextForImage } from '../../../../helpers';
import { titleRedefining } from '../../constants/title';

import '../../styles/Text.scss';
import founders from '../../../../assets/three-sections-of-stuff/untitled-design-51.jpg';

export const RedefiningCleaningSection = () => {
  const altImg = getAltTextForImage(founders);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title={titleRedefining} />
          <div>
            <p className="text__desc">
              Established in 2017, CrystalClear is on a mission to change the
              cleaning industry. Led by Ahmed and Abdul <i>(two engineers)</i>,
              CrystalClear strives to make cleaning services a better experience
              for both ends of the market: homeowners and cleaners. We offer
              value to homeowners through easy online booking and impeccable
              service, while offering a flexible, respected, and well paid paid
              opportunity to our cleaning partners.As certified members of the{' '}
              <span className="text__desc_bold">
                Ontario Living Wage, BC Living Wage
              </span>{' '}
              and <span className="text__desc_bold">Alberta Living Wage</span>{' '}
              initiatives, and a proud supporter of the{' '}
              <span className="text__desc_bold">Canadian Cancer Society</span>,
              pride in our pride in our dedication to supporting our cleaners as
              well as various causes and charities throughout Canada.
            </p>
          </div>
        </div>
        <div className="three-sections__img">
          <img alt={altImg} src={founders} />
        </div>
      </div>
    </div>
  );
};
