import React from 'react';

interface OptionMyPriceProps {
  arrayOptions: (string | number)[];
}

export const OptionMyPrice: React.FC<OptionMyPriceProps> = ({
  arrayOptions,
}) => {
  return (
    <>
      <option value="" hidden>
        {arrayOptions[0]}
      </option>
      {arrayOptions.slice(1).map((option) => (
        <option value={option} key={option}>
          {option}
        </option>
      ))}
    </>
  );
};
