import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { GiftCardFormSelect } from '../GiftCardFormSelect';
import { FormDataGiftCardType } from '../../type/FormDataGiftCardType';

import './styles/GiftCardForm.scss';
import { GiftCardFormInput } from '../GiftCardFormInput';
import { GiftCardFormTextarea } from '../GiftCardFormTextarea';

interface GiftCardFormProps {
  register: UseFormRegister<FormDataGiftCardType>;
  errors: FieldErrors<FormDataGiftCardType>;
}

export const GiftCardForm: React.FC<GiftCardFormProps> = ({
  register,
  errors,
}) => {
  return (
    <div className="gift-card-form">
      <GiftCardFormSelect register={register} errors={errors} />
      <GiftCardFormInput register={register} errors={errors} />
      <GiftCardFormTextarea register={register} errors={errors} />
    </div>
  );
};
