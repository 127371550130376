import { Link } from 'react-router-dom';
import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';

export const FriendlyTrustedeastGwillimbury = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Friendly and Trusted - Home Cleaning Services of East Gwillimbury" />
          <p className="text__desc">
            Our expert cleaning services in East Gwillimbury are tailored to
            bring you the ultimate level of cleanliness and comfort in your home
            or office. We understand the challenges and demands of maintaining a
            tidy and spotless environment, which is why we offer a spectrum of
            cleaning services to fit your specific needs. Our skilled cleaning
            specialists are equipped with the latest methods and tools to
            guarantee a comprehensive cleaning of your space, making it look and
            feel fresh! Whether you require routine cleaning,{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep cleaning
            </Link>
            , or anything in between, we have a customized plan to meet your
            needs!
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
