import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { FormDataGiftCardType } from '../type/FormDataGiftCardType';
import { GiftCardFormSelectData } from '../constants/GiftCardFormSelectData';

interface GiftCardFormSelectProps {
  register: UseFormRegister<FormDataGiftCardType>;
  errors: FieldErrors<FormDataGiftCardType>;
}

export const GiftCardFormSelect: React.FC<GiftCardFormSelectProps> = ({
  register,
  errors,
}) => {
  return (
    <label htmlFor="amount" className="gift-card-form__label">
      <span className="gift-card-form__label__text">amount *</span>
      <div className="gift-card-form__label__container">
        <select
          className={errors.amount ? 'select-form error' : 'select-form'}
          {...register('amount')}
        >
          {GiftCardFormSelectData.map((item) => {
            return (
              <option value={item} key={item} className="select-form__option">
                {item}
              </option>
            );
          })}
        </select>
        <span className="gift-card-form__error-message">
          {errors.amount ? errors.amount.message : ''}
        </span>
      </div>
    </label>
  );
};
