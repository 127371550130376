import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { StepCard } from './components/StepCard/StepCard';
import { stepsCardData } from './constants/stepsCardData';

import './styles/HowWeWork.styles.scss';

export const HowWeWork = () => {
  return (
    <section className="how-we-work">
      <Container>
        <div className="how-we-work__wrapper">
          <div className="how-we-work__cards">
            {stepsCardData.map((card) => {
              return <StepCard key={card.title} cardData={card} />;
            })}
          </div>
        </div>
      </Container>
    </section>
  );
};
