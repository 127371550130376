import React, { Fragment } from 'react';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { TextDataKeyEntity } from './types/TextDataTypeEntity';
import { TextData } from './constants/CleaningToolsData';

import './styles/TextWrapper.scss';

interface TextWithContentProperties {
  textDataType: TextDataKeyEntity;
  additionalContent: React.ReactNode;
  title?: React.ReactNode;
}

export const TextWrapper: React.FC<TextWithContentProperties> = ({
  textDataType,
  additionalContent,
  title = null,
}) => {
  return (
    <section className="text-with-content">
      <Container>
        {title && title}
        <div className="text-with-content__wrapper">
          <div className="text-with-content__text-wrapper">
            {TextData[textDataType].map((textItem) => {
              return <Fragment key={textItem.id}>{textItem.element}</Fragment>;
            })}
          </div>
          <div className="text-with-content__content-wrapper">
            {additionalContent}
          </div>
        </div>
      </Container>
    </section>
  );
};
