/* eslint-disable no-return-assign */
import React, { useEffect, useRef } from 'react';
import { getTimeOptionType } from '../../types/getTimeOptionType';
import { parseDate } from '../../helpers/parseDate';
import { scrollToElement } from '../../helpers/scrollToElement';

interface TimeDropdownProps {
  isDropdownShown: boolean;
  selectOption: getTimeOptionType[];
  selectedTime: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  selectedDate: Date | null | undefined;
}

export const TimeDropdown: React.FC<TimeDropdownProps> = ({
  isDropdownShown,
  selectOption,
  selectedTime,
  onClick,
  selectedDate,
}) => {
  const dateRefs = useRef<(HTMLUListElement | null)[]>([]);

  useEffect(() => {
    if (isDropdownShown && selectedDate) {
      const formattedSelectedDate = new Date(selectedDate).getTime();
      const dropdownContainer = dateRefs.current[0]?.parentElement;

      const selectedIndex = selectOption.findIndex((item) => {
        const itemDate = parseDate(item.date)?.getTime();

        return itemDate === formattedSelectedDate;
      });

      if (
        selectedIndex !== -1 &&
        dateRefs.current[selectedIndex] &&
        dropdownContainer
      ) {
        scrollToElement(
          dropdownContainer,
          dateRefs.current[selectedIndex] as HTMLElement,
          1500
        );
      }
    }
  }, [isDropdownShown, selectOption]);

  return (
    <>
      {isDropdownShown ? (
        selectOption.map((item, index) => (
          <ul
            className="where-when__dropdown__item"
            key={item.date}
            ref={(el) => (dateRefs.current[index] = el)}
          >
            <li>{item.date}</li>
            {item.time.map((time) => {
              const isActive =
                time === selectedTime &&
                parseDate(item.date)?.getTime() ===
                  new Date(selectedDate as Date).getTime();
              return (
                <li
                  key={time}
                  className={
                    isActive
                      ? 'active-time where-when__dropdown__item__time'
                      : 'where-when__dropdown__item__time'
                  }
                >
                  <button id={item.id} onClick={onClick}>
                    {time}
                  </button>
                </li>
              );
            })}
          </ul>
        ))
      ) : (
        <ul>
          <li>{selectedTime}</li>
        </ul>
      )}
    </>
  );
};
