import { useContext } from 'react';
import { bookingPriceContext } from '../context';

export const useBookingFormData = () => {
  const context = useContext(bookingPriceContext);

  if (!context) {
    throw new Error(
      'useBookingFormData hook can be used only inside the bookingPriceContext'
    );
  }

  return context;
};
