import { BookingContentPanelDataType } from '../types/BookingContentPanelDataType';

export const BookingContentPanelData: BookingContentPanelDataType[] = [
  {
    icon: '😊',
    title: '200% SATISFACTION GUARANTEE',
    desc: "In the rare chance that you aren't 100% satisfied with your cleaning, let us know within 24 hours and we'll be back to make things right",
  },
  {
    icon: '❤️',
    title: 'LIVING WAGE PROVIDER ',
    desc: 'Our teams are partners in success. We are a committed living wage provider and aim to make a positive impact on the communities we serve.',
  },
  {
    icon: '🛡️',
    title: 'SAFETY FIRST',
    desc: 'We are insured and rigorously vet all of our cleaning associates, who undergo background checks, regular quality-assurance reviews, and cleaning safety guidelines',
  },
  {
    icon: '📞',
    title: '24/7 CUSTOMER SUPPORT',
    desc: "Have questions? We're committed to the best customer service in the industry. Reach out to us via phone, email, chat, or log in to your booking and leave us a message.",
  },
  {
    icon: '💳',
    title: 'SECURE PAYMENT',
    desc: 'Payment is only processed after your service has been completed. Cancel anytime up to 24 hours before your service with no fee.',
  },
];
