/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import img from '../../../../assets/differences-between-us/home-cleaners-services-by-crystalclear-canada.jpg';
import { getAltTextForImage } from '../../../../helpers';
import { companyInfo } from '../../../../constants';
import { LocationSectionType } from '../../type/LocationSectionType';
import { scrollToElement } from '../../helpers/scrollToElement';
import { OpenLocation } from '../OpenLocation';
import { useWidthWindow } from '../../../../hooks/useWidthWindows';

import './styles/LocationSectionArea.scss';

interface LocationSectionAreaProps {
  content: LocationSectionType;
  ClickToArea: (name: string) => void;
  highlightedCity: { name: string; coordinates: [number, number] } | null;
  cardContainer: React.MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  selectedLocation: LocationSectionType | undefined;
  openLocationRef: React.MutableRefObject<HTMLDivElement | null>;
  onCityHover: (cityName: string | null) => void;
}

export const LocationSectionArea: React.FC<LocationSectionAreaProps> = ({
  content,
  ClickToArea,
  highlightedCity,
  cardContainer,
  isOpen,
  setIsOpen,
  selectedLocation,
  openLocationRef,
  onCityHover,
}) => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= 700;

  const cityRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [activeCity, setActiveCity] = useState<string | null>(null);
  const [scroll, setScroll] = useState<boolean>(true);

  const altText = getAltTextForImage(img);
  const { province, name, address, link } = content;

  useEffect(() => {
    if (!isMobile && scroll && !isOpen) {
      if (highlightedCity && cityRefs.current[highlightedCity.name]) {
        setActiveCity(highlightedCity.name);
        const element = cityRefs.current[highlightedCity.name];
        const container = cardContainer.current;
        if (element && container) {
          scrollToElement(element, container);
        }
      } else {
        setActiveCity(null);
      }
    }
  }, [highlightedCity]);

  const CloseLocation = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && selectedLocation && name === selectedLocation.name && (
        <div ref={openLocationRef}>
          <OpenLocation close={CloseLocation} content={selectedLocation} />
        </div>
      )}
      <div
        id={name}
        className={`location-section-area ${
          activeCity === name ? 'location-section-area__active' : ''
        }`}
        onClick={(e) => {
          ClickToArea(e.currentTarget.id);
        }}
        ref={(el) => (cityRefs.current[name] = el)}
        onMouseEnter={() => {
          onCityHover(name);
          setScroll(false);
        }}
        onMouseLeave={() => {
          onCityHover(null);
          setScroll(true);
        }}
      >
        <div className="location-section-area__information">
          <div className="location-section-area__header">
            <h3 className="location-section-area__title">
              Crystal Clear {name}
            </h3>
            <span className="location-section-area__province">{province}</span>
          </div>
          <div className="location-section-area__contacts">
            <p className="location-section-area__address">{address}</p>
            <p className="location-section-area__phone">
              {companyInfo.phoneNumber}
            </p>
            <p className="location-section-area__email">{companyInfo.email}</p>
            <p className="location-section-area__link">{link.url}</p>
          </div>
        </div>
        <div className="location-section-area__images__wrapper">
          <img
            className="location-section-area__images"
            alt={altText}
            src={img}
          />
        </div>
      </div>
    </>
  );
};
