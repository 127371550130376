import { Link } from 'react-router-dom';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';
import { TitleItem } from '../ui/TitleItem';

import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';

export const HappinessGuaranteedBurlington = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Happiness-Guaranteed Cleaning for Both you and your Home" />
          <p className="text__desc">
            Happiness is at the heart of what we do for both our clients and our
            cleaners, that’s why Crystal Clear stands where it is today. By
            investing in the happiness of our cleaning partners, and providing
            quality training, we ensure both you and your home are in good
            hands.
          </p>
          <p className="text__desc">
            Our highly skilled cleaning experts are equipped with the latest
            tools to guarantee a comprehensive cleaning of your space, making it
            look and feel revitalized! Whether you require routine cleaning,{' '}
            <Link
              className="three-sections__link text__desc_bold"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep cleaning
            </Link>{' '}
            or anything in between, we have a{' '}
            <Link
              className="three-sections__link text__desc_bold"
              to={paths.book}
            >
              customized plan
            </Link>{' '}
            to meet your requirements.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
