import React from 'react';
import { AreasGuaranteesData } from '../../constants/AreasGuaranteesData';

import './styles/AreasGuarantees.scss';

interface AreasGuaranteesProps {
  city: string;
}

export const AreasGuarantees: React.FC<AreasGuaranteesProps> = ({ city }) => {
  return (
    <div className="areas-guarantees">
      {AreasGuaranteesData.map((item, index) => {
        return (
          <div className="areas-guarantees__item" key={item.text}>
            <span className="areas-guarantees__svg">{item.svg}</span>
            <p className="areas-guarantees__text">
              {item.text} {index === 2 && city}
            </p>
          </div>
        );
      })}
    </div>
  );
};
