import { formInputConfigType } from '../types/formInputConfigType';

export const formInputConfig: formInputConfigType[] = [
  {
    placeholder: 'Name',
    type: 'name',
  },
  {
    placeholder: 'example@test.com',
    type: 'email',
  },
];
