import { Link } from 'react-router-dom';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { MapWrapper } from '../../ui/MapWrapper';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import { TrustIndicators } from '../DifferencesBetweenUs/components/TrustIndicators/TrustIndicators';

import './styles/TrustedChoice.scss';
import { Title } from '../../ui/MainTitle';

export const TrustedChoice = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  const {
    areas: { path: areasRoute, children: areasRoutes },
  } = paths;

  return (
    <section className="trusted-choice">
      <Container>
        <div className="trusted-choice__wrapper">
          <div className="trusted-choice__text-wrapper">
            <Title
              text="Your Trusted Choice for Premium Cleaning Services Across Canada"
              type="h2"
              color="black"
            />
            <div className="trusted-choice__description-wrapper">
              <p className="trusted-choice__description">
                Crystal Clear is proud to serve Canada&apos;s most unique and
                diverse cities. From the innovative hub of{' '}
                <Link
                  to={`${areasRoute}/${areasRoutes.toronto}`}
                  className="across-canada__link"
                >
                  Toronto
                </Link>{' '}
                to the warm countryside of{' '}
                <Link
                  to={`${areasRoute}/${areasRoutes.etobicoke}`}
                  className="across-canada__link"
                >
                  Etobicoke
                </Link>
                , the stunning natural beauty to the charming shores of{' '}
                <Link
                  to={`${areasRoute}/${areasRoutes.northYork}`}
                  className="across-canada__link"
                >
                  North York
                </Link>
                , the thriving suburban city of{' '}
                <Link
                  to={`${areasRoute}/${areasRoutes.mississauga}`}
                  className="across-canada__link"
                >
                  Mississauga
                </Link>{' '}
                to the rocky mountains of{' '}
                <Link
                  to={`${areasRoute}/${areasRoutes.brampton}`}
                  className="across-canada__link"
                >
                  Brampton
                </Link>
                , the historic city of{' '}
                <Link
                  to={`${areasRoute}/${areasRoutes.ajax}`}
                  className="across-canada__link"
                >
                  Ajax
                </Link>{' '}
                to the bustling metropolis of{' '}
                <Link
                  to={`${areasRoute}/${areasRoutes.milton}`}
                  className="across-canada__link"
                >
                  Milton
                </Link>
                .
              </p>
              <p className="trusted-choice__description">
                CrystalClear is a proud member{' '}
                <span className="trusted-choice__bold">Living Wage Canada</span>
                , ensuring our cleaning professionals are well compensated. By
                choosing CrystalClear, you’re selecting a superior experience
                delivered by motivated and empowered cleaning professionals.
              </p>
              <p className="trusted-choice__description">
                Moreover, we are proud to partner with the{' '}
                <span className="trusted-choice__bold">
                  Canadian Cancer Society
                </span>
                . Our collaboration extends beyond cleaning, as we actively
                support initiatives aimed at making a difference in the fight
                against cancer. When you choose CrystalClear, you’re
                contributing to a cause that extends far beyond spotless spaces.
              </p>
              <p className="trusted-choice__description">
                Booking a professional cleaning service with Crystal Clear is
                easy and convenient. You can{' '}
                <Link to={paths.book} className="trusted-choice__link">
                  book our cleaning services online
                </Link>{' '}
                , customizing your cleaning plan to your specific needs with
                just a few clicks. You can also choose to give us a call at
                <Link
                  className="trusted-choice__link"
                  to={`tel:${formattedPhone}`}
                >
                  {companyInfo.phoneNumber}
                </Link>
                , and our team will assist you in scheduling your cleaning!
              </p>
            </div>
          </div>
          <div className="trusted-choice__content-wrapper">
            <MapWrapper area="toronto" />
            <TrustIndicators isMinimized />
          </div>
        </div>
        <Link to={paths.areas.path} className="trusted-choice__button">
          View All Our Service Areas
        </Link>
      </Container>
    </section>
  );
};
