import { AreasTitle } from '../../modules/AreasTitle';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { AreasTestimonialCaledonData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { ActionButtons } from '../../modules/ActionButtons';
import { CaledonPageDescription } from '../../modules/PageDescription';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { breakpoints, companyInfo } from '../../constants';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { TrustIndicatorsSection } from '../../modules/TrustIndicatorsSection';
import { TrustIndicatorsSectionData } from '../../modules/TrustIndicatorsSection/constants/TrustIndicatorsSectionData';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { AboutSection } from '../../modules/AboutSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { AccordionDataCaledon } from '../../modules/FrequentlyQuestions/constants/AccordionDataCaledon';
import { ContactSection } from '../../modules/ContactSection';
import { MapWrapper } from '../../ui/MapWrapper';
import { GiftCards } from '../../modules/GiftCards';
import { Reviews } from '../../modules/Reviews';
import { GetQuote } from '../../modules/GetQuote';

import imgCaledon from '../../assets/caledon-page/caledon.png';

export const CaledonPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="caledonArea" />
      <AreasTitle
        title="Cleaning Services Caledon"
        city="Caledon"
        subtitle="Professional Cleaning Services of Caledon – Hire Trusted House Cleaners Near You Today!"
        imageSource={imgCaledon}
        areasTestimonial={AreasTestimonialCaledonData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <CaledonPageDescription />
      <ThreeEasySteps
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="caledon" />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && (
        <TrustIndicatorsSection content={TrustIndicatorsSectionData} />
      )}
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Caledon Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Caledon" componentName="сaledon" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Caledon"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <AcrossCanada />
      <AboutSection />
      <ActionButtons hasBackground data={twoButtonsData} />
      <FrequentlyQuestions questions={AccordionDataCaledon} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Caledon"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="caledon" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews
        title="Reviews of our Cleaning Services in Caledon"
        maxItems={2}
        swiper
      />
      <GetQuote title="Try our Cleaning Services in Caledon Today!" />
    </Main>
  );
};
