import { ContactInformationEntity } from '../types';
import Api from './Api';

export const submitContactInformation = async (
  contactInformation: ContactInformationEntity
) => {
  try {
    await Api.post('user/new', contactInformation);
  } catch (error) {
    console.error(error);
  }
};
