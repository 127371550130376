import { v4 as uuid } from 'uuid';
import { TextDataEntity } from '../types/TextDataTypeEntity';
import { companyInfo } from '../../../constants';

export const TextData: TextDataEntity = {
  cleaningToolsData: [
    {
      id: uuid(),
      element: (
        <p className="text-wrapper__paragraph">
          This page serves as a guideline and a reminder for contractors to use
          good quality tools and products when cleaning with CrystalClear.
        </p>
      ),
    },
    {
      id: uuid(),
      element: (
        <p className="text-wrapper__paragraph">
          Your tools and products don&apos;t have to be the exact same as the
          ones below, but they should be of similar quality (or even better).
        </p>
      ),
    },
  ],
  cleaningToolsFooterData: [
    {
      id: uuid(),
      element: (
        <p className="text-wrapper__paragraph">
          If you have any questions or concerns, please contact the CrystalClear
          Operations Manager or email us at{' '}
          <a
            className="text-wrapper__link"
            href={`mailto:${companyInfo.email}`}
          >
            {companyInfo.email}
          </a>
        </p>
      ),
    },
  ],
};
