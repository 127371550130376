import { TextDescription } from './ui/TextDescription';
import { Title } from '../../../../ui/MainTitle';

import './styles/Text.scss';

export const Text = () => {
  return (
    <div className="gift-cards__text text">
      <div className="text__wrapper">
        <span className="text__subtitle">Give the Gift of a Clean Home</span>
        <Title
          text="Now Offering House Cleaning Gift Cards!"
          type="h2"
          color="black"
        />
        <TextDescription />
      </div>
    </div>
  );
};
