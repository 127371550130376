import { discountsType } from '../types/discountsType';

export const discounts: discountsType[] = [
  {
    id: 6,
    name: 'One Time',
    value: 0,
  },
  {
    id: 4,
    name: 'Weekly - 20% off',
    value: 20,
  },
  {
    id: 5,
    name: 'Biweekly - 15% off',
    value: 15,
  },
  {
    id: 8,
    name: 'Tri-weekly - 12.5% off',
    value: 12.5,
  },
  {
    id: 2,
    name: 'Monthly - 10% off',
    value: 10,
  },
];
