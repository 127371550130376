import React from 'react';
import { NavLink } from 'react-router-dom';
import { getAreaURL } from '../../../../helpers';
import { paths } from '../../../../constants';
import { formatStringArea } from '../../../../helpers/formatStringArea';

import './styles.scss/NavAreas.scss';

export const NavAreas: React.FC = () => {
  const {
    areas: { children },
  } = paths;
  return (
    <div className="nav-areas">
      <p className="nav-areas__title">Areas</p>
      <div className="nav__area">
        <h3 className="nav__area__province">Ontario</h3>
        <ul className="nav__area__wrapper">
          {Object.keys(children).map((city) => {
            const cityName = formatStringArea(city);
            const URL = getAreaURL(cityName);
            return (
              <li key={city}>
                <NavLink className="nav__area__city" to={URL}>
                  {cityName}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
