import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const AuroraPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Your Premier Cleaning Service in Aurora"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Welcome to Crystal Clear – Aurora trusted cleaning partner,
            dedicated to providing exceptional cleaning solutions throughout the
            vibrant neighborhoods of York Region. Our commitment to quality and
            customer satisfaction ensures tailored cleaning plans that meet your
            unique needs, creating a spotless and comfortable environment every
            time.
          </p>
          <p className="page-description__text">
            Our dedicated team, conveniently located near Aurora, offers
            professional and insured cleaning services that you can depend on.
            Our strategic location allows us to efficiently serve the diverse
            communities of Aurora. With our flexible{' '}
            <span className="page-description__text_bold">
              24-hour cancellation policy,
            </span>
            we prioritize your convenience and peace of mind.
          </p>
          <p className="page-description__text">
            Discover the Crystal Clear difference in Aurora and enjoy a cleaner,
            more comfortable living space.{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your home cleaning service online
            </Link>{' '}
            or{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              call us at {companyInfo.phoneNumber}
            </Link>{' '}
            today!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
