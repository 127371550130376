import { CleaningToolsCardWrapper } from '../modules/CleaningToolsCardWrapper';
import { TextWrapper } from '../modules/TextWithContent';
import { MainWrapper as Main } from '../ui/MainWrapper';
import { Title } from '../ui/MainTitle';

import ourTools from '../assets/cleaning-tools/our-tools.png';
import outStuff from '../assets/cleaning-tools/crystalclear-house-cleaning-services.png';

export const CleaningToolsPage = () => {
  return (
    <Main hasBackground>
      <Title text="Your Cleaning Toolkit" type="h1" color="black" isCentered />
      <TextWrapper
        additionalContent={
          <div
            style={{
              background: `url(${ourTools}) center / contain no-repeat`,
            }}
            className="text-with-content__content-wrapper-image"
          />
        }
        textDataType="cleaningToolsData"
      />
      <CleaningToolsCardWrapper
        title="Cleaning Tools"
        cardDataType="cleaningTools"
      />
      <CleaningToolsCardWrapper
        title="Cleaning Solutions"
        cardDataType="cleaningSolutions"
      />
      <CleaningToolsCardWrapper
        title="Helpful Items to Make the Job Easier"
        cardDataType="helpfulItems"
      />
      <Title
        isCentered
        type="h2"
        color="black"
        text="Above all, please bring a wonderful smile and a great character to each home. Clean each house as if it's your own."
      />
      <TextWrapper
        textDataType="cleaningToolsFooterData"
        additionalContent={
          <div
            style={{
              background: `url(${outStuff}) center / contain no-repeat`,
            }}
            className="text-with-content__content-wrapper-image"
          />
        }
      />
    </Main>
  );
};
