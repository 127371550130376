import { ReviewsPageDataType } from '../types/ReviewsPageDataType';

import mirandaSullivan from '../../../assets/reviews-page-clients-say/miranda-sullivan.png';
import joynaChand from '../../../assets/reviews-page-clients-say/joyna-chand.png';
import mirandaBurton from '../../../assets/reviews-page-clients-say/miranda-burton.png';
import nicoleShuckett from '../../../assets/reviews-page-clients-say/nicole-shuckett.png';
import JPSingh from '../../../assets/reviews-page-clients-say/jp-singh.png';
import nicoleClark from '../../../assets/reviews-page-clients-say/nicole-clark.png';
import deanSherk from '../../../assets/reviews-page-clients-say/dean-sherk.png';
import { SvgIconGoogle } from '../../../ui/SvgIcon';

export const ReviewsPageData: ReviewsPageDataType[] = [
  {
    img: mirandaSullivan,
    name: "Miranda O'Sullivan",
    rating: 5,
    desc: 'Ligia was great! Loved the little details like the toilet paper stamp and organizing our shower products!',
    icon: <SvgIconGoogle />,
  },
  {
    img: joynaChand,
    name: 'Joyna Chand',
    rating: 4,
    desc: 'Sylvia did a great job. Everything was done efficiently',
    icon: <SvgIconGoogle />,
  },
  {
    img: mirandaBurton,
    name: 'Miranda Burton',
    rating: 5,
    desc: 'Tamara & Bitania did a wonderful job! Our house is sparkling and fresh 😄 Another fabulous cleaning this time with Blessing and Elleni. They did an amazing job - house smells fresh and clean! So thankful for their hardwork! ☺️',
    icon: <SvgIconGoogle />,
  },
  {
    img: nicoleShuckett,
    name: 'nicole shuckett',
    rating: 5,
    desc: 'Great job!!! Emma was awesome we plan to use her again!!!',
    icon: <SvgIconGoogle />,
  },
  {
    img: JPSingh,
    name: 'JP Singh',
    rating: 5,
    desc: 'Lexia.did a great job .Thanks',
    icon: <SvgIconGoogle />,
  },
  {
    img: nicoleClark,
    name: 'Nicole Clark',
    rating: 4.5,
    desc: 'I used Crystal Clear for my rental property cleaning recently and they were very professional and easy to use. Their online website made it easy to select my preferred cleaning tasks like cleaning the fridge, and the fridge turned out spotless. I would recommend Crystal Clear for any deep cleaning tasks you may have.',
    icon: <SvgIconGoogle />,
  },
  {
    img: deanSherk,
    name: 'Dean Sherk',
    rating: 5,
    desc: 'Loved the service!  Bitania was professional, kind, thorough, responsive and sensitive to our needs. We would welcome her back and highly recommend Crystal Clear',
    icon: <SvgIconGoogle />,
  },
];
