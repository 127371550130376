import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { BookingText } from '../../ui/BookingText';
import { GoogleCaptcha } from '../../../../components/Captcha/GoogleCaptcha';

interface BookingFinalStepProperties {
  captchaRef: React.MutableRefObject<ReCAPTCHA | null>;
}

export const BookingFinalStep: React.FC<BookingFinalStepProperties> = ({
  captchaRef,
}) => {
  return (
    <div className="booking__final">
      <BookingText option="title" text="Final: Payment Details" />
      <BookingText
        option="description"
        text=" You will only be charged AFTER the cleaning service is complete. You can cancel anytime as long as it is 24 hours before your cleaning day, otherwise a fee will apply."
      />
      <GoogleCaptcha captchaRef={captchaRef} />
    </div>
  );
};
