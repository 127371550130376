import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { yupResolver } from '@hookform/resolvers/yup';
import { Title } from '../../../ui/MainTitle';
import { authFormFieldsData } from '../constants/AuthFormFieldsData';
import { getTitleFromString } from '../helpers/getTitleFromString';
import { getEndpointFromType } from '../helpers/getEndpointFromType';
import { AuthFormTypeEntity, FormFields } from '../types/AuthFormDataEntity';
import { GoogleCaptcha } from '../../../components/Captcha/GoogleCaptcha';
import { handleVerifyCaptcha } from '../../../components/Captcha/helpers/captchaValidation';
import { paths } from '../../../constants';
import Api from '../../../service/Api';
import {
  schemaLoginForm,
  schemaRegisterForm,
} from '../../../validators/ValidateAuthForm';

interface AuthFormProperties {
  type: AuthFormTypeEntity;
}

export const AuthForm: React.FC<AuthFormProperties> = ({ type }) => {
  const validationSchema =
    type === 'login' ? schemaLoginForm : schemaRegisterForm;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const title = getTitleFromString(type);
  const endpoint = getEndpointFromType(type);

  const onSubmit = async (formData: FormFields) => {
    try {
      const recaptchaValue = captchaRef.current?.getValue();
      if (recaptchaValue) {
        handleVerifyCaptcha(recaptchaValue);
        const result = await Api.post(endpoint, formData);
        if (type === 'register') {
          toast.success('Your account was created successfully.');
          navigate(paths.login);
        } else {
          toast.success('You have successfully logged in to your account.');
        }
      } else {
        toast.error('Please complete the captcha.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('Something went wrong, please try again later.');
    }
  };

  return (
    <form className="auth__form auth-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="auth-form__wrapper">
        <Title text={title} type="h1" color="black" />
        <p className="auth-form__description">
          {`${title} to track your Bookings, Reschedule, manage Add-ons, and
    more.`}
        </p>
        <div className="auth-form__fields">
          {authFormFieldsData[type].map((field) => {
            const { fieldName, type: fieldType, placeholder } = field;
            const labelTitle = getTitleFromString(fieldName);

            return (
              <fieldset key={fieldName} className="auth-form__fieldset">
                <label htmlFor={fieldName} className="auth-form__label">
                  <span className="auth-form__label-title">{`${labelTitle}:`}</span>
                  <input
                    {...register(fieldName as keyof FormFields)}
                    id={fieldName}
                    type={fieldType}
                    placeholder={placeholder}
                    className={
                      errors[fieldName as keyof FormFields]
                        ? 'auth-form__input error'
                        : 'auth-form__input'
                    }
                  />
                </label>
                <span className="auth-form__error-message">
                  {errors[fieldName as keyof FormFields]
                    ? errors[fieldName as keyof FormFields]?.message
                    : ' '}
                </span>
              </fieldset>
            );
          })}
        </div>
        <GoogleCaptcha captchaRef={captchaRef} />
        <button type="submit" className="auth-form__button">
          {title}
        </button>
        {type !== 'login' ? (
          <div>
            <p className="auth-form__description">
              Already have an account? <Link to={paths.login}>Login</Link>
            </p>
          </div>
        ) : (
          <div>
            <p className="auth-form__description">
              Don&apos;t have an account?{' '}
              <Link to={paths.register}>Register</Link>
            </p>
          </div>
        )}
      </div>
    </form>
  );
};
