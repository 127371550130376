import { AuthFormFieldsDataEntity } from '../types/AuthFormDataEntity';

export const authFormFieldsData: AuthFormFieldsDataEntity = {
  login: [
    {
      fieldName: 'email',
      type: 'email',
      placeholder: 'Enter your Email',
    },
    {
      fieldName: 'password',
      type: 'password',
      placeholder: 'Enter your Password',
    },
  ],
  register: [
    {
      fieldName: 'firstName',
      type: 'text',
      placeholder: 'Enter your First Name',
    },
    {
      fieldName: 'lastName',
      type: 'text',
      placeholder: 'Enter your Last Name',
    },
    {
      fieldName: 'email',
      type: 'email',
      placeholder: 'Enter your Email',
    },
    {
      fieldName: 'phone',
      type: 'tel',
      placeholder: 'Enter your Phone',
    },
    {
      fieldName: 'password',
      type: 'password',
      placeholder: 'Enter your Password',
    },
    {
      fieldName: 'passwordConfirmation',
      type: 'password',
      placeholder: 'Enter your Password again',
    },
  ],
};
