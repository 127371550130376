export type MainArea =
  | 'toronto'
  | 'ajax'
  | 'markham'
  | 'mississauga'
  | 'pickering'
  | 'richmond-hill'
  | 'vaughan'
  | 'scarborough'
  | 'north-york'
  | 'etobicoke'
  | 'aurora'
  | 'brampton'
  | 'burlington'
  | 'caledon'
  | 'georgina'
  | 'halton-hills'
  | 'milton'
  | 'newmarket'
  | 'oakvile'
  | 'oshawa'
  | 'stouffville'
  | 'uxbridge'
  | 'whitby'
  | 'east-gwillimbury'
  | 'king'
  | 'allLocations';

// eslint-disable-next-line no-shadow
export enum SpecificArea {
  Toronto = 'Toronto',
  NorthYork = 'North York',
  York = 'York',
  EastYork = 'East York',
  Scarborough = 'Scarborough',
  Etobicoke = 'Etobicoke',
}
