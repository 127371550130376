import * as Yup from 'yup';
import { nameRegExp } from './constants/nameRegExp';
import { GiftCardFormSelectData } from '../modules/GiftCardFormSection/constants/GiftCardFormSelectData';

export const schemaGiftCardForm = Yup.object().shape({
  amount: Yup.string()
    .notOneOf([GiftCardFormSelectData[0]], 'Please select the amount')
    .required('Please select the amount'),
  to: Yup.string()
    .required('Please write the name')
    .matches(nameRegExp, 'To indicate only Latin letters')
    .min(2, 'This field must contain at least 2 characters.')
    .max(25, 'This field must be no more than 15 characters long.'),
  recipientEmail: Yup.string()
    .email('Please write email')
    .required('Please write email'),
  from: Yup.string()
    .required('Please write your name')
    .matches(nameRegExp, 'To indicate only Latin letters')
    .min(2, 'This field must contain at least 2 characters.')
    .max(25, 'This field must be no more than 15 characters long.'),
  yourEmail: Yup.string()
    .email('Please write your email')
    .required('Please write your email'),
  message: Yup.string().required('Please write your message'),
});
