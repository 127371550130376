export const parseDate = (dateStr: string) => {
  const [_, monthStr, dayWithSuffix] = dateStr.split(' ');
  const day = parseInt(dayWithSuffix.replace(/\D/g, ''), 10);
  const month = new Date(`${monthStr} 1, 2000`).getMonth();
  const year = new Date().getFullYear();

  const parsedDate = new Date(year, month, day, 0, 0, 0);

  return isNaN(parsedDate.getTime()) ? null : parsedDate;
};
