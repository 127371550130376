import { NavLink } from 'react-router-dom';

import './styles/FooterSignature.scss';

export const FooterSignature = () => {
  return (
    <div className="footer-signature">
      <p className="footer-signature__item">
        Copyright © 2024 Crystal Clear Inc - All Rights Reserved -&nbsp;
        <NavLink className="footer-signature__item" to="/">
          Terms and Conditions&nbsp;
        </NavLink>
        -&nbsp;
        <NavLink className="footer-signature__item" to="/">
          Privacy Policy&nbsp;
        </NavLink>
        - Proudly Canadian&nbsp;
        <span>🇨🇦</span>
      </p>
    </div>
  );
};
