import { Link } from 'react-router-dom';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';
import { TitleItem } from '../ui/TitleItem';

import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';

export const LocalFriendly = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Experience Happiness and Quality with Crystal Clear’s Premier Cleaning Services in Brampton" />
          <p className="text__desc">
            Our professional cleaning services in Milton are designed to provide
            the{' '}
            <span className="text__desc_bold">
              highest level of cleanliness and comfort for your home or office.
            </span>
            We understand the importance of maintaining a pristine and organized
            environment, which is why we offer a wide range of cleaning services
            tailored to your specific needs.
          </p>
          <p className="text__desc">
            Our experienced cleaning specialists in Milton use state-of-the-art
            tools to ensure a thorough and revitalizing clean for your space.
            Whether you need{' '}
            <span className="text__desc_bold">
              regular maintenance cleaning,{' '}
            </span>
            <Link
              className="three-sections__link text__desc_bold"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep clean
            </Link>{' '}
            , or specialized services, we have personalized cleaning plans to
            meet your requirements.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
