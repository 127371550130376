import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { getAltTextForImage, getAreaURL } from '../../helpers';
import { paths } from '../../constants';
import { LocationSection } from '../LocationSection';
import { formatStringArea } from '../../helpers/formatStringArea';

import map from '../../assets/areas-page/map-crystal-clear-toronto.png';
import './styles/AreasPageAreas.scss';

export const AreasPageAreas = () => {
  const altText = getAltTextForImage(map);
  const {
    areas: { children },
  } = paths;

  return (
    <section className="areas-page-areas">
      <ContainerComponent>
        <div className="areas-page-areas__wrapper">
          <p className="areas-page-areas__desc">
            Book a cleaning service in your city below. We provide a range of
            house cleaning services across multiple cities in Canada. If you
            have any questions, reach out to our friendly customer service team!
          </p>
          <LocationSection />
          <div className="areas-page-areas__content">
            <div className="areas-page-areas__province">Ontario</div>
            <div className="areas-page-areas__province__container">
              <div className="areas-page-areas__cities">
                {Object.keys(children).map((city) => {
                  const cityName = formatStringArea(city);
                  const cityPath = getAreaURL(cityName);
                  return (
                    <div key={city} className="areas-page-areas__city">
                      <Link to={cityPath}>{cityName}</Link>
                    </div>
                  );
                })}
              </div>
              <div className="areas-page-areas__img">
                <img alt={altText} src={map} />
              </div>
            </div>
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
