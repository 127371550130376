import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { Text } from './components/Text/Text';
import { TrustIndicators } from './components/TrustIndicators/TrustIndicators';
import { PhotoWrapper } from './components/PhotoWrapper/PhotoWrapper';

import './styles/DifferencesBetweenUs.scss';

export const DifferencesBetweenUs = () => {
  return (
    <section className="differences-between-us">
      <Container>
        <div className="differences-between-us__wrapper">
          <Text />
          <PhotoWrapper />
          <TrustIndicators />
        </div>
      </Container>
    </section>
  );
};
