import { NavLink } from 'react-router-dom';
import { helpfulLinks } from '../../constants/helpfulLinks';

import './styles/NavHelpfulLinks.scss';

export const NavHelpfulLinks = () => {
  return (
    <div className="nav-help-links">
      <p className="nav-help-links__title">Helpful Links</p>
      <ul className="nav-help-links__wrapper">
        {helpfulLinks.map((link) => (
          <li key={link.name}>
            <NavLink className="nav-help-links__item" to={link.path}>
              {link.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
