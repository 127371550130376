import React from 'react';
import { ReviewsImagesType } from './types/ReviewsImagesType';

import './style/ReviewsVideoWrapper.scss';

interface ReviewsVideoWrapperProps {
  review: ReviewsImagesType;
}

export const ReviewsVideoWrapper: React.FC<ReviewsVideoWrapperProps> = ({
  review,
}) => {
  const { img, alt } = review;
  return (
    <div className="reviews-video-wrapper">
      <div className="reviews-video-wrapper__container">
        <img alt={alt} src={img} />
      </div>
    </div>
  );
};
