import React from 'react';
import { CleaningCardDataKeyEntity } from './types/CleainigToolsCardDataEntity';
import { CleaningToolsCardData } from './constants/CleaningToolsCardData';
import { CleaningToolsCard } from './components/CleaningToolsCard/CleaningToolsCard';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';

import './styles/CleaningToolsCardWrapper.scss';

interface CleaningToolsCardWrapperProperties {
  title: string;
  cardDataType: CleaningCardDataKeyEntity;
}

export const CleaningToolsCardWrapper: React.FC<
  CleaningToolsCardWrapperProperties
> = ({ title, cardDataType }) => {
  return (
    <section className="cleaning-tools__cards">
      <Title text={title} type="h2" color="black" isCentered />
      <Container>
        <div className="cleaning-tools__cards-wrapper">
          {CleaningToolsCardData[cardDataType].map((cardData) => {
            return <CleaningToolsCard key={cardData.id} cardData={cardData} />;
          })}
        </div>
      </Container>
    </section>
  );
};
