import { BookingFieldSet } from '../types/bookingFieldset';

export const fields: BookingFieldSet = {
  contactInformation: [
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'First Name',
      category: 'contactInformation',
    },
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Last Name',
      category: 'contactInformation',
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'example@test.com',
      category: 'contactInformation',
    },
    {
      name: 'phone',
      type: 'tel',
      placeholder: '123 456 7890',
      category: 'contactInformation',
    },
    {
      name: 'useSms',
      type: 'checkbox',
      placeholder: '',
      category: 'contactInformation',
    },
  ],
  locationInformation: [
    {
      name: 'address',
      type: 'text',
      placeholder: 'Address',
      category: 'locationInformation',
    },
    {
      name: 'apt',
      type: 'text',
      placeholder: 'Apt/Suite #',
      category: 'locationInformation',
    },
    {
      name: 'city',
      type: 'text',
      placeholder: 'City',
      category: 'locationInformation',
    },
    {
      name: 'postal',
      type: 'text',
      placeholder: 'Postal Code',
      category: 'locationInformation',
    },
  ],
  cleaningIntstructions: [
    {
      type: 'text',
      placeholder: 'Elaborate on flexibility (optional)',
      name: 'flexibility',
      category: 'cleaningIntstructions',
    },
    {
      type: 'text',
      placeholder: 'Occasion for the cleaning (optional)',
      name: 'occasion',
      category: 'cleaningIntstructions',
    },
    {
      type: 'text',
      placeholder: 'How did you hear about us? (optional)',
      name: 'aboutUs',
      category: 'cleaningIntstructions',
    },
  ],
};
