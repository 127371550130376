import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { SetsApartItem } from './components/SetsApartItem/SetsApartItem';
import { features } from './constants/features';
import { Title } from '../../ui/MainTitle';

import './styles/SetsApart.scss';

export const SetsApart: React.FC = () => {
  return (
    <section className="sets-apart">
      <ContainerComponent>
        <div className="sets-apart__wrapper">
          <Title text="What Sets CrystalClear Apart?" type="h2" />
          <div className="sets-apart__container">
            {features.map((item) => (
              <SetsApartItem key={item.title} item={item} />
            ))}
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
