import React from 'react';
import { ValuesCleaningType } from '../type/ValuesCleaningType';
import { Title } from '../../../ui/MainTitle';

interface ValuesCleaningTextProps {
  text: ValuesCleaningType;
}

export const ValuesCleaningText: React.FC<ValuesCleaningTextProps> = ({
  text,
}) => {
  const { title, desc } = text;
  return (
    <div className="values-cleaning-text">
      <Title text={title} type="h2" color="black" />
      <p className="values-cleaning-text__desc">{desc}</p>
    </div>
  );
};
