import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';

export const FriendlyTrustedStouffville = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Friendly and Trusted - Home Cleaning Services of Stouffville" />
          <p className="text__desc">
            At Crystal Clear, happiness is the core of what we do for both our
            cleaners and clients in Stouffville. We know that when our cleaners
            are happy and motivated, they provide excellent service, making
            everyone’s experience better. Whether you need a deep cleaning or
            regular weekly cleaning, our services are designed to fit your needs
            perfectly. In the beautiful city of Stouffville, we’re proud to help
            you keep your home clean and welcoming. Experience the joy and
            satisfaction of a spotless space with Crystal Clear, where your
            happiness is our priority, one clean home at a time.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
