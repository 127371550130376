import { Link } from 'react-router-dom';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { paths } from '../../constants';
import { ProfessionalCleaningServicesData } from './constants/ProfessionalCleaningServicesData';
import { getAltTextForImage } from '../../helpers';
import cleaners from '../../assets/three-easy-steps/house-cleaners-by-crystal-clear.jpg';

import './styles/ProfessionalCleaningServices.scss';

export const ProfessionalCleaningServices = () => {
  const {
    areas: { children: areasRoutes },
  } = paths;
  const altText = getAltTextForImage(cleaners);

  return (
    <section className="professional-cleaning-services">
      <Container>
        <div className="professional-cleaning-services__wrapper">
          <div className="professional-cleaning-services__text-wrapper">
            <Title
              type="h2"
              text="Professional Cleaning Services of Toronto"
              color="black"
            />
            <p className="professional-cleaning-services__paragraph">
              Welcome to Crystal Clear – Toronto’s #1 cleaning company, trusted
              by homeowners, landlords, and business owners across the GTA,
              including the{' '}
              <span className="professional-cleaning-services__paragraph_bold">
                City of Toronto
              </span>
              ,{' '}
              <Link
                className="professional-cleaning-services__paragraph_link"
                to={areasRoutes.northYork}
              >
                North York
              </Link>
              ,{' '}
              <Link
                className="professional-cleaning-services__paragraph_link"
                to={areasRoutes.etobicoke}
              >
                Etobicoke
              </Link>
              ,{' '}
              <Link
                className="professional-cleaning-services__paragraph_link"
                to={areasRoutes.scarborough}
              >
                Scarborough
              </Link>
              , and more. Customize your cleaning service to fit your lifestyle
              with our flexible, satisfaction-guaranteed plans.
            </p>
            <ul className="professional-cleaning-services__list">
              {ProfessionalCleaningServicesData.map((item) => {
                return (
                  <li
                    key={item.id}
                    className="professional-cleaning-services__item"
                  >
                    <div className="professional-cleaning-services__item-icon-wrapper">
                      {item.icon}
                    </div>
                    <p className="professional-cleaning-services__item-title">
                      {item.title}
                    </p>
                    <span className="professional-cleaning-services__item-description">
                      {item.description}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="professional-cleaning-services__image-wrapper">
            <img
              className="professional-cleaning-services__image"
              src={cleaners}
              alt={altText}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};
