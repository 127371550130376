import React from 'react';

interface PriceRecurringProps {
  total: number;
  recurring: number;
}

export const PriceRecurring: React.FC<PriceRecurringProps> = ({
  total,
  recurring,
}) => {
  return (
    <div className="booking-summary-price__frequency">
      <div className="booking-summary-price__initial booking-summary-text">
        <p className="booking-summary-price__initial__title">
          Initial Clean Fee
        </p>
        <p className="booking-summary-price__initial_accent">
          ${total.toFixed(2)}
        </p>
      </div>
      <div className="booking-summary-price__recurring booking-summary-text">
        <p className="booking-summary-price__recurring__title">
          Recurring Clean Fee
        </p>
        <p className="booking-summary-price__recurring_accent">
          ${recurring.toFixed(2)}
        </p>
      </div>
    </div>
  );
};
