/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { FormDataEntity } from '../../../../types';
import { SvgIconMinus, SvgIconPlus } from '../../../../ui/SvgIcon';
import { DashboardSectionItem } from '../DashboardSectionItem';
import { useWidthWindow } from '../../../../hooks/useWidthWindows';

import './styles/DashboardSectionRow.scss';

interface DashboardSectionRowProps {
  content: FormDataEntity;
  index: number;
  onClick: (idAccord: string) => void;
  selected: boolean;
}

export const DashboardSectionRow: React.FC<DashboardSectionRowProps> = ({
  content,
  index,
  onClick,
  selected,
}) => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= 800;
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [addressUser, setAddressUser] = useState<string>('');

  const { totalPrice, dateInformation, locationInformation } = content;

  useEffect(() => {
    if (dateInformation?.date) {
      const { date } = dateInformation;
      setSelectedDate(new Date(date).toLocaleDateString('en-US'));
    }
    if (locationInformation) {
      const { address, apt, city } = locationInformation;
      setAddressUser(`${address} ${apt}, ${city},`);
    }
  }, []);

  return (
    <div className="dashboard-section-row">
      <div
        className="dashboard-section-row__title"
        id={String(index)}
        onClick={(e) => {
          onClick(e.currentTarget.id);
        }}
      >
        {!isMobile && (
          <div className="dashboard-section-row__item">
            <p>#123</p>
          </div>
        )}
        <div className="dashboard-section-row__item">
          <p>{addressUser}</p>
        </div>
        {!isMobile && (
          <div className="dashboard-section-row__item">
            <p>{totalPrice}$</p>
          </div>
        )}
        <div className="dashboard-section-row__item">
          <p className="dashboard-section-row__item__day">{selectedDate}</p>
        </div>
        <div className="dashboard-section-row__arrow">
          {selected ? <SvgIconMinus /> : <SvgIconPlus />}
        </div>
      </div>
      <div
        className={
          selected
            ? 'dashboard-section-row__open dashboard-section-row__content'
            : 'dashboard-section-row__content'
        }
      >
        <DashboardSectionItem content={content} />
      </div>
    </div>
  );
};
