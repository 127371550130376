import { Link } from 'react-router-dom';
import ccs from '../../../../assets/footer/CCS.png';
import livingWage from '../../../../assets/footer/living_wage.png';
import lw from '../../../../assets/footer/LW.png';
import olwn from '../../../../assets/footer/OLWN.png';

export const PartnersLinks = () => {
  return (
    <div className="partners-links">
      <Link to="https://www.ontariolivingwage.ca/">
        <img alt="partners-links" src={olwn} />
      </Link>
      <Link to="https://www.livingwagealberta.ca/">
        <img alt="partners-links" src={livingWage} />
      </Link>
      <Link to="https://cancer.ca/en">
        <img alt="partners-links" src={ccs} />
      </Link>
      <Link to="https://www.livingwagebc.ca/">
        <img alt="partners-links" src={lw} />
      </Link>
    </div>
  );
};
