/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderButtonTypeEntity } from '../../types/HeaderButtonDataEntity';
import { headerButtonData } from '../../constants/HeaderButtonData';

import './styles/HeaderButton.styles.scss';

interface ButtonProps {
  type: HeaderButtonTypeEntity;
  setIsSideMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderButton: React.FC<ButtonProps> = ({
  type,
  setIsSideMenuOpen = () => {},
}) => {
  const { path, text } = headerButtonData[type];

  return (
    <Link
      to={path}
      className={`header__button buttons__${type}`}
      onClick={() => setIsSideMenuOpen(false)}
    >
      {text}
    </Link>
  );
};
