import { BookingContentPanelData } from './constants/BookingContentPanelData';

import './styles/BookingContentPanel.scss';

export const BookingContentPanel = () => {
  return (
    <div className="booking-content-panel">
      <div className="booking-content-panel__wrapper">
        {BookingContentPanelData.map((content) => (
          <div className="booking-content-panel__item" key={content.title}>
            <h4 className="booking-content-panel__item_icon">{content.icon}</h4>
            <h4 className="booking-content-panel__item_title">
              {content.title}
            </h4>
            <p className="booking-content-panel__item_desc">{content.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
