import React, { ChangeEvent } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { BookingField, FieldDefinitionOption } from '../types/bookingFieldset';
import { SvgIconArrowDown } from '../../../ui/SvgIcon';
import { CleaningService } from '../../../types';
import { useBookingFormData } from '../../../hooks/useBookingFormData';

interface BookingSelectInputProperties {
  name: keyof BookingField;
  register: UseFormRegister<BookingField>;
  errors: FieldErrors<BookingField>;
  isPlaceholderDisabled: boolean;
  options: CleaningService[] | FieldDefinitionOption[];
  handleOptionChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export const BookingSelectInput: React.FC<BookingSelectInputProperties> = ({
  name,
  register,
  errors,
  isPlaceholderDisabled,
  handleOptionChange,
  options,
}) => {
  const {
    formData: { cleaningInformation },
  } = useBookingFormData();

  return (
    <div
      className={
        !errors[name]
          ? 'fieldset__select-wrapper'
          : 'fieldset__select-wrapper error'
      }
    >
      <select
        className="fieldset__select"
        id={name}
        value={cleaningInformation?.name}
        {...register(name, {
          onChange: (event) => {
            handleOptionChange(event);
          },
        })}
      >
        {options.map((option) => {
          const { name: optionName } = option;
          const isPlaceholder = optionName === '(select cleaning type)';

          return (
            <option
              disabled={isPlaceholder && isPlaceholderDisabled}
              key={optionName}
              className="fieldset__option"
            >
              {optionName}
            </option>
          );
        })}
      </select>
      <SvgIconArrowDown />
    </div>
  );
};
