import { Link } from 'react-router-dom';
import { companyInfo, paths } from '../../../constants';

export const ReasonsTrustListToronto = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Professional Housekeeping:
        </span>{' '}
        Expert cleaners delivering top-quality service.
      </li>
      <li className="reasons-trust-list__item">
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
        >
          Eco-friendly Cleaning Options
        </Link>
        <span className="reasons-trust-list__bold">:</span> Tailored to your
        green needs.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          100% Satisfaction Guarantee:
        </span>{' '}
        Your happiness is our priority.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Competitive Pricing:</span>{' '}
        High-quality services at great value.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Background-checked Cleaners:
        </span>{' '}
        Toronto maids you can trust.
      </li>
      <li className="reasons-trust-list__item">
        <Link className="reasons-trust-list__link" to={paths.book}>
          Easy Online Booking
        </Link>
        <span className="reasons-trust-list__bold">: </span>
        Schedule your cleaning effortlessly.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Excellent Customer Service:{' '}
        </span>{' '}
        24/7 support from start to finish.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Proven Track Record:</span>{' '}
        Trusted by clients, as seen in{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          our reviews
        </Link>
        .
      </li>
      <li className="reasons-trust-list__item">
        <Link
          className="reasons-trust-list__link"
          to={companyInfo.officesMap.toronto}
          target="_blank"
        >
          Local and Trusted Company
        </Link>
        <span className="reasons-trust-list__bold">:</span> Proudly serving the
        Toronto community.
      </li>
    </ul>
  );
};
