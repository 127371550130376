import { discounts } from '../../../constants';
import { countryCode } from '../../BookingForm/constants/countryCode';
import { CommercialFormValues } from '../type/CommercialFormValues';

export const getCommercialFormData = (data: CommercialFormValues) => {
  const formattedPhone = countryCode + data.phone.replaceAll(' ', '');

  let frequencyInformation = {
    frequency: '',
    frequencyId: 0,
    percentage: 0,
  };

  const selectedFrequency = discounts.find(
    (item) => item.id === Number(data.frequencyInformation?.frequencyId)
  );

  if (selectedFrequency) {
    frequencyInformation = {
      frequency: selectedFrequency.name,
      frequencyId: selectedFrequency.id,
      percentage: selectedFrequency.value,
    };
  }

  const CommercialFormData: CommercialFormValues = {
    name: data.name,
    businessName: data.businessName,
    officeAddress: data.officeAddress,
    email: data.email,
    phone: formattedPhone,
    squareFootage: data.squareFootage,
    frequencyInformation,
    washrooms: data.washrooms,
    kitchennetes: data.kitchennetes,
    additionalMessage: data.additionalMessage,
  };

  return CommercialFormData;
};
