import React from 'react';
import { BurgerIconType } from '../../../types/BurgerIconType';
import { SvgIconBurger, SvgIconClose } from '../../../../../ui/SvgIcon';

interface BurgerButtonProps {
  type: BurgerIconType;
}

export const BurgerIcon: React.FC<BurgerButtonProps> = ({ type }) => {
  return <>{type === 'burger' ? <SvgIconBurger /> : <SvgIconClose />}</>;
};
