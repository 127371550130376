import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import { NotFoundModule } from '../modules/NotFoundModule';
import { MainWrapper as Main } from '../ui/MainWrapper';

export const NotFoundPage = () => {
  return (
    <Main>
      <DynamicBrowserHeaderTitle page="notFound" />
      <NotFoundModule />
    </Main>
  );
};
