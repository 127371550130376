import { AuthFormTypeEntity } from '../types/AuthFormDataEntity';

export const getEndpointFromType = (type: AuthFormTypeEntity) => {
  return type
    .split('')
    .map((character) =>
      character === character.toUpperCase()
        ? character.toLowerCase()
        : character
    )
    .join('');
};
