import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { EmpoweredCleanersSection } from './components/EmpoweredCleanersSection/EmpoweredCleanersSection';
import { RedefiningCleaningSection } from './components/RedefiningCleaningSection/RedefiningCleaningSection';
import { TeamSection } from './components/TeamSection/TeamSection';
import { OurStuffItems } from './constants/OurStuffItems';
import './styles/ThreeSectionsOfStuff.scss';

interface OurStuffProperties {
  hasOnlyOneSection?: boolean;
  componentName?: string;
}

export const OurStuff: React.FC<OurStuffProperties> = ({
  hasOnlyOneSection = false,
  componentName = 'services',
}) => {
  const Component = OurStuffItems[componentName];
  if (!Component) {
    return null;
  }

  return (
    <section className="three-sections-of-stuff">
      <ContainerComponent>
        <div className="three-sections-of-stuff__container">
          {hasOnlyOneSection ? (
            <Component />
          ) : (
            <>
              <EmpoweredCleanersSection />
              <RedefiningCleaningSection />
              <TeamSection />
            </>
          )}
        </div>
      </ContainerComponent>
    </section>
  );
};
