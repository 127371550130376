import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import Api from '../../service/Api';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { GiftCardFormCard } from './components/GiftCardFormCard';
import { GiftCardForm } from './components/GiftCardForm';
import { FormDataGiftCardType } from './type/FormDataGiftCardType';
import { GiftCardFormCheckout } from './components/GiftCardFormCheckout';
import { schemaGiftCardForm } from '../../validators';
import { GiftCardFormTitle } from './components/GiftCardFormTitle';
import { handleVerifyCaptcha } from '../../components/Captcha/helpers/captchaValidation';

import './styles/GiftCardFormSection.scss';

export const GiftCardFormSection = () => {
  const captchaRef = useRef<ReCAPTCHA | null>(null);
  const isMounted = useRef<boolean>(false);
  const [isSubmited, setIsSubmitted] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormDataGiftCardType>({
    resolver: yupResolver(schemaGiftCardForm),
  });

  const formFields = watch();

  const onSubmit = async (data: FormDataGiftCardType) => {
    try {
      const recaptchaValue = captchaRef.current?.getValue();
      if (recaptchaValue) {
        handleVerifyCaptcha(recaptchaValue);
        await Api.post('/booking/giftCard/new', data);
        toast.success(
          'Your submission was successful. Our team will contact you as soon as possible.'
        );
      } else {
        toast.error('Please complete the captcha.');
      }
    } catch (error) {
      console.error('Submission error:', error);
      toast.error('Something went wrong, please try again later.');
    }
  };

  useEffect(() => {
    if (isSubmited && isMounted.current) {
      if (Object.keys(errors).length > 0) {
        toast.error(
          'Please check the highlighted fields for any invalid data.'
        );
      }
    } else {
      isMounted.current = true;
    }
  }, [errors, isSubmited]);

  return (
    <ContainerComponent>
      <div className="gift-card-form-section">
        <form
          className="gift-card-form-section__form"
          onSubmit={(event) => {
            event.preventDefault();
            setIsSubmitted(true);
            handleSubmit(onSubmit)();
          }}
        >
          <GiftCardFormTitle text="gift card" />
          <div className="gift-card-form-section__form-container">
            <GiftCardForm register={register} errors={errors} />
            <GiftCardFormCard formFields={formFields} />
          </div>
          <GiftCardFormTitle text="checkout" />
          <GiftCardFormCheckout
            captchaRef={captchaRef}
            formFields={formFields}
          />
        </form>
      </div>
    </ContainerComponent>
  );
};
