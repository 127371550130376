import { NavLink } from 'react-router-dom';
import { paths } from '../../../../constants';
import { getNameFromRoute } from '../../../../helpers';

import './styles/NavServices.scss';

export const NavServices = () => {
  const {
    services: { children, path: servicesPath },
  } = paths;

  return (
    <div className="nav-services">
      <p className="nav-services__title">Services</p>
      <ul className="nav-services__wrapper">
        {Object.values(children).map((route) => {
          const title = getNameFromRoute(route);

          return (
            <li key={title}>
              <NavLink
                className="nav-services__item"
                to={`${servicesPath}/${route}`}
              >
                {title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
