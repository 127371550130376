import React from 'react';
import { FieldErrors, UseFormRegister, UseFormTrigger } from 'react-hook-form';
import { SelectForm } from '../../../../../../ui/SelectForm';
import { provinces } from '../../../../../../constants/provinces';
import { BookingField } from '../../../../types/bookingFieldset';
import { BookingText } from '../../../../ui/BookingText';
import { fields } from '../../../../constants/fields';
import { BookingFieldsetInput } from '../../../../ui/BookingFieldsetInput';

import '../../styles/WhereWhenModule.scss';

interface WhereWhenAddressProps {
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
  errors: FieldErrors<BookingField>;
  trigger: UseFormTrigger<BookingField>;
}

export const WhereWhenAddress: React.FC<WhereWhenAddressProps> = ({
  register,
  formFields,
  errors,
  trigger,
}) => {
  const provinceOptions = provinces.map((item) => item.name);

  return (
    <div className="fieldset__address-wrapper">
      <div className="fieldset__text-wrapper">
        <BookingText text="Step 2 of 5: Where and When?" option="title" />
        <BookingText
          text="1) Please enter the address of the property to be cleaned"
          option="description"
        />
      </div>
      <div className="fieldset__input-wrapper fieldset__input-wrapper_address">
        {fields.locationInformation.map((field) => {
          const { name } = field;

          return (
            <BookingFieldsetInput
              key={name}
              register={register}
              errors={errors}
              trigger={trigger}
              fieldData={field}
              formFields={formFields}
            />
          );
        })}
        <SelectForm
          register={register}
          options={provinceOptions}
          formFields={formFields}
          name="province"
        />
      </div>
    </div>
  );
};
