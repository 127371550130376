import React from 'react';
import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { TrustIndicatorsSectionType } from './constants/type/TrustIndicatorsSectionType';
import { getAltTextForImage } from '../../helpers';

import './styles/TrustIndicatorsSection.scss';

interface TrustIndicatorsSectionProps {
  content: TrustIndicatorsSectionType[];
}

export const TrustIndicatorsSection: React.FC<TrustIndicatorsSectionProps> = ({
  content,
}) => {
  return (
    <ContainerComponent>
      <div className="trust-indicators-section">
        {content.map((item) => {
          const altText = getAltTextForImage(item.img);
          return (
            <Link
              key={item.img}
              className="trust-indicators-section__link"
              to={item.url}
            >
              <img
                className="trust-indicators-section__img"
                src={item.img}
                alt={altText}
              />
            </Link>
          );
        })}
      </div>
    </ContainerComponent>
  );
};
