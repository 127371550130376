import { FormMyPrice } from '../../components/FormMyPrice';
import { breakpoints } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { HeroSection } from '../../modules/HeroSection';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { RecurringCleaning } from '../../modules/RecurringCleaning';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { OurStuff } from '../../modules/OurStuff';
import { WrapperMyPrice } from '../../modules/WrapperMyPrice';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { SvgIconSmile } from '../../ui/SvgIcon';
import homeCleaningPageImage from '../../assets/heroSection/home-cleaning.jpg';
import { ProfessionalCleaning } from '../../modules/ProfessionalCleaning';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { CleaningZones } from '../../modules/CleaningZones';
import { ValuesCleaning } from '../../modules/ValuesCleaning';
import { Reviews } from '../../modules/Reviews';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { ServiceInclusions } from '../../modules/ServiceInclusions';
import { ecoInclusionsData } from '../../modules/ServiceInclusions/constants/ecoInclusionsData';
import { TrustedChoice } from '../../modules/TrustedChoice';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { AccordionData } from '../../modules/FrequentlyQuestions/constants/AccordionData';

export const EcoCleaningPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="ecoCleaningService" />
      <HeroSection
        title="Eco-Friendly and Green cleaning services"
        subtitle="Canada’s #1 Eco-Friendly and Green Cleaning Services Reducing our Environmental Impact, One Clean Home at a Time"
        imageSource={homeCleaningPageImage}
      />
      <ServiceInclusions inclusionsData={ecoInclusionsData} />
      <ProfessionalCleaning
        title="Professional Eco-Friendly and Green Cleaning Services of Canada"
        desc="green cleaning"
      />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <CleaningZones
        content={CleaningZonesData}
        title="Our Eco-Friendly Cleaning Services Checklist"
      />
      <TrustedChoice />
      <OurStuff hasOnlyOneSection />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <ServiceExclusions />
      <ValuesCleaning />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <RecurringCleaning />
      <FrequentlyQuestions questions={AccordionData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <WrapperMyPrice
        title="Empowering Cleaners, Elevating Homes"
        desc="If you're not happy, we'll work to make it right!"
        form={<FormMyPrice isMinimized buttonText="Get a free Quote" />}
        icon={<SvgIconSmile />}
      />
    </Main>
  );
};
