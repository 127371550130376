import { BookingPriceProvider } from '../context/BookingPriceRequestContext/bookingPriceContext';
import { BookingForm } from '../modules/BookingForm';
import { DynamicBrowserHeaderTitle } from '../modules/DynamicBrowserHeaderTitle';
import { PageTitleBackground } from '../modules/PageTitleBackground';
import { MainWrapper as Main } from '../ui/MainWrapper';

export const BookingPage = () => {
  return (
    <Main hasBackground>
      <DynamicBrowserHeaderTitle page="booking" />
      <PageTitleBackground
        title="View Pricing, Availability, and Book your Cleaning Below"
        hasTriangleShape
      />
      <BookingPriceProvider>
        <BookingForm />
      </BookingPriceProvider>
    </Main>
  );
};
