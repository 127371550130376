import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BookingField } from '../../../types/bookingFieldset';
import { CleaningInstructionsEntity } from '../../../../../types';
import { useBookingFormData } from '../../../../../hooks/useBookingFormData';

interface SpecialInstructionsInputProps {
  placeholder: string;
  name: keyof BookingField;
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
}

export const SpecialInstructionsInput: React.FC<
  SpecialInstructionsInputProps
> = ({ placeholder, name, register, formFields }) => {
  const {
    setInstructionsInformation,
    setDiscountCode,
    formData: { cleaningIntstructions },
  } = useBookingFormData();

  const handleDataAddOnBlur = () => {
    if (name === 'discountCode') {
      setDiscountCode(formFields[name] as string);
    } else {
      const cleaningInstructions: CleaningInstructionsEntity = {
        ...cleaningIntstructions,
        [name]: formFields[name],
      };
      setInstructionsInformation(cleaningInstructions);
    }
  };

  return (
    <input
      className="special-instructions__input"
      placeholder={placeholder}
      type="text"
      {...register(name, {
        onBlur: handleDataAddOnBlur,
      })}
    />
  );
};
