import React from 'react';
import { Link } from 'react-router-dom';
import { SvgIconCheck } from '../../../../ui/SvgIcon';
import { getAreaURL } from '../../../../helpers';

import '../../styles/AreaCleaning.scss';

interface AreaCleaningItemProps {
  area: string;
}

export const AreaCleaningItem: React.FC<AreaCleaningItemProps> = ({ area }) => {
  const areaUrl = getAreaURL(area);
  return (
    <li className="area-cleaning__item">
      <Link to={`${areaUrl}`}>
        <SvgIconCheck />
        <span>Cleaning services {area}</span>
      </Link>
    </li>
  );
};
