import { companyInfo } from '../../../../constants';
import {
  SvgIconFacebook,
  SvgIconLinkedin,
  SvgIconInstagram,
  SvgIconTikTok,
  SvgIconYouTube,
} from '../../../../ui/SvgIcon';

export const SocialLinks = () => {
  return (
    <div className="social-links">
      <a href={companyInfo.socialLinks.instagram}>
        <SvgIconInstagram />
      </a>
      <a href={companyInfo.socialLinks.linkedin}>
        <SvgIconLinkedin />
      </a>
      <a href={companyInfo.socialLinks.facebook}>
        <SvgIconFacebook />
      </a>
      <a href={companyInfo.socialLinks.youtube}>
        <SvgIconYouTube />
      </a>
      <a href={companyInfo.socialLinks.tiktok}>
        <SvgIconTikTok />
      </a>
    </div>
  );
};
