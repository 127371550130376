import { getAltTextForImage } from '../../../../helpers';
import { TitleItem } from '../ui/TitleItem';

import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';

export const HappinessGuaranteedUxbridge = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Happiness-Guaranteed House Cleaning Services of Uxbridge" />
          <p className="text__desc">
            Our cleaning services in Uxbridge are all about bringing you a
            fresh, clean, and comfortable space, whether it’s your home or
            office. We get that keeping things spotless can be tough with life’s
            daily demands, so we’ve created a range of cleaning options to match
            whatever you need.
          </p>
          <p className="text__desc">
            Our skilled cleaning pros come prepared with everything needed to
            leave your space looking and feeling revitalized. From regular
            cleaning to deep scrubs, you’re in control! Design a custom cleaning
            plan that fits your schedule and lifestyle, and let us take care of
            the rest.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
