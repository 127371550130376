import { useEffect } from 'react';
import leaflet from 'leaflet';
import { useMap } from 'react-leaflet';
import { GeoJsonObject } from 'geojson';

export const MapBoundsSetter = ({ data }: { data: GeoJsonObject }) => {
  const map = useMap();

  useEffect(() => {
    if (data) {
      const bounds = leaflet.geoJSON(data).getBounds();
      map.fitBounds(bounds);
    }
  }, [data, map]);

  return null;
};
