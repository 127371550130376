import { ReasonsTrustListAjax } from '../components/ReasonsTrustListAjax';
import { ReasonsTrustListMississauga } from '../components/ReasonsTrustListMississauga';
import { ReasonsTrustListScarborough } from '../components/ReasonsTrustListScarborough';
import { ReasonsTrustListVaughan } from '../components/ReasonsTrustListVaughan';
import { ReasonsTrustListMarkham } from '../components/ReasonsTrustListMarkham';
import { ReasonsTrustListToronto } from '../components/ReasonsTrustListToronto';
import { ReasonsTrustListPickering } from '../components/ReasonsTrustListPickering';
import { ReasonsTrustListRichmondHill } from '../components/ReasonsTrustListRichmondHill';
import { ReasonsTrustItemsType } from '../type/ReasonsTrustItemsType';
import { ReasonsTrustListBrampton } from '../components/ReasonsTrustListBrampton';
import { ReasonsTrustListMilton } from '../components/ReasonsTrustListMilton';
import { ReasonsTrustListOshawa } from '../components/ReasonsTrustListOshawa';
import { ReasonsTrustListUxbridge } from '../components/ReasonsTrustListUxbridge';
import { ReasonsTrustListWhitby } from '../components/ReasonsTrustListWHitby';
import { ReasonsTrustListCaledon } from '../components/ReasonsTrustListCaledon';
import { ReasonsTrustListAurora } from '../components/ReasonsTrustListAurora';
import { ReasonsTrustListNewmarket } from '../components/ReasonsTrustListNewmarket';
import { ReasonsTrustListKing } from '../components/ReasonsTrustListKing';
import { ReasonsTrustListEastGwillimbury } from '../components/ReasonsTrustListEastGwillimbury';
import { ReasonsTrustListHaltonHills } from '../components/ReasonsTrustListHaltonHills';
import { ReasonsTrustListBurlington } from '../components/ReasonsTrustListBurlington';
import { ReasonsTrustListGeorgina } from '../components/ReasonsTrustListGeorgina';
import { ReasonsTrustListStouffville } from '../components/ReasonsTrustListStouffville';
import { ReasonsTrustListOakville } from '../components/ReasonsTrustListOakville';

export const ReasonsTrustItems: ReasonsTrustItemsType = {
  mississauga: ReasonsTrustListMississauga,
  vaughan: ReasonsTrustListVaughan,
  scarborough: ReasonsTrustListScarborough,
  markham: ReasonsTrustListMarkham,
  toronto: ReasonsTrustListToronto,
  etobicoke: ReasonsTrustListToronto,
  northYork: ReasonsTrustListToronto,
  richmondHill: ReasonsTrustListRichmondHill,
  pickering: ReasonsTrustListPickering,
  ajax: ReasonsTrustListAjax,
  brampton: ReasonsTrustListBrampton,
  milton: ReasonsTrustListMilton,
  oshawa: ReasonsTrustListOshawa,
  uxbridge: ReasonsTrustListUxbridge,
  whitby: ReasonsTrustListWhitby,
  caledon: ReasonsTrustListCaledon,
  aurora: ReasonsTrustListAurora,
  newmarket: ReasonsTrustListNewmarket,
  king: ReasonsTrustListKing,
  eastGwillimbury: ReasonsTrustListEastGwillimbury,
  haltonHills: ReasonsTrustListHaltonHills,
  burlington: ReasonsTrustListBurlington,
  georgina: ReasonsTrustListGeorgina,
  stouffville: ReasonsTrustListStouffville,
  oakville: ReasonsTrustListOakville,
};
