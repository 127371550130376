import { TextDescription } from './ui/TextDescription';
import { Title } from '../../../../ui/MainTitle';

export const Text = () => {
  return (
    <div className="differences-between-us__text text">
      <Title
        text="Experience the Difference with a Cleaning Company that Cares"
        type="h2"
        color="black"
      />
      <TextDescription />
    </div>
  );
};
