import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { GiftCardsPageImg } from '../constants/GiftCardsPageImg';
import { getAltTextForImage } from '../../../helpers';

import 'swiper/css';
import 'swiper/css/pagination';

export const GiftCardsPageTitleSwiper = () => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Autoplay]}
      loop
      className="gift-cards-page-title__swiper"
    >
      {GiftCardsPageImg.map((img) => {
        const altText = getAltTextForImage(img);
        return (
          <SwiperSlide key={img}>
            <img alt={altText} src={img} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
