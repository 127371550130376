import { breakpoints, companyInfo } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { AboutSection } from '../../modules/AboutSection';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { AreasTestimonialOshawaData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { ContactSection } from '../../modules/ContactSection';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { GetQuote } from '../../modules/GetQuote';
import { GiftCards } from '../../modules/GiftCards';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { MapWrapper } from '../../ui/MapWrapper';
import { OshawaPageDescription } from '../../modules/PageDescription/OshawaPageDescription';
import { AcrossCanada } from '../../modules/AcrossCanada';
import { AccordionDataOshawa } from '../../modules/FrequentlyQuestions/constants/AccordionDataOshawa';
import { TrustIndicatorsSection } from '../../modules/TrustIndicatorsSection';
import { TrustIndicatorsSectionData } from '../../modules/TrustIndicatorsSection/constants/TrustIndicatorsSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';

import imgOshawa from '../../assets/oshawa-page/oshawa.png';

export const OshawaPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="oshawaArea" />
      <AreasTitle
        title="Cleaning Services Oshawa"
        city="Oshawa"
        subtitle="Experience the Best Cleaning Services in Oshawa – Your Home, Our Priority!"
        imageSource={imgOshawa}
        areasTestimonial={AreasTestimonialOshawaData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <OshawaPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      {!isMobile && (
        <TrustIndicatorsSection content={TrustIndicatorsSectionData} />
      )}
      <OurStuff hasOnlyOneSection componentName="oshawa" />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Oshawa Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Oshawa" componentName="oshawa" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Oshawa"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <AcrossCanada />
      <AboutSection />
      <ActionButtons hasBackground data={twoButtonsData} />
      <FrequentlyQuestions questions={AccordionDataOshawa} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Oshawa"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="oshawa" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews
        title="Reviews of our Cleaning Services in Oshawa"
        maxItems={2}
        swiper
      />
      <GetQuote title="Try our Cleaning Services in Oshawa Today!" />
    </Main>
  );
};
