import { breakpoints, companyInfo } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { AreasTestimonialNewmarketData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { NewmarketPageDescription } from '../../modules/PageDescription';
import { OurStuff } from '../../modules/OurStuff';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { TrustIndicatorsSection } from '../../modules/TrustIndicatorsSection';
import { TrustIndicatorsSectionData } from '../../modules/TrustIndicatorsSection/constants/TrustIndicatorsSectionData';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { CleaningZones } from '../../modules/CleaningZones/CleaningZones';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { AboutSection } from '../../modules/AboutSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { ContactSection } from '../../modules/ContactSection';
import { MapWrapper } from '../../ui/MapWrapper';
import { GiftCards } from '../../modules/GiftCards';
import { Reviews } from '../../modules/Reviews';
import { GetQuote } from '../../modules/GetQuote';
import { AccordionDataNewmarket } from '../../modules/FrequentlyQuestions/constants/AccordionDataNewmarket';

import imgNewmarket from '../../assets/newmarket-page/newmarket.jpg';

export const NewmarketPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="newmarketArea" />
      <AreasTitle
        title="Cleaning Services Newmarket"
        city="Newmarket"
        subtitle="Professional Cleaning Services of Newmarket – Hire Expert House Cleaners Near You Today!"
        imageSource={imgNewmarket}
        areasTestimonial={AreasTestimonialNewmarketData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <NewmarketPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="newmarket" />
      {!isMobile && (
        <TrustIndicatorsSection content={TrustIndicatorsSectionData} />
      )}
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices title="Our Cleaning Services in Newmarket Include" />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Newmarket" componentName="newmarket" />
      <CleaningZones
        title="House Cleaning Services Newmarket Checklist"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataNewmarket} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Newmarket"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="newmarket" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews
        title="Reviews of our Cleaning Services in Newmarket"
        maxItems={2}
        swiper
      />
      <GetQuote title="Try our Cleaning Services in Newmarket Today!" />
    </Main>
  );
};
