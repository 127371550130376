import livingWage from '../../../assets/trust-indicators-section/living-wage-canada-crystalclear-cleaning.png';
import cancerSociety from '../../../assets/trust-indicators-section/canadian-cancer-society-logo-crystalclear.png';
import google from '../../../assets/trust-indicators-section/crystalclear-google-reviews.png';
import { TrustIndicatorsSectionType } from './type/TrustIndicatorsSectionType';

export const TrustIndicatorsSectionData: TrustIndicatorsSectionType[] = [
  {
    img: livingWage,
    url: 'https://www.ontariolivingwage.ca',
  },
  {
    img: cancerSociety,
    url: 'https://cancer.ca/en',
  },
  {
    img: google,
    url: 'https://maps.app.goo.gl/C8Ngi9QdBjs7aNCB8',
  },
];
