/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { paths } from '../../../../constants';
import { MenuItem } from '../MenuItem/MenuItem';

import './styles/DropDownMenu.styles.scss';

interface DropdownMenuProperties {
  setIsSideMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DropdownMenu: React.FC<DropdownMenuProperties> = ({
  setIsSideMenuOpen = () => {},
}) => {
  const {
    services: { children },
  } = paths;

  return (
    <div className="dropdown">
      <ul className="dropdown__list">
        {Object.values(children).map((route) => {
          return (
            <MenuItem
              key={route}
              route={route}
              type="dropdown"
              isCleaningService
              setIsSideMenuOpen={setIsSideMenuOpen}
            />
          );
        })}
      </ul>
    </div>
  );
};
