import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import {
  calendarDateValue,
  calendarValuePiece,
} from '../../types/calendarType';
import { parseDate } from '../../helpers/parseDate';

interface WhereWhenCalendarProps {
  getSelectedDate: (date: Date) => void;
  selectedDate: Date | undefined | null;
}

export const WhereWhenCalendar: React.FC<WhereWhenCalendarProps> = ({
  getSelectedDate,
  selectedDate,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<calendarValuePiece>();
  const calendarRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedDate) {
      setSelectedDay(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDateChange = (value: calendarDateValue) => {
    if (value instanceof Date) {
      const dateNormalize = parseDate(value.toDateString());
      getSelectedDate(dateNormalize as Date);
      setSelectedDay(dateNormalize);
      setIsCalendarOpen(false);
    }
  };

  return (
    <div className="where-when__calendar">
      <input
        className="where-when__input where-when__input__calendar"
        type="text"
        ref={inputRef}
        value={selectedDay ? selectedDay.toLocaleDateString('en-US') : ''}
        onFocus={() => setIsCalendarOpen(true)}
        readOnly
        placeholder="Click to Choose a Date"
      />
      {isCalendarOpen && (
        <div ref={calendarRef}>
          <Calendar
            onChange={handleDateChange}
            value={selectedDay}
            locale="en"
            minDate={new Date()}
            minDetail="month"
            maxDetail="month"
          />
        </div>
      )}
    </div>
  );
};
