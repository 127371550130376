import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleCaptcha } from '../../../../components/Captcha/GoogleCaptcha';
import { FormDataGiftCardType } from '../../type/FormDataGiftCardType';
import { GiftCardFormSelectData } from '../../constants/GiftCardFormSelectData';

import './styles/GiftCardFormCheckout.scss';

interface GiftCardFormCheckoutProps {
  captchaRef: React.MutableRefObject<ReCAPTCHA | null>;
  formFields: FormDataGiftCardType;
}

export const GiftCardFormCheckout: React.FC<GiftCardFormCheckoutProps> = ({
  captchaRef,
  formFields,
}) => {
  const { amount } = formFields;
  return (
    <div className="gift-card-form-checkout">
      <p className="gift-card-form-checkout__text">
        Taking a moment to tick the reCAPTCHA box not only helps verify
        you&apos;re a human, but also plays a crucial role in fortifying our
        online security.
      </p>
      <div className="gift-card-form-checkout__captcha">
        <GoogleCaptcha captchaRef={captchaRef} />
      </div>
      <button className="gift-card-form-checkout__button" type="submit">
        Purchase Gift Card
      </button>
      <div className="gift-card-form-checkout__price">
        <p>final price</p>
        <p>
          {amount === GiftCardFormSelectData[0] || !amount
            ? '$0.00'
            : `${amount}`}
        </p>
      </div>
    </div>
  );
};
