import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { FormDataMyPriceType } from './types/formDataMyPriceType';
import { schemaFormMyPrice } from '../../validators';
import { formInputConfig } from './constants/formInputConfig';
import { InputMyPrice } from './components/InputMyPrice/InputMyPrice';
import { formSelectConfig } from './constants/formSelectConfig';
import { SelectMyPrice } from './components/SelectMyPrice/SelectMyPrice';
import Api from '../../service/Api';
import { InputPhone } from '../InputPhone';

import './styles/FormMyPrice.scss';

interface FormMyPriceProperties {
  isMinimized?: boolean;
  buttonText?: string;
}

export const FormMyPrice: React.FC<FormMyPriceProperties> = ({
  isMinimized = false,
  buttonText = 'see my price',
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormDataMyPriceType>({
    resolver: yupResolver(schemaFormMyPrice(isMinimized)),
  });

  const onSubmit = (data: FormDataMyPriceType) => {
    Api.post('price/see', data)
      .then(() => {
        toast.success('Success! Our team will contact you soon.');
      })
      .catch((error) => {
        console.log(error);
        toast.error('Something went wrong. Please, try again later.');
      });
  };

  useEffect(() => {
    if (errors.phone) {
      toast.error(errors.phone.message);
    }
    if (errors.email) {
      toast.error(errors.email.message);
    }
    if (!isMinimized && errors.name) {
      toast.error(errors.name.message);
    }
  }, [errors]);

  return (
    <form className="form-my-price" onSubmit={handleSubmit(onSubmit)}>
      {formInputConfig.map((input) => {
        const isNameInput = input.placeholder === 'Name';

        return (
          <Fragment key={input.placeholder}>
            {!isMinimized || (isMinimized && !isNameInput) ? (
              <InputMyPrice content={input} register={register} />
            ) : null}
          </Fragment>
        );
      })}
      <InputPhone register={register} setValue={setValue} />
      {formSelectConfig.map((select) => {
        const isServiceType = select.value === 'serviceType';

        return (
          <Fragment key={select.value}>
            {!isMinimized || (isMinimized && !isServiceType) ? (
              <SelectMyPrice content={select} register={register} />
            ) : null}
          </Fragment>
        );
      })}
      <button type="submit" className="form-my-price__action">
        {buttonText}
      </button>
    </form>
  );
};
