import { Link } from 'react-router-dom';
import { paths } from '../../../constants';

export const ReasonsTrustListHaltonHills = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Skilled Cleaning Professionals:
        </span>{' '}
        Expert cleaners delivering top-notch service.
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Sustainable Cleaning Solutions:
        </span>{' '}
        Catering to your{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
        >
          eco-friendly
        </Link>{' '}
        cleaning preferences
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Complete Satisfaction Guarantee:
        </span>{' '}
        We promise your absolute happiness
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Affordable Rates:</span>{' '}
        Superior cleaning services at competitive prices
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Thoroughly Vetted Staff:
        </span>{' '}
        Professional Halton Hills maids chosen for their integrity
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Easy Online Scheduling:{' '}
        </span>{' '}
        <Link className="reasons-trust-list__link" to={paths.book}>
          Book your cleaning
        </Link>{' '}
        with ease
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Exceptional Customer Support: 24/7 communication
        </span>{' '}
        from beginning to end
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Proven Excellence:</span>{' '}
        Praised by clients, as reflected in our{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          reviews
        </Link>
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Fully Insured Cleaning Services:
        </span>{' '}
        Ensuring your privacy and peace of mind
      </li>
    </ul>
  );
};
