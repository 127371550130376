/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { SvgIconArrowDown } from '../../../../ui/SvgIcon';
import { getNameFromRoute } from '../../../../helpers';
import { paths } from '../../../../constants';

import './styles/LinkWithDropdown.scss';

interface LinkWithDropdownProps {
  route: string;
  isDropdownShown: boolean;
  setIsDropdownShown: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSideMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LinkWithDropdown: React.FC<LinkWithDropdownProps> = ({
  route,
  setIsDropdownShown,
  isDropdownShown,
  setIsSideMenuOpen = () => {},
}) => {
  const { pathname } = useLocation();
  const {
    services: { children },
  } = paths;
  const isActive = pathname === `${route}/${children.cleaningServices}`;
  const title = getNameFromRoute(route);

  return (
    <>
      <NavLink
        className="menu__link dropdown-link"
        to={`${route}/${children.cleaningServices}`}
        onClick={() => setIsSideMenuOpen(false)}
      >
        {title}
      </NavLink>
      <button
        className={
          isActive
            ? 'dropdown-link__toggler dropdown-link__toggler_active '
            : 'dropdown-link__toggler'
        }
        onClick={() => setIsDropdownShown(!isDropdownShown)}
      >
        <SvgIconArrowDown />
      </button>
    </>
  );
};
