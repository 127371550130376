import { ItemsDataType } from '../types/ItemsDataType';

import onDemand from '../../../assets/recurring-cleaning/on-demand-cleaning.png';
import monthly from '../../../assets/recurring-cleaning/monthly-cleaning.png';
import biWeekly from '../../../assets/recurring-cleaning/bi-weekly-cleaning.png';
import weekly from '../../../assets/recurring-cleaning/weekly-cleaning.png';

export const OnDemandCleaning: ItemsDataType = {
  img: onDemand,
  text: 'On Demand Cleaning',
};

export const MonthlyCleaning = {
  img: monthly,
  text: 'Monthly Cleaning (10% OFF)',
};

export const BiWeeklyCleaning = {
  img: biWeekly,
  text: 'Bi-weekly Cleaning (15% OFF)',
};

export const WeeklyCleaning = {
  img: weekly,
  text: 'Weekly Cleaning (20% OFF)',
};
