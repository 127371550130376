import { Link } from 'react-router-dom';
import { paths } from '../../../constants';

export const ReasonsTrustListUxbridge = () => {
  return (
    <ul className="reasons-trust-list">
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Expert Cleaning Team:</span>{' '}
        Friendly cleaners delivering high-quality cleaning
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          <Link
            className="reasons-trust-list__link"
            to={`${paths.services.path}/${paths.services.children.ecoCleaning}`}
          >
            Eco-friendly Cleaning
          </Link>{' '}
          Options:
        </span>
        Accomodating to your green cleaning needs
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          100% Satisfaction Guarantee:
        </span>{' '}
        Your happiness is our promise
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Competitive and Transparent Pricing:
        </span>{' '}
        Quality{' '}
        <Link
          className="reasons-trust-list__link"
          to={`${paths.services.path}/${paths.services.children.cleaningServices}`}
        >
          cleaning services{' '}
        </Link>
        at great value
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Background-checked:</span>{' '}
        Professional Uxbridge maids hired for good character
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Convenient{' '}
          <Link className="reasons-trust-list__link" to={paths.book}>
            Online Booking:
          </Link>
        </span>{' '}
        Schedule your cleaning effortlessly
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">
          Customer Service Excellence:
        </span>{' '}
        24/7 communication, from start to finish
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Proven Track Record:</span>{' '}
        Celebrated by clients, as seen in our{' '}
        <Link className="reasons-trust-list__link" to={paths.reviews}>
          reviews
        </Link>
      </li>
      <li className="reasons-trust-list__item">
        <span className="reasons-trust-list__bold">Neighborhood’s Choice:</span>{' '}
        #1 Locally trusted Uxbridge home cleaning company
      </li>
    </ul>
  );
};
