import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { SelectForm } from '../../../../ui/SelectForm';
import { BookingText } from '../../ui/BookingText';
import { BookingFieldsetTextarea } from '../../ui/BookingFieldsetTextarea';
import { DiscountCode } from '../DiscountCode';
import { SpecialInstructionsInput } from './components/SpecialInstructionsInput';
import { SpecialInstructionsText } from './components/SpecialInstructionsText';
import { TimeRestriction } from './components/TimeRestriction';
import { SpecialInstructionsOptions } from './constants/SpecialInstructionsOptions';
import { BookingField } from '../../types/bookingFieldset';

import './styles/SpecialInstructions.scss';
import { fields } from '../../constants/fields';

interface SpecialInstructionsProps {
  register: UseFormRegister<BookingField>;
  formFields: BookingField;
}

export const SpecialInstructions: React.FC<SpecialInstructionsProps> = ({
  register,
  formFields,
}) => {
  return (
    <fieldset className="special-instructions">
      <div className="special-instructions__container">
        <BookingText
          text="Step 5 of 5: Special Instructions and Final Notes"
          option="title"
        />
        <BookingText
          text="Cleaning Instructions / Priorities:"
          option="description"
        />
        <BookingFieldsetTextarea
          name="instructions"
          placeholder="If you chose hourly cleaning, please provide your list of priorities here. If you chose a cleaning package, let us know if there's anything particular you'd like us to focus on."
          register={register}
          formFields={formFields}
        />
        <BookingText text="ACCESS INFO:" option="description" />
        <BookingText
          text="Please provide details on property access, anything we should be aware of"
          option="description"
        />
        <BookingFieldsetTextarea
          name="home"
          register={register}
          formFields={formFields}
          placeholder="Keypad code / key location / will you be home? / info about your pets etc."
        />
        <SpecialInstructionsInput
          placeholder="Parking instructions (driveway, street parking?)"
          name="parking"
          formFields={formFields}
          register={register}
        />
        <p className="special-instructions__select-title">
          Where should we place the collected garbage at the property?
        </p>
        <SelectForm
          name="garbage"
          register={register}
          options={SpecialInstructionsOptions}
          formFields={formFields}
        />
        <TimeRestriction register={register} formFields={formFields} />
        <div className="special-instructions__input-wrapper">
          {fields.cleaningIntstructions.map((item) => {
            const { name, placeholder } = item;

            return (
              <SpecialInstructionsInput
                key={name}
                placeholder={placeholder}
                name={name}
                register={register}
                formFields={formFields}
              />
            );
          })}
        </div>
        <SpecialInstructionsText />
      </div>
      <DiscountCode register={register} formFields={formFields} />
    </fieldset>
  );
};
