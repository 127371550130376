import { paths } from '../constants';

export const getNameFromRoute = (route: string) => {
  if (route === paths.services.children.cleaningServices) {
    return 'House Cleaning';
  }

  if (route === paths.services.children.checklist) {
    return 'Cleaning Checklist';
  }

  if (route === paths.giftCards) {
    return 'Gift Cards 🎁';
  }

  const normalizedName = route.replaceAll('-', ' ').replaceAll('/', '');
  const words = normalizedName.split(' ');
  const formattedName = words
    .map(
      (word) =>
        ((word.at(0)?.toUpperCase() as string) + word.slice(1)) as string
    )
    .join(' ');

  return formattedName;
};
