import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { ReviewsPageItem } from './ReviewsPageItem';
import { useWidthWindow } from '../../../hooks/useWidthWindows';
import { breakpoints } from '../../../constants';
import { ReviewsPageDataType } from '../types/ReviewsPageDataType';

interface ReviewsPageSwiper {
  content: ReviewsPageDataType[];
}

export const ReviewsPageSwiper: React.FC<ReviewsPageSwiper> = ({ content }) => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <div className="reviews-page-clients-say__swiper__container">
      <Swiper
        loop
        slidesPerView={isMobile ? 1 : 3}
        freeMode
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={{ nextEl: '.custom-next', prevEl: '.custom-prev' }}
        modules={[Pagination, Autoplay, Navigation]}
        className="reviews-page-clients-say__swiper"
      >
        {content.map((data) => {
          return (
            <SwiperSlide key={data.name}>
              <ReviewsPageItem content={data} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="custom-prev">&#10094;</div>
      <div className="custom-next">&#10095;</div>
    </div>
  );
};
