import React, { ChangeEvent, useEffect } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { BookingField } from '../../../types/bookingFieldset';
import { SvgIconArrowDown } from '../../../../../ui/SvgIcon';
import { getChoosedParameter } from '../helpers/getChoosedValue';
import { useBookingFormData } from '../../../../../hooks/useBookingFormData';
import {
  CleaningInformationAdditionsEntity,
  CleaningInformationEntity,
  RangesEntity,
} from '../../../../../types';

interface BookingCleaningServiceParameterProperties {
  parameterNameNormalized: string;
  parameterRanges: RangesEntity[];
  parameterID: number;
  register: UseFormRegister<BookingField>;
  setValue: UseFormSetValue<BookingField>;
}

export const BookingCleaningServiceParameter: React.FC<
  BookingCleaningServiceParameterProperties
> = ({
  parameterNameNormalized,
  parameterRanges,
  parameterID,
  register,
  setValue,
}) => {
  const {
    formData: { cleaningInformation },
    removeChoosedAddition,
    setCleaningInformation,
  } = useBookingFormData();

  useEffect(() => {
    const parameter = parameterRanges[0];
    setValue(parameterNameNormalized as keyof BookingField, parameter.label);
  }, [parameterRanges]);

  const handleCleaningParameterChange = (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedParameter = getChoosedParameter(event, parameterRanges);

    if (
      (selectedParameter && selectedParameter.quantity > 0) ||
      (selectedParameter &&
        parameterID === 41 &&
        selectedParameter.quantity === 0)
    ) {
      const { cleaningParameters } =
        cleaningInformation as CleaningInformationEntity;
      let newCleaningParameters = [
        ...(cleaningParameters as CleaningInformationAdditionsEntity[]),
      ];
      const isInFormData =
        cleaningParameters &&
        cleaningParameters.find(
          (currentParameter) => parameterID === currentParameter.id
        );
      const formattedParameter: CleaningInformationAdditionsEntity = {
        id: parameterID,
        name: selectedParameter.label,
        quantity: selectedParameter.quantity,
        totalPrice: selectedParameter.price,
      };

      if (!isInFormData) {
        newCleaningParameters.push(formattedParameter);
      } else {
        newCleaningParameters = cleaningParameters.filter(
          (currentParameter) => currentParameter.id !== parameterID
        );
        newCleaningParameters.push(formattedParameter);
      }

      const newCleaningInformation: CleaningInformationEntity = {
        ...cleaningInformation,
        cleaningParameters: newCleaningParameters,
      };
      setCleaningInformation(newCleaningInformation);
    } else {
      removeChoosedAddition(parameterID, 'cleaningParameters');
    }
  };

  return (
    <label
      htmlFor={`cleaning-${parameterNameNormalized}-select`}
      className="fieldset__label fieldset__label_centerd"
    >
      <div className="fieldset__select-wrapper">
        <select
          id={`cleaning-${parameterNameNormalized}-select`}
          className="fieldset__select"
          {...register(parameterNameNormalized as keyof BookingField, {
            onChange: handleCleaningParameterChange,
          })}
        >
          {parameterRanges.map((range) => {
            return (
              <option key={range.label} className="fieldset__option">
                {range.label}
              </option>
            );
          })}
        </select>
        <SvgIconArrowDown />
      </div>
    </label>
  );
};
