import React from 'react';
import { ServiceExclusionsType } from '../../type/ServiceExclusionsType';

import './styles/ServiceExclusionsItem.scss';

interface ServiceExclusionsItemProps {
  item: ServiceExclusionsType;
}

export const ServiceExclusionsItem: React.FC<ServiceExclusionsItemProps> = ({
  item,
}) => {
  const { icon, text, desc } = item;
  return (
    <div className="service-exclusions-item">
      <div className="service-exclusions-item__icon">{icon}</div>
      <p className="service-exclusions-item__text">{text}</p>
      <p className="service-exclusions-item__desc">{desc}</p>
    </div>
  );
};
