import { ContainerComponent } from '../../ui/ContainerComponent';

import './styles/TermsConditionsSection.scss';

export const TermsConditionsSection = () => {
  return (
    <section className="terms-conditions">
      <ContainerComponent>
        <div className="terms-conditions__desc">
          <h2 className="terms-conditions__title">Agreement Overview</h2>
          <p>
            These Terms & Conditions (“Agreement”) form a legally binding
            contract between you and CrystalClear Cleaning Services
            (“CrystalClear,” “we,” “us,” or “our”). By accessing or using our
            services, including the website (collectively referred to as the
            “Site”), you agree to comply with and be bound by these terms. If
            you do not accept these Terms, you are not authorized to use our
            Site or services.
          </p>
          <p>
            Modification of Terms: We reserve the right to update or modify
            these Terms at any time by posting changes to the Site. Continued
            use of the Site or services constitutes acceptance of those changes.
          </p>
          <div className="terms-conditions__container">
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Key Obligations</h2>
              <ul className="terms-conditions__list">
                <li>
                  You must be at least 18 years old and capable of entering into
                  legally binding agreements.
                </li>
                <li>
                  You are responsible for safeguarding your account credentials
                  and using the Site for lawful purposes only.
                </li>
              </ul>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Services Disclaimer</h2>
              <p>
                CrystalClear is Not a Cleaning Service Provider: CrystalClear
                acts as a platform to connect individuals with independent
                third-party cleaning providers. We facilitate scheduling and
                background checks but do not guarantee the quality, reliability,
                or safety of the services provided by these third parties. Use
                caution and discretion when engaging with cleaning providers.
              </p>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Reporting Issues</h2>
              <p>
                To address service concerns (e.g., missed areas, damages), you
                must notify us within 48 hours of the service completion.
                Reports submitted after this period may not be eligible for
                resolution or compensation.
              </p>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Payment Terms</h2>
              <p>
                Fees are due immediately upon service completion. Pricing is
                subject to change, and promotional rates do not guarantee future
                pricing. Refunds are issued at our discretion.
              </p>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Liability Limitations</h2>
              <ul className="terms-conditions__list">
                <li>
                  No Warranty: Services are provided “as is.” We make no
                  guarantees regarding reliability, quality, or safety.
                </li>
                <li>
                  Liability Cap: Our liability is limited to amounts paid by you
                  in the six months preceding any claim.
                </li>
                <li>
                  Third-Party Responsibility: CrystalClear is not liable for
                  issues arising from third-party cleaning providers.
                </li>
              </ul>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">User Responsibilities</h2>
              <p>By using the Site, you agree to:</p>
              <ul className="terms-conditions__list">
                <li>Use the services only for lawful purposes.</li>
                <li>
                  Avoid causing harm, disruption, or unauthorized access to the
                  Site or services.
                </li>
                <li>
                  Abstain from hiring CrystalClear’s cleaning partners directly
                  unless paying a $3,000 referral fee.
                </li>
              </ul>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Intellectual Property</h2>
              <p>
                You are granted a non-exclusive, non-transferable license to use
                our services for personal, non-commercial purposes. All
                intellectual property rights remain with CrystalClear or its
                licensors.
              </p>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Dispute Resolution</h2>
              <p>
                Disputes must be resolved through binding arbitration, waiving
                rights to court trials or class actions. Arbitration will occur
                in Guelph, Ontario, under Ontario law. In the event arbitration
                is deemed unenforceable, disputes must be resolved exclusively
                in Ontario courts.
              </p>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Termination</h2>
              <p>
                CrystalClear may terminate your account or access to services at
                any time for any breach of these Terms or other reasons deemed
                appropriate.
              </p>
            </div>
            <div className="terms-conditions__block">
              <h2 className="terms-conditions__title">Privacy Policy</h2>
              <p>
                We are committed to protecting your personal information. Our
                Privacy Policy outlines how data is collected, used, and
                safeguarded.
              </p>
              <p>
                For additional questions or concerns, contact us at
                https://crystalclear.art/contact-us
              </p>
            </div>
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
