/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { SvgIconMinus, SvgIconPlus } from '../../../../ui/SvgIcon';
import { AccordionDataType } from '../../type/AccordionDataType';

import './styles/AccordionItem.scss';

interface AccordionProps {
  item: AccordionDataType;
  clickQuestions: (idQuestion: string) => void;
  selected: boolean;
}

export const AccordionItem: React.FC<AccordionProps> = ({
  item,
  clickQuestions,
  selected,
}) => {
  const { id, content, title } = item;

  return (
    <div className="accordion-item">
      <div
        id={id}
        onClick={(e) => {
          clickQuestions(e.currentTarget.id);
        }}
        className="accordion-item__header"
      >
        <h3
          className={
            selected
              ? 'accordion-item__title accordion-item__title__open'
              : 'accordion-item__title'
          }
        >
          {title}
        </h3>
        {selected ? <SvgIconMinus /> : <SvgIconPlus />}
      </div>
      <div
        className={
          selected
            ? 'accordion-item__open accordion-item__content'
            : 'accordion-item__content'
        }
      >
        {content}
      </div>
    </div>
  );
};
