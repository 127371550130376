import React from 'react';
import { FormDataEntity } from '../../../../types';
import { ContactInformation } from './components/ContactInformation';
import { CleaningInformation } from './components/CleaningInformation';
import { CleaningInstructions } from './components/CleaningInstructions';

import './styles/DashboardSectionItem.scss';

interface DashboardSectionItemProps {
  content: FormDataEntity;
}

export const DashboardSectionItem: React.FC<DashboardSectionItemProps> = ({
  content,
}) => {
  const {
    totalPrice,
    recurringPrice,
    dateInformation,
    locationInformation,
    cleaningInformation,
    contactInformation,
    cleaningIntstructions,
  } = content;

  return (
    <div className="dashboard-section-item">
      <ContactInformation
        locationInformation={locationInformation}
        contactInformation={contactInformation}
      />
      <CleaningInformation
        cleaningInformation={cleaningInformation}
        totalPrice={totalPrice}
        recurringPrice={recurringPrice}
      />
      <div className="dashboard-section-item__block">
        <h3 className="dashboard-section-item__title">Frequency Information</h3>
        <div className="dashboard-section-item__block__wrapper">
          <p className="dashboard-section-item__element frequency-information">
            {dateInformation?.frequencyInformation?.frequency} -{' '}
            {dateInformation?.frequencyInformation?.percentage}
          </p>
        </div>
      </div>
      <CleaningInstructions cleaningIntstructions={cleaningIntstructions} />
    </div>
  );
};
