import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const StouffvillePageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Your Premier Cleaning Service in Stouffville"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Introducing Crystal Clear – your trusted provider of professional
            cleaning services in Stouffville, catering to the unique needs of
            homeowners and businesses alike.
          </p>
          <p className="page-description__text">
            Tailor your cleaning needs with ease through our customizable plans
            and seamless online booking process. Our team of seasoned, insured,
            and thoroughly vetted cleaning professionals delivers exceptional
            service, ensuring your peace of mind. With a{' '}
            <span className="page-description__text_bold">
              flexible 24-hour cancellation policy,
            </span>{' '}
            we make it convenient to fit cleaning into your schedule.
          </p>
          <p className="page-description__text">
            What sets us apart? Our satisfaction guarantee. If you’re not
            completely thrilled with our service, we’ll re-clean until it’s
            perfect. Elevate your quality of life with Crystal Clear’s
            outstanding cleaning services in Stouffville.{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your home cleaning services
            </Link>{' '}
            online or call us at{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>{' '}
            today!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
