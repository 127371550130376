import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';

export const FriendlyProfessionals = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Professional, Friendly, and Insured Cleaning Services" />
          <p className="text__desc">
            Our expert cleaning services are tailored to bring you the ultimate
            level of cleanliness and professionalism in your office. We
            understand the challenges and demands of maintaining a tidy and
            spotless environment, which is why we offer a spectrum of cleaning
            services to fit your specific needs. Our highly skilled cleaning
            specialists are equipped with the latest methods and tools to
            guarantee a comprehensive cleaning of your space, making it look and
            feel revitalized! Whether you require routine cleaning, deep
            cleaning, or anything in between, we have a customized plan to meet
            your requirements.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
