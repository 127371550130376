import React from 'react';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { getAltTextForImage } from '../../helpers';
import { headerHeight } from '../../constants';
import { Title } from '../../ui/MainTitle';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { ActionButtons } from '../ActionButtons';
import img from '../../assets/contact-us/crystalclear-house-cleaning-services.png';

import './styles/GetQuote.scss';

interface GetQuoteProps {
  formRef?: React.RefObject<HTMLElement>;
  isTwoButtons?: boolean;
  title: string;
}

export const GetQuote: React.FC<GetQuoteProps> = ({
  formRef,
  isTwoButtons = true,
  title,
}) => {
  const altText = getAltTextForImage(img);

  const handleScrollToForm = () => {
    if (formRef && formRef.current) {
      const formPosition =
        formRef.current.getBoundingClientRect().top + window.scrollY;

      const offsetPosition = formPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className="get-quote">
      <ContainerComponent>
        <div className="get-quote__wrapper">
          <div className="get-quote__container">
            <Title text={title} type="h2" color="black" isCentered />
            <div className="get-quote__button__wrapper">
              {isTwoButtons ? (
                <ActionButtons data={twoButtonsData} />
              ) : (
                <button
                  onClick={handleScrollToForm}
                  className="get-quote__button"
                  type="button"
                >
                  get a quote
                </button>
              )}
            </div>
          </div>
          <div className="get-quote__img">
            <img alt={altText} src={img} />
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
