import { breakpoints, companyInfo } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { AreasTitle } from '../../modules/AreasTitle';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { ContactSection } from '../../modules/ContactSection';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { GiftCards } from '../../modules/GiftCards';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { Reviews } from '../../modules/Reviews';
import { ThreeEasySteps } from '../../modules/ThreeEasySteps';
import { OurStuff } from '../../modules/OurStuff';
import { MainWrapper } from '../../ui/MainWrapper';
import { PickeringPageDescription } from '../../modules/PageDescription';
import { ReasonsTrust } from '../../modules/ReasonsTrust';
import { AboutSection } from '../../modules/AboutSection';
import { CleaningZonesData } from '../../modules/CleaningZones/constants/CleaningZonesData';
import { GetQuote } from '../../modules/GetQuote';
import { MapWrapper } from '../../ui/MapWrapper';
import { AreasTestimonialPickeringData } from '../../modules/AreasTitle/constants/AreasTestimonialData';
import { AccordionDataPickering } from '../../modules/FrequentlyQuestions/constants/AccordionDataPickering';
import {
  ThreeEasyStepsDataBook,
  ThreeEasyStepsDataCleaner,
  ThreeEasyStepsDataRelax,
} from '../constants/CondoCleaningPageData/ThreeEasyStepsData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

import imgPickering from '../../assets/pickering-page/pickering.jpg';

export const PickeringPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;
  return (
    <MainWrapper>
      <DynamicBrowserHeaderTitle page="pickeringArea" />
      <AreasTitle
        title="Cleaning Services Pickering"
        city="Pickering"
        subtitle="Professional Cleaning Services of Pickering – Hire Expert House Cleaners Near You Today!"
        imageSource={imgPickering}
        areasTestimonial={AreasTestimonialPickeringData}
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <PickeringPageDescription />
      <ThreeEasySteps
        isShowTitle
        book={ThreeEasyStepsDataBook}
        cleaner={ThreeEasyStepsDataCleaner}
        relax={ThreeEasyStepsDataRelax}
      />
      <OurStuff hasOnlyOneSection componentName="pickering" />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ReasonsTrust city="Pickering" componentName="pickering" />
      <CleaningZones
        title="Our Checklist for Cleaning Services in Pickering"
        content={CleaningZonesData}
      />
      <InSixtySeconds />
      <ActionButtons hasBackground data={twoButtonsData} />
      <AboutSection />
      <FrequentlyQuestions questions={AccordionDataPickering} />
      <ActionButtons hasBackground data={twoButtonsData} />
      <ContactSection
        city="Pickering"
        address={companyInfo.offices.toronto}
        hours={companyInfo.hours}
        officesMap={companyInfo.officesMap.toronto}
        frame={<MapWrapper area="pickering" />}
      />
      <GiftCards />
      <ActionButtons hasBackground data={twoButtonsData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <GetQuote title="Try our Cleaning Services in Pickering Today!" />
    </MainWrapper>
  );
};
