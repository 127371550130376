import houseCleaning from '../../../assets/our-cleaning-services/house-cleaning.png';
import condoCleaning from '../../../assets/our-cleaning-services/condo-cleaning.png';
import moveOutCleaning from '../../../assets/our-cleaning-services/move-in-move-out-cleaning.png';
import deepCleaning from '../../../assets/our-cleaning-services/deep-cleaning.png';
import renovationCleaning from '../../../assets/our-cleaning-services/post-renovation-cleaning.png';
import airBnbBCleaning from '../../../assets/our-cleaning-services/airbnb-cleaning.png';
import cleaningForSeniors from '../../../assets/our-cleaning-services/cleaning-for-seniors.png';
import commercialOfficeCleaning from '../../../assets/our-cleaning-services/commercial-office-cleaning.png';
import greenEcoFriendlyCleaning from '../../../assets/our-cleaning-services/green-and-eco-friendly-cleaning-service.png';
import { OurCleaningServicesDataType } from '../types/OurCleaningServicesDataType';
import { paths } from '../../../constants';

export const OurCleaningServicesData: OurCleaningServicesDataType[] = [
  {
    img: houseCleaning,
    title: 'House Cleaning',
    desc: 'Standard upkeep of high-traffic areas of your home to help you live stress-free. Book one time, bi-weekly, or monthly services!',
    path: paths.services.children.cleaningServices,
  },
  {
    img: condoCleaning,
    title: 'Condo Cleaning',
    desc: 'A thorough cleaning of your condo that includes dusting and wiping of surfaces. Just provide us the buzzer code and we are all set!',
    path: paths.services.children.apartmentCleaning,
  },
  {
    img: moveOutCleaning,
    title: 'Move Out Cleaning',
    desc: 'A top-to-bottom cleaning service including dusting, vacuuming, and disinfecting of all surfaces to prepare the home for new occupants.',
    path: paths.services.children.moveInCleaning,
  },
  {
    img: deepCleaning,
    title: 'Deep Cleaning',
    desc: 'A thorough cleaning of your home including hard-to-reach areas like under furniture and appliances, as well as baseboards and window sills.',
    path: paths.services.children.deepCleaning,
  },
  {
    img: renovationCleaning,
    title: 'Renovation Cleaning',
    desc: 'A cleaning that involves removing dust, debris, and other construction materials after a renovation or construction project.',
    path: paths.services.children.postRenovationCleaning,
  },
  {
    img: airBnbBCleaning,
    title: 'AirBnB Cleaning',
    desc: 'A cleaning service tailored to clean short-term rentals, including loading laundry, stocking, and ensuring top guest reviews!',
    path: paths.services.children.airbnbCleaning,
  },
  {
    img: cleaningForSeniors,
    title: 'Cleaning for Seniors',
    desc: 'A cleaning service catered to long term rental properties, ensuring property is clean and in welcoming condition for next tenants.',
    path: paths.services.children.seniorsCleaning,
  },
  {
    img: commercialOfficeCleaning,
    title: 'Commercial and Office Cleaning',
    desc: 'A cleaning service that involves maintaining cleanliness and upkeep of office spaces and clinics.',
    path: paths.services.children.commercialCleaning,
  },
  {
    img: greenEcoFriendlyCleaning,
    title: 'Eco Friendly and Green Cleaning',
    desc: 'At your request, we can utilize organic cleaning products to ensure your home is chemical free.',
    path: paths.services.children.ecoCleaning,
  },
];
