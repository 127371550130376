import { ElevateCleaningExperience } from '../components/ElevateCleaningExperience/ElevateCleaningExperience';
import { FriendlyProfessionals } from '../components/FriendlyProfessionals/FriendlyProfessionals';
import { FriendlyTrustedAjax } from '../components/FriendlyTrustedAjax/FriendlyTrustedAjax';
import { FriendlyTrustedRichmondHill } from '../components/FriendlyTrustedRichmondHill/FriendlyTrustedRichmondHill';
import { HappinessQuality } from '../components/HappinessQuality/HappinessQuality';
import { SatisfactionGuaranteed } from '../components/SatisfactionGuaranteed/SatisfactionGuaranteed';
import { VaughansExpert } from '../components/VaughansExpert/VaughansExpert';
import { MarkhamExpert } from '../components/MarkhamExpert/MarkhamExpert';
import { OurStuffItemsType } from '../type/OurStuffItemsType';
import { NorthYorkExpert } from '../components/NorthYorkExpert/NorthYorkExpert';
import { EtobicokeExpert } from '../components/EtobicokeExpert/EtobicokeExpert';
import { ExperienceHappiness } from '../components/ExperienceHappiness/ExperienceHappiness';
import { LocalFriendly } from '../components/LocalFriendly/LocalFriendly';
import { ExpertHouseOshawa } from '../components/ExpertHouseOshawa/ExpertHouseOshawa';
import { HappinessGuaranteedUxbridge } from '../components/HappinessGuaranteedUxbridge/HappinessGuaranteedUxbridge';
import { ExpertHouseWhitby } from '../components/WhitbyCleaningExperience/WhitbyCleaningExperience';
import { FriendlyTrustedCaledon } from '../components/FriendlyTrustedCaledon/FriendlyTrustedCaledon';
import { FriendlyTrustedAurora } from '../components/FriendlyTrustedAurora/FriendlyTrustedAurora';
import { DependableFriendlyNewmarket } from '../components/DependableFriendlyNewmarket/DependableFriendlyNewmarket';
import { TrustedProfessionalKing } from '../components/TrustedProfessionalKing/TrustedProfessionalKing';
import { FriendlyTrustedeastGwillimbury } from '../components/FriendlyTrustedeastGwillimbury/FriendlyTrustedeastGwillimbury';
import { HaltonHillsExpert } from '../components/HaltonHillsExpert/HaltonHillsExpert';
import { HappinessGuaranteedBurlington } from '../components/HappinessGuaranteedBurlington/HappinessGuaranteedBurlington';
import { FriendlyTrustedGeorgina } from '../components/FriendlyTrustedGeorgina/FriendlyTrustedGeorgina';
import { FriendlyTrustedStouffville } from '../components/FriendlyTrustedStouffville/FriendlyTrustedStouffville';
import { OakvilleExperts } from '../components/OakvilleExperts/OakvilleExperts';

export const OurStuffItems: OurStuffItemsType = {
  services: FriendlyProfessionals,
  mississauga: SatisfactionGuaranteed,
  vaughan: VaughansExpert,
  scarborough: ElevateCleaningExperience,
  markham: MarkhamExpert,
  northYork: NorthYorkExpert,
  etobicoke: EtobicokeExpert,
  richmondHill: FriendlyTrustedRichmondHill,
  pickering: HappinessQuality,
  ajax: FriendlyTrustedAjax,
  brampton: ExperienceHappiness,
  milton: LocalFriendly,
  oshawa: ExpertHouseOshawa,
  uxbridge: HappinessGuaranteedUxbridge,
  whitby: ExpertHouseWhitby,
  caledon: FriendlyTrustedCaledon,
  aurora: FriendlyTrustedAurora,
  newmarket: DependableFriendlyNewmarket,
  king: TrustedProfessionalKing,
  eastGwillimbury: FriendlyTrustedeastGwillimbury,
  haltonHills: HaltonHillsExpert,
  oakville: OakvilleExperts,
  burlington: HappinessGuaranteedBurlington,
  georgina: FriendlyTrustedGeorgina,
  stouffville: FriendlyTrustedStouffville,
};
