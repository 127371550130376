import React from 'react';
import { StepCardsType } from '../../types/stepCardsType';
import frame from '../../../../assets/how-we-work/frame.png';
import './styles/StepCard.styles.scss';

interface StepCardProps {
  cardData: StepCardsType;
}

export const StepCard: React.FC<StepCardProps> = ({ cardData }) => {
  return (
    <div className="card">
      <div className="card__wrapper">
        <div
          style={{
            background: `url(${cardData.pathToImage})
          center center / contain no-repeat`,
          }}
          className="card__top"
        >
          <img className="card__frame" src={frame} alt={cardData.alt} />
        </div>
        <div className="card__bottom">
          <p className="card__title">{cardData.title}</p>
          <p className="card__description">{cardData.description}</p>
        </div>
      </div>
    </div>
  );
};
