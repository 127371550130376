import React from 'react';

interface GiftCardFormTitleProps {
  text: string;
}

export const GiftCardFormTitle: React.FC<GiftCardFormTitleProps> = ({
  text,
}) => {
  return (
    <div className="gift-card-form-section__title">
      <p className="gift-card-form-section__title_text">{text}</p>
      <span className="gift-card-form-section__title_line"> </span>
    </div>
  );
};
