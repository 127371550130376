import { Link } from 'react-router-dom';
import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';

export const TrustedProfessionalKing = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Trusted and Professional Cleaning Services in King" />
          <p className="text__desc">
            At Crystal Clear, happiness is at the heart of everything we do. Our
            cleaners in Kingston are not just skilled—they genuinely love their
            work, and it shows in every spotless home they clean. Whether you
            need a{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep cleaning
            </Link>
            to refresh your space or regular recurring services to keep things
            tidy, we’ve got you covered. Living in Kingston, you deserve the
            best cleaning services Kingston has to offer, creating a home where
            comfort and cleanliness go hand in hand. With Crystal Clear, it’s
            more than just cleaning; it’s about bringing happiness into your
            home.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
