import { Link } from 'react-router-dom';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';

import './styles/PageDescription.scss';

export const VaughanPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Professional House Cleaning Services of Vaughan"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            At Crystal Clear, we know that a clean environment is the foundation
            of a happy and healthy life, and we’re excited to bring our expert
            cleaning services to{' '}
            <span className="page-description__text_bold">Vaughan</span>.
            Whether you’re residing in the charming neighborhoods of{' '}
            <span className="page-description__text_bold">Maple</span> or{' '}
            <span className="page-description__text_bold">Kleinburg</span>, the
            bustling{' '}
            <span className="page-description__text_bold">Woodbridge</span>{' '}
            area, or the family-friendly community of{' '}
            <span className="page-description__text_bold">Thornhill</span>, our
            dedicated team is here to make your space shine. We’re also proud to
            serve those in{' '}
            <span className="page-description__text_bold">Concord</span> and{' '}
            <span className="page-description__text_bold">Vellore Village</span>
            , ensuring that all of Vaughan enjoys the pristine spaces they
            deserve.
          </p>
          <p className="page-description__text">
            We understand that life can get busy, which is why we’ve made it
            incredibly easy to customize your cleaning plan to suit your needs.
            Need a one-time deep clean or regular upkeep? Crystal Clear has you
            covered.{' '}
            <span className="page-description__text_bold">Booking</span> is a
            breeze with our{' '}
            <span className="page-description__text_bold">
              seamless online system
            </span>{' '}
            - just a few clicks, and your cleaning service is scheduled. Plus,
            with our{' '}
            <span className="page-description__text_bold">
              24-hour cancellation policy
            </span>
            , you have the flexibility to adjust your plans without any stress.
          </p>
          <p className="page-description__text">
            Our cleaning team? They’re more than just professionals; they’re
            passionate about what they do. Fully vetted, insured, and
            meticulously trained, they’ll treat your space with the care and
            respect it deserves. And we stand behind our work with a
            satisfaction guarantee—if you’re not completely happy, we’ll come
            back and make it right.
          </p>
          <p className="page-description__text">
            Ready to elevate the cleanliness of your Vaughan home or business?
            Let’s get started!{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning today
            </Link>{' '}
            —visit our website or give us a call at
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>
            . We’re looking forward to bringing a new level of cleanliness to
            your space in Vaughan!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
