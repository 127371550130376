import React from 'react';
import { FormDataGiftCardType } from '../../type/FormDataGiftCardType';
import { GiftCardFormSelectData } from '../../constants/GiftCardFormSelectData';

import './styles/GiftCardFormCard.scss';
import { GiftCardFormTextareaData } from '../../constants/GiftCardFormTextareaData';

interface GiftCardFormCardProps {
  formFields: FormDataGiftCardType;
}

export const GiftCardFormCard: React.FC<GiftCardFormCardProps> = ({
  formFields,
}) => {
  const { amount, to, message } = formFields;

  return (
    <div className="gift-card-form-card">
      <div className="gift-card-form-card__header">
        <h3 className="gift-card-form-card__title">crystal clear service</h3>
        <p className="gift-card-form-card__price">
          {amount === GiftCardFormSelectData[0] || !amount
            ? '$0.00'
            : `${amount}`}
        </p>
      </div>
      <div className="gift-card-form-card__text">
        <p className="gift-card-form-card__name">
          Dear {!to ? 'Recipient Name' : to},
        </p>
        <p className="gift-card-form-card__desc">
          {!message ? GiftCardFormTextareaData.defaultValue : message}
        </p>
      </div>
      <div className="gift-card-form-card__code">
        <p className="gift-card-form-card__code_text">Code: xxxx-xxxx-xxxx</p>
      </div>
    </div>
  );
};
