import React from 'react';
import { FormMyPrice } from '../../components/FormMyPrice';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { AreasGuarantees } from './components/AreasGuarantees';
import { AreasTestimonial } from './components/AreasTestimonial';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { breakpoints } from '../../constants';
import { AreasTestimonialType } from './type/AreasTestimonialType';

import './styles/AreasTitle.scss';
import { SvgIconLine } from '../../ui/SvgIcon';

interface AreasTitleProps {
  title: string;
  city: string;
  subtitle?: string;
  imageSource: string;
  areasTestimonial: AreasTestimonialType;
}

export const AreasTitle: React.FC<AreasTitleProps> = ({
  title,
  city,
  subtitle,
  imageSource,
  areasTestimonial,
}) => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;

  return (
    <section className="areas-title">
      <div
        style={{
          backgroundImage: `url(${imageSource})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
        className="hero-section__background"
      />
      <ContainerComponent>
        <div className="areas-title__container">
          <div className="areas-title__text">
            <Title text={title} type="h1" />
            <p className="areas-title__subtitle">{subtitle}</p>
            {!isMobile && <AreasGuarantees city={city} />}
            {!isMobile && (
              <div className="areas-title__testimonial">
                <AreasTestimonial content={areasTestimonial} />
              </div>
            )}
          </div>
          <div className="areas-title__form">
            <Title
              text={`House Cleaning Price ${city}`}
              type="h2"
              color="black"
              isCentered
            />
            <p className="areas-title__form__subtitle">
              Get an{' '}
              <span className="areas-title__form__line-wrapper">
                <span className="areas-title__form__subtitle_bold">
                  instant quote
                </span>{' '}
                <SvgIconLine />
              </span>{' '}
              for your next home cleaning services!
            </p>
            <FormMyPrice />
          </div>
          {isMobile && <AreasGuarantees city={city} />}
        </div>
      </ContainerComponent>
    </section>
  );
};
