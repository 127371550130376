import { Link } from 'react-router-dom';
import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';

export const FriendlyTrustedGeorgina = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Friendly and Trusted - Home Cleaning Services of Georgina" />
          <p className="text__desc">
            At the core of our mission is{' '}
            <span className="text__desc_bold">happiness</span>—both for our
            dedicated cleaning team and for you, our valued clients in Georgina.
            This commitment to joy and satisfaction has established Crystal
            Clear as a leader in cleaning services throughout Georgina.
          </p>
          <p className="text__desc">
            Our professional house cleaners in Georgina arrive fully equipped
            with the top cleaning tools and methods, ready to deliver a
            sparkling transformation to your home. Whether you need regular{' '}
            <span className="text__desc_bold">maintenance cleans,</span> a
            thorough{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep cleaning
            </Link>
            , or a{' '}
            <span className="text__desc_bold">customized cleaning plan,</span>
            we’re here to develop a solution that perfectly fits your lifestyle
            in Georgina. Play Video
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
