import { paths } from '../../constants';
import { ContainerComponent as Container } from '../../ui/ContainerComponent';
import { AreaItem } from './ui/AreaItem/AreaItem';
import { Title } from '../../ui/MainTitle';
import { getServingAreas } from './helpers/getServingAreas';
import { areasQuantity } from './constants/areasQuantity';

import './styles/WhereWeWork.styles.scss';

export const WhereWeWork = () => {
  const {
    areas: { children: areasChildren },
  } = paths;
  const visibleAreas = getServingAreas(
    Object.values(areasChildren),
    areasQuantity
  );

  return (
    <section className="where-we-work">
      <Container>
        <div className="where-we-work__wrapper">
          <Title
            text="Where are you looking for cleaning services?"
            type="h2"
            color="black"
            isCentered
          />
          <div className="where-we-work__links">
            <ul className="where-we-work__list">
              {visibleAreas.map((route) => {
                return <AreaItem key={route} route={route} />;
              })}
              <AreaItem isAreasLink />
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
};
