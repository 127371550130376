import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const NorthYorkPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Professional House Cleaning Services of North York"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            At Crystal Clear, we believe that a clean space is a happy space,
            and we&apos;re proud to offer our professional cleaning services to
            the vibrant community of North York. Whether you reside in{' '}
            <span className="page-description__text_bold">Willowdale</span>,
            <span className="page-description__text_bold">Bayview Village</span>
            , or <span className="page-description__text_bold">Don Mills</span>,
            our dedicated team is here to make your space sparkle. We also serve
            neighborhoods like{' '}
            <span className="page-description__text_bold">York Mills</span>,{' '}
            <span className="page-description__text_bold">Downsview</span>,{' '}
            <span className="page-description__text_bold">Flemingdon Park</span>
            , and{' '}
            <span className="page-description__text_bold">Lawrence Manor</span>,
            as well as{' '}
            <span className="page-description__text_bold">Newtonbrook</span>,{' '}
            <span className="page-description__text_bold">Bathurst Manor</span>,
            and the lively{' '}
            <span className="page-description__text_bold">Jane and Finch</span>{' '}
            area. North York is diverse, and our services are tailored to meet
            every need.
          </p>
          <p className="page-description__text">
            We understand that life can get busy, which is why we make it easy
            to customize your cleaning plan. Whether you need a one-time deep
            clean or regular maintenance, Crystal Clear has you covered. Booking
            is a breeze with our seamless online system—schedule with just a few
            clicks to fit your day. And if your plans change, our flexible{' '}
            <span className="page-description__text_bold">
              24-hour cancellation
            </span>{' '}
            policy has you covered.
          </p>
          <p className="page-description__text">
            Our cleaners are more than just professionals—they genuinely care
            about doing a great job. Fully vetted, insured, and trained,
            they&apos;ll treat your home with the utmost care. And if
            you&apos;re not completely satisfied, we&apos;ll make it right,
            guaranteed.
          </p>
          <p className="page-description__text">
            Ready to experience the Crystal Clear difference? Let&apos;s make it
            happen.{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning
            </Link>{' '}
            today-either online or by calling{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>
            . We can&apos;t wait to help make your North York home shine.
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
