import { v4 as uuid } from 'uuid';
import { Link } from 'react-router-dom';
import { AccordionDataType } from '../type/AccordionDataType';
import { companyInfo, paths } from '../../../constants';
import { getFormattedPhoneNumber } from '../../../helpers';
import { notIncluded } from './AccordionData';

const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);

export const AccordionDataNewmarket: AccordionDataType[] = [
  {
    id: uuid(),
    title: 'Which areas do you serve?',
    content: (
      <div>
        <p>
          Wondering where we provide cleaning services in Newmarket? Our
          services extend throughout Newmarket,{' '}
          <Link to={`${paths.areas.path}/${paths.areas.children.aurora}`}>
            Aurora
          </Link>
          ,{' '}
          <Link to={`${paths.areas.path}/${paths.areas.children.vaughan}`}>
            Vaughan
          </Link>{' '}
          and other parts of York Region. Check our{' '}
          <Link to={paths.areas.path}>Areas</Link> page for a complete list.
        </p>
      </div>
    ),
  },
  {
    id: uuid(),
    title: 'What determines the cost of cleaning services in Newmarket?',
    content: (
      <p>
        The cost of a thorough cleaning depends on various factors such as the
        size of your home, the number of bedrooms and bathrooms, and whether you
        require a cleaning package or hourly services. Other considerations also
        include whether your home has recently undergone renovations, the
        frequency of{' '}
        <Link
          to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
        >
          deep cleaning
        </Link>
        , or if you require a general cleaning, all of which play a role in
        determining the cost.{' '}
        <Link
          to={`${paths.services.path}/${paths.services.children.postRenovationCleaning}`}
        >
          Post-construction cleaning
        </Link>
        , for example, involves detailed work to remove micro-dust accumulated
        throughout the home, impacting the pricing. Deep cleanings delve deeper
        than surface-level cleaning, taking longer and thus costing more.
      </p>
    ),
  },
  {
    id: uuid(),
    title: "What's included in a deep cleaning service?",
    content: (
      <p>
        Our deep cleaning service in Newmarket is tailored to your specific
        needs. On top of the standard cleaning checklist, our deep cleaning
        involves extra attention to detail, including cleaning baseboards, spot
        cleaning walls, window sills, window tracks, window frames, door frames,
        and more. We also clean behind and inside appliances if they’re pulled
        out before our arrival. Please visit our{' '}
        <Link
          to={`${paths.services.path}/${paths.services.children.checklist}`}
        >
          cleaning checklist page
        </Link>{' '}
        for a full breakdown of the{' '}
        <Link
          to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
        >
          deep cleaning
        </Link>{' '}
        checklist. Other areas of focus include windows, wall wiping, and
        baseboard cleaning.
      </p>
    ),
  },
  {
    id: uuid(),
    title: 'How much to expect to pay for Cleaning Services in Newmarket?',
    content: (
      <p>
        We offer both house cleaning packages and hourly cleaning services in
        Newmarket. Our starting price for a general cleaning package is $179,
        while{' '}
        <Link
          to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
        >
          deep cleaning
        </Link>{' '}
        range from $249 to $999, depending on your home’s size and condition.{' '}
        <Link
          to={`${paths.services.path}/${paths.services.children.postRenovationCleaning}`}
        >
          Post-renovation
        </Link>{' '}
        cleanings typically fall between $449 and $1,000, with recurring
        cleaning services offering discounts for multiple visits per week,
        bi-weekly, or monthly schedules. Our hourly rate for cleaning in
        Newmarket is $49 per hour. Please see our{' '}
        <Link to={paths.book}>booking page</Link> to learn more.
      </p>
    ),
  },
  {
    id: uuid(),
    title: 'How can I book a Maid Service In Newmarket?',
    content: (
      <p>
        Booking your maid service in Newmarket is a breeze! Use our{' '}
        <Link to={paths.book}>online quote and booking page</Link> to schedule a
        cleaning date and time based on our availability. If your preferred date
        isn’t available online, feel free to call us to check for additional
        booking spots. You can call us at{' '}
        <Link className="page-description__link" to={`tel:${formattedPhone}`}>
          {companyInfo.phoneNumber}
        </Link>
        .
      </p>
    ),
  },
  {
    id: uuid(),
    title: 'Are Our Cleaners in Newmarket Insured and Certified?',
    content: (
      <p>
        Yes, our maid service professionals in Newmarket are insured. Before
        entering clients’ homes, all our cleaners undergo background checks and
        rigorous training programs to provide top-notch service.
      </p>
    ),
  },
  {
    id: uuid(),
    title: 'Do We Provide Cleaning Tools and Supplies in Newmarket?',
    content: (
      <p>
        Our cleaners bring mops and all necessary{' '}
        <Link to={paths.tools}>cleaning tools and supplies</Link> to your home.
        If you prefer cleaners to use your own tools and/or specific cleaning
        products, you can let us know.
      </p>
    ),
  },
  {
    id: uuid(),
    title: "What if I'm not happy with the cleaning service?",
    content: (
      <p>
        Your satisfaction is our priority. We conduct before-and-after
        walkthroughs with the cleaner to ensure quality, and you rate your
        cleaning as soon as it’s complete. If you’re not satisfied, we will work
        to make it right immediately.
      </p>
    ),
  },
  {
    id: uuid(),
    title: "What's your Cancellation Policy?",
    content: (
      <p>
        Flexibility is key. You can <span>cancel free of charge 24 hours</span>{' '}
        before your appointment. We understand that plans can change, and we’re
        here to accommodate your needs.
      </p>
    ),
  },
  {
    id: uuid(),
    title: 'What is not Included in the Cleaning Service?',
    content: (
      <div>
        <p>
          We do our best to offer as many services as possible; however, there
          are a few things we are unable to do for safety or logistical reasons.
          We are unable to clean the following:
        </p>
        <ul>
          {notIncluded.map((list) => (
            <li key={list}>{list}</li>
          ))}
        </ul>
      </div>
    ),
  },
];
