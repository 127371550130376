import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const MarkhamPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Your Premier Cleaning Service in Markham"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            At Crystal Clear Cleaning Services, we believe a clean space is the
            foundation of a happy, healthy home or business. We’re excited to
            bring our expertise to the vibrant community of Markham. Whether you
            live in{' '}
            <span className="page-description__text_bold">Unionville</span>,{' '}
            <span className="page-description__text_bold">Milliken</span>, or{' '}
            <span className="page-description__text_bold">Cornell</span>, our
            dedicated team is ready to make your space shine. We also proudly
            serve{' '}
            <span className="page-description__text_bold">Greensborough</span>,{' '}
            <span className="page-description__text_bold">Cachet</span>, and{' '}
            <span className="page-description__text_bold">Wismer Commons</span>,
            ensuring every corner of Markham receives the care it deserves.
          </p>
          <p className="page-description__text">
            Life in Markham can be busy, so we make it easy to customize your
            cleaning plan. Whether you need a one-time{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep clean
            </Link>{' '}
            or regular upkeep, we’ve got you covered. Booking is a breeze with
            our simple online system. Plus, our{' '}
            <span className="page-description__text_bold">
              flexible 24-hour cancellation policy
            </span>{' '}
            gives you peace of mind.
          </p>
          <p className="page-description__text">
            Our fully vetted, insured, and highly trained professionals treat
            your space with respect and care. We stand by our work with a
            satisfaction guarantee—if you’re not happy, we’ll make it right.
          </p>
          <p className="page-description__text">
            Ready to experience the Crystal Clear difference?{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning service today online
            </Link>{' '}
            or call{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>
            .
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
