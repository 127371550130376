import { useRef } from 'react';
import { breakpoints } from '../../constants';
import { twoButtonsData } from '../../constants/buttonSectionData';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { ActionButtons } from '../../modules/ActionButtons';
import { CleaningServicesText } from '../../modules/CleaningServicesText';
import { CleaningZones } from '../../modules/CleaningZones';
import { FrequentlyQuestions } from '../../modules/FrequentlyQuestions';
import { InSixtySeconds } from '../../modules/InSixtySeconds';
import { OurCleaningServices } from '../../modules/OurCleaningServices';
import { Reviews } from '../../modules/Reviews';
import { ServiceExclusions } from '../../modules/ServiceExclusions';
import { OurStuff } from '../../modules/OurStuff';
import { TrustedChoice } from '../../modules/TrustedChoice';
import { ValuesCleaning } from '../../modules/ValuesCleaning';
import { MainWrapper as Main } from '../../ui/MainWrapper';
import { ProfessionalCommercialCleaning } from '../../modules/ProfessionalCommercialCleaning';
import { CommercialForm } from '../../modules/CommercialForm';
import { CleaningZonesCommercialData } from '../../modules/CleaningZones/constants/CleaningZonesCommercialData';
import { CommercialCleaningTitle } from '../../modules/CommercialCleaningTitle';
import { GetQuote } from '../../modules/GetQuote';
import { AccordionData } from '../../modules/FrequentlyQuestions/constants/AccordionData';
import { DynamicBrowserHeaderTitle } from '../../modules/DynamicBrowserHeaderTitle';

export const CommercialCleaningPage = () => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= breakpoints.tablet;
  const formRef = useRef<HTMLElement>(null);

  return (
    <Main>
      <DynamicBrowserHeaderTitle page="commercialCleaningService" />
      <CommercialCleaningTitle />
      <CommercialForm formRef={formRef} />
      <ProfessionalCommercialCleaning />
      <TrustedChoice />
      <CleaningZones
        content={CleaningZonesCommercialData}
        isShowDesc={false}
        title="Our Commercial Cleaning Services Checklist"
      />
      <ActionButtons hasBackground data={twoButtonsData} />
      <OurStuff hasOnlyOneSection />
      <ActionButtons hasBackground data={twoButtonsData} />
      {!isMobile && <CleaningServicesText />}
      <OurCleaningServices />
      <ServiceExclusions />
      <ActionButtons hasBackground data={twoButtonsData} />
      <InSixtySeconds />
      <ValuesCleaning />
      <ActionButtons hasBackground data={twoButtonsData} />
      <FrequentlyQuestions questions={AccordionData} />
      <Reviews title="Reviews of our Cleaning Services" maxItems={2} swiper />
      <GetQuote
        title="Get a Quote for our Commercial Cleaning Services!"
        isTwoButtons={false}
        formRef={formRef}
      />
    </Main>
  );
};
