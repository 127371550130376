/* eslint-disable no-continue */
import { v4 as uuidv4 } from 'uuid';
import { getTimeOptionType } from '../types/getTimeOptionType';

export const getTimeOption = (
  time: string[],
  date: Date
): getTimeOptionType[] => {
  const days: getTimeOptionType[] = [];
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  for (let i = -4; i <= 4; i++) {
    const adjustedDate = new Date(date);
    adjustedDate.setDate(date.getDate() + i);

    if (adjustedDate < today) continue;

    const formattedDate = adjustedDate.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'long',
      day: 'numeric',
    });

    const timeOption = {
      date: `${formattedDate}th`,
      time,
      id: uuidv4(),
    };

    days.push(timeOption);
  }

  return days;
};
