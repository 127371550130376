import * as Yup from 'yup';
import { phoneRegExp } from './constants/phoneRegExp';
import { nameRegExp } from './constants/nameRegExp';
import { checkPhoneDigits } from './helpers/checkPhoneDigits';
import { subjectsFormData } from '../components/FormContactUs/constants/subjectsFormData';

export const schemaFormContacUs = Yup.object().shape({
  name: Yup.string()
    .required('Please write your name')
    .matches(nameRegExp, 'To indicate only Latin letters')
    .min(2, 'This field must contain at least 2 characters.')
    .max(25, 'This field must be no more than 15 characters long.'),
  email: Yup.string()
    .email('Please write your email')
    .required('Please write your email'),
  phone: Yup.string()
    .matches(phoneRegExp, 'The phone must match the type: 123 456 7890')
    .test('Phone number must contain exactly 10 digits', checkPhoneDigits)
    .required('Please write your phone number'),
  subject: Yup.string()
    .required('Please choose a valid option')
    .test(
      'invalid-subject',
      'Please choose a valid option',
      (value) => value !== subjectsFormData[0]
    ),
  additionalMessage: Yup.string().optional(),
});
