import { CreatePricingParametersDataEntity } from '../types/CreatePricingParametersDataEntity';

export const pricingParametersNames: CreatePricingParametersDataEntity[] = [
  {
    newName: 'Washroom',
    oldName: 'Bathroom',
  },
  {
    newName: 'Half Wash',
    oldName: 'Half Baths',
  },
];

export const hourlyPricingParametersNames: CreatePricingParametersDataEntity[] =
  [
    {
      newName: 'Cleaner',
      id: 10001,
    },
    {
      newName: 'Hour',
      id: 10002,
    },
  ];
