import axios from 'axios';

export const handleVerifyCaptcha = async (token: string) => {
  const SITE_KEY =
    process.env.GOOGLE_CAPTCHA_SITE_KEY ||
    '6LfRK3AqAAAAAA-jb33vasHKN6FmtTM_f1x4Uduj';
  const POST_LINK =
    process.env.GOOGLE_CAPTCHA_SITE_URL ||
    'https://recaptchaenterprise.googleapis.com/v1/projects/crystalclear-1730083823284/assessments?key=AIzaSyASBpsZij9tj1THVob_unYECB8hHss3c1s';

  const requestBody = {
    event: {
      token,
      expectedAction: 'LOGIN',
      siteKey: SITE_KEY,
    },
  };

  try {
    await axios.post(POST_LINK, requestBody);
  } catch (error) {
    console.error('Error during reCAPTCHA validation:', error);
  }
};
