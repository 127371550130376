import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';

export const DependableFriendlyNewmarket = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Dependable and Friendly Home Cleaning Services in Newmarket" />
          <p className="text__desc">
            At Crystal Clear, we believe that happiness is the cornerstone of
            our success. By fostering a positive and supportive environment for
            our cleaners, we ensure that their happiness translates into
            exceptional service for our clients. Serving the vibrant community
            of Newmarket, Crystal Clear offers top-quality cleaning services
            that cater to all your needs, whether it’s a thorough deep cleaning
            or regular recurring services to keep your home sparkling. Our
            dedicated team is committed to bringing a smile to your face with
            every visit. Experience the joy and peace of mind that comes with a
            spotless home, all thanks to Crystal Clear’s exceptional cleaning
            services in Newmarket. Your satisfaction is our mission, and we’re
            here to add a little extra happiness to your everyday life, one
            pristine home at a time.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
