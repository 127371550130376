import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { ReviewsPageDataType } from '../../ReviewsPageClientsSay/types/ReviewsPageDataType';
import { ReviewsPageItem } from '../../ReviewsPageClientsSay/components/ReviewsPageItem';

import 'swiper/css';
import 'swiper/css/pagination';

interface ReviewsCleaningSwiperProps {
  content: ReviewsPageDataType[];
}

export const ReviewsCleaningSwiper: React.FC<ReviewsCleaningSwiperProps> = ({
  content,
}) => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination]}
      className="reviews-cleaning-swiper"
    >
      {content.map((data) => {
        return (
          <SwiperSlide key={data.name}>
            <ReviewsPageItem content={data} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
