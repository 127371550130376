import { ContainerComponent } from '../../ui/ContainerComponent';
import { BeforeAfterSwiper } from './components/BeforeAfterSwiper';
import { Title } from '../../ui/MainTitle';

import './styles/BeforeAfterSection.scss';

export const BeforeAfterSection = () => {
  return (
    <section className="before-after">
      <ContainerComponent>
        <div className="before-after__container">
          <Title text="Before and After Scenes of our Work!" type="h2" />
          <BeforeAfterSwiper />
        </div>
      </ContainerComponent>
    </section>
  );
};
