import { GiftCardFormInputDataType } from '../type/GiftCardFormInputDataType';

export const GiftCardFormInputData: GiftCardFormInputDataType[] = [
  {
    type: 'text',
    name: 'to',
  },
  {
    type: 'email',
    name: 'recipientEmail',
  },
  {
    type: 'text',
    name: 'from',
  },
  {
    type: 'email',
    name: 'yourEmail',
  },
];
