import React from 'react';
import { SvgIconCheck } from '../../ui/SvgIcon';

import './styles/GuaranteesItem.scss';
import { GuaranteesColorEntity } from './type/GuaranteesColorEntity';

interface GuaranteesItemProps {
  text: string;
  color?: GuaranteesColorEntity;
}

export const GuaranteesItem: React.FC<GuaranteesItemProps> = ({
  text,
  color = 'black',
}) => {
  return (
    <p className={`guarantees-item guarantees-item_${color}`}>
      <span className="guarantees-item__svg">
        <SvgIconCheck />
      </span>
      {text}
    </p>
  );
};
