import { getNumberSequence } from '../../../components/FormMyPrice/helpers/getNumberSequence';
import { CommercialSelectType } from '../type/CommercialSelectType';

const kitchennetesEnv = 6;
const washroomsEnv = 8;

const step = 1;

const amountKitchennetes: number[] = getNumberSequence(kitchennetesEnv, step);
const amountWashrooms: number[] = getNumberSequence(washroomsEnv, step);

amountKitchennetes.unshift(0);
amountWashrooms.unshift(0);

export const CommercialSelectData: CommercialSelectType[] = [
  {
    label: 'Number of Kitchennetes',
    type: 'kitchennetes',
    options: amountKitchennetes,
  },
  {
    label: 'No. of Washrooms',
    type: 'washrooms',
    options: amountWashrooms,
  },
];
