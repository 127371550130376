import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const RichmondHillPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Your Premier Cleaning Service in Richmond Hill"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            At Crystal Clear Cleaning Services, we believe a clean space is the
            cornerstone of a happy home or productive workplace. We’re excited
            to bring our top-tier cleaning services to the vibrant community of
            Richmond Hill. Whether you’re in the peaceful streets of{' '}
            <span className="page-description__text_bold">Mill Pond</span>,
            enjoying the views of{' '}
            <span className="page-description__text_bold">Oak Ridges</span>, or
            living in the family-friendly{' '}
            <span className="page-description__text_bold">Jefferson</span>{' '}
            neighborhood, our dedicated team is here to make your space sparkle.
            We also proudly serve{' '}
            <span className="page-description__text_bold">Bayview Hill</span>,{' '}
            <span className="page-description__text_bold">Richvale</span>, and{' '}
            <span className="page-description__text_bold">Westbrook</span>,{' '}
            giving every corner of Richmond Hill the care it deserves.
          </p>
          <p className="page-description__text">
            Life in Richmond Hill is busy, so your cleaning service should be
            flexible. At Crystal Clear, we make it easy to customize your
            cleaning plan around your schedule. Need a{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              one-time deep clean
            </Link>{' '}
            or regular upkeep? We’ve got you covered. Booking is simple with our
            online system, and our{' '}
            <span className="page-description__text_bold">
              24-hour cancellation
            </span>{' '}
            policy ensures no stress if plans change.
          </p>
          <p className="page-description__text">
            Our cleaning team is more than just professional—they care about
            doing the job well. Fully vetted, insured, and trained, they’ll
            treat your home or office with the utmost respect. We stand by our
            work with a{' '}
            <span className="page-description__text_bold">
              satisfaction guarantee
            </span>
            : if you’re not thrilled, we’ll make it right.
          </p>
          <p className="page-description__text">
            Ready to elevate the cleanliness of your Richmond Hill home or
            business?{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your cleaning services
            </Link>{' '}
            <span className="page-description__text_bold">
              in Richmond Hill today
            </span>{' '}
            online or call{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>{' '}
            . We can’t wait to make your space shine!
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
