export const getFormattedPhoneNumber = (phoneNumber: string) => {
  let formattedPhone = '+';
  for (let i = 0; i < phoneNumber.length; i++) {
    const isWhitespace = phoneNumber[i] === ' ';
    const isNumber = isNaN(Number(phoneNumber[i]));

    if (!isWhitespace && !isNumber) {
      formattedPhone += phoneNumber[i];
    }
  }

  return formattedPhone;
};
