import safePets from '../../../assets/service-inclusions/safe-for-pets.png';
import ecoProducts from '../../../assets/service-inclusions/eco-products.png';
import sustainablePractises from '../../../assets/service-inclusions/sustainable-practises.png';
import customizedPlans from '../../../assets/service-inclusions/customized-plans.png';
import courteousStaff from '../../../assets/service-inclusions/courteous-staff.png';
import allergenControl from '../../../assets/service-inclusions/allergen-control.png';
import { InclusionDataEntity } from '../types/InclusionDataEntity';

export const ecoInclusionsData: InclusionDataEntity[] = [
  {
    text: 'Safe for People and Pets',
    imageSource: safePets,
  },
  {
    text: 'Eco-Friendly Products',
    imageSource: ecoProducts,
  },
  {
    text: 'Sustainable Practises',
    imageSource: sustainablePractises,
  },
  {
    text: 'Customized Plans',
    imageSource: customizedPlans,
  },
  {
    text: 'Courteous Staff',
    imageSource: courteousStaff,
  },
  {
    text: 'Allergen Control',
    imageSource: allergenControl,
  },
];
