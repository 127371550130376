import { Link } from 'react-router-dom';
import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';
import { paths } from '../../../../constants';

export const FriendlyTrustedRichmondHill = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Friendly and Trusted - Home Cleaning Services of Richmond Hill" />
          <p className="text__desc">
            At Crystal Clear, we believe that happiness is at the heart of a
            great cleaning experience. That’s why we make sure our cleaners are
            not only highly skilled but also genuinely happy in their work,
            because a happy team delivers the best results. Whether you’re in
            need of a{' '}
            <Link
              className="page-description__link"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep cleaning
            </Link>{' '}
            to refresh your home or regular recurring services to maintain that
            spotless shine, we’ve got you covered. Living in Richmond Hill, you
            deserve nothing less than top-quality cleaning services Richmond
            Hill residents can rely on. With Crystal Clear, it’s more than just
            cleaning—it’s about creating a space that brings joy and comfort to
            your life. We’re here to ensure your home is a place where happiness
            and cleanliness go hand in hand, one visit at a time.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
