import { v4 as uuid } from 'uuid';
import { CleaningCardDataEntity } from '../types/CleainigToolsCardDataEntity';
import microfibreCloth from '../../../assets/cleaning-tools/microfibre-cloth.jpg';
import scrubber from '../../../assets/cleaning-tools/scrubber.png';
import heavyDutySponge from '../../../assets/cleaning-tools/heavy-duty-sponge.png';
import gloves from '../../../assets/cleaning-tools/gloves.png';
import vacuum from '../../../assets/cleaning-tools/vacuum.png';
import garbageBags from '../../../assets/cleaning-tools/garbage-bags.png';
import disinfectantWipes from '../../../assets/cleaning-tools/disinfectant-wipes.png';
import floorMop from '../../../assets/cleaning-tools/floor-mop.jpg';
import caddy from '../../../assets/cleaning-tools/caddy.jpg';
import extensionCord from '../../../assets/cleaning-tools/extension-cord.jpg';
import ladder from '../../../assets/cleaning-tools/ladder.jpg';
import crocs from '../../../assets/cleaning-tools/crocs.jpg';
import windowCleaner from '../../../assets/cleaning-tools/window-cleaner.png';
import vinegarWaterCleaner from '../../../assets/cleaning-tools/vinegar-water.png';
import multiCleaner from '../../../assets/cleaning-tools/multi-surface-cleaner.png';
import washroomCleaner from '../../../assets/cleaning-tools/washroom-cleaner.png';
import stainlessSteelCleaner from '../../../assets/cleaning-tools/stainless-steal-cleaner.png';
import clr from '../../../assets/cleaning-tools/clr.png';
import greaseCleaner from '../../../assets/cleaning-tools/grease-cleaner.png';

export const CleaningToolsCardData: CleaningCardDataEntity = {
  cleaningTools: [
    {
      id: uuid(),
      title: 'Microfibre cloth',
      description:
        'Tip: color code cloths based on area of use (eg. bathroom: blue, Kitchen: green, living areas: yellow)',
      imageSource: microfibreCloth,
    },
    {
      id: uuid(),
      title: 'Floor mop (with bucket)',
      description: 'Water filled bucket, used with floor cleaner',
      imageSource: floorMop,
    },
    {
      id: uuid(),
      title: 'Vacuum (bagless, with hose)',
      description: 'Empty and clean vacuum after each session',
      imageSource: vacuum,
    },
    {
      id: uuid(),
      title: 'Disinfectant wipes',
      description:
        'Great for cleaning very dirty areas such as window tracks, the toilet lip, and for spot cleaning walls',
      imageSource: disinfectantWipes,
    },
    {
      id: uuid(),
      title: 'Scrubber',
      description:
        'Great for removing tough stains on the tile floor, as well as removing stains from carpet',
      imageSource: scrubber,
    },
    {
      id: uuid(),
      title: 'Heavy duty sponge',
      description:
        'Great for removing tough stains on the tile floor, as well as removing stains from carpet',
      imageSource: heavyDutySponge,
    },
    {
      id: uuid(),
      title: 'Gloves',
      description: 'Please keep 1 or 2 pairs with you on the job',
      imageSource: gloves,
    },
    {
      id: uuid(),
      title: 'Garbage bags',
      description: 'Please keep 1 or 2 with you on the job',
      imageSource: garbageBags,
    },
  ],
  cleaningSolutions: [
    {
      id: uuid(),
      title: 'Vinegar + Water',
      description:
        'Good for steel faucets and sinks, shower faucets and water marks & stains',
      imageSource: vinegarWaterCleaner,
    },
    {
      id: uuid(),
      title: 'Multi-surface cleaner (eco-friendly)',
      description:
        'Good for steel faucets and sinks, shower faucets and water marks & stains',
      imageSource: multiCleaner,
    },
    {
      id: uuid(),
      title: 'Multi-floor cleaner',
      description:
        'Good for steel faucets and sinks, shower faucets and water marks & stains',
      imageSource: greaseCleaner,
    },
    {
      id: uuid(),
      title: 'Window cleaner',
      description:
        'Good for steel faucets and sinks, shower faucets and water marks & stains',
      imageSource: windowCleaner,
    },
    {
      id: uuid(),
      title: 'Bowl cleaner',
      description:
        'Good for steel faucets and sinks, shower faucets and water marks & stains',
      imageSource: clr,
    },
    {
      id: uuid(),
      title: 'Washroom grime cleaner',
      description:
        'Use a scrubbie on water stains for glass shower doors, for messy shower walls or baths. works well for hard water stains ',
      imageSource: washroomCleaner,
    },
    {
      id: uuid(),
      title: 'Grease cleaner',
      description:
        'Good for steel faucets and sinks, shower faucets and water marks & stains',
      imageSource: greaseCleaner,
    },
    {
      id: uuid(),
      title: 'Stainless steel cleaner',
      description:
        'Good for steel faucets and sinks, shower faucets and water marks & stains',
      imageSource: stainlessSteelCleaner,
    },
    {
      id: uuid(),
      title: 'CLR (for tough stains)',
      description:
        'Good for steel faucets and sinks, shower faucets and water marks & stains',
      imageSource: clr,
    },
  ],
  helpfulItems: [
    {
      id: uuid(),
      title: 'Caddy',
      description: 'Great for carrying your cleaning tools and supplies',
      imageSource: caddy,
    },
    {
      id: uuid(),
      title: 'Extension Cord',
      description:
        'Great for extending your vacuum to rooms with no power outlets',
      imageSource: extensionCord,
    },
    {
      id: uuid(),
      title: 'Step ladder',
      description:
        'For hard to reach areas during deep cleans, this is good to keep in your car (just in case)',
      imageSource: ladder,
    },
    {
      id: uuid(),
      title: 'Indoor Shoes/Crocs',
      description: 'Get comfortable on the job and keep your feet dry',
      imageSource: crocs,
    },
  ],
};
