import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { DashboardSectionRow } from './components/DashboardSectionRow';
import { FormDataEntity } from '../../types';
import { useWidthWindow } from '../../hooks/useWidthWindows';
import { BookingContentPanel } from '../BookingForm/components/BookingContentPanel';

import './styles/DashboardSection.scss';

interface DashboardSectionProps {
  setUserName: Dispatch<SetStateAction<string>>;
}

export const DashboardSection: React.FC<DashboardSectionProps> = ({
  setUserName,
}) => {
  const { windowWidth } = useWidthWindow();
  const isMobile = windowWidth <= 800;

  const [cleaningInformation, setCleaningInformation] = useState<
    FormDataEntity[]
  >([]);
  const [openAccord, setOpenAccord] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        // const result = await Api.get('booking/dashboard');
        const result = await axios.get('./dashboard.json');
        const firstNameUser = result.data[0].contactInformation.firstName;
        const lastNameUser = result.data[0].contactInformation.lastName;
        setCleaningInformation(result.data);
        setUserName(`${firstNameUser} ${lastNameUser}`);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const onClick = (idAccord: string) => {
    if (openAccord === idAccord) {
      setOpenAccord('');
    } else {
      setOpenAccord(idAccord);
    }
  };

  return (
    <section className="dashboard-section">
      <ContainerComponent>
        <div className="dashboard-section__container">
          <div className="dashboard-section__wrapper">
            <div className="dashboard-section__header">
              {!isMobile && (
                <div className="dashboard-section__column">№ Number</div>
              )}
              <div className="dashboard-section__column">Address</div>
              {!isMobile && (
                <div className="dashboard-section__column">Sum</div>
              )}
              <div className="dashboard-section__column">Day</div>
            </div>
            <div className="dashboard-section__content">
              {cleaningInformation.map((item, index) => {
                return (
                  <DashboardSectionRow
                    key={item.cleaningInformation?.id}
                    content={item}
                    index={index}
                    onClick={onClick}
                    selected={openAccord === String(index)}
                  />
                );
              })}
            </div>
          </div>
          <div className="dashboard-section__panel">
            <BookingContentPanel />
          </div>
        </div>
      </ContainerComponent>
    </section>
  );
};
