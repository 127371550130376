import { ContainerComponent } from '../../ui/ContainerComponent';
import { ReviewsImages } from '../Reviews/components/ReviewsVideoWrapper/constants/Images';
import { ReviewsPageVideoContent } from './styles/components/ReviewsPageVideoContent';

import './styles/ReviewsPageVideo.scss';

export const ReviewsPageVideo = () => {
  return (
    <section className="reviews-page-video">
      <ContainerComponent>
        <div className="reviews-page-video__wrapper">
          <p className="reviews-page-video__text">
            Discover why Crystal Clear is the trusted choice for cleaning
            services in Ontario! Our reviews page highlights honest feedback
            from satisfied clients, showcasing our dedication to excellence and
            reliability. See real testimonials that reveal why homes shine
            brighter with Crystal Clear&lsquo;s professional touch.
          </p>
          <ReviewsPageVideoContent
            title="Client Reviews"
            content={ReviewsImages}
          />
          <ReviewsPageVideoContent
            title="Cleaner Reviews"
            content={ReviewsImages}
          />
        </div>
      </ContainerComponent>
    </section>
  );
};
