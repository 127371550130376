import { Link } from 'react-router-dom';
import { ContainerComponent } from '../../ui/ContainerComponent';
import { Title } from '../../ui/MainTitle';
import { companyInfo, paths } from '../../constants';
import { getFormattedPhoneNumber } from '../../helpers';
import './styles/PageDescription.scss';

export const BramptonPageDescription = () => {
  const formattedPhone = getFormattedPhoneNumber(companyInfo.phoneNumber);
  return (
    <section className="page-description">
      <ContainerComponent>
        <div className="page-description__wrapper">
          <Title
            text="Reliable Cleaning Services of Brampton"
            type="h2"
            color="black"
          />
          <p className="page-description__text">
            Meet Crystal Clear – the trusted cleaning service for Brampton,
            dedicated to serving homeowners, landlords, and business owners
            throughout the city. Whether you’re in{' '}
            <span className="page-description__text_bold">
              Downtown Brampton, Mount Pleasant, Bramalea, Heart Lake,
            </span>{' '}
            or other welcoming neighborhoods like{' '}
            <span className="page-description__text_bold">
              Springdale, Fletcher’s Meadow, Gore Meadows, Vales of Castlemore,
              Credit Valley, Madoc, Peel Village, Churchville, Southgate,
              Claireville, Northwood Park,
            </span>
            , or{' '}
            <span className="page-description__text_bold">Huttonville</span>,
            Crystal Clear is ready to make your space shine.
          </p>
          <p className="page-description__text">
            At Crystal Clear, customizing your cleaning plan to suit your needs
            couldn’t be easier. With our{' '}
            <span className="page-description__text_bold">
              simple online booking system
            </span>
            , you can schedule a cleaning service at a time that works best for
            you. Plus, with our{' '}
            <span className="page-description__text_bold">
              flexible 24-hour cancellation policy
            </span>{' '}
            , you have the freedom to adjust your plans without hassle.
          </p>
          <p className="page-description__text">
            Our team is composed of highly skilled professionals who are fully
            insured and thoroughly vetted, giving you peace of mind every step
            of the way. We’re committed to your satisfaction—if something isn’t
            right, we’ll return and clean until it is.
          </p>
          <p className="page-description__text">
            Transform your home or business environment with Crystal Clear’s
            exceptional cleaning services Brampton.{' '}
            <Link className="page-description__link" to={paths.book}>
              Book your service online
            </Link>{' '}
            today, or give us a call at{' '}
            <Link
              className="page-description__link"
              to={`tel:${formattedPhone}`}
            >
              {companyInfo.phoneNumber}
            </Link>
            !
          </p>
        </div>
      </ContainerComponent>
    </section>
  );
};
