import { Link } from 'react-router-dom';
import { paths } from '../../../../constants';
import { TitleItem } from '../ui/TitleItem';
import proffesionalCleaning from '../../../../assets/three-sections-of-stuff/professional-and-friendly-cleaning-services-by-crystal-clear.jpg';
import { getAltTextForImage } from '../../../../helpers';

export const HappinessQuality = () => {
  const altText = getAltTextForImage(proffesionalCleaning);

  return (
    <div className="three-sections">
      <div className="three-sections__wrapper">
        <div className="three-sections__text">
          <TitleItem title="Professional, Friendly, and Insured Cleaning Services" />
          <p className="text__desc">
            At Crystal Clear, happiness is at the core of everything we do—for
            both our clients and our cleaners. We ensure our team is not only
            skilled but genuinely happy, because a positive attitude leads to
            exceptional service. Whether you need a{' '}
            <Link
              className="three-sections__link text__desc_bold"
              to={`${paths.services.path}/${paths.services.children.deepCleaning}`}
            >
              deep clean
            </Link>{' '}
            to rejuvenate your space or recurring services to keep your home
            spotless, our commitment to quality is evident in every visit.
            Living in Scarborough, you deserve the best, and our cleaning
            services Scarborough are designed to bring more happiness into your
            life. With Crystal Clear, it’s about creating a welcoming
            environment, cared for by a team that truly values your
            satisfaction. A clean home is a happy home, and we’re here to make
            that happen, one visit at a time.
          </p>
        </div>
        <div className="three-sections__img">
          <img alt={altText} src={proffesionalCleaning} />
        </div>
      </div>
    </div>
  );
};
